import { deleteTagApi, updateTagApi } from "../api/TagApi";

export const tagsSlice = (set, get) => ({
  tags: [],
  initializeTags: (data) => set({ tags: data }),
  deleteTag: (uuid) => {
    let tags = get().tags;
    set({ tags: tags.filter((tag) => tag.uuid !== uuid) });
    get().deleteTagInImages(uuid);
    get().deleteTagInRecipeTemplates(uuid);
    deleteTagApi({ uuid });
  },
  deleteTagInImages: (uuid) => {
    let images = get().images;
    let new_images = images.map((img) => {
      return { ...img, tags: img.tags.filter((tag) => tag.uuid !== uuid) };
    });
    set({ images: new_images });
  },
  deleteTagInRecipeTemplates: (uuid) => {
    let recipe_templates = get().event_items;
    let new_recipe_templates = recipe_templates.map((template) => {
      return {
        ...template,
        tags: template.tags.filter((tag) => tag.uuid !== uuid),
      };
    });
    set({ event_items: new_recipe_templates });
  },
  updateTag: (data) => {
    let tags = get().tags;
    let tag = tags.find((tag) => tag.uuid === data.uuid);
    const new_tags = [
      ...tags.filter((tag) => tag.uuid !== data.uuid),
      { ...tag, ...data },
    ];
    set({ tags: new_tags });
    get().updateTagInImages(data);
    get().updateTagInRecipeTemplates(data);
    updateTagApi(data);
  },
  updateTagInImages: (data) => {
    let images = get().images;
    let new_images = images.map((img) => {
      if (img.tags.find((tag) => tag.uuid === data.uuid)) {
        let new_tags = img.tags.map((tag) => {
          if (tag.uuid === data.uuid) {
            return { ...tag, ...data };
          }
          return tag;
        });
        return { ...img, tags: new_tags };
      }
      return img;
    });
    set({ images: new_images });
  },
  updateTagInRecipeTemplates: (data) => {
    let recipe_templates = get().event_items;
    let new_recipe_templates = recipe_templates.map((template) => {
      if (template.tags.find((tag) => tag.uuid === data.uuid)) {
        let new_tags = template.tags.map((tag) => {
          if (tag.uuid === data.uuid) {
            return { ...tag, ...data };
          }
          return tag;
        });
        return { ...template, tags: new_tags };
      }
      return template;
    });
    set({ event_items: new_recipe_templates });
  },
});
