import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Step,
  StepButton,
  Stepper,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CroppedImg from "./CroppedImg";
import { eventItemTypeName } from "../utils/event_items_utils";
import { getColorNameByCode, itemDisplayName } from "../utils/item_utils";
import { matchSorter } from "match-sorter";
import { CreateEventItemTemplateDialog } from "./EventItem";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { getTagsApi } from "../api/TagApi";
import { useAccountState } from "../state/store";
import { getRecipeTemplatesApi } from "../api/EventsApi";
import { set, template } from "lodash";
import { Check } from "@mui/icons-material";
import { sort_by_relevance } from "../utils/utils";
import ColorIcon from "./ColorIcon";
import { TagFilterAndSearch } from "./Tags";

const RecipeTemplates = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [addEventItem, event_items, getEventItemByUuid] = useAccountState(
    (state) => [state.addEventItem, state.event_items, state.getEventItemByUuid]
  );

  const [loading, setLoading] = React.useState(props.selector ? true : false);
  const [templates, setTemplates] = React.useState(
    props.selector ? [] : event_items
  );

  React.useEffect(() => {
    if (!props.selector) {
      setTemplates(event_items);
    }
  }, [event_items]);

  React.useEffect(() => {
    if (props.selector) {
      getRecipeTemplatesApi().then((resp) => {
        setTemplates(resp.data);
        setLoading(false);
      });
    }
  }, []);

  const [loadingNewTemplate, setLoadingNewTemplate] = React.useState(false);
  const [adding, setAdding] = React.useState(false);
  const [recipeTemplate, setRecipeTemplate] = React.useState(null);

  React.useEffect(() => {
    if (recipeTemplate) {
      let event_item = getEventItemByUuid(recipeTemplate.uuid);
      setRecipeTemplate(event_item);
    }
  }, [templates]);

  const toggleAdding = () => {
    setAdding(!adding);
    if (!adding) {
      setRecipeTemplate(null);
    }
  };

  const handleAddTemplate = (type, is_percent) => {
    setLoadingNewTemplate(true);
    addEventItem(
      {
        event_item_type: type,
        is_template: true,
        is_percent: is_percent ? true : false,
      },
      (ei) => {
        setRecipeTemplate(ei);
        setAdding(true);
        setLoadingNewTemplate(false);
      }
    );
  };
  const [filteredTemplates, setFilteredTemplates] = React.useState(templates);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.selector ? 5 : 10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleTemplates = React.useMemo(
    () =>
      filteredTemplates.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, filteredTemplates]
  );

  const staticFilters = () => {
    let filters = [];
    if (props.noGroups) {
      filters.push((template) => template.event_item_type !== "GR");
    }
    if (props.noPercentCosts) {
      filters.push(
        (template) => template.event_item_type !== "OT" || !template.is_percent
      );
    }
    return filters;
  };

  return (
    <Grid container spacing={1} justifyContent={"center"}>
      {loading ? (
        <Grid item xs={"auto"}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {!props.selector && (
            <Grid
              item
              xs={12}
              container
              spacing={1}
              justifyContent={"flex-end"}
            >
              {loadingNewTemplate && (
                <Grid item xs="auto">
                  <CircularProgress />
                </Grid>
              )}
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => handleAddTemplate("RE")}
                  id="new-template-button"
                  disabled={loadingNewTemplate}
                >
                  <AddOutlinedIcon />
                  Recipe
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => handleAddTemplate("OT")}
                  id="new-template-button"
                  disabled={loadingNewTemplate}
                >
                  <AddOutlinedIcon />
                  Fixed
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => handleAddTemplate("OT", true)}
                  id="new-template-button"
                  disabled={loadingNewTemplate}
                >
                  <AddOutlinedIcon />
                  Percent
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => handleAddTemplate("GR")}
                  id="new-template-button"
                  disabled={loadingNewTemplate}
                >
                  <AddOutlinedIcon />
                  Group
                </Button>
              </Grid>
              {adding && (
                <CreateEventItemTemplateDialog
                  open={adding}
                  handleCancel={toggleAdding}
                  event_item={recipeTemplate}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <TagFilterAndSearch
              list={templates}
              setFilteredList={setFilteredTemplates}
              searchKeys={["template_name", "name"]}
              staticFilters={staticFilters()}
            />
          </Grid>
          <Grid item xs={12}>
            {visibleTemplates.length > 0 ? (
              <Grid container spacing={1}>
                {visibleTemplates.map((template) => (
                  <Grid item xs={12} key={template.uuid}>
                    <RecipeTemplateRow
                      event_item={template}
                      key={template.uuid}
                      selector={props.selector}
                      toggleSelected={props.toggleSelected}
                      selected={props.selected}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box
                sx={{
                  p: "1rem",
                  borderStyle: "dashed",
                  borderRadius: "5px",
                  borderColor: "lightgray",
                  borderWidth: "2px",
                }}
              >
                <Typography align="center">No templates found</Typography>
              </Box>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredTemplates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const AddTagFilterDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [filterTags, setFilterTags] = React.useState([]);

  const options = () => {
    if (!props.event_items) {
      return [];
    }
    const tag_key = props.colors ? "color" : "name";
    let tags = props.event_items.map((ei) => ei.tags).flat();
    tags = tags.filter((tag) => tag[tag_key]);
    tags = tags.map((tag) => tag[tag_key]);
    tags = tags.filter((t) => !props.filterTags?.includes(t));
    return [...new Set(tags)];
  };

  const handleCancel = () => {
    setFilterTags([]);
    props.handleCancel();
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={handleCancel}>
      <DialogTitle>Add Tag Filter</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: ".5rem", minWidth: fullScreen ? undefined : "300px" }}>
          <Autocomplete
            multiple
            id="tags-filter"
            options={options()}
            filterSelectedOptions
            value={filterTags}
            onChange={(event, value) => setFilterTags(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tags"
                placeholder="Tags"
              />
            )}
            renderTags={
              props.colors
                ? (values, getTagProps) => {
                    return values.map((value, ind) => (
                      <Box
                        key={value}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        {...getTagProps(value)}
                      >
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            mr: ".25rem",
                            backgroundColor: value,
                          }}
                        ></Box>
                        <Typography>{getColorNameByCode(value)}</Typography>
                      </Box>
                    ));
                  }
                : undefined
            }
            renderOption={
              props.colors
                ? (props, option) => {
                    return [
                      null,
                      <Box
                        key={option}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        {...props}
                      >
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            mr: ".25rem",
                            backgroundColor: option,
                          }}
                        ></Box>
                        <Typography>{getColorNameByCode(option)}</Typography>
                      </Box>,
                    ];
                  }
                : undefined
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.handleSubmit(filterTags);
            handleCancel();
          }}
          color="secondary"
          variant="contained"
          disabled={filterTags.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RecipeTemplateRow = (props) => {
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const imageSize = () => {
    if (props.selector) {
      return "80px";
    } else {
      return "120px";
    }
  };

  const [selected, setSelected] = React.useState(
    props.selected?.includes(props.event_item.uuid)
  );

  React.useEffect(() => {
    setSelected(props.selected?.includes(props.event_item.uuid));
  }, [props.selected]);

  const handleSelect = (event) => {
    setSelected(event.target.checked);
    props.toggleSelected(props.event_item.uuid);
  };

  const detailTextSize = () => {
    if (props.selector) {
      return ".55rem";
    } else {
      return ".75rem";
    }
  };

  return (
    <Box>
      <Grid container spacing={2} justifyContent={"flex-start"}>
        <Grid item xs={"auto"} container alignItems={"center"}>
          {props.selector && (
            <Grid item xs={4}>
              <Checkbox
                size="small"
                onChange={handleSelect}
                checked={selected}
                color="secondary"
              />
            </Grid>
          )}
          <Grid item xs={8}>
            {props.event_item.recipe_imgs.length > 0 ? (
              <CroppedImg
                img={props.event_item.recipe_imgs[0].cropped_image}
                key={props.event_item.uuid}
                width={imageSize()}
                height={imageSize()}
                fill
              />
            ) : (
              <Box
                sx={{
                  width: imageSize(),
                  height: imageSize(),
                  borderStyle: "solid",
                  borderColor: "info.main",
                  borderWidth: "1px",
                }}
                display={"flex"}
              >
                <ImageOutlinedIcon color="info" sx={{ m: "auto" }} />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={9}
          container
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <Box>
            <Grid container spacing={0.5} alignItems={"center"}>
              <Grid item xs="auto">
                <Typography
                  variant="caption"
                  textTransform={"uppercase"}
                  sx={{
                    borderColor: "info.light",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    pt: "1px",
                    pb: "1px",
                    pl: "3px",
                    pr: "3px",
                    fontSize: detailTextSize(),
                  }}
                >
                  {eventItemTypeName(props.event_item)}
                </Typography>
              </Grid>
              {props.event_item.tags.filter((t) => t.name).length > 0 && (
                <>
                  {props.event_item.tags
                    .filter((tag) => tag.name)
                    .map((tag, idx, list) => (
                      <Grid item xs="auto">
                        <Typography
                          variant="caption"
                          sx={{
                            borderColor: "info.light",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderRadius: "10px",
                            pt: "1px",
                            pb: "1px",
                            pl: "3px",
                            pr: "3px",
                            fontSize: detailTextSize(),
                          }}
                        >
                          {tag.name}
                        </Typography>
                      </Grid>
                    ))}
                </>
              )}
              {props.event_item.tags.filter((t) => t.color).length > 0 && (
                <>
                  {props.event_item.tags
                    .filter((tag) => tag.color)
                    .map((tag) => (
                      <Grid item xs="auto">
                        <ColorIcon color={tag.color} />
                      </Grid>
                    ))}
                </>
              )}
            </Grid>
            <Typography
              sx={{
                "&:hover": { cursor: "pointer", textDecoration: "underline" },
              }}
              onClick={props.selector ? handleSelect : toggleEditing}
            >
              {props.event_item.template_name}
            </Typography>
            {props.event_item.event_items_in_group.length > 0 && (
              <>
                <Typography
                  variant="caption"
                  sx={{
                    fontStyle: "italic",
                    fontWeight: "bold",
                    fontSize: detailTextSize(),
                    mb: 0,
                  }}
                  paragraph
                >
                  {props.event_item.event_items_in_group
                    .map((ei) => ei.name)
                    .sort()
                    .join(", ")}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    mb: 0,
                    fontSize: detailTextSize(),
                  }}
                  paragraph
                >
                  {[
                    ...new Set(
                      props.event_item.event_items_in_group
                        .map((ei) => ei.recipe_items)
                        .flat()
                        .map((item) => itemDisplayName(item.item))
                        .sort()
                    ),
                  ].join(", ")}
                </Typography>
              </>
            )}
            {props.event_item.recipe_items.length > 0 && (
              <>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: detailTextSize(),
                    mb: 0,
                  }}
                  paragraph
                >
                  {props.event_item.recipe_items
                    .map((item) => itemDisplayName(item.item))
                    .sort()
                    .join(", ")}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      {editing && (
        <CreateEventItemTemplateDialog
          open={editing}
          handleCancel={toggleEditing}
          event_item={props.event_item}
          edit
        />
      )}
    </Box>
  );
};

export const RecipeTemplateSelector = (props) => {
  const [selected, setSelected] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (!props.open) {
      setSubmitting(false);
    }
  }, [props.open]);

  const toggleSelected = (uuid) => {
    if (selected.includes(uuid)) {
      setSelected(selected.filter((s) => s !== uuid));
    } else {
      setSelected([...selected, uuid]);
    }
  };

  const handleSubmit = () => {
    setSubmitting(true);
    props.handleSubmit(selected);
    setSelected([]);
  };

  const handleCancel = () => {
    setSelected([]);
    props.handleCancel();
  };

  return (
    <Dialog open={props.open} onClose={handleCancel}>
      <DialogTitle>Add Templates</DialogTitle>
      <DialogContent>
        <RecipeTemplates
          selector
          selected={selected}
          toggleSelected={toggleSelected}
          noGroups={props.noGroups}
          noPercentCosts={props.noPercentCosts}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid
            item
            xs="auto"
            container
            justifyContent={"flex-start"}
            spacing={2}
          >
            <Grid item xs="auto">
              <Typography color="info.light" sx={{ ml: "2rem" }}>
                {selected.length} Selected
              </Typography>
            </Grid>
            {selected.length > 0 && (
              <Grid item xs="auto">
                <Typography
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                  color="info.light"
                  onClick={() => setSelected([])}
                >
                  Clear Selected
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs="auto"
            container
            justifyContent={"flex-end"}
            spacing={1}
          >
            <Grid item xs="auto">
              <Button onClick={handleCancel} color="info" variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              {submitting ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                  disabled={selected.length === 0}
                >
                  Add
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export const ApplyRecipeTemplateDialog = (props) => {
  const [selected, setSelected] = React.useState([]);

  const toggleSelected = (uuid) => {
    if (selected.includes(uuid)) {
      setSelected([]);
    } else {
      setSelected([uuid]);
    }
  };

  const handleSubmit = () => {
    props.handleSubmit({ ...settings, recipe_template_uuid: selected[0] });
    setSelected([]);
    setActiveStep(0);
  };

  const handleCancel = () => {
    setSelected([]);
    setActiveStep(0);
    props.handleCancel();
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const step_sx = {
    "& .MuiStepLabel-root .Mui-completed": {
      color: "secondary.dark", // circle color (COMPLETED)
    },
    "& .MuiStepLabel-root .Mui-active": {
      color: "secondary.main", // circle color (ACTIVE)
    },
    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
      fill: "white", // circle's number (ACTIVE)
    },
  };

  const [settings, setSettings] = React.useState({});

  const options = [
    {
      id: "price",
      label: "Pricing",
      tooltip: "Overwrite pricing with pricing from template",
    },
    {
      id: "quantity",
      label: "Quantity",
      tooltip: "Overwrite quantity with quantity from template",
    },
    {
      id: "sales_tax",
      label: "Sales Tax",
      tooltip:
        "Overwrite sales tax setting with sales tax setting from template",
    },
    {
      id: "recipe_imgs",
      label: "Inspiration Photos",
      tooltip: "Overwrite inspiration photos with photos from template",
    },
    {
      id: "proposal_notes",
      label: "Proposal Notes",
      tooltip: "Overwrite proposal notes with notes from template",
    },
    {
      id: "florist_notes",
      label: "Florist Notes",
      tooltip: "Overwrite florist notes with notes from template",
    },
  ];

  const handleChangeFunc = (id) => {
    return (event) => {
      setSettings({ ...settings, [id]: event.target.checked });
    };
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Apply Template</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} color="secondary">
          <Step key={0} sx={step_sx}>
            <StepButton>{"Select Template"}</StepButton>
          </Step>
          <Step key={1} sx={step_sx}>
            <StepButton>{"apply template"}</StepButton>
          </Step>
        </Stepper>
        <Box sx={{ pt: "1rem" }}>
          {activeStep === 0 && (
            <RecipeTemplates
              selector
              selected={selected}
              toggleSelected={toggleSelected}
            />
          )}
          {activeStep === 1 && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography>
                  All template flowers, hard goods, labor and sub-items will be
                  applied. Additional data can be optionally applied using the
                  checkboxes below.
                </Typography>
              </Grid>
              {options.map((option) => (
                <Grid item xs={6}>
                  <Tooltip title={option.tooltip}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={settings[option.id]}
                          onChange={handleChangeFunc(option.id)}
                        />
                      }
                      label={option.label}
                    />
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid
            item
            xs="auto"
            container
            justifyContent={"flex-start"}
            spacing={1}
          >
            <Grid item xs="auto">
              <Button onClick={handleCancel} color="info" variant="outlined">
                Cancel
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs="auto"
            container
            justifyContent={"flex-end"}
            spacing={1}
          >
            <Grid item xs="auto">
              <Button
                onClick={() => setActiveStep(0)}
                color="info"
                variant="outlined"
                disabled={activeStep === 0}
              >
                Back
              </Button>
            </Grid>
            {activeStep === 0 && (
              <Grid item xs="auto">
                <Button
                  onClick={() => {
                    setActiveStep(1);
                  }}
                  color="secondary"
                  variant="contained"
                  disabled={selected.length === 0}
                >
                  Next
                </Button>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid item xs="auto">
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                  disabled={selected.length === 0}
                >
                  Apply
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(RecipeTemplates);
