import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  TextField,
  Typography,
  Autocomplete,
  Link,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { sortByName } from "../utils/utils";

import { useFormik } from "formik";
import { addEventItemApi } from "../api/EventsApi";
import { useAccountState } from "../state/store";

const TaxProfileSettings = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [adding, setAdding] = React.useState(false);
  const [addingGroup, setAddingGroup] = React.useState(false);

  const RenderValue = (props) => {
    return props.row.is_percent
      ? props.row.percent.toFixed(2) + "%"
      : formatCurrency(props.row.cost);
  };

  const tax_columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      hideable: false,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
      renderCell: RenderType,
      sortable: false,
      hideable: false,
      hide: fullScreen,
    },
    {
      field: "value",
      headerName: "Value",
      minWidth: 100,
      renderCell: RenderValue,
      sortable: false,
      hideable: false,
      hide: fullScreen,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      renderCell: RenderActions,
      updateTax: props.updateTax,
      deleteTax: props.deleteTax,
      sortable: false,
      hideable: false,
    },
  ];

  const tax_group_columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 3,
      hideable: false,
    },
    {
      field: "taxes",
      headerName: "Taxes",
      flex: 4,
      renderCell: RenderTaxes,
      sortable: false,
      hideable: false,
      hide: fullScreen,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      renderCell: RenderGroupActions,
      updateTaxGroup: props.updateTaxGroup,
      deleteTaxGroup: props.deleteTaxGroup,
      tax_profiles: props.tax_profiles,
      sortable: false,
      hideable: false,
    },
  ];

  const getTaxId = (tax) => {
    return tax.uuid;
  };

  const addTax = (values) => {
    props.addTax(values);
  };

  return (
    <Grid container justifyContent="space-between" spacing={1}>
      <Grid item xs="auto">
        <Typography variant="h6">Taxes</Typography>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setAdding(true)}
          id="add-tax-profile-button"
        >
          Add Tax Profile
        </Button>
        <EditTaxProfileDialog
          open={adding}
          handleCancel={() => {
            setAdding(false);
          }}
          handleSubmit={addTax}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={props.tax_profiles}
          columns={tax_columns}
          getRowId={getTaxId}
          autoHeight
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          // disable
          rowHeight={55}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          sx={{ border: "none" }}
        />
      </Grid>
      <Grid item xs="auto">
        <Typography variant="h6">Tax Groups</Typography>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setAddingGroup(true)}
          id="add-tax-group-button"
        >
          Add Tax Group
        </Button>
        <EditTaxGroupDialog
          open={addingGroup}
          handleCancel={() => {
            setAddingGroup(false);
          }}
          handleSubmit={props.addTaxGroup}
          tax_profiles={props.tax_profiles}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={props.tax_groups}
          columns={tax_group_columns}
          getRowId={getTaxId}
          autoHeight
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          // disable
          rowHeight={55}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          sx={{ border: "none" }}
        />
      </Grid>
    </Grid>
  );
};

const RenderType = (props) => {
  return props.row.is_percent ? "Percent" : "Amount";
};

const RenderTaxes = (props) => {
  let tax_names = props.row.taxes.sort(sortByName).map((tax) => tax.name);
  return tax_names.join(", ");
};

const taxValues = ({ uuid, is_percent, cost, percent, name }) => ({
  uuid,
  is_percent,
  cost,
  percent,
  name,
});

const taxGroupValues = ({ uuid, name }) => ({
  uuid,
  name,
});

const RenderActions = (props) => {
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const updateTax = (values) => {
    const row = taxValues(props.row);
    props.colDef.updateTax({ ...row, ...values });
  };

  return (
    <Box key={props.row.uuid}>
      <Tooltip title="Edit">
        <IconButton
          onClick={() => setEditing(true)}
          id={props.row.uuid + "-edit-tax-profile-button"}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => setDeleting(true)}
          id={props.row.uuid + "-delete-tax-profile-button"}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      <EditTaxProfileDialog
        open={editing}
        handleCancel={() => {
          setEditing(false);
        }}
        handleSubmit={updateTax}
        initialValues={props.row}
      />
      <DeleteTaxDialog
        open={deleting}
        handleCancel={() => {
          setDeleting(false);
        }}
        handleSubmit={() => {
          props.colDef.deleteTax(props.row.uuid);
        }}
        tax={props.row}
      />
    </Box>
  );
};

const RenderGroupActions = (props) => {
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const updateTax = (values) => {
    const row = taxGroupValues(props.row);
    props.colDef.updateTaxGroup({ ...row, ...values });
  };

  return (
    <Box key={props.row.uuid}>
      <Tooltip title="Edit">
        <IconButton
          onClick={() => setEditing(true)}
          id={props.row.uuid + "-edit-tax-group-button"}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => setDeleting(true)}
          id={props.row.uuid + "-delete-tax-group-button"}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      <EditTaxGroupDialog
        open={editing}
        handleCancel={() => {
          setEditing(false);
        }}
        handleSubmit={updateTax}
        initialValues={props.row}
        tax_profiles={props.colDef.tax_profiles}
      />
      <DeleteTaxGroupDialog
        open={deleting}
        handleCancel={() => {
          setDeleting(false);
        }}
        handleSubmit={() => {
          props.colDef.deleteTaxGroup(props.row.uuid);
        }}
        tax_group={props.row}
      />
    </Box>
  );
};

export const EditTaxProfileDialog = (props) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      is_percent: true,
      percent: null,
      cost: null,
      ...taxValues(props.initialValues ? props.initialValues : {}),
    },
    onSubmit: (values, { resetForm }) => {
      props.handleSubmit(values);
      // resetForm();
      props.handleCancel();
    },
  });
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleCancel();
        formik.resetForm();
      }}
    >
      <DialogTitle>
        {props.initialValues ? "Edit Tax" : "Create Tax"}
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={1} sx={{ mt: ".25rem", maxWidth: "300px" }}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id={props.id}>Tax Type</InputLabel>
                <Select
                  id="tax_type"
                  onChange={(e) => {
                    formik.setFieldValue("is_percent", e.target.value);
                  }}
                  label="Tax Type"
                  value={formik.values.is_percent}
                  sx={props.sx}
                >
                  <MenuItem value={false}>Amount</MenuItem>
                  <MenuItem value={true}>Percent</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formik.values.is_percent ? (
              <Grid item xs={12}>
                <TextField
                  id="percent"
                  label="Percent"
                  value={formik.values.percent}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  id="cost"
                  label="Amount"
                  value={formik.values.cost}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
            )}

            {props.initialValues ? (
              <Grid item xs={12}>
                <Typography color="error.main">
                  Note: Updating this tax profile will affect existing events.{" "}
                  <Tooltip title="This update will flow to all events within this calendar year and future years. The update affects the calculated cost of the tax but any contract prices will remain the same. To update a contract tax price, go to the event and create an amendment.">
                    <HelpOutlineOutlinedIcon fontSize="inherit" />
                  </Tooltip>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="Outlined"
          color="info"
          onClick={props.handleCancel}
          id="cancel-tax-profile"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
          id="save-tax-profile"
        >
          {props.initialValues ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditTaxGroupDialog = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  const [creatingTax, setCreatingTax] = React.useState(false);
  const [taxProfiles, setTaxProfiles] = React.useState(props.tax_profiles);

  React.useEffect(() => {
    setTaxProfiles(props.tax_profiles);
  }, [props.tax_profiles]);

  const addTax = (data) => {
    addEventItemApi({ ...data, event_item_type: "TA" }).then((resp) => {
      setTaxProfiles([...taxProfiles, resp.data]);
      formik.setFieldValue("taxes", [...formik.values.taxes, resp.data]);
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      taxes: [],
      ...(props.initialValues ? props.initialValues : {}),
    },
    onSubmit: (values, { resetForm }) => {
      props.handleSubmit(values);
      if (!props.initialValues) {
        resetForm();
      }
      props.handleCancel();
    },
  });
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleCancel();
        formik.resetForm();
      }}
    >
      <DialogTitle>
        {props.initialValues ? "Edit Tax Group" : "Create Tax Group"}
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={1} sx={{ mt: ".25rem", maxWidth: "300px" }}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="taxes"
                multiple
                disableCloseOnSelect
                options={[...taxProfiles].sort(sortByName)}
                renderInput={(params) => (
                  <TextField {...params} label="Taxes" />
                )}
                getOptionLabel={(option) =>
                  `${option.name} (${
                    option.is_percent
                      ? option.percent + "%"
                      : formatCurrency(option.cost)
                  })`
                }
                isOptionEqualToValue={(option, value) =>
                  option.uuid === value.uuid
                }
                value={formik.values.taxes}
                onChange={(e, val) => {
                  formik.setFieldValue("taxes", val);
                }}
                noOptionsText={
                  <Typography>
                    No taxes match your search.{" "}
                    <Link
                      onClick={() => {
                        setCreatingTax(true);
                      }}
                    >
                      Create a new tax?
                    </Link>
                  </Typography>
                }
                sx={{
                  width: "300px",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            </Grid>

            {props.initialValues ? (
              <Grid item xs={12}>
                <Typography color="error.main">
                  Note: Updating this tax group will affect existing events.{" "}
                  <Tooltip title="This update will flow to all events within this calendar year and future years. Adding or removing taxes will add or remove taxes from existing events. For booked events, this will generate unpublished amendments.">
                    <HelpOutlineOutlinedIcon fontSize="inherit" />
                  </Tooltip>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </form>
        <EditTaxProfileDialog
          open={creatingTax}
          handleCancel={() => {
            setCreatingTax(false);
          }}
          handleSubmit={addTax}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="Outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
        >
          {props.initialValues ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteTaxDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Tax Profile</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the tax profile "{props.tax.name}"?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="Outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={props.handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteTaxGroupDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Tax Profile</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the tax group "{props.tax_group.name}
          "? Note, this will only remove the tax group designation from existing
          events. This will not remove the taxes associated with this group from
          any events.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="Outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={props.handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(TaxProfileSettings);
