import React from "react";

import { format } from "date-fns";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, Box, Typography, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getBalanceTransactionsApi } from "../api/StripeApi";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { format_currency, titleCase } from "../utils/utils";
import { useAccountState } from "../state/store";

export const BalanceTransactions = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const page_size_options = [25, 50, 100];
  const [transactions, setTransactions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [startDate] = React.useState(undefined);
  const [endDate] = React.useState(undefined);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(page_size_options[0]);

  const reloadTransactions = () => {
    setIsLoading(true);
    getBalanceTransactionsApi(
      pageSize,
      pageNumber * pageSize,
      startDate,
      endDate,
    ).then((resp) => {
      console.log(resp);
      setTransactions(resp.data.results);
      setTotalPages(resp.data.count);
      setIsLoading(false);
    });
  };

  React.useEffect(() => {
    reloadTransactions(pageSize);
  }, [pageNumber, pageSize]);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      type: "date",

      valueFormatter: formatCreated,
      valueGetter: (params) => new Date(params.row.created),
      sortable: false,
      hideable: false,
      minWidth: 85,
    },
    {
      field: "amount",
      headerName: "Amount",

      valueGetter: (params) => params.row.amount * -1,
      valueFormatter: formatAmount,
      sortable: false,
      hideable: false,
      minWidth: 110,
    },
    {
      field: "balance",
      // headerName: "Amount Paid",
      renderHeader: (params) => (
        <Grid container alignItems="center">
          <Grid item xs="auto">
            <strong>Balance</strong>
          </Grid>

          <Grid item xs="auto">
            <Tooltip title="Balance after transaction">
              <HelpOutlineOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </Grid>
        </Grid>
      ),

      valueGetter: (params) => params.row.ending_balance * -1,
      valueFormatter: formatAmount,
      sortable: false,
      hideable: false,
      hide: fullScreen,
      minWidth: 110,
    },
    {
      field: "description",
      headerName: "Description",

      valueGetter: (params) =>
        params.row.invoice?.description
          ? params.row.invoice.description
          : params.row.description
            ? params.row.description
            : params.row.invoice?.billing_reason
              ? titleCase(params.row.invoice?.billing_reason.replace("_", " "))
              : "",
      sortable: false,
      hideable: false,
      minWidth: 200,
    },
  ];

  const getTransactionId = (transaction) => {
    return transaction.id;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">FlowerBuddy Credit Balance</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        spacing={1}
        alignItems="center"
      >
        <Grid item xs={"auto"}>
          <Typography variant="h6">Current Balance:</Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Box sx={{ border: "solid 1px", borderRadius: "5px", p: ".5rem" }}>
            <Typography
              variant="h6"
              color={
                props.user.stripe_customer.balance * -1 > 0
                  ? "success.main"
                  : null
              }
            >
              {format_currency((props.user.stripe_customer.balance * -1) / 100)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={transactions}
          columns={columns}
          getRowId={getTransactionId}
          autoHeight
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          rowHeight={55}
          density="compact"
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={page_size_options}
          pagination
          paginationMode="server"
          loading={isLoading}
          rowCount={totalPages}
          onPageChange={(newPage) => setPageNumber(newPage)}
          sx={{ border: "none" }}
        />
      </Grid>
    </Grid>
  );
};

const formatCreated = (params) => {
  return format(params.value, "MM/dd/yy");
};

//TODO NEEDS SOME MORE THOUGHT AROUND CURRENCIES
const formatAmount = (params) => {
  return format_currency(params.value);
};
