import {
  addEventLocationApi,
  deleteEventLocationApi,
  updateEventLocationApi,
} from "../api/EventsApi";
import { byUUID } from "../utils/utils";

export const eventLocationsSlice = (set, get) => ({
  event_locations: [],
  addEventLocation: (data) => {
    addEventLocationApi({ ...data, event_uuid: get().event.uuid }).then(
      (resp) => {
        set({ event_locations: [...get().event_locations, resp.data] });
      },
    );
  },
  deleteEventLocation: (uuid) => {
    let event_locations = get().event_locations;
    set({
      event_locations: event_locations.filter(
        (event_location) => event_location.uuid !== uuid,
      ),
    });
    deleteEventLocationApi(uuid).then((resp) => {
      if (!resp.data.success) {
        set({ event_locations: event_locations });
      }
    });
  },
  initializeEventLocations: (data) => set({ event_locations: data }),
  updateEventLocation: (data) => {
    let event_locations = get().event_locations;
    let event_location = event_locations.find(byUUID(data.uuid));
    let index = event_locations.findIndex(byUUID(data.uuid));
    set({
      event_locations: [
        ...event_locations.slice(0, index),
        { ...event_location, ...data },
        ...event_locations.slice(index + 1),
      ],
    });
    updateEventLocationApi(data);
  },
  updateEventLocationState: (data) => {
    let event_locations = get().event_locations;
    let event_location = event_locations.find(byUUID(data.uuid));
    let index = event_locations.findIndex(byUUID(data.uuid));
    set({
      event_locations: [
        ...event_locations.slice(0, index),
        { ...event_location, ...data },
        ...event_locations.slice(index + 1),
      ],
    });
  },
});
