import { Decimal } from "decimal.js";
import _ from "lodash";
import { decimalConfig } from "../utils/utils";
import {
  createInventoryTransactionApi,
  getInventoryTransactionsApi,
} from "../api/InventoryApi";

Decimal.set(decimalConfig);

export const inventoryTransactionsSlice = (set, get) => ({
  inventoryTransactions: [],
  initializeInventoryTransactions: (inventoryTransactions) => {
    set({ inventoryTransactions: inventoryTransactions });
  },
  createInventoryTransaction: (inventoryTransaction, cb) => {
    createInventoryTransactionApi(inventoryTransaction).then((res) => {
      let inventoryTransactions = get().inventoryTransactions;
      let inventoryItems = get().inventoryItems;
      let inventoryItemIndex = _.findIndex(
        inventoryItems,
        (item) => item.uuid === res.data.inventory_item.uuid,
      );
      set({
        inventoryTransactions: [
          ...inventoryTransactions,
          res.data.inventory_transaction,
        ],
        inventoryItems: [
          ...inventoryItems.slice(0, inventoryItemIndex),
          res.data.inventory_item,
          ...inventoryItems.slice(inventoryItemIndex + 1),
        ],
      });
      if (cb) {
        cb();
      }
    });
  },
  getInventoryTransactions: (
    limit,
    offset,
    inventory_item_uuid,
    event_uuid,
    cb,
  ) => {
    getInventoryTransactionsApi(
      limit,
      offset,
      inventory_item_uuid,
      event_uuid,
    ).then((res) => {
      set({ inventoryTransactions: res.data.results });
      if (cb) cb(res.data);
    });
  },
});
