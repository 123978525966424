import { withRouter } from "react-router-dom";

import { Badge, Grid, Box, Tooltip } from "@mui/material";

const StatusIcon = (props) => {
  const Icon = props.icon;
  return (
    <Grid item xs="auto">
      <Badge
        color="error"
        variant="dot"
        overlap="rectangular"
        invisible={!props.alert}
      >
        <Box
          sx={{
            backgroundColor: props.active ? "secondary.main" : "white",
            borderColor: props.active ? "secondary.main" : "info.main",
            border: props.active ? undefined : "solid 1px",
            width: ".9rem",
            height: ".9rem",
            p: ".25rem",
          }}
          borderRadius="50%"
        >
          <Tooltip
            title={
              props.alert && props.active
                ? props.alertTooltip
                : props.active
                ? props.activeTooltip
                : props.inactiveTooltip
            }
          >
            <Icon
              // fontSize={"1rem"}
              sx={{
                color: props.active ? "white" : "info.main",
                fontSize: ".9rem",
              }}
            />
          </Tooltip>
        </Box>
      </Badge>
    </Grid>
  );
};

export default withRouter(StatusIcon);
