import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import CroppedImg from "../CroppedImg";
import { eventDateTime } from "../EventDetails";
import { useAccountState } from "../../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { sort_by_order } from "../../utils/utils";
import { ColorPickerDialog } from "../Proposal";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InspirationImgs from "../InspirationImgs";
import RichTextField, {
  RichTextFieldEditable,
  RichTextFieldView,
} from "../RichTextField";
import { DefaultStyles } from "../../utils/proposal_utils";

export const ImagesV1 = (props) => {
  return (
    <Box sx={{ p: "1rem" }}>
      {props.proposal_section.section_type === "images_v1" && (
        <Images {...props} />
      )}
      {props.proposal_section.section_type === "image_left_with_text" && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Images {...props} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Text {...props} pt="3.5rem" />
          </Grid>
        </Grid>
      )}
      {props.proposal_section.section_type === "image_right_with_text" && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Text {...props} pt="3.5rem" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Images {...props} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const Text = (props) => {
  const [proposal, updateProposalSectionStyle] = useAccountState((state) => [
    state.proposal,
    state.updateProposalSectionStyle,
  ]);
  const save = (value) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      rich_text: value,
    });
  };
  return (
    <Box sx={{ pt: props.pt }}>
      {props.displayOnly ? (
        <RichTextFieldView
          id={props.proposal_section.uuid}
          value={props.proposal_section.style?.rich_text}
          font={props.proposal_section.style?.font || DefaultStyles.fontFamily}
          font_color={
            props.proposal_section.style?.font_color || DefaultStyles.fontColor
          }
          textAlign={
            props.proposal_section.style?.text_align || DefaultStyles.textAlign
          }
          font_size={
            props.proposal_section.style?.font_size || DefaultStyles.fontSize
          }
        />
      ) : (
        <RichTextFieldEditable
          id={props.proposal_section.uuid}
          value={props.proposal_section.style?.rich_text}
          updateValue={save}
          placeholder={"Notes..."}
          font={props.proposal_section.style?.font || DefaultStyles.fontFamily}
          font_color={
            props.proposal_section.style?.font_color || DefaultStyles.fontColor
          }
          textAlign={
            props.proposal_section.style?.text_align || DefaultStyles.textAlign
          }
          font_size={
            props.proposal_section.style?.font_size || DefaultStyles.fontSize
          }
          autofocus
          stacked
        />
      )}
    </Box>
  );
};

const Images = (props) => {
  const [addProposalImg, imgsInEvent, removeProposalImg] = useAccountState(
    (state) => [
      state.addProposalImg,
      state.imgsInEvent,
      state.removeProposalImg,
    ]
  );

  const addImg = (data) => {
    addProposalImg({
      ...data,
      proposal_section_uuid: props.proposal_section.uuid,
    });
  };

  const removeImg = (image_uuid) => {
    removeProposalImg(props.proposal_section.uuid, image_uuid);
  };

  return (
    <Box sx={{ p: !props.displayOnly && "1rem" }}>
      <InspirationImgs
        imgListItemProps={props.InspirationImgListItemProps}
        imgListProps={{
          sx: {
            width: "100%",
            height: "auto",
            m: "auto",
            overflowY: "visible",
          },
          cols: 6,
          rowHeight: "57.5px",
        }}
        imgListPropsFullScreen={{
          sx: {
            width: "350px",
            height: "auto",
            m: "auto",
          },
          cols: 6,
          rowHeight: "57.5px",
        }}
        addImg={addImg}
        removeImg={removeImg}
        imgs={props.proposal_section.proposal_imgs}
        options={imgsInEvent()}
        displayOnly={props.displayOnly}
      />
    </Box>
  );
};

export const ImageLeftWithTextPreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      columnSpacing={0.5}
      alignItems={"center"}
      height={"100%"}
    >
      <Grid item xs={6} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={35}
            height={40}
            animation={false}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} container justifyContent={"center"}>
        {[...Array(5).keys()].map((i) => (
          <Grid item xs={12}>
            <Skeleton
              variant="rounded"
              width={35}
              height={5}
              sx={{ mt: 0.25 }}
              animation={false}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export const ImageRightWithTextPreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      columnSpacing={0.5}
      alignItems={"center"}
      height={"100%"}
    >
      <Grid item xs={6} container justifyContent={"center"}>
        {[...Array(5).keys()].map((i) => (
          <Grid item xs={12}>
            <Skeleton
              variant="rounded"
              width={35}
              height={5}
              sx={{ mt: 0.25 }}
              animation={false}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={6} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={35}
            height={40}
            animation={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ImagesV1View = (props) => {
  return (
    <Box>
      {props.proposal_section.section_type === "images_v1" && (
        <Images displayOnly={true} {...props} />
      )}
      {props.proposal_section.section_type === "image_left_with_text" && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Images displayOnly={true} {...props} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Text displayOnly={true} {...props} />
          </Grid>
        </Grid>
      )}
      {props.proposal_section.section_type === "image_right_with_text" && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Text displayOnly={true} {...props} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Images displayOnly={true} {...props} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
