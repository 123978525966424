import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import "./index.css";

if (process.env.REACT_APP_ENV !== "DEV") {
    Sentry.init({
        dsn: "https://6b349f19812442f5b3c825b2cfcf64fc@o1141868.ingest.sentry.io/6200618",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <App />
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
