import React from "react";
import { withRouter } from "react-router-dom";

import { useFormik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Divider,
  FormGroup,
  Switch,
  Button,
  Alert,
  Box,
  TableContainer,
  TableCell,
  TableRow,
  Table,
  TableHead,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getPaymentDate,
  getPaymentMethodString,
  webPaymentsEnabled,
} from "../utils/payment_utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  getCurrentInvoiceNumberApi,
  getPaymentIntentApi,
  getPaymentScheduleUnsecureApi,
  getPaymentUnsecureApi,
  refundPaymentApi,
  setInvoiceNumberApi,
} from "../api/PaymentsApi";
import {
  formatDate,
  twoDec,
  validateEmail,
  validateName,
} from "../utils/utils";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";

import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAccountState } from "../state/store";
import * as Yup from "yup";
import { useLocaleContext } from "./locale";
import { RichTextFieldEditable, RichTextFieldView } from "./RichTextField";
import { eventDateFormatted } from "../utils/event_utils";

const format_percent = (fraction) => {
  let percent = Number.parseFloat(fraction);
  return percent.toFixed(2) + "%";
};

const PaymentScheduleEditor = (props) => {
  const [
    addPayment,
    addFinalPayment,
    allPaymentsPaid,
    eventHasAmendments,
    eventLocked,
    paymentScheduleErrors,
    payment_schedule,
    sortPayments,
    updatePaymentSchedule,
    user,
  ] = useAccountState((state) => [
    state.addPayment,
    state.addFinalPayment,
    state.allPaymentsPaid,
    state.eventHasAmendments,
    state.eventLocked,
    state.paymentScheduleErrors,
    state.payment_schedule,
    state.sortPayments,
    state.updatePaymentSchedule,
    state.user,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const updateEnabled = (e) => {
    updatePaymentSchedule({
      enabled: e.target.checked,
    });
  };

  const updateEnableWebPayments = (e) => {
    updatePaymentSchedule({
      enable_web_payments: e.target.checked,
    });
  };

  const updateAttachInvoice = (e) => {
    updatePaymentSchedule({
      attach_invoice_to_payment_notifications: e.target.checked,
    });
  };

  const [invoice_notes, setInvoiceNotes] = React.useState(
    payment_schedule.invoice_notes
  );

  const handleInvoiceNotesChange = (e) => {
    setInvoiceNotes(e.target.value);
  };

  const handleInvoiceNotesBlur = () => {
    updatePaymentSchedule({
      invoice_notes: invoice_notes,
    });
  };

  return (
    <Box
      sx={{ p: fullScreen ? ".25rem" : "1rem" }}
      id="payment-schedule-editor-container"
    >
      <Grid container spacing={fullScreen ? 1 : 2} alignItems="center">
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          spacing={1}
          alignItems="center"
        >
          <Grid item xs="auto">
            <Typography variant="h6">Payment Schedule Editor</Typography>
          </Grid>
          <Grid item xs="auto">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={payment_schedule.enabled}
                    onChange={updateEnabled}
                    disabled={eventLocked()}
                    id="payment-schedule-enabled"
                    size="small"
                  />
                }
                label="Enable Schedule"
                labelPlacement="start"
              />
            </FormGroup>
          </Grid>
        </Grid>
        {payment_schedule.enabled ? (
          <Grid item xs={12} container spacing={1} alignItems="center">
            <Grid
              item
              xs={12}
              container
              spacing={1}
              alignItems="center"
              justifyContent={fullScreen ? "flex-start" : "flex-end"}
            >
              <Grid item xs="auto" container alignItems="center">
                <Grid item xs="auto">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={webPaymentsEnabled(payment_schedule, user)}
                          onChange={updateEnableWebPayments}
                          disabled={
                            eventLocked() || !user.stripe_account?.email
                          }
                          id="payment-schedule-enable-web-payments"
                          size="small"
                        />
                      }
                      label="Enable Web Payments"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </Grid>

                {!user.stripe_account?.email ? (
                  <Grid item xs="auto">
                    <Tooltip
                      title={
                        "You cannot enable web payments until you have setup a Stripe integration. Go to your Account page to setup your Stripe integration."
                      }
                    >
                      <HelpOutlineOutlinedIcon fontSize="inherit" />
                    </Tooltip>
                  </Grid>
                ) : null}
              </Grid>
              {!allPaymentsPaid() && (
                <Grid item xs={fullScreen ? 12 : "auto"}>
                  <Box display="flex">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={addPayment}
                      disabled={eventLocked() || !payment_schedule.enabled}
                      sx={{ m: "auto" }}
                      id="add-scheduled-payment"
                    >
                      Add Scheduled Payment
                    </Button>
                  </Box>
                </Grid>
              )}
              {allPaymentsPaid() && (
                <Grid item xs={fullScreen ? 12 : "auto"}>
                  <Box display="flex">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={addFinalPayment}
                      disabled={eventLocked() || !payment_schedule.enabled}
                      sx={{ m: "auto" }}
                      id="add-scheduled-payment"
                    >
                      Add Final Payment
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} container spacing={0.5}>
              {paymentScheduleErrors(props.ignore_amendments).map((err) => (
                <Grid item xs={12} key={err}>
                  <Alert severity="error" key={err}>
                    {err}
                  </Alert>
                </Grid>
              ))}
            </Grid>
            {sortPayments().map((payment, idx) => (
              <PaymentEditor
                key={"payment-editor-" + idx}
                id={"payment-editor-" + idx}
                payment={payment}
                ignore_amendments={props.ignore_amendments}
              />
            ))}
            <PaymentEditor
              payment={{
                ...payment_schedule,
                is_percentage: false,
              }}
              final
              ignore_amendments={props.ignore_amendments}
              key={"payment-editor-final"}
              id={"payment-editor-final"}
            />
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
              <Typography variant="h6" sx={{ mt: "1rem" }}>
                Invoice Settings
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        payment_schedule.attach_invoice_to_payment_notifications
                      }
                      onChange={updateAttachInvoice}
                      disabled={eventLocked()}
                      id="payment-schedule-attach-invoice"
                      size="small"
                    />
                  }
                  label="Attach Invoice to Payment Notifications"
                  labelPlacement="start"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Invoice Notes"
                value={invoice_notes}
                onChange={handleInvoiceNotesChange}
                onBlur={handleInvoiceNotesBlur}
                fullWidth
                multiline
                disabled={eventLocked()}
                size="small"
              />
            </Grid>
            {props.settings && (
              <Grid item xs={12}>
                <InvoiceNumberSetting />
              </Grid>
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

const PaymentEditor = (props) => {
  const [
    event,
    eventLocked,
    deletePayment,
    finalPaymentAmount,
    formatCurrency,
    getCurrencySymbol,
    paymentAmount,
    updatePayment,
  ] = useAccountState((state) => [
    state.event,
    state.eventLocked,
    state.deletePayment,
    state.finalPaymentAmount,
    state.formatCurrency,
    state.getCurrencySymbol,
    state.paymentAmount,
    props.final ? state.updatePaymentSchedule : state.updatePayment,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const update = (data) => {
    updatePayment({ uuid: props.payment.uuid, ...data });
  };

  const updateName = (e) => {
    update({ name: e.target.value });
  };

  const [name, setName] = React.useState(props.payment.name);
  React.useEffect(() => {
    setName(props.payment.name);
  }, [props.payment.name]);
  const updateNameState = (e) => {
    setName(e.target.value);
  };
  const _paymentAmount = (payment, ignore_amendments) => {
    var ignore_amendments =
      ignore_amendments !== undefined
        ? ignore_amendments
        : props.ignore_amendments;
    return props.final
      ? finalPaymentAmount(ignore_amendments)
      : paymentAmount(payment ? payment : props.payment, ignore_amendments);
  };

  const [percent, setPercent] = React.useState(undefined);
  const [amount, setAmount] = React.useState(undefined);

  React.useEffect(() => {
    setPercent(twoDec(_paymentAmount().percent));

    setAmount(twoDec(_paymentAmount().amount));
  }, [props.payment]);

  const updateAmountState = (e) => {
    var new_amount = e.target.value;
    if (props.payment.is_percentage) {
      setPercent(twoDec(new_amount));
      setAmount(
        _paymentAmount({ ...props.payment, amount: new_amount }).amount
      );
    } else {
      if (!new_amount.endsWith(".")) {
        new_amount = twoDec(new_amount);
      }
      setAmount(new_amount);
      setPercent(
        twoDec(_paymentAmount({ ...props.payment, amount: new_amount }).percent)
      );
    }
  };

  const updateAmount = (e) => {
    update({ amount: props.payment.is_percentage ? percent : amount });
    setPercent(twoDec(percent));
    setAmount(twoDec(amount));
  };

  const [dueOffset, setDueOffset] = React.useState(props.payment.due_offset);
  React.useEffect(() => {
    setDueOffset(props.payment.due_offset);
  }, [props.payment.due_offset]);
  const updateDueOffsetState = (e) => {
    const new_due_offset = e.target.value;
    setDueOffset(new_due_offset);
  };
  const updateDueOffset = (e) => {
    update({ due_offset: dueOffset });
  };

  const [dueDirection, setDueDirection] = React.useState(
    props.payment.due_direction
  );
  React.useEffect(() => {
    setDueDirection(props.payment.due_direction);
  }, [props.payment.due_direction]);
  const updateDueDirection = (e) => {
    setDueDirection(e.target.value);
    if (e.target.value === "BE" && dueEvent === "SI") {
      updateDueEvent({ target: { value: "EV" } });
    }
    update({ due_direction: e.target.value });
  };

  const [dueEvent, setDueEvent] = React.useState(props.payment.due_event);
  React.useEffect(() => {
    setDueEvent(props.payment.due_event);
  }, [props.payment.due_event]);
  const updateDueEvent = (e) => {
    setDueEvent(e.target.value);
    if (e.target.value === "SI" && dueDirection === "BE") {
      updateDueDirection({ target: { value: "AF" } });
    }
    update({ due_event: e.target.value });
  };

  const updateType = (e) => {
    const is_percentage = e.target.value === "percent" ? true : false;
    if (is_percentage !== props.payment.is_percentage) {
      if (!event.is_template) {
        const payment_amount = _paymentAmount();
        update({ amount: e.target.value === "percent" ? percent : amount });
        setPercent(twoDec(payment_amount.amount));
        setAmount(twoDec(payment_amount.percent));
      }
      update({ is_percentage: e.target.value === "percent" ? true : false });
    }
  };

  const [deleting, setDeleting] = React.useState(false);

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      alignItems="center"
      id={props.id + "-grid"}
    >
      <Grid item xs={12} sm={9} container spacing={1}>
        <Grid item xs={10} sm={4}>
          <TextField
            label="Name"
            value={name}
            disabled={props.payment.paid || eventLocked()}
            onBlur={updateName}
            onChange={updateNameState}
            fullWidth
            id={props.id + "-name-input"}
          />
        </Grid>
        {fullScreen ? (
          <Grid item xs={2}>
            <IconButton
              disabled={props.final || props.payment.paid || eventLocked()}
              onClick={() => setDeleting(true)}
              id={props.id + "-delete-button"}
            >
              <DeleteOutlineOutlinedIcon size="inherit" />
            </IconButton>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Amount (%)"
            value={percent}
            disabled={
              props.final ||
              !props.payment.is_percentage ||
              props.payment.paid ||
              eventLocked()
            }
            onChange={updateAmountState}
            onBlur={updateAmount}
            fullWidth
            id={props.id + "-percent-input"}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={"Amount (" + getCurrencySymbol() + ")"}
            value={amount}
            disabled={
              props.final ||
              props.payment.is_percentage ||
              props.payment.paid ||
              eventLocked()
            }
            onChange={updateAmountState}
            onBlur={updateAmount}
            fullWidth
            id={props.id + "-amount-input"}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} sm={2} container justifyContent="center">
        <Grid item xs="auto">
          <FormControl>
            <RadioGroup
              row
              name="type"
              defaultValue="percent"
              value={props.payment.is_percentage ? "percent" : "amount"}
              onChange={updateType}
              id={props.id + "-type-input"}
            >
              <FormControlLabel
                value="percent"
                control={<Radio size="small" />}
                label="%"
                labelPlacement="top"
                sx={{ m: 0 }}
                disabled={props.final || props.payment.paid || eventLocked()}
                id={props.id + "-percent-radio"}
              />
              <FormControlLabel
                value="amount"
                control={<Radio size="small" />}
                label={getCurrencySymbol()}
                labelPlacement="top"
                sx={{ m: 0 }}
                disabled={props.final || props.payment.paid || eventLocked()}
                id={props.id + "-amount-radio"}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {!fullScreen ? (
        <Grid item xs={1}>
          <IconButton
            disabled={props.final || props.payment.paid || eventLocked()}
            onClick={() => setDeleting(true)}
            id={props.id + "-delete-button"}
          >
            <DeleteOutlineOutlinedIcon size="inherit" />
          </IconButton>
        </Grid>
      ) : null}
      <Grid item xs={8} sm={4}>
        <TextField
          label="Due (days)"
          value={dueOffset}
          fullWidth
          onChange={updateDueOffsetState}
          onBlur={updateDueOffset}
          disabled={props.payment.paid || eventLocked()}
          id={props.id + "-due-offset-input"}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Before Or After</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={dueDirection}
            label="Before or After"
            onChange={updateDueDirection}
            disabled={props.payment.paid || eventLocked()}
            id={props.id + "-due-direction-input"}
          >
            <MenuItem value="BE">Before</MenuItem>
            <MenuItem value="AF">After</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Calendar Event</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={dueEvent}
            label="Calendar Event"
            onChange={updateDueEvent}
            disabled={props.payment.paid || eventLocked()}
            id={props.id + "-due-event-input"}
          >
            <MenuItem value="SI">Contract Signing</MenuItem>
            <MenuItem value="EV">Event Date</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {!_paymentAmount(props.payment, false).amount.equals(
        _paymentAmount(props.payment, true).amount
      ) && (
        <Grid item xs={12}>
          <Alert severity="info">
            {`Amended payment amount will be ${formatCurrency(
              _paymentAmount(props.payment, false).amount
            )}`}
          </Alert>
        </Grid>
      )}
      {!props.final ? <Divider sx={{ width: "100%", mt: "1rem" }} /> : null}
      <DeletePaymentDialog
        open={deleting}
        handleCancel={() => setDeleting(false)}
        handleSubmit={() => deletePayment(props.payment.uuid)}
        name={props.payment.name}
      />
    </Grid>
  );
};

const DeletePaymentDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Confirm Delete Payment</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete {props.name}?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="delete-payment-cancel-button"
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            props.handleSubmit();
            props.handleCancel();
          }}
          id="delete-payment-submit-button"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const PaymentSchedule = (props) => {
  const [
    event,
    eventHasAmendments,
    finalPaymentAmount,
    formatCurrency,
    outstandingBalance,
    payment_schedule,
    sortPayments,
    updatePaymentSchedule,
  ] = useAccountState((state) => [
    state.event,
    state.eventHasAmendments,
    state.finalPaymentAmount,
    state.formatCurrency,
    state.outstandingBalance,
    state.payment_schedule,
    state.sortPayments,
    state.updatePaymentSchedule,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const updateNotes = (data) => {
    updatePaymentSchedule({ notes_rich: data });
  };
  return (
    <Grid container spacing={1} id="payment-schedule-container">
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems={"center"}
      >
        {!props.client && (
          <Grid item xs="auto">
            <Typography variant="h6">Payment Schedule</Typography>
          </Grid>
        )}
        {!props.client ? (
          <Grid item xs="auto">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={payment_schedule.show_due_dates}
                  onChange={(e) =>
                    updatePaymentSchedule({ show_due_dates: e.target.checked })
                  }
                  id="show-due-dates-switch"
                  size="small"
                />
              }
              label={
                <>
                  Show Due Dates{" "}
                  <Tooltip
                    title={
                      "Disabling this option will hide payment due dates and overdue notices on your clients portal."
                    }
                  >
                    <HelpOutlineOutlinedIcon fontSize="inherit" />
                  </Tooltip>
                </>
              }
              labelPlacement="end"
            />
          </Grid>
        ) : null}
      </Grid>

      {eventHasAmendments() && !props.ignore_amendments ? (
        <Grid item xs={12}>
          <Alert severity="info">
            These payment amounts reflect the amended contract.
          </Alert>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Due</TableCell>
              {fullScreen ||
              (!payment_schedule.show_due_dates && props.client) ? null : (
                <TableCell>Due Date</TableCell>
              )}
              <TableCell>Paid</TableCell>
              {fullScreen ? null : <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          {sortPayments().map((payment, idx) => (
            <Payment
              key={"payment-" + idx}
              id={"payment-" + idx}
              payment={payment}
              client={props.client}
              client_details={props.client_details}
              ignore_amendments={props.ignore_amendments}
              disablePayment={event.status !== "BO" || eventHasAmendments()}
            />
          ))}
          {!finalPaymentAmount(props.ignore_amendments).amount.equals(0) && (
            <Payment
              payment={{
                ...payment_schedule,
                is_percentage: false,
              }}
              final
              client={props.client}
              client_details={props.client_details}
              ignore_amendments={props.ignore_amendments}
              disablePayment={event.status !== "BO" || eventHasAmendments()}
              key={"payment-final"}
              id={"payment-final"}
            />
          )}
        </Table>
      </Grid>
      {event.status !== "IN" && event.status !== "PR" && (
        <Grid item xs={12}>
          <Alert severity="info" size="small">
            Outstanding Balance: {formatCurrency(outstandingBalance(false))}
          </Alert>
        </Grid>
      )}
      {!props.client && (
        <Grid item xs={12}>
          <Box sx={{ p: ".5rem" }}>
            <Box
              sx={{
                p: ".5rem",
                backgroundColor: "#f8f8f8",
                borderRadius: "2px",
              }}
            >
              <RichTextFieldEditable
                value={payment_schedule.notes_rich}
                updateValue={updateNotes}
                autoFocus
                placeholder={`Click to add payment schedule notes.`}
              />
            </Box>
            <Alert severity="info" size="small" sx={{ mt: ".5rem" }}>
              Note: These notes will be visible to your clients on their portal.
              Client portals are accesible to anyone who has the sharable link.
              Do not include any sensitive information in these notes.
            </Alert>
          </Box>
        </Grid>
      )}
      {props.client && payment_schedule.notes_rich && (
        <Grid item xs={12}>
          <Box
            sx={{
              p: ".5rem",
              backgroundColor: "#f8f8f8",
              borderRadius: "2px",
            }}
          >
            <RichTextFieldView value={payment_schedule.notes_rich} />
          </Box>
        </Grid>
      )}
      {payment_schedule.show_due_dates &&
        (event.status === "IN" || event.status === "PR") && (
          <Grid item xs={12}>
            <Typography sx={{ fontSize: ".65rem" }}>
              <sup>*</sup>Calendar dates are subject to change based on the
              actual date of the contract signing and the event date.
            </Typography>
          </Grid>
        )}
    </Grid>
  );
};

const Payment = (props) => {
  const locale = useLocaleContext();
  const [
    back_office,
    contractSignDate,
    event,
    finalPaymentAmount,
    formatCurrency,
    paymentAmount,
    payment_schedule,
    proposal,
    updatePayment,
    webPaymentsEnabled,
  ] = useAccountState((state) => [
    state.back_office,
    state.contractSignDate,
    state.event,
    state.finalPaymentAmount,
    state.formatCurrency,
    state.paymentAmount,
    state.payment_schedule,
    state.proposal,
    props.final ? state.updatePaymentSchedule : state.updatePayment,
    state.webPaymentsEnabled,
  ]);
  const [markingPaid, setMarkingPaid] = React.useState(false);
  const [markingUnpaid, setMarkingUnpaid] = React.useState(false);
  const [makingPayment, setMakingPayment] = React.useState(false);
  const [refunding, setRefunding] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const markPaid = (values) => {
    updatePayment({
      uuid: props.payment.uuid,
      paid: true,
      paid_on: values.paid_on,
      amount_paid: values.amount_paid,
      payment_method: values.payment_method,
    });
  };

  const markUnpaid = () => {
    updatePayment({
      uuid: props.payment.uuid,
      paid: false,
      paid_on: null,
      amount_paid: null,
      payment_method: null,
    });
  };

  return fullScreen ? (
    <TableRow id={props.id + "-row"}>
      <TableCell>
        <Grid container>
          <Grid item xs={12}>
            {props.payment.name}
          </Grid>
          <Grid
            item
            xs={12}
            sx={
              getPaymentDate(
                props.payment,
                contractSignDate() ? contractSignDate() : new Date(),
                new Date(event.date)
              ) < new Date() && !props.payment.paid
                ? { color: "error.main" }
                : undefined
            }
          >
            {payment_schedule.show_due_dates || !props.client ? (
              <>
                {eventDateFormatted(
                  getPaymentDate(
                    props.payment,
                    contractSignDate() ? contractSignDate() : new Date(),
                    new Date(event.date)
                  ),
                  back_office.timezone,
                  back_office.date_format
                )}

                {props.client &&
                  (event.status === "IN" || event.status === "PR") && (
                    <sup>*</sup>
                  )}
              </>
            ) : null}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid item xs={12}>
          {formatCurrency(
            props.final
              ? finalPaymentAmount(props.payments, props.ignore_amendments)
                  .amount
              : paymentAmount(props.payment, props.ignore_amendments).amount
          )}
        </Grid>
      </TableCell>

      <TableCell>
        {props.payment.paid ? (
          formatCurrency(props.payment.amount_paid)
        ) : props.client && webPaymentsEnabled() ? (
          <Button
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setMakingPayment(true)}
            disabled={props.disablePayment}
            id={props.id + "-pay-button"}
          >
            Pay
          </Button>
        ) : !props.client ? (
          <Button
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setMarkingPaid(true)}
            id={props.id + "-mark-paid-button"}
          >
            Mark Paid
          </Button>
        ) : null}
      </TableCell>

      <MarkPaidDialog
        open={markingPaid}
        handleCancel={() => setMarkingPaid(false)}
        name={props.payment.name}
        amount={
          props.final
            ? finalPaymentAmount().amount
            : paymentAmount(props.payment, props.ignore_amendments).amount
        }
        handleSubmit={markPaid}
      />
      <MarkUnpaidDialog
        open={markingUnpaid}
        handleCancel={() => setMarkingUnpaid(false)}
        name={props.payment.name}
        amount={
          props.final
            ? finalPaymentAmount(props.ignore_amendments).amount
            : paymentAmount(props.payment, props.ignore_amendments).amount
        }
        handleSubmit={markUnpaid}
      />
      <RefundDialog
        open={refunding}
        handleCancel={() => setRefunding(false)}
        name={props.payment.name}
        amount={
          props.final
            ? finalPaymentAmount(props.ignore_amendments).amount
            : paymentAmount(props.payment, props.ignore_amendments).amount
        }
        payment_uuid={props.payment.uuid}
        final={props.final}
      />
      {webPaymentsEnabled() ? (
        <MakePaymentDialog
          open={makingPayment}
          handleCancel={() => setMakingPayment(false)}
          amount={
            props.final
              ? finalPaymentAmount(props.ignore_amendments).amount
              : paymentAmount(props.payment, props.ignore_amendments).amount
          }
          payment_uuid={props.payment.uuid}
          final={props.final}
          client_email={props.client_details?.email}
          client_name={props.client_details?.name}
          due_date={eventDateFormatted(
            getPaymentDate(
              props.payment,
              contractSignDate() ? contractSignDate() : new Date(),
              new Date(event.date)
            ),
            back_office.timezone,
            back_office.date_format
          )}
        />
      ) : null}
    </TableRow>
  ) : (
    <TableRow>
      <TableCell>{props.payment.name}</TableCell>
      <TableCell>
        {formatCurrency(
          props.final
            ? finalPaymentAmount(props.ignore_amendments).amount
            : paymentAmount(props.payment, props.ignore_amendments).amount
        )}
      </TableCell>
      {payment_schedule.show_due_dates || !props.client ? (
        <TableCell>
          {event.is_template
            ? null
            : eventDateFormatted(
                getPaymentDate(
                  props.payment,
                  contractSignDate() ? contractSignDate() : new Date(),
                  new Date(event.date)
                ),
                back_office.timezone,
                back_office.date_format
              )}

          {props.client && (event.status === "IN" || event.status === "PR") && (
            <sup>*</sup>
          )}
        </TableCell>
      ) : null}

      <TableCell>
        {props.payment.paid ? formatCurrency(props.payment.amount_paid) : null}

        {getPaymentDate(
          props.payment,
          contractSignDate() ? contractSignDate() : new Date(),
          new Date(event.date)
        ) < new Date() &&
        !props.payment.paid &&
        !event.is_template &&
        payment_schedule.show_due_dates ? (
          <Chip size="small" label="Overdue" color="error" />
        ) : props.payment.paid ? (
          " (" +
          eventDateFormatted(
            props.payment.paid_on,
            back_office.timezone,
            back_office.date_format
          ) +
          ")"
        ) : null}
      </TableCell>

      {props.payment.paid ? (
        <TableCell align="right">
          <Chip
            size="small"
            label={getPaymentMethodString(props.payment.payment_method)}
          />
          {props.payment.paid &&
          !props.client &&
          props.payment.payment_method !== "ST" ? (
            // <TableCell align="center">
            <Tooltip title="Mark Unpaid">
              <IconButton
                size="small"
                onClick={() => setMarkingUnpaid(true)}
                id={props.id + "-mark-unpaid-button"}
              >
                <ReplayOutlinedIcon fontSize="inherit" />{" "}
              </IconButton>
            </Tooltip>
          ) : // </TableCell>
          null}
          {props.payment.paid &&
          !props.client &&
          props.payment.payment_method === "ST" ? (
            // <TableCell align="center">
            <Tooltip title="Refund Payment">
              <IconButton
                size="small"
                onClick={() => setRefunding(true)}
                id={props.id + "-refund-button"}
              >
                <CurrencyExchangeOutlinedIcon fontSize="inherit" />{" "}
              </IconButton>
            </Tooltip>
          ) : // {/* </TableCell> */}
          null}
          {!props.client && <DownloadInvoiceButton payment={props.payment} />}
        </TableCell>
      ) : null}

      {!props.payment.paid && !props.client ? (
        <TableCell align="center">
          <Tooltip title="Mark Paid">
            <IconButton
              size="small"
              variant="outlined"
              color="info"
              onClick={() => setMarkingPaid(true)}
              id={props.id + "-mark-paid-button"}
            >
              <PriceCheckOutlinedIcon />
            </IconButton>
          </Tooltip>
          <DownloadInvoiceButton payment={props.payment} />
        </TableCell>
      ) : null}

      {!props.payment.paid && props.client && webPaymentsEnabled() ? (
        <TableCell align="right">
          <Button
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setMakingPayment(true)}
            disabled={props.disablePayment}
            id={props.id + "-pay-button"}
          >
            Pay
          </Button>
        </TableCell>
      ) : null}

      <MarkPaidDialog
        open={markingPaid}
        handleCancel={() => setMarkingPaid(false)}
        name={props.payment.name}
        amount={
          props.final
            ? finalPaymentAmount(props.ignore_amendments).amount
            : paymentAmount(props.payment, props.ignore_amendments).amount
        }
        handleSubmit={markPaid}
      />
      <MarkUnpaidDialog
        open={markingUnpaid}
        handleCancel={() => setMarkingUnpaid(false)}
        name={props.payment.name}
        amount={
          props.final
            ? finalPaymentAmount(props.ignore_amendments).amount
            : paymentAmount(props.payment, props.ignore_amendments).amount
        }
        handleSubmit={markUnpaid}
      />
      <RefundDialog
        open={refunding}
        handleCancel={() => setRefunding(false)}
        name={props.payment.name}
        amount={
          props.final
            ? finalPaymentAmount(props.ignore_amendments).amount
            : paymentAmount(props.payment, props.ignore_amendments).amount
        }
        payment_uuid={props.payment.uuid}
        final={props.final}
      />
      {webPaymentsEnabled() ? (
        <MakePaymentDialog
          open={makingPayment}
          handleCancel={() => setMakingPayment(false)}
          amount={
            props.final
              ? finalPaymentAmount(props.ignore_amendments).amount
              : paymentAmount(props.payment, props.ignore_amendments).amount
          }
          payment_uuid={props.payment.uuid}
          final={props.final}
          client_email={props.client_details?.email}
          client_name={props.client_details?.name}
          due_date={formatDate(
            getPaymentDate(
              props.payment,
              contractSignDate() ? contractSignDate() : new Date(),
              new Date(event.date)
            ),
            locale
          )}
        />
      ) : null}
    </TableRow>
  );
};

const MarkPaidDialog = (props) => {
  const [back_office, eventHasAmendments, getCurrencySymbol] = useAccountState(
    (state) => [
      state.back_office,
      state.eventHasAmendments,
      state.getCurrencySymbol,
    ]
  );
  const formik = useFormik({
    initialValues: {
      amount_paid: props.amount ? props.amount.toFixed(2) : "",
      paid_on: new Date(),
      payment_method: "CH",
    },
    onSubmit: (values) => {
      props.handleCancel();
      props.handleSubmit(values);
      formik.resetForm();
    },
  });
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Mark Paid</DialogTitle>
      <DialogContent>
        <form>
          <Box sx={{ p: "1rem" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography>Mark {props.name} as paid?</Typography>
              </Grid>
              {eventHasAmendments() ? (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    There are unsigned amendments on this event that could
                    effect the total event price and calculated payment amounts.
                  </Alert>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <TextField
                  name="amount_paid"
                  label={"Amount (" + getCurrencySymbol() + ")"}
                  id="amount_paid"
                  value={formik.values.amount_paid}
                  onChange={(e, val) => {
                    formik.setFieldValue("amount_paid", e.target.value);
                  }}
                  error={
                    formik.touched.individual_per_group &&
                    Boolean(formik.errors.individual_per_group)
                  }
                  helperText={
                    formik.touched.individual_per_group &&
                    formik.errors.individual_per_group
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  inputFormat={back_office.date_format}
                  name="paid_on"
                  id="paid_on"
                  label="Paid On"
                  value={formik.values.paid_on}
                  onChange={(val) => {
                    formik.setFieldValue("paid_on", val);
                  }}
                  error={
                    formik.touched.event_date &&
                    Boolean(formik.errors.event_date)
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="payment_method"
                  label="Payment Method"
                  id="payment_method"
                  value={formik.values.payment_method}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.unit_name && Boolean(formik.errors.unit_name)
                  }
                  helperText={
                    formik.touched.unit_name && formik.errors.unit_name
                  }
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="CA">Cash</MenuItem>
                  <MenuItem value="CH">Check</MenuItem>
                  <MenuItem value="OT">Other</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="cancel-mark-paid"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={formik.handleSubmit}
          id="confirm-mark-paid"
        >
          Mark Paid
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MarkUnpaidDialog = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Mark Unpaid</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to mark {props.name} as unpaid? This will add{" "}
          {formatCurrency(props.amount)} to the outstanding total on your
          payment schedule.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="cancel-mark-unpaid"
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            props.handleSubmit();
            props.handleCancel();
          }}
          id="confirm-mark-unpaid"
        >
          Mark Unpaid
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RefundDialog = (props) => {
  const [formatCurrency, updatePaymentInState] = useAccountState((state) => [
    state.formatCurrency,
    state.updatePaymentInState,
  ]);
  const [loading, setLoading] = React.useState(false);
  const refund = () => {
    setLoading(true);
    refundPaymentApi(props.payment_uuid).then((resp) => {
      updatePaymentInState(resp.data);
      props.handleCancel();
      setLoading(false);
    });
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Refund Payment</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to refund {props.name}? This will add{" "}
          {formatCurrency(props.amount)} to the outstanding total on your
          payment schedule and the ammount will be returned to the original
          payment method of your client.
        </Typography>
      </DialogContent>
      {!loading ? (
        <DialogActions>
          <Button
            variant="outlined"
            color="info"
            onClick={props.handleCancel}
            id="cancel-refund-button"
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={refund}
            id="confirm-refund-button"
          >
            Refund
          </Button>
        </DialogActions>
      ) : (
        <Box display="flex" sx={{ p: ".5rem" }}>
          <CircularProgress sx={{ ml: "auto" }} />
        </Box>
      )}
    </Dialog>
  );
};

const MakePaymentDialog = (props) => {
  const [
    formatCurrency,
    updatePaymentInState,
    updatePaymentScheduleInState,
    user,
  ] = useAccountState((state) => [
    state.formatCurrency,
    state.updatePaymentInState,
    state.updatePaymentScheduleInState,
    state.user,
  ]);
  const stripe = user?.stripe_account?.id
    ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
        stripeAccount: user.stripe_account.id,
      })
    : undefined;
  const [amountConfirmed, setAmountConfirmed] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [options, setOptions] = React.useState(undefined);
  const [email, setEmail] = React.useState(props.client_email);
  const [name, setName] = React.useState(props.client_name);
  const [emailIsValid, setEmailIsValid] = React.useState(validateEmail(email));

  const handleCancel = () => {
    setAmountConfirmed(false);
    setLoading(true);
    setOptions(undefined);
    props.handleCancel();
  };

  const handleContinue = () => {
    setAmountConfirmed(true);
    getPaymentIntentApi(
      props.payment_uuid,
      props.amount,
      email,
      name,
      props.due_date
    ).then((resp) => {
      setOptions({ clientSecret: resp.data.client_secret });
      setLoading(false);
    });
  };

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const updateName = (e) => {
    setName(e.target.value);
  };

  const validateEmailOnBlur = (e) => {
    setEmailIsValid(validateEmail(email));
  };

  return (
    <Dialog open={props.open} onClose={handleCancel}>
      <DialogTitle>Make Payment</DialogTitle>
      {amountConfirmed ? (
        <DialogContent>
          {!loading && options ? (
            <Elements stripe={stripe} options={options}>
              <PaymentDetailsCollect
                handleCancel={handleCancel}
                updatePaymentInState={
                  props.final
                    ? updatePaymentScheduleInState
                    : updatePaymentInState
                }
                payment_uuid={props.payment_uuid}
                final={props.final}
                amount={props.amount}
              />
            </Elements>
          ) : (
            <Box display="flex">
              <CircularProgress sx={{ m: "auto" }} />
            </Box>
          )}
        </DialogContent>
      ) : null}
      {!amountConfirmed ? (
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                You will be charged {formatCurrency(props.amount)}.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                onChange={updateName}
                value={name}
                id="name-input"
              />
              {!validateName(name) && name && name.length > 1 && (
                <Typography color="error" variant="caption">
                  Name cannot contain special characters.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                onChange={updateEmail}
                value={email}
                onBlur={validateEmailOnBlur}
                id="email-input"
              />
              {email && !emailIsValid && (
                <Typography color="error" variant="caption">
                  Valid email is required.
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      ) : null}
      {!amountConfirmed ? (
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            id="cancel-payment-button"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleContinue}
            disabled={!validateEmail(email) || !validateName(name)}
            id="continue-payment-button"
          >
            Continue
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

const PaymentDetailsCollect = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = React.useState(false);
  const [error, setErrorMessage] = React.useState(undefined);
  const [error_alert_open, setErrorAlertOpen] = React.useState(false);

  const setError = (error_message) => {
    setErrorMessage(error_message);
    setErrorAlertOpen(true);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { setupIntent, error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_BASE_URL + "/account",
      },
      redirect: "if_required",
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setError(error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setTimeout(function () {
        if (props.final) {
          getPaymentScheduleUnsecureApi(props.payment_uuid).then((resp) => {
            props.updatePaymentInState(resp.data);
            props.handleCancel();
            setLoading(false);
          });
        } else {
          getPaymentUnsecureApi(props.payment_uuid).then((resp) => {
            props.updatePaymentInState(resp.data);
            props.handleCancel();
            setLoading(false);
          });
        }
      }, 5000);
    }
  };

  return stripe && elements ? (
    <Box>
      {error_alert_open ? (
        <Grid item xs={12}>
          <Alert
            severity="error"
            onClose={() => {
              setErrorAlertOpen(false);
            }}
          >
            {error}
          </Alert>
        </Grid>
      ) : null}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} sx={{ pt: ".5rem" }}>
          <Grid item xs={12}>
            <Typography fontWeight="bold">
              Payment Amount: {formatCurrency(props.amount)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PaymentElement />
          </Grid>

          {!loading ? (
            <Grid item xs={12} container spacing={1} justifyContent="flex-end">
              <Grid item xs="auto">
                <Button
                  onClick={props.handleCancel}
                  variant="outlined"
                  color="info"
                  sx={{ ml: "auto" }}
                  id="cancel-payment-button"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  disabled={!stripe}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ ml: "auto" }}
                  id="submit-payment-button"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Box display="flex">
                <CircularProgress sx={{ ml: "auto" }} />
              </Box>
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  ) : (
    <CircularProgress />
  );
};

const DownloadInvoiceButton = (props) => {
  const [downloading, setDownloading] = React.useState(false);

  const handleClick = () => {
    setDownloading(true);
    const anchor = document.createElement("a");
    anchor.href = `${process.env.REACT_APP_API_URL}/api/payments/get_invoice_url/${props.payment.uuid}/`;
    anchor.target = "_blank";
    anchor.download =
      (props.payment.name + " Invoice").replace(" ", "_") + ".pdf";

    // Append the anchor to the body (required for Firefox)
    document.body.appendChild(anchor);

    // Trigger the download
    anchor.click();

    // Remove the anchor from the document
    document.body.removeChild(anchor);
    setDownloading(false);
  };
  return downloading ? (
    <CircularProgress />
  ) : (
    <Tooltip title="Download Invoice">
      <IconButton
        size="small"
        variant="outlined"
        color="info"
        onClick={handleClick}
        id={props.id + "-download-invoice-button"}
      >
        <DownloadOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

const InvoiceNumberSetting = (props) => {
  const [invoice_number, setInvoiceNumber] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getCurrentInvoiceNumberApi().then((resp) => {
      setInvoiceNumber(resp.data.invoice_number);
      setLoaded(true);
    });
  }, []);
  const [settingInvoiceNumber, setSettingInvoiceNumber] = React.useState(false);
  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs="auto">
        <Typography>Current Invoice Number:</Typography>
      </Grid>
      <Grid item xs="auto">
        <Box
          sx={{
            p: ".5rem",
            border: "dashed lightgrey 1px",
            borderRadius: "5px",
          }}
        >
          {loaded ? (
            <Typography>{invoice_number}</Typography>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Grid>
      {loaded && (
        <Grid item xs="auto">
          <Tooltip title="Set Invoice Number">
            <IconButton
              onClick={() => setSettingInvoiceNumber(true)}
              size="small"
            >
              <EditOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <SetInvoiceNumberDialog
            open={settingInvoiceNumber}
            handleCancel={() => setSettingInvoiceNumber(false)}
            current_invoice_number={invoice_number}
            setInvoiceNumber={setInvoiceNumber}
          />
        </Grid>
      )}
    </Grid>
  );
};

const SetInvoiceNumberDialog = (props) => {
  const [loading, setLoading] = React.useState(false);
  const validationSchema = Yup.object({
    invoice_number: Yup.number()
      .required("Invoice number is required")
      .integer("Invoice number must be an integer")
      .moreThan(
        props.current_invoice_number,
        `Invoice number must be greater than ${props.current_invoice_number}`
      ),
  });
  const formik = useFormik({
    initialValues: {
      invoice_number: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setInvoiceNumberApi(values.invoice_number).then((resp) => {
        props.setInvoiceNumber(values.invoice_number);
        props.handleCancel();
        setLoading(false);
      });
    },
  });
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Set Invoice Number</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              Setting the invoice number will change the invoice number for all
              newly generated invoices. This can result in a gap in your invoice
              numbers. This action cannot be undone. The invoice number cannot
              be set lower than its current value.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Invoice Number"
              value={formik.values.invoice_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              id="invoice_number"
              size="small"
              error={
                formik.touched.invoice_number && formik.errors.invoice_number
              }
              helperText={
                formik.touched.invoice_number && formik.errors.invoice_number
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="cancel-set-invoice-number"
        >
          Cancel
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            color="secondary"
            variant="contained"
            onClick={formik.handleSubmit}
            id="confirm-set-invoice-number"
          >
            Set Invoice Number
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(PaymentScheduleEditor);
