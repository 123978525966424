import React from "react";
import { withRouter } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { useAccountState } from "../state/store.js";

import { eventStatuses } from "../utils/event_utils.js";

import {
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  Chip,
  Menu,
  MenuItem,
  CircularProgress,
  Alert,
  Link,
} from "@mui/material";

import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { payEventFeeApi } from "../api/StripeApi.js";
import { format_percent } from "../utils/utils.js";
import AmendmentBadge from "./AmendmentBadge.js";

const percent_formatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 0,
});

const EventSummaryCard = (props) => {
  const [
    back_office,
    daysToEvent,
    event,
    eventAmendments,
    eventCost,
    eventDateAmended,
    eventDateFormatted,
    eventItemsCost,
    eventHasAmendments,
    eventLocked,
    eventPrice,
    eventPriceSubtotal,
    eventProfit,
    eventProfitPercent,
    eventStatusName,
    eventTax,
    eventWasteCost,
    flowerbuddyFeeCost,
    formatCurrency,
    lockEventItemPrices,
    otherCostsCost,
    otherCostsCostPreFee,
    otherCostsPrice,
    overheadCosts,
    recipesLabor,
    toggleEventLocked,
    totalRecipesPrice,
    unsignedDocumentExists,
    updateEvent,
    userPlanType,
  ] = useAccountState((state) => [
    state.back_office,
    state.daysToEvent,
    state.event,
    state.eventAmendments,
    state.eventCost,
    state.eventDateAmended,
    state.eventDateFormatted,
    state.eventItemsCost,
    state.eventHasAmendments,
    state.eventLocked,
    state.eventPrice,
    state.eventPriceSubtotal,
    state.eventProfit,
    state.eventProfitPercent,
    state.eventStatusName,
    state.eventTax,
    state.eventWasteCost,
    state.flowerbuddyFeeCost,
    state.formatCurrency,
    state.lockEventItemPrices,
    state.otherCostsCost,
    state.otherCostsCostPreFee,
    state.otherCostsPrice,
    state.overheadCosts,
    state.recipesLabor,
    state.toggleEventLocked,
    state.totalRecipesPrice,
    state.unsignedDocumentExists,
    state.updateEvent,
    state.userPlanType,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [unlock_dialog_open, setUnlockDialogOpen] = React.useState(false);
  const [confirmingBookedEvent, setConfirmingBookedEvent] =
    React.useState(false);

  const profitColor = () => {
    if (eventProfitPercent() > 0.5) {
      return "success.main";
    } else if (eventProfit() > 0) {
      return "warning.main";
    } else {
      return "error.main";
    }
  };

  const updateEventStatus = (status) => {
    let lock_event = false;
    if (["BO", "PR"].includes(status)) {
      lock_event = true;
    }
    if (
      status === "BO" &&
      flowerbuddyFeeCost() > 0 &&
      !event.flowerbuddy_fee_paid &&
      userPlanType() !== "UNL"
    ) {
      setConfirmingBookedEvent(true);
    } else {
      updateEvent({ status: status, locked: lock_event ? true : event.locked });
      lockEventItemPrices();
    }
  };

  // const eventHasAmendments = () => {
  //   return eventAmendments() > 0;
  // };

  const disableLock = () => {
    return unsignedDocumentExists();
  };

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const unsetMenuAnchorEl = () => {
    setMenuAnchorEl(null);
  };

  const item_props = {
    variant: fullScreen ? "h5" : "h6",
    sx: {
      textAlign: "center",
    },
  };
  const label_props = {
    // variant: "h6",
    sx: {
      fontWeight: "light",
      textAlign: "center",
    },
  };
  const grid_item_props = {
    item: true,
    xs: 3,
    sm: 2,
  };
  return (
    <Paper
      sx={{
        maxWidth: "800px",
        m: "auto",
      }}
      id="event-summary-card"
    >
      <Box
        sx={{
          p: ".25rem",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          // sx={{p:'.5rem'}}
        >
          {event.is_template ? (
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Tooltip title="This is a template which can be used to create an event. Some items (e.g. event date, clients, documents, ect.) are hidden as they are not saveable settings that should be copied to a new event.">
                <Typography color="primary.main">TEMPLATE</Typography>
              </Tooltip>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            sm={2}
            container
            spacing={1}
            justifyContent="space-around"
          >
            <Grid item xs="auto" sm={12}>
              <Typography
                align="center"
                sx={{ fontWeight: "bold" }}
                id="event-name"
              >
                {event.name}
              </Typography>
            </Grid>
            <Grid item xs="auto" sm={12} display="flex">
              {!event.is_template ? (
                <AmendmentBadge
                  tooltip={"Contracted Date: " + eventDateFormatted(true)}
                  invisible={!eventDateAmended()}
                  sx={{ m: "auto" }}
                >
                  <Typography
                    textAlign="center"
                    sx={{ color: "info.light" }}
                    id="event-date"
                  >
                    {eventDateFormatted()}
                  </Typography>
                </AmendmentBadge>
              ) : null}
            </Grid>
          </Grid>
          {!event.is_template ? (
            <Grid
              {...grid_item_props}
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                },
              }}
            >
              <Typography {...item_props} id="days-to-event">
                {daysToEvent()}
              </Typography>
              <Typography {...label_props}>days to event</Typography>
            </Grid>
          ) : null}
          <Grid {...grid_item_props}>
            <Tooltip
              title={
                <TooltipTable
                  items={[
                    "Flowers",
                    eventItemsCost("FL"),
                    "Waste",
                    eventWasteCost(),
                    "Hard Goods",
                    eventItemsCost("MA"),
                    "Labor",
                    recipesLabor(),
                    "Other Costs",
                    otherCostsCostPreFee(),
                    "Overhead",
                    overheadCosts(),
                    "Tax",
                    eventTax(),
                  ]}
                ></TooltipTable>
              }
            >
              <Typography {...item_props} id="event-cost">
                {formatCurrency(eventCost())}
              </Typography>
            </Tooltip>
            <Typography {...label_props}>cost</Typography>
          </Grid>
          <Grid {...grid_item_props}>
            <Tooltip
              title={
                <TooltipTable
                  items={["Subtotal", eventPriceSubtotal(), "Tax", eventTax()]}
                ></TooltipTable>
              }
            >
              <Typography {...item_props} id="event-price">
                {formatCurrency(eventPrice())}
              </Typography>
            </Tooltip>
            <Typography {...label_props}>price</Typography>
          </Grid>
          <Grid {...grid_item_props}>
            <Tooltip
              title={
                <TooltipTable
                  items={[
                    "Price",
                    eventPrice(),
                    "Cost",
                    eventCost() * -1,
                    back_office.view_profit_as_percent
                      ? "Profit ($)"
                      : "Profit (%)",
                    !back_office.view_profit_as_percent
                      ? eventProfitPercent()
                      : eventProfit(),
                  ]}
                ></TooltipTable>
              }
            >
              <Typography
                {...item_props}
                color={profitColor()}
                id="event-profit"
              >
                {back_office.view_profit_as_percent
                  ? format_percent(eventProfitPercent())
                  : formatCurrency(eventProfit())}
              </Typography>
            </Tooltip>
            <Typography {...label_props}>profit</Typography>
          </Grid>
          {!event.is_template ? (
            <Grid item xs={2} sm={2} container>
              <Grid item xs={12} display="flex">
                <Tooltip
                  title={
                    disableLock()
                      ? "Cannot unlock event with an unsigned contract"
                      : eventLocked()
                        ? "Unlock event"
                        : "Lock event"
                  }
                >
                  <Box display="flex" width="100%">
                    <IconButton
                      onClick={toggleEventLocked}
                      sx={{ m: "auto" }}
                      disabled={disableLock()}
                      id="lock-event-button"
                    >
                      {eventLocked() ? (
                        <LockOutlinedIcon sx={{ color: "primary.main" }} />
                      ) : (
                        <LockOpenOutlinedIcon />
                      )}
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={12} display="flex">
                <Chip
                  label={eventHasAmendments() ? "Amended" : eventStatusName()}
                  sx={{
                    m: "auto",
                    backgroundColor: eventHasAmendments()
                      ? "error.main"
                      : undefined,
                    color: eventHasAmendments() ? "white" : undefined,
                  }}
                  size="small"
                  onClick={(e) => setMenuAnchorEl(e.target)}
                  id="event-status-button"
                />
                <Menu
                  sx={{ mt: "25px" }}
                  id="menu-statuscell"
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(menuAnchorEl)}
                  onClose={unsetMenuAnchorEl}
                  onClick={unsetMenuAnchorEl}
                >
                  {eventStatuses()
                    .filter(
                      (event_status) => event_status.value !== event.status,
                    )
                    .map((event_status) => (
                      <MenuItem
                        onClick={() => updateEventStatus(event_status.value)}
                        key={event_status.value}
                        id={"status-menu-item-" + event_status.value}
                      >
                        <Typography textAlign="center" alignItems="center">
                          {event_status.label}
                        </Typography>
                      </MenuItem>
                    ))}
                </Menu>
              </Grid>
              <ConfirmBookEvent
                open={confirmingBookedEvent}
                handleCancel={() => {
                  setConfirmingBookedEvent(false);
                }}
              />
              <UnlockDialog
                open={unlock_dialog_open}
                onClose={() => {
                  setUnlockDialogOpen(false);
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Paper>
  );
};

const UnlockDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Event Unlocked</DialogTitle>
      <DialogContent>
        <Typography>
          The price and quantities of your event are now unlocked.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="outlined"
          color="info"
          id="unlock-dialog-close-button"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TooltipTable = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  return (
    <Grid container spacing={1}>
      {props.items.map((item, idx) => (
        <Grid item xs={6} key={idx}>
          <Typography>
            {idx % 2 == 0
              ? item
              : props.items[idx - 1].includes("%")
                ? format_percent(item)
                : formatCurrency(item)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const ConfirmBookEvent = (props) => {
  const [error_message, setErrorMessage] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [
    event,
    flowerbuddyFeeCost,
    formatCurrency,
    initializeEvent,
    updateEvent,
  ] = useAccountState((state) => [
    state.event,
    state.flowerbuddyFeeCost,
    state.formatCurrency,
    state.initializeEvent,
    state.updateEvent,
  ]);

  const handleSubmit = () => {
    updateEvent({ status: "BO", locked: true });
    //lockRecipePrices();
  };

  const submit = () => {
    setLoading(true);
    payEventFeeApi({
      event_uuid: event.uuid,
      flowerbuddy_fee_cost: flowerbuddyFeeCost(),
    })
      .then((resp) => {
        initializeEvent(resp.data);
        handleSubmit();
        props.handleCancel();
        setLoading(false);
      })
      .catch((resp) => {
        console.log("ERROR RESPONSE", resp.response);
        setErrorMessage(resp.response.data.message);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setErrorMessage(false);
    setLoading(false);
    props.handleCancel();
  };

  return (
    <Dialog open={props.open} onClose={handleCancel}>
      <DialogTitle>Book Event</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to book this event? You will be charged{" "}
          {formatCurrency(flowerbuddyFeeCost())}.{" "}
          <Link href="/account?active_section=plan_selection">
            Upgrade to an unlimited plan here.
          </Link>
        </Typography>
        {error_message ? (
          <Alert severity="error">
            <Typography color="error.main">{error_message}</Typography>
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        {!loading ? (
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item xs={"auto"}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                color="info"
                id="confirm-book-event-cancel-button"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="secondary"
                onClick={submit}
                id="confirm-book-event-confirm-button"
              >
                Book Event
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(EventSummaryCard);
