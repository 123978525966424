import React from "react";
import "./TimezonePicker.css";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { IconButton, Tooltip } from "@mui/material";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import { Grid } from "@mui/material";

export const TimezonePicker = (props) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={11}>
        <div className="select-wrapper">
          <TimezoneSelect {...props} />
        </div>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Set to my current timezone (based on browser settings)">
          <IconButton
            id="timezone-picker-current-location-button"
            onClick={() => {
              props.onChange({
                value: Intl.DateTimeFormat().resolvedOptions().timeZone,
              });
            }}
          >
            <MyLocationOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
