import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Box,
  Typography,
  Grid,
  Snackbar,
  Button,
  Paper,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  Link,
  DialogActions,
} from "@mui/material";

import ReactPlayer from "react-player";

import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const Support = (props) => {
  const [copied, setCopied] = React.useState(false);
  const [tabsValue, setTabsValue] = React.useState("account_setup");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ p: ".5rem", pb: "2rem" }}>
      <Box
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",
          mt: "1rem",
        }}
      >
        <Typography variant="h2">Support</Typography>
        <Box
          sx={{
            overflow: "auto",
            height: "calc(100vh - 225px)",
          }}
        >
          <Box
            sx={{
              mb: "calc(100vh/2)",
            }}
          >
            <Paper sx={{ p: "1rem", mt: "1rem" }}>
              <Typography variant="h6">Email</Typography>
              <Typography>
                Have an issue? Or, have an idea on how we can improve
                FlowerBuddy?
              </Typography>
              <Typography>
                <CopyToClipboard
                  text={"support@flowerbuddy.co"}
                  onCopy={() => setCopied(true)}
                >
                  <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    sx={{ mt: ".5rem" }}
                  >
                    support@flowerbuddy.co
                  </Button>
                </CopyToClipboard>
                <Snackbar
                  open={copied}
                  autoHideDuration={3000}
                  onClose={() => {
                    setCopied(false);
                  }}
                  message="Email copied!"
                />
              </Typography>
            </Paper>
            <Paper sx={{ p: "1rem", mt: "1rem" }}>
              <Typography variant="h6">
                Schedule Free 1 on 1 Help Session
              </Typography>
              <Typography>
                Have and issue or idea? Just want someone to walk you through
                the app or a particular feature? Schedule a 1-2-1 video call
                with us.
              </Typography>
              <Button
                href="https://go.oncehub.com/FlowerBuddyUserSoftwareDemo?name=ADD_DYNAMIC_FIELD&email=ADD_DYNAMIC_FIELD&skip=1"
                target="_blank"
                variant="outlined"
                color="info"
                size="small"
                sx={{ mt: ".5rem" }}
              >
                Schedule Meeting
              </Button>
            </Paper>
            <Paper sx={{ p: "1rem", mt: "1rem" }}>
              <Typography variant="h6">Support Videos</Typography>
              <Tabs
                value={tabsValue}
                onChange={(event, newValue) => {
                  setTabsValue(newValue);
                }}
                variant="scrollable"
                scrollButtons={fullScreen}
              >
                <Tab label="Account Setup" value="account_setup" />
                <Tab label="Creating An Event" value="creating_an_event" />
                <Tab label="Education" value="education" />
              </Tabs>
              <SupportVideoTabPanel value={tabsValue} index="account_setup">
                <SupportVideoSection
                  number="1"
                  title="Mark Up & Fees"
                  duration="5"
                  description="Adjust and update the default markups and fees for your business.  Understand how changing profile fees only affects future events, not existing ones."
                  url="https://youtu.be/iiiemzna2_g"
                />
                <SupportVideoSection
                  number="2"
                  title="Account Settings"
                  duration="2"
                  description="Add your photo or company logo to represent your account, and update business info. (name, address, phone, email), or reset your password."
                  url="https://youtu.be/3oHFy3K_tiM"
                />
                <SupportVideoSection
                  number="3"
                  title="Proposal Default Design"
                  duration="6"
                  description="Set a default proposal design (add logo, set section visibility, add company urls, etc)"
                  url="https://youtu.be/uZKtUuqSqLo"
                />
                <SupportVideoSection
                  number="4"
                  title="Add Contract"
                  duration="6"
                  description="Add your floral contract to your account so that it appears on, and can be signed from, your client proposals."
                  url="https://youtu.be/XgLqmwVYXnI"
                />
                <SupportVideoSection
                  number="5"
                  title="Event Templates"
                  duration="7"
                  description="Create event templates so you don't have to start from scratch for every proposal."
                  url="https://youtu.be/tDgffw7WrkM"
                />
              </SupportVideoTabPanel>
              <SupportVideoTabPanel value={tabsValue} index="creating_an_event">
                <SupportVideoSection
                  number="1"
                  title="Create A New Event"
                  duration="7"
                  description="Event creation, overview of events page, and detailed guide to the “event details” tab."
                  url="https://youtu.be/WFkzuqSaII8"
                />
                <SupportVideoSection
                  number="2"
                  title="Floral Recipes"
                  duration="8"
                  description="Learn how to add photos, flowers, hard goods and labor to your floral recipe cards."
                  url="https://youtu.be/NybMT6O6WiY"
                />
                <SupportVideoSection
                  number="3"
                  title="Other Costs"
                  duration="2"
                  description="Add additional, non-recipe related costs, such as set up and strike fees."
                  url="https://youtu.be/qdX-GJZhF1M"
                />
                <SupportVideoSection
                  number="4"
                  title="Proposal Editor"
                  duration="8"
                  description="Tailor the design and content of your proposal prior to sending it to your client."
                  url="https://youtu.be/EQIFFVLgo0k"
                />
                <SupportVideoSection
                  number="5"
                  title="Proposal From Client's Perspective"
                  duration="5"
                  description="See what your client will see when you send them a FlowerBuddy proposal and they review and sign your contract."
                  url="https://youtu.be/pTYlsFiGOv0"
                />
                <SupportVideoSection
                  number="6"
                  title="Ordering And Waste Reduction"
                  duration="5"
                  description="See the stems / bunches required to deliver your event, and understand and reduce waste to improve profit."
                  url="https://youtu.be/crtHmuR7cls"
                />
              </SupportVideoTabPanel>
              <SupportVideoTabPanel value={tabsValue} index="education">
                <SupportVideoSection
                  number="1"
                  title="Flower Pricing Formula"
                  duration="3"
                  description="Learn about the industry standard formula and how it is calculated.  Remember, FlowerBuddy does the math for you, this video just help you see how we get there."
                  url="https://youtu.be/ExLZcTndY4c"
                />
              </SupportVideoTabPanel>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SupportVideoTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-TabPanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: "1rem" }}>{children}</Box>}
    </div>
  );
};

const SupportVideoSection = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = React.useState(false);
  return (
    <Grid
      item
      xs={12}
      container
      alignItems="flex-start"
      sx={{
        backgroundColor: props.number % 2 !== 0 ? "#EEEEEE" : undefined,
        p: ".5rem",
      }}
    >
      <Grid item xs={1}>
        <Typography>{props.number}</Typography>
      </Grid>
      <Grid item xs={8} sm={4}>
        <Link
          sx={{ "&:hover": { cursor: "pointer" } }}
          color="inherit"
          onClick={() => setOpen(true)}
        >
          <Typography>{props.title}</Typography>
        </Link>
      </Grid>
      <Grid item xs={3} sm={1}>
        <Typography>{props.duration + " min"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>{props.description}</Typography>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center">
            <Grid item xs="auto">
              {fullScreen ? (
                <Box>
                  <ReactPlayer
                    url={props.url}
                    width="19rem"
                    height="11.875rem"
                    controls
                  />
                </Box>
              ) : tablet && !fullScreen ? (
                <ReactPlayer
                  url={props.url}
                  width="37rem"
                  height="23.125rem"
                  controls
                />
              ) : (
                <ReactPlayer
                  url={props.url}
                  width="37rem"
                  height="23.125rem"
                  controls
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default withRouter(Support);
