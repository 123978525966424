import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Tooltip } from "@mui/material";
import { getColorNameByCode } from "../utils/item_utils";

const ColorIcon = (props) => {
  const light_colors = ["White", "Ivory"];

  const colorIsLight = () => {
    return light_colors.includes(getColorNameByCode(props.color));
  };

  return (
    <Tooltip title={getColorNameByCode(props.color)}>
      <Box
        sx={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: props.color,
          borderColor: colorIsLight() ? "lightgray" : undefined,
          borderWidth: colorIsLight() ? "1px" : undefined,
          borderStyle: colorIsLight() ? "solid" : undefined,
        }}
      ></Box>
    </Tooltip>
  );
};

export default withRouter(ColorIcon);
