import React from "react";
import { withRouter } from "react-router-dom";

import {
  getUserEvents,
  updateUserEvent,
  addUserEvent,
  getEventTemplates,
} from "../api/EventsApi";
import {
  eventDateFormatted,
  eventStatusName,
  eventStatuses,
} from "../utils/event_utils";

import {
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Link,
  TextField,
  Box,
  Tooltip,
  Grid,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@mui/material";

import { NewEventDialog } from "./Dashboard";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { userPlanType } from "../utils/user_utils";
import { useAccountState } from "../state/store";
import Tags, { TagFilterAndSearch } from "./Tags";
import { sortByDate, sortByName } from "../utils/utils";

const filterValues = [
  { label: "Inquiry", value: "IN" },
  { label: "Proposal", value: "PR" },
  { label: "Booked", value: "BO" },
  { label: "Completed", value: "CO" },
  { label: "Cancelled", value: "CA" },
  { label: "Lost", value: "LO" },
  { label: "Archived", value: "AR" },
];

const Events = (props) => {
  const [
    back_office,
    events,
    eventTemplates,
    initializeBackOffice,
    initializeEvents,
    initializeEventTemplates,
    updateEventInState,
  ] = useAccountState((state) => [
    state.back_office,
    state.events,
    state.eventTemplates,
    state.initializeBackOffice,
    state.initializeEvents,
    state.initializeEventTemplates,
    state.updateEventInState,
  ]);
  let qs = new URLSearchParams(props.location.search);
  let initial_status = qs.has("status") ? qs.get("status").toUpperCase() : "BO";
  const [filter_value, setFilterValue] = React.useState(initial_status);

  const updateFilterValue = (event, newValue) => {
    setFilterValue(newValue);
  };

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getRows();
    document.title = "Events";
  }, []);

  React.useEffect(() => {
    getEventTemplates().then((resp) => {
      initializeEventTemplates(resp.data);
    });
  }, []);

  const updateEventStatus = (uuid, new_status) => {
    let data = {
      uuid: uuid,
      status: new_status,
    };
    updateEventInState(data);
    updateUserEvent(data);
  };

  const getRows = () => {
    getUserEvents().then((resp) => {
      initializeEvents(resp.data.events);
      initializeBackOffice(resp.data.back_office);
      setLoading(false);
    });
  };

  const [canBookEvent, setCanBookEvent] = React.useState(
    !(userPlanType(props.user) == "PPE" || userPlanType(props.user) == "FRE")
  );

  React.useEffect(() => {
    setCanBookEvent(
      !(userPlanType(props.user) == "PPE" || userPlanType(props.user) == "FRE")
    );
  }, [props.user]);

  const numEvents = (status) => {
    return events.filter((row) => row.status == status).length;
  };

  const tabLabel = (filter) => {
    if (["IN", "PR", "BO"].includes(filter.value)) {
      return `${filter.label} (${numEvents(filter.value)})`;
    } else {
      return filter.label;
    }
  };

  const tabFontWeight = (filter) => {
    if (["IN", "PR", "BO"].includes(filter.value)) {
      return "bold";
    } else {
      return "normal";
    }
  };

  const [addingEvent, setAddingEvent] = React.useState(false);

  const handleAddEvent = (data) => {
    addUserEvent(data).then((resp) => {
      window.location.href = "/event/" + resp.data.uuid;
    });
  };

  const [sortBy, setSortBy] = React.useState("date");
  const [sortDirection, setSortDirection] = React.useState("desc");
  const handleSortClick = (field) => {
    if (field === sortBy) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortDirection("asc");
    }
  };

  const [filteredEvents, setFilteredEvents] = React.useState(events);

  const rows = () => {
    let results = filteredEvents.filter((row) => row.status == filter_value);
    if (sortBy === "name") {
      results = results.sort(sortByName);
    } else if (sortBy === "date") {
      results = results.sort(sortByDate);
    }
    if (sortBy && sortDirection === "desc") {
      results = results.reverse();
    }
    return results;
  };

  return (
    <Box sx={{ maxWidth: "800px", m: "auto", p: "1rem" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs="auto">
            <Typography variant="h5">Events</Typography>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setAddingEvent(true)}
            >
              <AddOutlinedIcon />
              New Event
            </Button>
            <NewEventDialog
              open={addingEvent}
              handleCancel={() => setAddingEvent(false)}
              handleSubmit={handleAddEvent}
              templates={eventTemplates}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={filter_value}
            onChange={updateFilterValue}
            variant="scrollable"
          >
            {filterValues.map((filter) => (
              <Tab
                key={filter.value}
                sx={{ fontSize: ".75rem", fontWeight: tabFontWeight(filter) }}
                label={tabLabel(filter)}
                value={filter.value}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              maxWidth: "800px",
              ml: "auto",
              mr: "auto",
              mt: "2rem",
              p: "1rem",
              height: "74vh",
              overflow: "auto",
            }}
          >
            <TagFilterAndSearch
              list={events}
              setFilteredList={setFilteredEvents}
              searchKeys={["name"]}
              disableColorTags
            />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "name"}
                      direction={sortDirection}
                      onClick={() => handleSortClick("name")}
                    >
                      Event Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === "date"}
                      direction={sortDirection}
                      onClick={() => handleSortClick("date")}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Grid container alignItems="center">
                      <Grid item xs="auto">
                        Status
                      </Grid>
                      {!canBookEvent && (
                        <Grid item xs="auto">
                          <Tooltip title="Note, you must edit event to change status to booked with your current subscription.">
                            <HelpOutlineOutlinedIcon fontSize="inherit" />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell sx={{ width: "120px" }}></TableCell>
                </TableRow>
              </TableHead>
              {rows().map((row) => (
                <TableRow key={row.uuid}>
                  <TableCell>
                    <Typography>
                      <Link
                        underline="hover"
                        color="text.primary"
                        href={"/event/" + row.uuid}
                        id={row.uuid + "-event-link"}
                      >
                        {row.name}
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {eventDateFormatted(
                        row.date,
                        row.timezone,
                        back_office.date_format
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <StatusCell
                      event_uuid={row.uuid}
                      label={eventStatusName(row.status)}
                      value={row.status}
                      updateEventStatus={updateEventStatus}
                      user={props.user}
                    />
                  </TableCell>
                  <TableCell>
                    <Tags event={row} disableColorTags addTagLabel="+" />
                  </TableCell>
                  <TableCell>
                    <RenderActions row={row} />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const event_statuses = eventStatuses();

const StatusCell = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const unsetMenuAnchorEl = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="110px"
    >
      <Typography>{props.label}</Typography>
      <Typography sx={{ textAlign: "center" }}>
        <IconButton
          onClick={(e) => setMenuAnchorEl(e.target)}
          id={props.event_uuid + "-status-menu-button"}
        >
          <MoreVertOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Typography>
      {Boolean(menuAnchorEl) && (
        <Menu
          sx={{ mt: "25px" }}
          id="menu-statuscell"
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menuAnchorEl)}
          onClose={unsetMenuAnchorEl}
          onClick={unsetMenuAnchorEl}
        >
          {event_statuses
            .filter((event_status) => event_status.value !== props.value)
            .map((event_status) => (
              <MenuItem
                onClick={() =>
                  props.updateEventStatus(props.event_uuid, event_status.value)
                }
                key={props.event_uuid + event_status.value}
                disabled={
                  event_status.value == "BO" &&
                  (userPlanType(props.user) == "PPE" ||
                    userPlanType(props.user) == "FRE")
                }
                id={event_status.value}
              >
                <Typography textAlign="center" alignItems="center">
                  {event_status.label}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}
    </Box>
  );
};

const RenderActions = (props) => {
  const [creating, setCreating] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const handleAddEvent = React.useCallback((data) => {
    addUserEvent(data).then((resp) => {
      window.location.href = "/event/" + resp.data.uuid;
    });
  }, []);

  const handleCreating = React.useCallback(() => {
    setCreating(true);
  }, []);

  const handleCancelCreating = React.useCallback(() => {
    setCreating(false);
  }, []);

  const handleDeleting = React.useCallback(() => {
    setDeleting(true);
  }, []);

  const handleCancelDeleting = React.useCallback(() => {
    setDeleting(false);
  }, []);

  return (
    <Box key={props.row.uuid}>
      <Tooltip title="Edit Event">
        <IconButton
          href={"/event/" + props.row.uuid}
          id={props.row.uuid + "-edit-button"}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Create Template From Event">
        <IconButton
          onClick={handleCreating}
          id={props.row.uuid + "-create-template-button"}
        >
          <NoteAddOutlinedIcon />
        </IconButton>
      </Tooltip>
      {creating && (
        <NewEventDialog
          open={creating}
          handleCancel={handleCancelCreating}
          handleSubmit={handleAddEvent}
          event_template={props.row}
          is_template={true}
          disable_template_selection={true}
        />
      )}
      <Tooltip title="Delete Event">
        <IconButton onClick={handleDeleting}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      {deleting && (
        <DeleteEventDialog
          event={props.row}
          open={deleting}
          handleCancel={handleCancelDeleting}
        />
      )}
    </Box>
  );
};

const DeleteEventDialog = (props) => {
  const [deleteEvent] = useAccountState((state) => [state.deleteEvent]);
  const handleSubmit = () => {
    deleteEvent(props.event.uuid);
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Event</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete event with name "{props.event.name}"
          on {eventDateFormatted(props.event.date, props.event.timezone)}? This
          action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(Events);
