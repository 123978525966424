import { other_costs } from "./event_items_utils";

export const AmendableOtherCostFields = ["quantity", "price", "sales_tax"];

export function otherCostHasAmendments(other_cost) {
  var res = false;
  AmendableOtherCostFields.forEach((field) => {
    if (field == "price") {
      if (
        other_cost["amend_" + field] !== null &&
        parseFloat(other_cost[field]) !==
          parseFloat(other_cost["amend_" + field])
      ) {
        res = true;
      }
    } else {
      if (
        other_cost["amend_" + field] !== null &&
        other_cost[field] !== other_cost["amend_" + field]
      ) {
        res = true;
      }
    }
  });
  return res;
}

export function otherCostsWithAmendments(event_items) {
  var other_costs_with_amendments = other_costs(event_items).filter(
    (other_cost) => otherCostHasAmendments(other_cost),
  );
  return other_costs_with_amendments.length;
}

export function otherCostsHaveAmendments(event_items) {
  return otherCostsWithAmendments(event_items) > 0;
}
