import React, { Component } from "react";

import {
  AppBar,
  Toolbar,
  Box,
  Link,
  Avatar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FBLogo from "../FBLogo.png";
import FBLogoWithText from "../FBLogoWithText.png";
import { getCroppedImgObj } from "../utils/cropImage";
import CroppedImg from "./CroppedImg";
import { ProfilePicture } from "./ItemIcon";
import { userPlanType } from "../utils/user_utils";

const navBarItems = [
  // { name: "Dashboard", href: "/dashboard" },
  {
    name: "Tasks",
    href: "/tasks",
  },
  {
    name: "Events",
    href: "/events",
  },
  {
    name: "Orders",
    href: "/orders",
  },
  {
    name: "Data",
    href: "/data",
  },
  {
    name: "Inventory",
    href: "/inventory",
  },
  {
    name: "Reports",
    href: "/reports",
  },
  {
    name: "Settings",
    href: "/profile_settings",
  },
];

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuAnchorEl: null,
      profileAnchorEl: null,
      showFreeTrialMessage: true,
    };

    this.setMenuAnchorEl = this.setMenuAnchorEl.bind(this);
    this.unsetMenuAnchorEl = this.unsetMenuAnchorEl.bind(this);
    this.setProfileAnchorEl = this.setProfileAnchorEl.bind(this);
    this.unsetProfileAnchorEl = this.unsetProfileAnchorEl.bind(this);
  }

  setMenuAnchorEl(e) {
    this.setState({
      menuAnchorEl: e.currentTarget,
    });
  }

  unsetMenuAnchorEl() {
    this.setState({
      menuAnchorEl: null,
    });
  }

  setProfileAnchorEl(e) {
    this.setState({
      profileAnchorEl: e.currentTarget,
    });
  }

  unsetProfileAnchorEl() {
    this.setState({
      profileAnchorEl: null,
    });
  }
  userPlanLabel() {
    if (userPlanType(this.props.user) === "FRE") {
      return "Starter";
    } else if (userPlanType(this.props.user) === "PPE") {
      return "Pro";
    } else {
      return "Unlimited";
    }
  }

  render() {
    return (
      <Box
        sx={{
          // backgroundColor: "#eeeeee",
          minHeight: "inherit",
        }}
      >
        <AppBar position="fixed" sx={{ bgcolor: "white", zIndex: 998 }}>
          <Toolbar disableGutters>
            <Logo
              withText
              display={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
              imgStyle={{ maxHeight: "40px" }}
            />
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <NavButton
                href={
                  process.env.REACT_APP_BASE_URL +
                  "/account?active_section=referral_code"
                }
                key={"referral_code"}
                color="primary"
              >
                Referral Code
              </NavButton>
              {navBarItems.map((navBarItem) => (
                <NavButton
                  href={navBarItem.href}
                  key={navBarItem.name}
                  target={navBarItem.target}
                >
                  {navBarItem.name}
                </NavButton>
              ))}
              <NavButton
                href="/account?active_section=plan_selection"
                variant="outlined"
              >
                {this.userPlanLabel()}
              </NavButton>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.setMenuAnchorEl}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.menuAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(this.state.menuAnchorEl)}
                onClose={this.unsetMenuAnchorEl}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {navBarItems.map((navBarItem) => (
                  <MenuItem key={navBarItem.name}>
                    <Link
                      href={navBarItem.href}
                      underline="none"
                      textAlign="center"
                      sx={{
                        color: "text.primary",
                      }}
                      target={navBarItem.target}
                    >
                      {navBarItem.name}
                    </Link>
                  </MenuItem>
                ))}
                <MenuItem key={"referral_code"}>
                  <Link
                    href={
                      process.env.REACT_APP_BASE_URL +
                      "/account?active_section=referral_code"
                    }
                    underline="none"
                    textAlign="center"
                    sx={{
                      color: "primary",
                    }}
                  >
                    Referral Code
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
            <Logo
              withText
              display={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "none",
                },
              }}
              imgStyle={{ maxHeight: "40px" }}
            />
            <Logo
              display={{
                display: {
                  xs: "flex",
                  sm: "none",
                },
              }}
              imgStyle={{ maxHeight: "40px" }}
            />
            <Box sx={{ marginRight: "1rem", marginLeft: "1rem" }}>
              <Tooltip title="Account">
                <IconButton sx={{ p: 0 }} onClick={this.setProfileAnchorEl}>
                  {/* <CroppedImg img={this.props.user.profile_picture} avatar /> */}
                  <ProfilePicture user={this.props.user} />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={this.state.profileAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(this.state.profileAnchorEl)}
              onClose={this.unsetProfileAnchorEl}
            >
              <Link
                href="/account"
                underline="none"
                sx={{ color: "text.primary" }}
              >
                <MenuItem>
                  <Typography>Account</Typography>
                </MenuItem>
              </Link>
              <MenuItem onClick={this.props.handleLogout}>
                <Typography textAlign="center">Log Out</Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Toolbar />
        {this.props.children}
      </Box>
    );
  }
}
const days_formatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

const daysToDate = (date) => {
  let d1 = new Date();
  let d2 = new Date(date);
  let delta = d2 - d1;
  return days_formatter.format(delta / (1000 * 3600 * 24));
};

const NavButton = (props) => {
  return (
    <Button
      {...props}
      sx={{
        color: props.color ? props.color : "text.primary",
      }}
    >
      {props.children}
    </Button>
  );
};

export const Logo = (props) => {
  return (
    <Link
      underline="none"
      href="/"
      sx={{
        fontFamily: "Pacifico",
        color: "text.primary",
        "&:hover": {
          color: "secondary.dark",
        },
        margin: ".5rem",
        flexGrow: 1,
        marginRight: "auto",
        ...props.display,
      }}
      variant="h4"
    >
      {props.withText ? (
        <LogoBeeWithText
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
          imgStyle={props.imgStyle}
        />
      ) : (
        <LogoBee
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
          imgStyle={props.imgStyle}
        />
      )}
    </Link>
  );
};

export const LogoBeeWithText = (props) => {
  const { imgStyle, ...otherProps } = props;
  return (
    <Box {...otherProps}>
      <img alt="" src={FBLogoWithText} style={imgStyle} />
    </Box>
  );
};

export const LogoBee = (props) => {
  const { imgStyle, ...otherProps } = props;
  return (
    <Box {...otherProps}>
      <img alt="" src={FBLogo} style={imgStyle} />
    </Box>
  );
};

export default Navbar;
