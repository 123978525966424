import { userPlanType } from "./user_utils.js";
import { unsignedDocumentExists } from "./document_utils.js";
import { eventItemsWithAmendments } from "./event_items_utils.js";
import { formatInTimeZone } from "date-fns-tz";

export const AmendableEventFields = ["date"];

export function eventStatusName(event_status) {
  switch (event_status) {
    case "AR":
      return "Archived";
    case "IN":
      return "Inquiry";
    case "PR":
      return "Proposal";
    case "BO":
      return "Booked";
    case "CO":
      return "Completed";
    case "CA":
      return "Cancelled";
    case "LO":
      return "Lost";
  }
}

export function eventStatuses() {
  return [
    {
      label: "Archived",
      value: "AR",
    },
    {
      label: "Inquiry",
      value: "IN",
    },
    {
      label: "Proposal",
      value: "PR",
    },
    {
      label: "Booked",
      value: "BO",
    },
    {
      label: "Completed",
      value: "CO",
    },
    {
      label: "Cancelled",
      value: "CA",
    },
    {
      label: "Lost",
      value: "LO",
    },
  ];
}

export const EventStatusOptions = {
  AR: { label: "Archived", value: "AR" },
  BO: { label: "Booked", value: "BO" },
  CA: { label: "Cancelled", value: "CA" },
  CO: { label: "Completed", value: "CO" },
  IN: { label: "Inquiry", value: "IN" },
  LO: { label: "Lost", value: "LO" },
  PR: { label: "Proposal", value: "PR" },
};

export function contractSignDate(proposal) {
  const signed_contracts = proposal.contracts.filter(
    (c) => (c.status = "signed")
  );
  if (signed_contracts.length > 0) {
    const ordered_signed_contracts = signed_contracts.sort(orderByDate);
    return ordered_signed_contracts[0].date_modified;
  } else {
    return undefined;
  }
}

export function contractExists(proposal) {
  if (proposal.contracts.length > 0) {
    return true;
  } else {
    return false;
  }
}

function orderByDate(a, b) {
  return a.date_modified - b.date_modified;
}

export function contractIsEnabled(back_office, user) {
  return userPlanType(user) == "UNL" ? back_office.contract_is_enabled : true;
}

export function eventHasAmendments(event_items) {
  return eventItemsWithAmendments(event_items) > 0;
}

export function eventLocked(event, documents) {
  return (
    (event.locked || unsignedDocumentExists(documents)) && !event.is_template
  );
}

export function daysToEvent(event) {
  const event_date = event.date;
  let d1 = new Date();
  let d2 = new Date(event_date);
  let delta = d2 - d1;
  return days_formatter.format(delta / (1000 * 3600 * 24));
}

export function eventDateFormatted(event_date, timezone, date_format) {
  if (date_format === undefined) {
    date_format = "MM/dd/yy";
  }
  if (typeof event_date !== "undefined") {
    return formatInTimeZone(new Date(event_date), timezone, date_format);
  } else {
    return null;
  }
}
export function eventDateTimeFormatted(event_date, timezone, date_format) {
  if (date_format === undefined) {
    date_format = "MM/dd/yy";
  }
  if (!timezone) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  if (typeof event_date !== "undefined") {
    return formatInTimeZone(
      new Date(event_date),
      timezone,
      `${date_format} h:mm a`
    );
  } else {
    return null;
  }
}

const days_formatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

export const eventNameAndDate = (event) => {
  return `${event.name} - ${eventDateFormatted(event.date, event.timezone)}`;
};
