import React, { createContext, useContext } from "react";
import { useAccountState } from "../state/store";

const AccountStateOverridesContext = createContext({});

export const AccountStateOverridesProvider = ({ children, ...overrides }) => {
  return (
    <AccountStateOverridesContext.Provider value={overrides}>
      {children}
    </AccountStateOverridesContext.Provider>
  );
};

export const useContextOrAccountState = (key) => {
  const context = useContext(AccountStateOverridesContext);
  const accountStateValue = useAccountState((state) => state[key]);

  // Return the value from context if it exists, otherwise fall back to useAccountState value
  return context[key] || accountStateValue;
};
