import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const FontSelector = (props) => {
  return (
    <FormControl fullWidth sx={props.formControlSx}>
      <InputLabel size={props.size} id="font-family-select-label">
        {props.label}
      </InputLabel>
      <Select
        {...props}
        name={props.id}
        label={props.label}
        id={props.id}
        value={props.value}
        onChange={props.updateFont}
        labelId={"font-family-select-label"}
        sx={props.sx}
        inputProps={props.inputProps}
        size={props.size}
        endAdornment={props.endAdornment}
        variant={props.variant}
      >
        <MenuItem value="Bebas Neue" sx={{ fontFamily: "Bebas Neue" }}>
          Bebas Neue
        </MenuItem>
        <MenuItem value="Parisienne" sx={{ fontFamily: "Parisienne" }}>
          Parisienne
        </MenuItem>
        <MenuItem value="PT Serif" sx={{ fontFamily: "PT Serif" }}>
          PT Serif
        </MenuItem>
        <MenuItem value="Raleway" sx={{ fontFamily: "Raleway" }}>
          Raleway
        </MenuItem>
        <MenuItem value="Sacramento" sx={{ fontFamily: "Sacramento" }}>
          Sacramento
        </MenuItem>
        <MenuItem value="Open Sans" sx={{ fontFamily: "Open Sans" }}>
          Open Sans
        </MenuItem>
        <MenuItem value="Roboto" sx={{ fontFamily: "Roboto" }}>
          Roboto
        </MenuItem>
        <MenuItem value="Merriweather" sx={{ fontFamily: "Merriweather" }}>
          Merriweather
        </MenuItem>
        <MenuItem value="Noto Sans" sx={{ fontFamily: "Noto Sans" }}>
          Noto Sans
        </MenuItem>
        <MenuItem value="Source Sans" sx={{ fontFamily: "Source Sans" }}>
          Source Sans
        </MenuItem>
        <MenuItem
          value="Playfair Display"
          sx={{ fontFamily: "Playfair Display" }}
        >
          Playfair Display
        </MenuItem>
        <MenuItem value="Poppins" sx={{ fontFamily: "Poppins" }}>
          Poppins
        </MenuItem>
        <MenuItem
          value="Libre Baskerville"
          sx={{ fontFamily: "Libre Baskerville" }}
        >
          Libre Baskerville
        </MenuItem>
        <MenuItem value="Quicksand" sx={{ fontFamily: "Quicksand" }}>
          Quicksand
        </MenuItem>
        <MenuItem value="Ubuntu" sx={{ fontFamily: "Ubuntu" }}>
          Ubuntu
        </MenuItem>
        <MenuItem value="Lato" sx={{ fontFamily: "Lato" }}>
          Lato
        </MenuItem>
        <MenuItem value="Montserrat" sx={{ fontFamily: "Montserrat" }}>
          Montserrat
        </MenuItem>
        <MenuItem value="Oswald" sx={{ fontFamily: "Oswald" }}>
          Oswald
        </MenuItem>
        <MenuItem
          value="Cormorant Garamond"
          sx={{ fontFamily: "Cormorant Garamond" }}
        >
          Cormorant Garamond
        </MenuItem>
        <MenuItem value="Pacifico" sx={{ fontFamily: "Pacifico" }}>
          Pacifico
        </MenuItem>
        <MenuItem value="Alex Brush" sx={{ fontFamily: "Alex Brush" }}>
          Alex Brush
        </MenuItem>
        <MenuItem value="Kaushan Script" sx={{ fontFamily: "Kaushan Script" }}>
          Kaushan Script
        </MenuItem>
        <MenuItem
          value="Monsieur La Doulaise"
          sx={{ fontFamily: "Monsieur La Doulaise" }}
        >
          Monsieur La Doulaise
        </MenuItem>
        <MenuItem value="Courgette" sx={{ fontFamily: "Courgette" }}>
          Courgette
        </MenuItem>
        <MenuItem
          value="Euphoria Script"
          sx={{ fontFamily: "Euphoria Script" }}
        >
          Euphoria Script
        </MenuItem>
        <MenuItem value="Redressed" sx={{ fontFamily: "Redressed" }}>
          Redressed
        </MenuItem>
        <MenuItem value="WindSong" sx={{ fontFamily: "WindSong" }}>
          WindSong
        </MenuItem>
        <MenuItem value="Yellowtail" sx={{ fontFamily: "Yellowtail" }}>
          Yellowtail
        </MenuItem>
        <MenuItem value="League Script" sx={{ fontFamily: "League Script" }}>
          League Script
        </MenuItem>
        <MenuItem value="Newsreader" sx={{ fontFamily: "Newsreader" }}>
          Newsreader
        </MenuItem>

        <MenuItem value="IBM Plex Sans" sx={{ fontFamily: "IBM Plex Sans" }}>
          IBM Plex Sans
        </MenuItem>
        <MenuItem value="Source Sans 3" sx={{ fontFamily: "Source Sans 3" }}>
          Source Sans 3
        </MenuItem>
        <MenuItem value="Alegreya" sx={{ fontFamily: "Alegreya" }}>
          Alegreya
        </MenuItem>
        <MenuItem value="Fira Sans" sx={{ fontFamily: "Fira Sans" }}>
          Fira Sans
        </MenuItem>

        <MenuItem value="Volkorn" sx={{ fontFamily: "Volkorn" }}>
          Volkorn
        </MenuItem>
        <MenuItem value="Varela Round" sx={{ fontFamily: "Varela Round" }}>
          Varela Round
        </MenuItem>
      </Select>
    </FormControl>
  );
};
