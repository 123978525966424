import { updateItemPriceApi } from "../api/EventsApi";

export const itemPricesSlice = (set, get) => ({
  item_prices: {},
  addItemPriceState: (data) => {
    let item_prices = get().item_prices;
    item_prices[data.item] = data;
    set({ item_prices: item_prices });
  },
  initializeItemPrices: (data) => set({ item_prices: data }),
  updateItemPrice: (data, cb) => {
    get().updateItemPriceInState(data);
    if (cb) {
      cb();
    }
    updateItemPriceApi(data);
  },
  updateItemPriceInState: (data) => {
    let item_prices = get().item_prices;
    if (item_prices[data.item] === undefined) {
      get().updateItemPriceInEventItemState(data);
    } else {
      item_prices[data.item] = {
        ...item_prices[data.item],
        ...data,
      };
      set({
        item_prices: {
          ...item_prices,
          [data.item]: { ...item_prices[data.item], ...data },
        },
      });
    }
  },
  updateItemPriceInEventItemState: (data) => {
    let event_items = get()
      .allEventItems(false, true)
      .find((ei) =>
        ei.template_item_prices.some((tp) => tp.uuid === data.uuid),
      );
    if (event_items.event_item_group) {
      event_items = [
        event_items,
        ...get().getEventItemByUuid(event_items.event_item_group)
          .event_items_in_group,
      ];
    } else {
      event_items = [event_items];
    }
    event_items.forEach((ei) => {
      let current_price = ei.template_item_prices.find(
        (tp) => tp.item === data.item,
      );
      if (current_price) {
        let new_template_item_prices = ei.template_item_prices.filter(
          (tp) => tp.item !== data.item,
        );
        new_template_item_prices = [
          ...new_template_item_prices,
          { ...current_price, ...data, uuid: current_price.uuid },
        ];
        get().updateEventItemInState({
          uuid: ei.uuid,
          template_item_prices: new_template_item_prices,
        });
      }
    });
  },
});
