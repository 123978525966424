import { unsecureAPI } from "../api";

export const getPaymentScheduleApi = () => {
  return unsecureAPI.get("api/payments/payment_schedule/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updatePaymentScheduleApi = (data) => {
  return unsecureAPI.put("api/payments/payment_schedule/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updatePaymentApi = (data) => {
  return unsecureAPI.put("api/payments/payment/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addPaymentApi = (data) => {
  return unsecureAPI.post("api/payments/payment/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deletePaymentApi = (uuid) => {
  return unsecureAPI.delete("api/payments/payment/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: { uuid: uuid },
  });
};

export const getStripeAccountApi = () => {
  return unsecureAPI.get("stripe/stripe_account/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getStripeLinkApi = () => {
  return unsecureAPI.get("stripe/stripe_link/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getPaymentIntentApi = (
  payment_uuid,
  amount,
  email,
  name,
  due_date,
) => {
  const data = {
    payment_uuid: payment_uuid,
    amount: amount,
    email: email,
    name: name,
    due_date: due_date,
  };
  return unsecureAPI.post("api/payments/payment_intent/", data, {});
};

export const getPaymentUnsecureApi = (payment_uuid) => {
  return unsecureAPI.get("api/payments/payment/unsecure/", {
    params: { payment_uuid: payment_uuid },
  });
};

export const getPaymentScheduleUnsecureApi = (payment_schedule_uuid) => {
  return unsecureAPI.get("api/payments/payment_schedule/unsecure/", {
    params: { payment_schedule_uuid: payment_schedule_uuid },
  });
};

export const refundPaymentApi = (payment_uuid) => {
  return unsecureAPI.post(
    "api/payments/payment/refund/",
    { payment_uuid: payment_uuid },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    },
  );
};

export const addFinalPayment = (event_uuid) => {
  return unsecureAPI.post(
    "api/payments/add_final_payment/",
    { event_uuid: event_uuid },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    },
  );
};

export const getCurrentInvoiceNumberApi = () => {
  return unsecureAPI.get("api/payments/invoice/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const setInvoiceNumberApi = (invoice_number) => {
  return unsecureAPI.put(
    "api/payments/invoice/",
    { invoice_number: invoice_number },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    },
  );
};
