import React from "react";

import PropTypes from "prop-types";
import * as Yup from "yup";

import { useFormik } from "formik";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { SignupLoginField, FormError } from "./SignupForm";
import { Logo } from "./Navbar";

const validationSchema = Yup.object({
  email: Yup.string().email().required("Must provide an email"),
});

const PasswordResetForm = (props) => {
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: { email: "", password: "" },
    onSubmit: (values) => {
      props.handlePasswordReset(values);
    },
  });

  return (
    <Grid container width="325px" sx={{ m: "auto" }}>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs="auto">
          <Logo withText imgStyle={{ maxHeight: "45px" }} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            width: "90%",
            padding: "1rem",
          }}
        >
          <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
            <Grid container justifyContent="space-around" rowSpacing={2}>
              <Grid item xs={12}>
                <SignupLoginField name="email" label="Email" formik={formik} />
              </Grid>
              <Grid item xs={12}>
                {!props.loading ? (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "100%",
                      marginTop: "1rem",
                    }}
                    color="secondary"
                  >
                    Reset Password
                  </Button>
                ) : (
                  <Box display="flex">
                    <CircularProgress sx={{ m: "auto" }} />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Button
                      size="small"
                      color="info"
                      onClick={props.toggle_forgot_password}
                    >
                      Return to Login
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default PasswordResetForm;
