import React, { useRef } from "react";
import { withRouter } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
  IconButton,
  Tabs,
  Tab,
  ImageList,
  ImageListItem,
  Link,
} from "@mui/material";

import { useDropzone } from "react-dropzone";
import ReactCrop from "react-image-crop";
import { getCroppedImgFile } from "../utils/cropImage";
import {
  copyCroppedImagesApi,
  getLibraryImagesApi,
  newImage,
  updateImage,
  uploadImageFile,
  uploadImageUrl,
  uploadPdfFile,
} from "../api/ImageApi";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CroppedImg from "./CroppedImg";
import { v4 as uuidv4 } from "uuid";
import { TagFilterAndSearch } from "./Tags";
import { useAccountState } from "../state/store";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton } from "@mui/lab";
import { set } from "lodash";

export const FileDropZoneDialog = (props) => {
  const handleSubmit = (images) => {
    props.handleSubmit(images);
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <FileDropZone {...props} handleSubmit={handleSubmit} />
    </Dialog>
  );
};

export const FileDropZone = (props) => {
  const [image, setImage] = React.useState(props.image);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(props.error);
  const [cropping, setCropping] = React.useState(false);
  const [moreOptionsOpen, setMoreOptionsOpen] = React.useState(false);

  const toggleMoreOptionsOpen = (e) => {
    e.stopPropagation();
    setMoreOptionsOpen(!moreOptionsOpen);
  };

  const inputFile = useRef(null);

  const onDrop = async (e) => {
    try {
      setLoading(true);
      stopDefaults(e);
      var file = e.dataTransfer.files[0];
      if (file) {
        try {
          var res = await uploadImageFile(
            file,
            undefined,
            props.user?.email,
            props.imageUploadData
          );
          var image = res.data;
          setImage(image);
          setCropping(true);
        } catch (err) {
          console.log("Failed to upload image: ", err);
          setLoading(false);
          setError(
            "Failed to upload image. Please try again or use a different image."
          );
        }
      } else {
        var imageUrl = e.dataTransfer.getData("text/html");
        if (!imageUrl) {
          var imageUrl = e.dataTransfer.getData("URL");
        } else {
          var rex = /src="?([^"\s]+)"?\s*/;
          var url;
          imageUrl = rex.exec(imageUrl)[1];
        }
        try {
          var res = await uploadImageUrl(
            imageUrl,
            props.filename,
            props.imageUploadData
          );
          var image = res.data;
          setImage(image);
          setCropping(true);
        } catch {
          setLoading(false);
          setError(
            "Failed to upload image from URL. Please try again, or try downloading the image before uploading to FlowerBuddy."
          );
        }
      }
      setLoading(false);
    } catch {
      setLoading(false);
      setError(
        "Something went wrong. Please try again. If the problem persists, try a different image."
      );
    }
  };

  const stopDefaults = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    stopDefaults(e);
  };

  const handleInputChange = (e) => {
    e.dataTransfer = { files: e.target.files };
    onDrop(e);
  };

  const handleClick = (e) => {
    if (!loading && !cropping && !moreOptionsOpen) {
      inputFile.current.click();
    }
  };

  const handleCancel = () => {
    setError(undefined);
    setLoading(false);
    setMoreOptionsOpen(false);
    setCropping(false);
    setImage(undefined);
  };

  const handleSubmit = (images) => {
    props.handleSubmit(images);
    handleCancel();
  };

  const handleSelectorSubmit = (images) => {
    copyCroppedImagesApi({
      cropped_image_uuids: images.map((img) => img.uuid),
    }).then((resp) => {
      images = resp.data;
      if (props.forceRecrop && props.maxSelections === 1) {
        setImage(images[0]);
        setCropping(true);
        setMoreOptionsOpen(false);
      } else {
        handleSubmit(images);
      }
    });
  };

  return (
    <Box
      style={{
        borderColor: "lightgrey",
        borderStyle: props.hideBorder ? "hidden" : "dotted",
        width: props.width ? props.width : "150px",
        height: props.height ? props.height : "150px",
        borderRadius: "5px",
      }}
      sx={{ "&:hover": { cursor: "pointer" }, ...(props.sx ? props.sx : {}) }}
      display="flex"
      alignItems="center"
      onDrop={onDrop}
      onDragOver={handleDragOver}
      onClick={handleClick}
      id={props.id + -"dropzone"}
    >
      <input
        type="file"
        id="file"
        style={{ display: "none" }}
        ref={inputFile}
        onChange={handleInputChange}
      />
      <Grid container justifyContent={"center"} spacing={1}>
        {loading ? (
          <Grid item xs="auto">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  m: "auto",
                  color: "info.main",
                  fontSize: ".75rem",
                }}
              >
                Drag and Drop
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex">
              <Button
                sx={{ m: "auto", fontSize: ".75rem" }}
                color="info"
                variant="outlined"
                size="small"
                id={props.id + -"browse-button"}
              >
                Browse Files
              </Button>
            </Grid>
            {!props.disableSelector && (
              <Grid item xs={12} display="flex">
                <Button
                  sx={{ m: "auto", fontSize: ".75rem" }}
                  color="info"
                  variant="outlined"
                  size="small"
                  id={props.id + -"browse-button"}
                  onClick={toggleMoreOptionsOpen}
                >
                  Image Library
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Cropper
        aspect={props.aspect}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        image={image}
        open={cropping}
      />
      {!props.disableSelector && (
        <ImageSelectorDialog
          handleSubmit={handleSelectorSubmit}
          handleCancel={handleCancel}
          open={moreOptionsOpen}
          maxSelections={props.maxSelections}
          disableMaxSelectionsText={props.disableMaxSelectionsText}
        />
      )}
    </Box>
  );
};

export const Cropper = (props) => {
  const [loading, setLoading] = React.useState(false);
  const initialCrop = () => {
    return {
      unit: "%",
      aspect: props.aspect,
      x: 0,
      y: 0,
      width: 100,
      height: props.aspect ? undefined : 100,
    };
  };
  const [crop, setCrop] = React.useState(initialCrop());
  React.useEffect(() => {
    setCrop(initialCrop());
  }, [props.aspect, props.image]);
  const handleChange = (newCrop, newPercentCrop) => {
    setCrop(newPercentCrop);
  };
  const handleCancel = () => {
    setCrop(initialCrop());
    props.handleCancel();
    setLoading(false);
  };
  const handleSubmit = () => {
    setLoading(true);
    updateImage({ ...props.image, crop: crop }).then((resp) => {
      props.handleSubmit([resp.data]);
      handleCancel();
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogContent>
        <Grid container>
          <Grid item xs={11}>
            <ReactCrop
              src={props.image?.src}
              crop={crop}
              onChange={handleChange}
              imageStyle={{ maxHeight: "550px" }}
            />
          </Grid>
          <Grid item xs={1} container>
            <Box>
              {!props.disableDelete && (
                <IconButton
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              )}

              <IconButton
                onClick={() =>
                  setCrop({
                    unit: "%",
                    x: 0,
                    y: 0,
                    width: 100,
                    height: props.aspect ? undefined : 100,
                    aspect: props.aspect,
                  })
                }
              >
                <FullscreenOutlinedIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id={"cancel-crop-button"}
          onClick={handleCancel}
          variant="outlined"
          color="info"
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          id={"submit-crop-button"}
          onClick={handleSubmit}
          disabled={!crop}
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const ImageSelectorDialog = (props) => {
  const [images, imgsInEvent, initializeImages] = useAccountState((state) => [
    state.images,
    state.imgsInEvent,
    state.initializeImages,
  ]);
  const [tabValue, setTabValue] = React.useState("library");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    if (images.length === 0) {
      getLibraryImagesApi().then((resp) => {
        initializeImages(resp.data);
      });
    }
  }, []);

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogContent>
        <>
          {imgsInEvent().length > 0 && !props.disableEventImages && (
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Library" value={"library"} />
              <Tab label="Event Images" value={"event"} />
            </Tabs>
          )}

          <TabPanel value={tabValue} index="library">
            <ImageSelector
              images={images}
              handleSubmit={props.handleSubmit}
              handleCancel={props.handleCancel}
              maxSelections={props.maxSelections}
              disableMaxSelectionsText={props.disableMaxSelectionsText}
            />
          </TabPanel>
          <TabPanel value={tabValue} index="event">
            <ImageSelector
              images={imgsInEvent()}
              disableFilters
              handleSubmit={props.handleSubmit}
              handleCancel={props.handleCancel}
              maxSelections={props.maxSelections}
              disableMaxSelectionsText={props.disableMaxSelectionsText}
            />
          </TabPanel>
        </>
      </DialogContent>
    </Dialog>
  );
};

const TabPanel = (props) => {
  return (
    <Box hidden={props.value !== props.index}>
      {props.value === props.index && <Box sx={{ p: 3 }}>{props.children}</Box>}
    </Box>
  );
};

const ImageSelector = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [filteredImages, setFilteredImages] = React.useState(props.images);
  const [selectedImages, _setSelectedImages] = React.useState([]);
  const clearSelections = () => {
    setSelectedImages([]);
  };

  const setSelectedImages = (images) => {
    if (props.maxSelections && images.length > props.maxSelections) {
      return;
    }
    _setSelectedImages(images);
  };

  return (
    <Grid container spacing={1}>
      {!props.disableFilters && (
        <Grid item xs={12}>
          <TagFilterAndSearch
            list={props.images}
            setFilteredList={setFilteredImages}
            searchKeys={["name", "description"]}
          />
        </Grid>
      )}
      {(!props.maxSelections || props.maxSelections > 1) && (
        <>
          <Grid item xs={12} container alignItems={"center"}>
            {props.maxSelections && !props.disableMaxSelectionsText && (
              <Grid item xs={12}>
                <Typography>Select up to {props.maxSelections}</Typography>
              </Grid>
            )}
            <Grid item xs="auto">
              <Typography alignItems={"center"}>
                {selectedImages.length} Selected{" "}
              </Typography>
            </Grid>
            {selectedImages.length > 0 && (
              <Grid item xs="auto">
                <IconButton onClick={clearSelections} size="small">
                  <CancelOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            )}

            <Grid item xs={12} container spacing={0.25}>
              {selectedImages.map((img) => (
                <Grid item xs="auto">
                  <SelectorImage
                    img={img}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    thumbnail
                    maxSelections={props.maxSelections}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {filteredImages.length > 0 && (
          <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
            <Grid container spacing={1} justifyContent={"center"}>
              {filteredImages.map((img) => (
                <SelectorImage
                  img={img}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  maxSelections={props.maxSelections}
                />
              ))}
            </Grid>
          </Box>
        )}
        {filteredImages.length === 0 && (
          <Typography align="center">No images found.</Typography>
        )}
      </Grid>
      <Grid item xs={12} container justifyContent={"flex-end"} spacing={1}>
        <Grid item xs="auto">
          <Button onClick={props.handleCancel} variant="outlined" color="info">
            Cancel
          </Button>
        </Grid>
        <Grid item xs="auto">
          <LoadingButton
            onClick={() => {
              setLoading(true);
              props.handleSubmit(selectedImages);
            }}
            variant="contained"
            color="secondary"
            disabled={selectedImages.length === 0}
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SelectorImage = (props) => {
  const isSelected = () => {
    return props.selectedImages.map((img) => img.uuid).includes(props.img.uuid);
  };
  const handleClick = () => {
    if (isSelected()) {
      props.setSelectedImages(
        props.selectedImages.filter((img) => img.uuid !== props.img.uuid)
      );
    } else {
      if (props.maxSelections && props.maxSelections === 1) {
        props.setSelectedImages([props.img]);
      } else {
        props.setSelectedImages([...props.selectedImages, props.img]);
      }
    }
  };
  return (
    <Grid item xs="auto">
      <Box
        sx={
          isSelected() && !props.thumbnail
            ? { border: "solid 5px", borderColor: "primary.main" }
            : undefined
        }
        onClick={handleClick}
      >
        {props.thumbnail ? (
          <CroppedImg img={props.img} height="50px" contain="100px50" />
        ) : (
          <CroppedImg img={props.img} height={100} contain="100px100" />
        )}
      </Box>
    </Grid>
  );
};

export const PDFUploader = (props) => {
  const [error, setError] = React.useState(props.error);
  const [loading, setLoading] = React.useState(props.loading);
  const inputFile = useRef(null);

  React.useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const onDrop = async (e) => {
    try {
      setLoading(true);
      stopDefaults(e);
      var file = e.dataTransfer.files[0];
      var extension = file.name.split(".").pop();
      uploadPdfFile(file, file.name).then((resp) => {
        props.savePdfUrl(resp.data.pdf_url);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(
        "Something went wrong. Please try again. If the problem persists, try a different document."
      );
    }
  };

  const stopDefaults = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    stopDefaults(e);
  };

  const handleInputChange = (e) => {
    e.dataTransfer = { files: e.target.files };
    onDrop(e);
  };

  return loading ? (
    <Box display="flex">
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  ) : (
    <Grid container justifyContent="center">
      <Grid item xs="auto">
        <Box
          style={{
            borderColor: "lightgrey",
            borderStyle: props.hideBorder ? "hidden" : "dotted",
            width: props.width ? props.width : "150px",
            height: props.height ? props.height : "150px",
            borderRadius: "5px",
          }}
          sx={{ "&:hover": { cursor: "pointer" } }}
          display="flex"
          alignItems="center"
          onDrop={onDrop}
          onDragOver={handleDragOver}
          onClick={
            props.onClick ? props.onClick : () => inputFile.current.click()
          }
          id="pdf-dropzone"
        >
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            ref={inputFile}
            onChange={handleInputChange}
          />
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  m: "auto",
                  color: "info.main",
                  fontSize: ".75rem",
                }}
              >
                Drag and Drop <br />
                or
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex">
              <Button
                sx={{ m: "auto", fontSize: ".75rem" }}
                color="info"
                variant="outlined"
                size="small"
                id="pdf-browse-button"
              >
                Browse
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {error ? (
        <Grid item xs={12}>
          <Typography color="error" textAlign="center">
            {error}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
