import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
} from "@mui/material";

import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";

export const PdfViewerDialog = (props) => {
  const transform = (slot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
    // GoToFirstPageMenuItem: () => <></>,
    // GoToLastPageMenuItem: () => <></>,
    // GoToNextPageMenuItem: () => <></>,
    // GoToPreviousPageMenuItem: () => <></>,
    // EnterFullScreenMenuItem: () => <></>,
    // OpenMenuItem: () => <></>,
    // RotateBackwardMenuItem: () => <></>,
    // RotateForwardMenuItem: () => <></>,
    // ShowPropertiesMenuItem: () => <></>,
    // SwitchScrollModeMenuItem: () => <></>,
    // SwitchSelectionModeMenuItem: () => <></>,
    // SwitchThemeMenuItem: () => <></>,
  });

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar,
  });

  //   const defaultLayoutPluginInstanceToolbar = defaultLayoutPlugin({
  //     renderToolbar,
  //   });

  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullScreen
      onClick={handleClick}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item xs="auto">
            {props.title}
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={props.handleCancel}>
              <DisabledByDefaultOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.js">
          <div style={{ height: "100%" }}>
            <Viewer
              fileUrl={props.pdf_url}
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        </Worker>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
