import React from "react";
import { withRouter } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tabs,
  Tab,
  InputAdornment,
} from "@mui/material";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CroppedImg from "./CroppedImg";
import { Cropper, FileDropZone } from "./FileUploader";
import { useAccountState } from "../state/store";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { DefaultStyles } from "../utils/proposal_utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const InspirationImgSectionV2 = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleUploadedFile = (images) => {
    var data = {
      cropped_image: images,
      version: 2,
    };
    props.addImg(data);
  };

  const max_photos = 50;

  const decreaseColumns = () => {
    if (
      (props.proposal_section.style?.inspiration_images_cols ||
        DefaultStyles.imageCols) > 1
    ) {
      updateProposalSectionStyle(props.proposal_section.uuid, {
        inspiration_images_cols:
          (props.proposal_section.style?.inspiration_images_cols ||
            DefaultStyles.imageCols) - 1,
      });
    }
  };

  const increaseColumns = () => {
    if (
      (props.proposal_section.style?.inspiration_images_cols ||
        DefaultStyles.imageCols) < 9
    ) {
      updateProposalSectionStyle(props.proposal_section.uuid, {
        inspiration_images_cols:
          (props.proposal_section.style?.inspiration_images_cols ||
            DefaultStyles.imageCols) + 1,
      });
    }
  };

  const decreaseGaps = () => {
    if (
      (props.proposal_section.style?.inspiration_images_gap ||
        DefaultStyles.imageGap) > 0
    ) {
      updateProposalSectionStyle(props.proposal_section.uuid, {
        inspiration_images_gap:
          (props.proposal_section.style?.inspiration_images_gap ||
            DefaultStyles.imageGap) - 1,
      });
    }
  };

  const increaseGaps = () => {
    if (
      (props.proposal_section.style?.inspiration_images_gap ||
        DefaultStyles.imageGap) < 50
    ) {
      updateProposalSectionStyle(props.proposal_section.uuid, {
        inspiration_images_gap:
          (props.proposal_section.style?.inspiration_images_gap ||
            DefaultStyles.imageGap) + 1,
      });
    }
  };

  return (
    <Grid container>
      {!props.displayOnly ? (
        <Grid item xs={12} container spacing={1}>
          <Grid item xs="auto">
            <TextField
              sx={{
                width: "6rem",
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0!important",
                  paddingLeft: "0!important",
                },
              }}
              size="small"
              label="Columns"
              value={
                props.proposal_section.style?.inspiration_images_cols ||
                DefaultStyles.imageCols
              }
              inputProps={{
                style: { textAlign: "center" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      sx={{ pr: 0 }}
                      onClick={decreaseColumns}
                      disabled={
                        (props.proposal_section.style
                          ?.inspiration_images_cols ||
                          DefaultStyles.imageCols) < 2
                      }
                    >
                      <RemoveCircleOutlineOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ pl: 0 }}
                      onClick={increaseColumns}
                      disabled={
                        (props.proposal_section.style
                          ?.inspiration_images_cols ||
                          DefaultStyles.imageCols) > 8
                      }
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <TextField
              sx={{
                width: "7rem",
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0!important",
                  paddingLeft: "0!important",
                },
              }}
              size="small"
              label="Spacing"
              value={
                props.proposal_section.style?.inspiration_images_gap ||
                DefaultStyles.imageGap
              }
              inputProps={{
                style: { textAlign: "center" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      sx={{ pr: 0 }}
                      onClick={decreaseGaps}
                      disabled={
                        (props.proposal_section.style?.inspiration_images_gap ||
                          DefaultStyles.imageGap) < 1
                      }
                    >
                      <RemoveCircleOutlineOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ pl: 0 }}
                      onClick={increaseGaps}
                      disabled={
                        (props.proposal_section.style?.inspiration_images_gap ||
                          DefaultStyles.imageGap) > 49
                      }
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box>
          <ImageList
            variant="masonry"
            cols={
              props.proposal_section.style?.inspiration_images_cols ||
              DefaultStyles.imageCols
            }
            gap={
              props.proposal_section.style?.inspiration_images_gap ||
              DefaultStyles.imageGap
            }
          >
            {props.imgs
              .filter((img) => img.version === 2)
              .map((img, ind) => (
                <InspirationImg
                  img={img}
                  removeImg={props.removeImg}
                  addImg={props.addImg}
                  updateProposalImg={props.updateProposalImg}
                  key={ind}
                  display="contents"
                  ind={ind}
                  id={props.id + "-" + ind}
                  displayOnly={props.displayOnly}
                  zoom={props.zoom}
                />
              ))}
          </ImageList>
        </Box>
      </Grid>
      {!props.displayOnly &&
      props.imgs.filter((img) => img.version === 2).length < max_photos ? (
        <Grid item xs={12}>
          <>
            <Box
              sx={{
                width: "95%",
                p: "1rem",
                borderStyle: "dotted",
                borderColor: "lightgrey",
                display: props.displayOnly ? "none" : "flex",
                overflow: "visible",
              }}
            >
              <Box sx={{ m: "auto", width: "100%", height: "100%" }}>
                <FileDropZone
                  hideBorder
                  width="100%"
                  height="100%"
                  id={props.id + "-dropzone"}
                  handleSubmit={handleUploadedFile}
                  maxSelections={
                    max_photos -
                    props.imgs.filter((img) => img.version === 2).length
                  }
                  disableMaxSelectionsText
                />
              </Box>
            </Box>
          </>
        </Grid>
      ) : null}
    </Grid>
  );
};

const InspirationImg = (props) => {
  const [img, setImg] = React.useState(props.img);
  const [cropping, setCropping] = React.useState(false);
  const toggleCropping = () => setCropping(!cropping);

  const handleCropSubmit = (cropped_images) => {
    props.updateProposalImg({
      uuid: img.uuid,
      cropped_image: { ...img.cropped_image, ...cropped_images[0] },
    });
  };

  const handleDelete = () => {
    props.removeImg(img.uuid);
    setImg(undefined);
  };

  React.useEffect(() => {
    setImg(props.img);
  }, [props.img, props.img.cropped_image]);

  return img ? (
    <ImageListItem
      id={props.id + "-image-list-item"}
      key={props.id + "-image-list-item"}
    >
      {!props.displayOnly && (
        <ImageListItemBar
          sx={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
              "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
          }}
          // title={props.ind}
          position="top"
          actionIcon={
            <>
              <IconButton
                sx={{ color: "white" }}
                aria-label={`star ${img.uuid}`}
                onClick={handleDelete}
                size="small"
                id={props.id + "-delete-button"}
              >
                <ClearOutlinedIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                sx={{ color: "white" }}
                aria-label={`star ${img.uuid}`}
                onClick={toggleCropping}
                size="small"
                id={props.id + "-delete-button"}
              >
                <EditOutlinedIcon fontSize="inherit" />
              </IconButton>
            </>
          }
          actionPosition="left"
        />
      )}
      <CroppedImg img={img.cropped_image} zoom={props.zoom} width="100%" />
      {!props.displayOnly && (
        <Cropper
          open={cropping}
          handleCancel={toggleCropping}
          handleSubmit={handleCropSubmit}
          image={img.cropped_image}
          disableDelete
        />
      )}
    </ImageListItem>
  ) : null;
};

export default withRouter(InspirationImgSectionV2);
