import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import { sortByName } from "../utils/utils";
import { useFormik } from "formik";
import { useAccountState } from "../state/store";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Forms = (props) => {
  const [forms] = useAccountState((state) => [state.forms]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [adding, setAdding] = React.useState(false);

  return (
    <Grid container justifyContent="space-between" spacing={1}>
      <Grid item xs="auto">
        <Typography variant="h6">Forms</Typography>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setAdding(true)}
          id="add-tax-profile-button"
        >
          Add Form
        </Button>
        <AddFormDialog open={adding} handleCancel={() => setAdding(false)} />
      </Grid>
      {forms && forms.length > 0 ? (
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {forms.sort(sortByName).map((form) => {
                return <FormLine form={form} />;
              })}
            </TableBody>
          </Table>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box
            sx={{
              m: "1rem",
              p: "1rem",
              border: "dashed 2px",
              borderRadius: "5px",
              borderColor: "info.main",
            }}
          >
            <Typography textAlign={"center"}>
              No forms have been added.
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const FormLine = (props) => {
  const [deleting, setDeleting] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body1">{props.form.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{props.form.description}</Typography>
      </TableCell>
      <TableCell>
        <Grid container spacing={1} justifyContent={"flex-end"}>
          <Grid item xs="auto">
            <IconButton href={`/form/edit/${props.form.uuid}`}>
              <EditOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={() => setDeleting(true)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
            <DeleteFormDialog
              form={props.form}
              open={deleting}
              handleCancel={() => setDeleting(false)}
            />
          </Grid>
          <Grid item xs="auto">
            <CopyToClipboard
              text={process.env.REACT_APP_BASE_URL + "/form/" + props.form.uuid}
              onCopy={() => {
                setCopied(true);
              }}
            >
              <Tooltip title="Copy sharable form link" placement="top">
                <IconButton id="copy-form-link-button">
                  <IosShareOutlinedIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
            <Snackbar
              open={copied}
              autoHideDuration={3000}
              onClose={() => {
                setCopied(false);
              }}
              message="Link copied!"
            />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const AddFormDialog = (props) => {
  const [addForm] = useAccountState((state) => [state.addForm]);
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    onSubmit: (values, { resetForm }) => {
      addForm(values);
      resetForm();
      props.handleCancel();
    },
  });
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Form</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: ".5rem" }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="description"
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteFormDialog = (props) => {
  const [deleteForm] = useAccountState((state) => [state.deleteForm]);
  const handleSubmit = () => {
    deleteForm(props.form.uuid);
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Form</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete form with name "{props.form.name}"?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(Forms);
