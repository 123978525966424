import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  CircularProgress,
  LinearProgress,
  IconButton,
  TextField,
  Autocomplete,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";

import { useAccountState } from "../state/store";
import {
  deleteReportApi,
  getReportStatusApi,
  getReportsApi,
} from "../api/ReportsApi";
import { useFormik } from "formik";
import {
  reportGenerationArgs,
  reportGenerationArgsKeys,
  reportStatusOptions,
  reportTypeOptions,
} from "../utils/report_utils";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { formatDateWithTime } from "../utils/utils";
import { EventStatusOptions } from "../utils/event_utils";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { DataGrid } from "@mui/x-data-grid";
import Papa from "papaparse";
import { copyFormatted } from "./OrderItems";
import { DatePicker } from "@mui/x-date-pickers";
import { useLocaleContext } from "./locale";

const Reports = (props) => {
  const [reports, setReports] = React.useState([]);
  const page_size_options = [10, 25, 50];
  const [limit, setLimit] = React.useState(page_size_options[0]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [next, setNext] = React.useState(null);
  const [previous, setPrevious] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [generatingReport, setGeneratingReport] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const loadReports = () => {
    setLoading(true);
    getReportsApi(limit, pageNumber * limit).then((res) => {
      setReports(res.data.results);
      setLoading(false);
      setCount(res.data.count);
      setNext(res.data.next);
      setPrevious(res.data.previous);
      document.title = "Reports";
    });
  };

  React.useEffect(() => {
    loadReports();
  }, [limit, pageNumber]);

  return (
    <Box>
      <Box
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",
          mt: "1rem",
          overflow: "auto",
          scrollMargin: "50vh",
          height: "92vh",
        }}
      >
        <Paper sx={{ p: "1rem" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} container justifyContent={"space-between"}>
              <Grid item xs="auto">
                <Typography
                  variant="h5"
                  sx={fullScreen ? { ml: ".5rem" } : null}
                >
                  Reports
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setGeneratingReport(true)}
                >
                  <AddOutlinedIcon /> New Report
                </Button>
                <GenerateReportDialog
                  open={generatingReport}
                  handleCancel={() => setGeneratingReport(false)}
                  handleSubmit={() => {
                    loadReports();
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Configuration</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <CircularProgress />
                  ) : reports.length > 0 ? (
                    reports.map((report) => (
                      <ReportRow
                        key={report.id}
                        report={report}
                        loadReports={loadReports}
                      />
                    ))
                  ) : (
                    <TableCell colSpan={4}>
                      <Box
                        sx={{
                          p: "1rem",
                          border: "dashed 2px",
                          borderColor: "info.main",
                          borderRadius: "10px",
                        }}
                      >
                        <Typography textAlign={"center"} color="info.main">
                          No reports have been generated.
                        </Typography>
                      </Box>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={page_size_options}
                component="div"
                count={count}
                rowsPerPage={limit}
                onRowsPerPageChange={(e) => parseInt(setLimit(e.target.value))}
                page={pageNumber}
                onPageChange={(e, newPage) => setPageNumber(newPage)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

const ReportRow = (props) => {
  const locale = useLocaleContext();
  const [report, setReport] = React.useState(props.report);
  React.useEffect(() => {
    setReport(props.report);
  }, [props.report]);
  const [statusChecker, setStatusChecker] = React.useState(null);

  const [loadingStatus, setLoadingStatus] = React.useState(false);

  const updateStatus = () => {
    // setLoadingStatus(true);
    getReportStatusApi(props.report.uuid).then((res) => {
      setReport(res.data);
      if (res.data.status !== "PE") {
        clearInterval(statusChecker);
      }
      setLoadingStatus(false);
    });
  };

  React.useEffect(() => {
    if (props.report.status === "PE") {
      const interval = setInterval(updateStatus, 10000);
      setStatusChecker(interval);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  const download = () => {
    const link = document.createElement("a");
    link.href = report.report_url;
    link.download = "FlowerBuddyReport.csv";
    link.click();
  };

  const [deleting, setDeleting] = React.useState(false);
  const [viewing, setViewing] = React.useState(false);
  const [copySuccess, setCopySuccess] = React.useState(false);
  const [copyError, setCopyError] = React.useState(false);

  const handleCopyToClipboard = async () => {
    try {
      const response = await fetch(report.report_url);
      const csvData = await response.text();

      var rows = csvData.split("\n").map((row) => row.split(","));
      rows = [rows[0], ...rows.slice(2, -2)];
      var output = `<table border="1" cellpadding="5" cellspacing="1" style="width:50%;background-color:#ffffff;border-collapse:collapse;border:1px solid #000000">`;
      if (rows.length > 0) {
        var headers = rows[0].map((v) => `<th>${v}</th>`);

        output += `<thead><tr>${headers.join("")}</tr></thead>`;
      }
      if (rows.length > 1) {
        output += "<tbody>";
        var output_rows = rows.slice(1).map((r) => {
          var row_values = r.map((v) => `<td nowrap>${v}</td>`);
          return `<tr>${row_values.join("")}</tr>`;
        });
        output += output_rows.join("");
        output += "</tbody>";
      }
      output += "</table>";
      copyFormatted(output);
      setCopySuccess(true);
    } catch (error) {
      console.log("ERROR", error);
      setCopyError(true);
    }
  };

  return (
    <TableRow>
      <TableCell>{reportTypeOptions[report.report_type].label}</TableCell>
      <TableCell>
        {report.status === "PE" ? (
          <LinearProgress variant="determinate" value={report.progress} />
        ) : (
          formatDateWithTime(report.requested_on, locale)
        )}
      </TableCell>
      <TableCell>{reportStatusOptions[report.status].label}</TableCell>
      <TableCell>
        {reportGenerationArgsKeys
          .filter((key) => report?.report_generation_args[key]?.length > 0)
          .map((key) => (
            <>
              <Typography variant="body3" color={"info.main"}>
                {`${reportGenerationArgs[key].label}: ${reportGenerationArgs[
                  key
                ].format_func(report.report_generation_args[key], locale)}`}
              </Typography>
              <br />
            </>
          ))}
      </TableCell>
      <TableCell>
        <Tooltip title="View data">
          <IconButton
            onClick={() => setViewing(true)}
            disabled={report.status !== "CO"}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
        <ViewReportDialog
          handleCancel={() => setViewing(false)}
          open={viewing}
          report={report}
        />
        <Tooltip title="Copy data to clip board">
          <IconButton
            onClick={handleCopyToClipboard}
            disabled={report.status !== "CO"}
          >
            <ContentCopyOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Snackbar
          open={copySuccess}
          autoHideDuration={5000}
          onClose={() => setCopySuccess(false)}
        >
          <Alert severity="success">Copied to clipboard!</Alert>
        </Snackbar>
        <Snackbar
          open={copyError}
          autoHideDuration={5000}
          onClose={() => setCopyError(false)}
        >
          <Alert severity="error">Error copying to clipboard!</Alert>
        </Snackbar>
        <Tooltip title="Download CSV file">
          <IconButton disabled={report.status !== "CO"} onClick={download}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete report">
          <IconButton onClick={() => setDeleting(true)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
        <DeleteReportDialog
          report={report}
          open={deleting}
          handleSubmit={props.loadReports}
          handleCancel={() => setDeleting(false)}
        />
      </TableCell>
    </TableRow>
  );
};

const GenerateReportDialog = (props) => {
  const [generateReport] = useAccountState((state) => [state.generateReport]);

  const formik = useFormik({
    initialValues: {
      report_type: "EV",
      start_date: null,
      end_date: null,
      event_status: [],
    },
    onSubmit: (values, { resetForm }) => {
      generateReport(values, props.handleSubmit);
      props.handleCancel();
      resetForm();
    },
  });

  const handleCancel = () => {
    props.handleCancel();
    formik.resetForm();
  };
  return (
    <Dialog open={props.open} onClose={handleCancel}>
      <DialogTitle>Generate Report</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mt: ".5rem" }}>
              <InputLabel id="report-type-select-label">Report Type</InputLabel>
              <Select
                labelId="report-type-select-label"
                id="report-type-select"
                value={formik.values.report_type}
                label="Report Type"
                onChange={formik.handleChange("report_type")}
              >
                {Object.entries(reportTypeOptions).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <EventStatusField formik={formik} />
          <DatetimeField
            id="start_date"
            formik={formik}
            label={"Start Date (optional)"}
          />
          <DatetimeField
            id="end_date"
            formik={formik}
            label={"End Date (optional)"}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DatetimeField = (props) => {
  return (
    <Grid item xs={12}>
      <DatePicker
        name={props.id}
        id={props.id}
        label={props.label}
        value={props.formik.values[props.id]}
        onChange={(val) => {
          props.formik.setFieldValue(props.id, val);
        }}
        error={
          props.formik.touched.event_date &&
          Boolean(props.formik.errors.event_date)
        }
        renderInput={(params) => <TextField {...params} fullWidth />}
        fullWidth
      />
    </Grid>
  );
};

const EventStatusField = (props) => {
  return (
    <Grid item xs={12}>
      <Autocomplete
        id="event_status"
        name="event_status"
        options={Object.entries(EventStatusOptions).map(
          ([key, value]) => value
        )}
        getOptionLabel={(option) => option.label}
        value={props.formik.values.event_status}
        multiple
        onChange={(event, newValue) => {
          props.formik.setFieldValue("event_status", newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Event Status (optional)"
            placeholder="Event Status"
          />
        )}
      />
    </Grid>
  );
};

const DeleteReportDialog = (props) => {
  const handleSubmit = () => {
    deleteReportApi(props.report.uuid).then((resp) => {
      props.handleSubmit();
      props.handleCancel();
    });
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Report</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this report? This action cannot be
          undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ViewReportDialog = (props) => {
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    // Fetch and parse CSV data
    fetch(props.report.report_url)
      .then((response) => response.text())
      .then((csvData) => {
        const results = Papa.parse(csvData, { header: true });
        setData(results.data.map((row, idx) => ({ ...row, id: idx })));

        const cols = results.meta.fields.map((field) => ({
          field: field,
          headerName: field,
          width: 150,
        }));
        setColumns(cols);
      });
  }, [props.report.report_url]);

  return (
    <Dialog open={props.open} onClose={props.handleCancel} fullScreen>
      <DialogTitle>
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs="auto">
            <IconButton onClick={props.handleCancel}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DataGrid rows={data} columns={columns} autoPageSize />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"flex-start"}>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="info"
              onClick={props.handleCancel}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>{" "}
    </Dialog>
  );
};

export default withRouter(Reports);
