import {
  addEventCoordinatorApi,
  getEventCoordinatorsApi,
  updateEventCoordinator,
} from "../api/EventsApi";

export const eventCoordinatorsSlice = (set, get) => ({
  event_coordinators: [],
  initialized: false,

  addEventCoordinator: (data, cb) => {
    addEventCoordinatorApi({ ...data, event_uuid: get().event.uuid }).then(
      (resp) => {
        let event_coordinators = get().event_coordinators;
        set({ event_coordinators: [...event_coordinators, resp.data] });
        if (cb) {
          cb(resp.data);
        }
      },
    );
  },
  createEventContact: (data, cb) => {
    addEventCoordinatorApi(data).then((resp) => {
      let event_coordinators = get().event_coordinators;
      set({ event_coordinators: [...event_coordinators, resp.data] });
      if (cb) {
        cb(resp.data);
      }
    });
  },
  filteredEventCoordinators: () => {
    get().getEventCoordinators();
    let event_coordinators = get().event_coordinators;
    let event_contacts = get().event.event_contacts;
    let event_contacts_uuids = event_contacts.map((ec) => ec.uuid);
    if (event_coordinators.length === 0) {
      return [];
    }
    return event_coordinators.filter(
      (ec) => !event_contacts_uuids.includes(ec.uuid),
    );
  },
  getEventCoordinators: () => {
    if (!get().initialized) {
      getEventCoordinatorsApi().then((resp) => {
        get().initializeEventCoordinators(resp.data);
      });
    }
  },
  initializeEventCoordinators: (data) =>
    set({ event_coordinators: data, initialized: true }),
  updateEventContact: (data) => {
    let event_coordinators = get().event_coordinators;
    let index = event_coordinators.findIndex((ec) => ec.uuid === data.uuid);
    let new_event_coordinator = { ...event_coordinators[index], ...data };
    let new_event_coordinators = [
      ...event_coordinators.slice(0, index),
      new_event_coordinator,
      ...event_coordinators.slice(index + 1),
    ];
    let event_contacts = get().event.event_contacts;
    let event_contacts_index = event_contacts.findIndex(
      (ec) => ec.uuid === data.uuid,
    );
    let new_event_contact = {
      ...event_contacts[event_contacts_index],
      ...data,
    };
    let new_event_contacts = [
      ...event_contacts.slice(0, event_contacts_index),
      new_event_contact,
      ...event_contacts.slice(event_contacts_index + 1),
    ];
    set({
      event_coordinators: new_event_coordinators,
      event: { ...get().event, event_contacts: new_event_contacts },
    });
    updateEventCoordinator(data).then((resp) => {
      if (!resp.data.success) {
        set({
          event_coordinators: event_coordinators,
          event: { ...get().event, event_contacts: event_contacts },
        });
      }
    });
  },
});
