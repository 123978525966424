import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Typography,
  Box,
  Grid,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  Chip,
} from "@mui/material";

import TabPanel from "./TabPanel";

import { useAccountState } from "../state/store";
import { getInventoryPageData } from "../api/InventoryApi";
import InventoryItems from "./InventoryItems";
import InventoryLog from "./InventoryLog";
import InventoryPurchases from "./InventoryPurchases";
import InventoryLocations from "./InventoryLocations";
import { getSelectableItems } from "../api/EventsApi";
import PremiumFeature, { PremiumOnlyFeature } from "./PremiumFeature";

const Inventory = (props) => {
  const [
    initializeInventoryAddresses,
    initializeInventoryLocations,
    initializeInventoryItems,
    initializeSelectableItems,
    initializeUser,
    updateUser,
    user,
    userPlanType,
  ] = useAccountState((state) => [
    state.initializeInventoryAddresses,
    state.initializeInventoryLocations,
    state.initializeInventoryItems,
    state.initializeSelectableItems,
    state.initializeUser,
    state.updateUser,
    state.user,
    state.userPlanType,
  ]);
  const [loading, setLoading] = React.useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabsValue, setTabsValue] = React.useState("inventory");
  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  React.useEffect(() => {
    initializeUser(props.user);
    getInventoryPageData().then((resp) => {
      initializeInventoryAddresses(resp.data.inventory_addresses);
      initializeInventoryLocations(resp.data.inventory_locations);
      initializeInventoryItems(resp.data.inventory_items);
      setLoading(false);
    });
    getSelectableItems().then((resp) => {
      initializeSelectableItems(resp.data);
    });
    document.title = "Inventory";
  }, []);

  const toggleInventoryEnabled = () => {
    updateUser({
      inventory_enabled: !user.inventory_enabled,
    });
  };

  return (
    <Box>
      <Box
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",
          mt: "1rem",
          overflow: "auto",
          scrollMargin: "50vh",
          height: "92vh",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={fullScreen ? { ml: ".5rem" } : null}>
              Inventory
              <Chip label="BETA" size="small" sx={{ ml: ".25rem" }} />
              {(userPlanType() === "FRE" || !userPlanType()) && (
                <PremiumOnlyFeature />
              )}
            </Typography>
          </Grid>
          {userPlanType !== "FRE" && user.inventory_enabled ? (
            <>
              <Grid item xs={12}>
                <Tabs
                  value={tabsValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons={false}
                  id="data-tabs"
                >
                  <Tab label="Inventory" value="inventory" id="inventory-tab" />
                  <Tab label="Purchases" value="purchases" id="purchases-tab" />
                  <Tab label="Log" value="log" id="log-tab" />
                  <Tab
                    label="Locations & Addresses"
                    value="locations"
                    id="locations-tab"
                  />
                  <Tab label="Settings" value="settings" id="settings-tab" />
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    overflow: "auto",
                    height: "calc(100vh - 125px)",
                  }}
                >
                  <Box
                    sx={{
                      mb: "calc(100vh/2)",
                    }}
                  >
                    <TabPanel value={tabsValue} index="inventory">
                      <InventoryItems loading={loading} />
                    </TabPanel>
                    <TabPanel value={tabsValue} index="purchases">
                      <InventoryPurchases loading={loading} />
                    </TabPanel>
                    <TabPanel value={tabsValue} index="log">
                      <InventoryLog loading={loading} />
                    </TabPanel>
                    <TabPanel value={tabsValue} index="locations">
                      <InventoryLocations loading={loading} />
                    </TabPanel>
                    <TabPanel value={tabsValue} index="settings">
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Inventory Settings
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={user.inventory_enabled}
                                onChange={toggleInventoryEnabled}
                              />
                            }
                            label="Inventory Enabled"
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Box>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: "1rem",
                    border: "dashed 2px",
                    borderRadius: "10px",
                    borderColor: "info.main",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography color="info.main">
                        Inventory management features are disabled for your
                        account. Use the switch to enable inventory management
                        features.
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={user.inventory_enabled}
                            onChange={toggleInventoryEnabled}
                          />
                        }
                        label="Inventory Enabled"
                        disabled={userPlanType() === "FRE"}
                      />
                      {userPlanType() === "FRE" && <PremiumOnlyFeature />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default withRouter(Inventory);
