import { sort } from "fast-sort";
import { formatDate } from "./utils";

export const reportTypeOptions = {
  EV: { label: "Event" },
  TA: { label: "Tax" },
  PA: { label: "Payment" },
};

export const reportStatusOptions = {
  PE: { label: "Pending" },
  CO: { label: "Completed" },
  FA: { label: "Failed" },
};

export const reportGenerationArgs = {
  start_date: { label: "Start Date", format_func: formatDate },
  end_date: { label: "End Date", format_func: formatDate },
  event_status: {
    label: "Event Status",
    format_func: (vals) => vals.map((v) => v.label).join(", "),
  },
};

export const reportGenerationArgsKeys = [
  "start_date",
  "end_date",
  "event_status",
];
