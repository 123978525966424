import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CssTextField = styled(TextField)(({ theme, focusColor }) => ({
  "& label.Mui-focused": {
    color: focusColor,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: focusColor,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: focusColor,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: focusColor,
    },
  },
}));
