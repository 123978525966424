import { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { useAccountState } from "../../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useResizeObserver from "use-resize-observer";
import { Document, Page } from "react-pdf";
import { PdfViewerDialog } from "../PdfViewerDialog";
import { PDFUploader } from "../FileUploader";

export const DesignDocument = (props) => {
  const [
    addProposalAttachment,
    back_office,
    deleteProposalAttachment,
    event,
    proposal,
    updateProposalSection,
  ] = useAccountState((state) => [
    state.addProposalAttachment,
    state.back_office,
    state.deleteProposalAttachment,
    state.event,
    state.proposal,
    state.updateProposalSection,
  ]);

  const removeDocument = () => {
    deleteProposalAttachment(
      props.proposal_section.uuid,
      props.proposal_section.proposal_attachments[0].uuid
    );
  };

  const savePdfUrl = (pdf_url) => {
    addProposalAttachment(props.proposal_section.uuid, pdf_url);
  };

  return (
    <Grid item xs={12} container>
      <Box sx={{ p: "1rem" }}>
        <ProposalPdf
          pdf_url={
            props.proposal_section.proposal_attachments.length > 0 &&
            props.proposal_section.proposal_attachments[0].attachment_url
          }
          removeDocument={removeDocument}
          savePdfUrl={savePdfUrl}
        />
      </Box>
    </Grid>
  );
};

export const ProposalPdf = (props) => {
  const [proposal] = useAccountState((state) => [state.proposal]);
  const [viewing, setViewing] = useState(false);

  const {
    ref,
    width = 300,
    height = 1,
  } = useResizeObserver({ box: "border-box" });
  return props.pdf_url ? (
    <>
      <Grid container justifyContent={"center"} spacing={1}>
        <Grid item xs={12} display="flex">
          <Box ref={ref} sx={{ width: "75%", m: "auto" }}>
            <PdfThumnail pdf_url={props.pdf_url} width={width} />
          </Box>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <Grid item xs="auto">
            <Button
              sx={{ color: proposal.font_color }}
              onClick={() => {
                setViewing(true);
              }}
              id="view-design-document-button"
            >
              View Design Document
            </Button>
          </Grid>
          {!props.view_only ? (
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  props.removeDocument();
                }}
                id="remove-design-document-button"
              >
                <DeleteOutlineOutlinedIcon /> Remove Document
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <PdfViewerDialog
        title="Design Document"
        pdf_url={props.pdf_url}
        open={viewing}
        handleCancel={() => {
          setViewing(false);
        }}
      />
    </>
  ) : !props.view_only ? (
    <PDFUploader savePdfUrl={props.savePdfUrl} />
  ) : null;
};

export const PdfThumnail = (props) => {
  const [viewing, setViewing] = useState(false);
  return (
    <Box
      sx={{ boxShadow: 10, "&:hover": { cursor: "pointer" } }}
      onClick={() => {
        setViewing(true);
      }}
    >
      <Document file={props.pdf_url}>
        <Page
          pageNumber={1}
          width={props.width}
          renderAnnotationLayer={false}
        />
      </Document>
      <PdfViewerDialog
        title={props.title ? props.title : "Design Document"}
        pdf_url={props.pdf_url}
        open={viewing}
        handleCancel={() => {
          setViewing(false);
        }}
      />
    </Box>
  );
};

export const DesignDocumentPreview = (props) => {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={50}
            height={5}
            sx={{ mt: 0.5 }}
            animation={false}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={50}
            height={60}
            sx={{ mt: 0.5 }}
            animation={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const DesignDocumentView = (props) => {
  return (
    <Box sx={{ p: "1rem" }}>
      <ProposalPdf
        pdf_url={
          props.proposal_section.proposal_attachments.length > 0 &&
          props.proposal_section.proposal_attachments[0].attachment_url
        }
        view_only
      />
    </Box>
  );
};
