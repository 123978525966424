import { updateUserEvent } from "../api/EventsApi";

export const eventsSlice = (set, get) => ({
  events: [],
  eventTemplates: [],
  deleteEvent: (uuid) => {
    const events = get().events.filter((event) => event.uuid !== uuid);
    set({ events });
    updateUserEvent({ uuid: uuid, deleted: true });
  },
  initializeEvents: (data) => set({ events: data }),
  initializeEventTemplates: (data) => set({ eventTemplates: data }),
});
