import { unsecureAPI } from "../api";

export const uploadImageUrl = (url, filename, upload_data) => {
  return unsecureAPI.post(
    "api/image/url_upload/",
    { url: url, filename: filename, ...upload_data },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const uploadImageFile = (file, filename, user_email, upload_data) => {
  const data = new FormData();
  data.append("file", file, file.name);
  data.append("filename", filename);
  if (user_email) {
    data.append("user_email", user_email);
  }
  if (upload_data) {
    Object.entries(upload_data).forEach(([key, value]) => {
      data.append(key, value);
    });
  }
  const auth = localStorage.getItem("token")
    ? `JWT ${localStorage.getItem("token")}`
    : undefined;
  return unsecureAPI.post("api/image/file_upload/", data, {
    headers: {
      Authorization: auth,
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    },
  });
};

export const updateImage = (data) => {
  const auth = localStorage.getItem("token")
    ? `JWT ${localStorage.getItem("token")}`
    : undefined;
  return unsecureAPI.put("api/image/", data, {
    headers: {
      Authorization: auth,
    },
  });
};

export const newImage = (data) => {
  return unsecureAPI.post("api/image/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const uploadPdfFile = (file, filename) => {
  const data = new FormData();
  data.append("file", file, file.name);
  data.append("filename", filename);
  return unsecureAPI.post("api/pdf/file_upload/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    },
  });
};

export const deleteImageApi = (uuid) => {
  return unsecureAPI.delete(`api/image/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: { uuid: uuid },
  });
};

export const getLibraryImagesApi = () => {
  return unsecureAPI.get("api/cropped_image/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const copyCroppedImagesApi = (data) => {
  return unsecureAPI.post("api/cropped_image/copy/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};
