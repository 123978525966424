import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Grid,
  TextField,
  Typography,
  Divider,
  Tooltip,
  InputAdornment,
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useAccountState } from "../state/store";
import { eventDateFormatted } from "../utils/event_utils";

const ViewSettings = (props) => {
  const [back_office, event, getCurrencySymbol, updateBackOffice] =
    useAccountState((state) => [
      state.back_office,
      state.event,
      state.getCurrencySymbol,
      state.updateBackOffice,
    ]);

  const updateSetting = (e) => {
    let data = {
      [e.target.id]: e.target.value,
    };
    updateBackOffice(data);
  };

  return (
    <Grid container justifyContent="center" spacing={2} display="flex">
      <SettingsSection title="Profit View Settings">
        <SettingsSwitch
          label="View Profit as Percent"
          tooltip={
            "View profit as a percent of event price. Profit dollar value will show on hover."
          }
          id="view_profit_as_percent"
          back_office={back_office}
          updateSetting={updateSetting}
        />
      </SettingsSection>
      <SettingsSection title="Warnings/Errors Settings">
        <SettingsField
          label={`Price Warning Tolerance (${getCurrencySymbol()})`}
          id="price_error_tolerance"
          back_office={back_office}
          updateSetting={updateSetting}
          tooltip={
            "The amount an item price can differ from the suggested item price before a warning is displayed."
          }
        />
      </SettingsSection>
      <SettingsSection title="Date Format">
        <Grid container alignItems={"center"} spacing={1}>
          <Grid item xs="auto">
            <FormControl>
              <InputLabel>Date Format</InputLabel>
              <Select
                value={back_office.date_format}
                label="Date Format"
                onChange={(e) =>
                  updateBackOffice({
                    date_format: e.target.value,
                  })
                }
                id="date_format"
              >
                <MenuItem value={"M/d/yyyy"}>
                  {eventDateFormatted(
                    event.date ? event.date : new Date(),
                    back_office.timezone,
                    "M/d/yyyy"
                  )}{" "}
                  (m/d/yyyy)
                </MenuItem>
                <MenuItem value={"MM/dd/yyyy"}>
                  {eventDateFormatted(
                    event.date ? event.date : new Date(),
                    back_office.timezone,
                    "MM/dd/yyyy"
                  )}{" "}
                  (mm/dd/yyyy)
                </MenuItem>
                <MenuItem value={"d/M/yyyy"}>
                  {eventDateFormatted(
                    event.date ? event.date : new Date(),
                    back_office.timezone,
                    "d/M/yyyy"
                  )}{" "}
                  (d/m/yyyy)
                </MenuItem>
                <MenuItem value={"dd/MM/yyyy"}>
                  {eventDateFormatted(
                    event.date ? event.date : new Date(),
                    back_office.timezone,
                    "dd/MM/yyyy"
                  )}{" "}
                  (dd/mm/yyyy)
                </MenuItem>
                <MenuItem value={"yyyy/MM/dd"}>
                  {eventDateFormatted(
                    event.date ? event.date : new Date(),
                    back_office.timezone,
                    "yyyy/MM/dd"
                  )}{" "}
                  (yyyy/mm/dd)
                </MenuItem>
                <MenuItem value={"yyyy/M/d"}>
                  {eventDateFormatted(
                    event.date ? event.date : new Date(),
                    back_office.timezone,
                    "yyyy/M/d"
                  )}{" "}
                  (yyyy/m/d)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Date format used throughout event (except for proposal title pages which have their own date format settings).">
              <span>
                <HelpOutlineOutlinedIcon color="info" />
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </SettingsSection>
    </Grid>
  );
};

const SettingsSection = (props) => {
  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">{props.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ width: "100%", m: "auto", mb: "1rem" }} />
      </Grid>
      {React.Children.map(props.children, (child) => (
        <Grid item xs={12}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

const SettingsField = (props) => {
  return (
    <TextField
      label={props.label}
      id={props.id}
      value={props.back_office[props.id] || 0}
      onChange={props.updateSetting}
      variant="filled"
      fullWidth
      disabled={props.disabled}
      InputProps={
        props.tooltip
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={props.tooltip}>
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
};

const SettingsSwitch = (props) => {
  const handleChange = (event) => {
    props.updateSetting({
      target: { value: event.target.checked, id: props.id },
    });
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={props.back_office[props.id]}
            onChange={handleChange}
            disabled={props.disabled}
            id={props.id}
          />
        }
        label={
          <Typography>
            {props.label}{" "}
            <Tooltip title={props.tooltip}>
              <HelpOutlineOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default withRouter(ViewSettings);
