import React from "react";
import { withRouter } from "react-router-dom";

import { Badge, Tooltip } from "@mui/material";

const AmendmentBadge = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (!props.invisible) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Badge
      variant="dot"
      color="error"
      overlap="rectangular"
      invisible={props.invisible}
      sx={props.sx}
    >
      <Tooltip
        open={open && !props.invisible}
        onOpen={handleOpen}
        onClose={handleClose}
        title={!props.invisible ? props.tooltip : ""}
        placement={props.placement}
      >
        {props.children}
      </Tooltip>
    </Badge>
  );
};

export default withRouter(AmendmentBadge);
