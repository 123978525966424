import React from "react";
import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import {
  NewPaymentDetailsWrapper,
  NewPaymentDetailsWrapperNoDialog,
} from "./PaymentDetails.js";
import { format_currency } from "../utils/utils.js";

export const CollectPaymentDetailsForPlan = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <Grid container>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Box sx={{ m: "1rem" }}>
            <Grid container spacing={2}>
              {props.plan_type === "FRE" && <FreemiumDetails />}
              {props.plan_type === "PPE" && <ScalableDetails />}
              {props.plan_type === "UNL" && <UnlimitedDetails />}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <NewPaymentDetailsWrapperNoDialog
            handleCancel={props.handleCancel}
            callback={props.callback}
            setStripeCustomer={props.setStripeCustomer}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

const FreemiumDetails = (props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="h6"
            sx={{
              p: ".25rem",
              backgroundColor: "primary.light",
              borderRadius: "5px",
              color: "info.main",
            }}
          >
            Scalable Starter
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }}>
          We get paid when you get paid!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          As a Starter subscriber, you’ll be charged a 2% fee when you book this
          event.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Once we have a credit card on file you can start sending unlimited
          proposals.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }}>
          What if an event doesn't book?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          You will not be charged anything. Send unlimited free proposals, only
          pay fees when you successfully book clients.
        </Typography>
      </Grid>
    </>
  );
};

const ScalableDetails = (props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="h6"
            sx={{
              p: ".25rem",
              backgroundColor: "primary.light",
              borderRadius: "5px",
              color: "info.main",
            }}
          >
            Scalable Pro
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }}>
          We get paid when you get paid!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          As a Pro subscriber, you’ll be charged a 1% fee when you book an
          event.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }}>
          What if an event doesn't book?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          You will not be charged the booking fee. Send unlimited proposals,
          only pay a fee when you successfully book clients.
        </Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }}>
          You will be charged {format_currency(props.amount)} to begin your
          subscription.
        </Typography>
      </Grid> */}
    </>
  );
};

const UnlimitedDetails = (props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="h6"
            sx={{
              p: ".25rem",
              backgroundColor: "primary.light",
              borderRadius: "5px",
              color: "info.main",
            }}
          >
            Unlimited
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }}>
          All features, all the time!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          As an unlimited subscriber, upon paying your annual subscription fee,
          you have access to all features all the time.
        </Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }}>
          You will be charged {format_currency(props.amount)} to begin your
          subscription.
        </Typography>
      </Grid> */}
    </>
  );
};

export default withRouter(CollectPaymentDetailsForPlan);
