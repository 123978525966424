export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImgObj(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  let x = (pixelCrop.x / 100) * image.width;
  let y = (pixelCrop.y / 100) * image.height;
  let width = (pixelCrop.width / 100) * image.width;
  let height = (pixelCrop.height / 100) * image.height;

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/png");
  });
}

export async function getCroppedImgFile(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const max_dim = 600;
  if (!ctx) {
    return null;
  }

  let x = (pixelCrop.x / 100) * image.width;
  let y = (pixelCrop.y / 100) * image.height;
  let width = (pixelCrop.width / 100) * image.width;
  let height = (pixelCrop.height / 100) * image.height;
  let new_w, new_h;
  if (width > height && width > max_dim) {
    new_w = max_dim;
    new_h = (height / width) * new_w;
  } else if (height > width && height > max_dim) {
    new_h = max_dim;
    new_w = (width / height) * new_h;
  } else {
    new_w = width;
    new_h = height;
  }
  canvas.width = new_w;
  canvas.height = new_h;

  ctx.drawImage(image, x, y, width, height, 0, 0, new_w, new_h);

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, "image/png");
  });
}

export function cropped_image_url(img, item) {
  if (img) {
    var crop;
    if (img?.crop) {
      crop = img.crop;
    } else {
      crop = {};
    }
    let x = crop.x ? crop.x : 0;
    let y = crop.y ? crop.y : 0;
    let width = crop.width ? crop.width : 100;
    let height = crop.height ? crop.height : 100;

    let image_file_name = img.src;
    image_file_name = image_file_name
      .replace("https://flowerbuddy-uploads-test.s3.amazonaws.com/", "")
      .replace("https://flowerbuddy-uploads-prod.s3.amazonaws.com/", "");

    let url = `${process.env.REACT_APP_API_URL}/api/image/cropped/${image_file_name}?x=${x}&y=${y}&width=${width}&height=${height}`;
    return url;
  } else if (item) {
    return thumbnail_proxy_url(item);
  } else {
    console.log("IMG IS UNDEFINED");
    return undefined;
  }
}

export function thumbnail_proxy_url(item) {
  if (!item) {
    return undefined;
  }
  if (!item.thumbnail_url || item.thumbnail_url.includes("shopify")) {
    return undefined;
  }
  return `${process.env.REACT_APP_API_URL}/api/image/thumbnail/${item?.uuid}`;
}

export function twicpic_url(image, resize, quality, contain) {
  if (typeof image === "object" && image != null) {
    var env = "prod";
    if (image.src.includes("test")) {
      env = "test";
    }
    var url_vec = image.src.split("/");
    var file = url_vec[url_vec.length - 1];
    var x = image.crop?.x ? image.crop.x : 0;
    var y = image.crop?.y ? image.crop.y : 0;
    var width = image.crop?.width ? image.crop.width : 100;
    var height = image.crop?.height ? image.crop.height : 100;
    var twicpic_url = `https://flowerbuddy.twic.pics/${env}/${file}?twic=v1/crop=${width}px${height}p@${x}px${y}p`;
    if (
      resize &&
      resize.width &&
      typeof resize.width === "number" &&
      resize.height &&
      typeof resize.height === "number"
    ) {
      const c = 2;
      var resize_width = resize.width ? resize.width * c : "100p";
      var resize_height = resize.height ? resize.height * c : "100p";
      twicpic_url = `${twicpic_url}/resize=${resize_width}x${resize_height}`;
    }
    if (contain) {
      twicpic_url = `${twicpic_url}/contain=${contain}`;
    }
    if (quality) {
      twicpic_url = `${twicpic_url}/quality=${quality}`;
    } else {
      twicpic_url = `${twicpic_url}/quality=100`;
    }
    return twicpic_url;
  } else {
    return image;
  }
}
