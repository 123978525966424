import { Tooltip } from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";

import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";

export const PremiumOnlyFeature = (props) => {
  return (
    <Tooltip title="Available on Scalable and Unlimted plans.">
      <WorkspacePremiumOutlinedIcon color="secondary" />
    </Tooltip>
  );
};

export const UnlockAfterBooked = (props) => {
  return (
    <Tooltip title="Unlocked after event is booked. Upgrade your plan to access before event books.">
      <BeenhereOutlinedIcon color="secondary" fontSize="inherit" />
    </Tooltip>
  );
};

export default withRouter(PremiumOnlyFeature);
