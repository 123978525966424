import { sort } from "fast-sort";
import { materialTypeName } from "./item_utils";

export const TransactionName = (transaction_type) => {
  switch (transaction_type) {
    case "PU":
      return "Pull";
    case "ST":
      return "Stock";
    case "RS":
      return "Restock";
    case "RE":
      return "Receive";
    case "AD":
      return "Adjust";
    case "PC":
      return "Purchase";
  }
};

export const formatInventoryAddress = (inventory_address) => {
  return [
    inventory_address.address_line_1,
    inventory_address.address_line_2,
    inventory_address.city,
    inventory_address.state,
  ]
    .filter((el) => el)
    .join(", ");
};

export const sortInventoryItemOptions = (inventory_item_options) => {
  return sort(inventory_item_options).by([
    { asc: (ii) => materialTypeName(ii.item.type) },
    { asc: (ii) => ii.item.name },
  ]);
};
