import React from "react";
import { withRouter } from "react-router-dom";

import {
  Autocomplete,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { useAccountState } from "../state/store";
import { formatInventoryAddress } from "../utils/inventory_utils";
import { formatStringForId, sortByName } from "../utils/utils";
import { ExpandButton } from "./EventItem";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useFormik } from "formik";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const InventoryLocations = (props) => {
  const [inventoryAddresses, inventoryLocations] = useAccountState((state) => [
    state.inventoryAddresses,
    state.inventoryLocations,
  ]);

  const [addingAddress, setAddingAddress] = React.useState(false);
  const [addingLocation, setAddingLocation] = React.useState(false);

  const groupLocationsByAddress = inventoryAddresses.map((ia) => {
    return {
      ...ia,
      locations: inventoryLocations.filter(
        (il) => il.inventory_address.uuid === ia.uuid,
      ),
    };
  });

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs="auto">
          <Typography variant="h6">Locations & Addresses</Typography>
        </Grid>
        <Grid item xs="auto" container justifyContent={"flex-end"} spacing={1}>
          {/* <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setAddingLocation(true)}
            >
              <AddOutlinedIcon /> Add Location
            </Button>
            <AddEditLocationDialog
              open={addingLocation}
              handleCancel={() => setAddingLocation(false)}
            />
          </Grid> */}
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setAddingAddress(true)}
            >
              <AddOutlinedIcon /> Add Address
            </Button>
            <AddEditAddressDialog
              open={addingAddress}
              handleCancel={() => setAddingAddress(false)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2} sx={{ mt: "1rem" }}>
        {groupLocationsByAddress.sort(sortByName).map((ia) => {
          return (
            <AddressSection
              inventory_address={ia}
              key={ia.uuid}
            ></AddressSection>
          );
        })}
      </Grid>
    </Grid>
  );
};

const AddressSection = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const toggleOpen = () => {
    setExpanded(!expanded);
  };
  const [addingLocation, setAddingLocation] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  return (
    <Grid item xs={12} container>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs="auto">
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold" }}>
              {props.inventory_address.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {props.inventory_address.city} {props.inventory_address.state}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <ExpandButton
            id={formatStringForId(
              props.inventory_address.uuid + "-expand-button",
            )}
            expand={expanded}
            size="large"
            onClick={toggleOpen}
          >
            <KeyboardArrowDownOutlinedIcon fontSize="inherit" />
          </ExpandButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={expanded}>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            sx={{ pl: "1rem", pr: "1rem" }}
          >
            <AddressDetailSection
              title="Address"
              actions={
                <>
                  <IconButton size="small" onClick={() => setUpdating(true)}>
                    <Typography variant="body2" color="info.main">
                      <EditOutlinedIcon fontSize="small" />
                    </Typography>
                  </IconButton>
                  <AddEditAddressDialog
                    initialValues={props.inventory_address}
                    open={updating}
                    handleCancel={() => setUpdating(false)}
                    update
                  />
                  <IconButton size="small" onClick={() => setDeleting(true)}>
                    <Typography variant="body2" color="info.main">
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </Typography>
                  </IconButton>
                  <DeleteAddressDialog
                    inventory_address={props.inventory_address}
                    open={deleting}
                    handleCancel={() => setDeleting(false)}
                  />
                </>
              }
            >
              <Grid item xs={12} container justifyContent={"space-between"}>
                <Grid item xs="auto">
                  <Typography variant="body2" color="info.main">
                    {formatInventoryAddress(props.inventory_address)
                      ? formatInventoryAddress(props.inventory_address)
                      : "None"}
                  </Typography>
                </Grid>
              </Grid>
            </AddressDetailSection>
            <AddressDetailSection
              title="Locations"
              actions={
                <>
                  <Tooltip title="Add Location">
                    <IconButton
                      size="small"
                      onClick={() => setAddingLocation(true)}
                    >
                      <AddOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <AddEditLocationDialog
                    inventory_address={props.inventory_address}
                    open={addingLocation}
                    handleCancel={() => setAddingLocation(false)}
                  />
                </>
              }
            >
              <Grid item xs={12} container columnSpacing={6}>
                {props.inventory_address.locations
                  .sort(sortByName)
                  .map((il) => {
                    return (
                      <InventoryLocationLine
                        inventory_location={il}
                        inventory_address={props.inventory_address}
                      />
                    );
                  })}
              </Grid>
            </AddressDetailSection>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

const InventoryLocationLine = (props) => {
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  return (
    <Grid
      item
      xs={6}
      key={props.inventory_location.uuid}
      container
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid item xs="auto">
        <Typography variant="body2" color="info.main">
          {props.inventory_location.name}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <IconButton size="small" onClick={() => setEditing(true)}>
          <Typography variant="body2" color="info.main">
            <EditOutlinedIcon fontSize="inherit" size="small" />
          </Typography>
        </IconButton>
        <AddEditLocationDialog
          open={editing}
          handleCancel={() => setEditing(false)}
          inventory_address={props.inventory_address}
          initialValues={props.inventory_location}
          update
        />
        <IconButton size="small" onClick={() => setDeleting(true)}>
          <Typography variant="body2" color="info.main">
            <DeleteOutlineOutlinedIcon fontSize="inherit" size="small" />
          </Typography>
        </IconButton>
        <DeleteInventoryLocationDialog
          inventory_location={props.inventory_location}
          open={deleting}
          handleCancel={() => setDeleting(false)}
        />
      </Grid>
    </Grid>
  );
};

const AddressDetailSection = (props) => {
  return (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs="auto">
          <Typography sx={{ fontSize: ".75rem", fontWeight: "bold" }}>
            {props.title}
          </Typography>
        </Grid>
        {props.actions && (
          <Grid item xs="auto">
            {props.actions}
          </Grid>
        )}
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12} container>
        {props.children}
      </Grid>
    </>
  );
};

const AddEditAddressDialog = (props) => {
  const [addInventoryAddress, updateInventoryAddress] = useAccountState(
    (state) => [state.addInventoryAddress, state.updateInventoryAddress],
  );
  const handleSubmit = props.update
    ? updateInventoryAddress
    : addInventoryAddress;
  const formik = useFormik({
    initialValues: {
      name: null,
      address_line_1: null,
      address_line_2: null,
      city: null,
      state: null,
      country: null,
      postal_code: null,
      ...props.initialValues,
      locations: undefined,
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, props.cb);
      props.handleCancel();
      resetForm();
    },
  });
  const inputIsValid = () => {
    return formik.values.name !== null && formik.values.name !== "";
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        {props.update ? "Update" : "Add"} Inventory Address
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: ".5rem" }}>
          <FormikTextField id="name" label="Name" formik={formik} />
          <FormikTextField
            id="address_line_1"
            label="Address Line 1"
            formik={formik}
          />
          <FormikTextField
            id="address_line_2"
            label="Address Line 2"
            formik={formik}
          />
          <FormikTextField id="city" label="City" formik={formik} width={6} />
          <FormikTextField id="state" label="State" formik={formik} width={6} />
          <FormikTextField
            id="country"
            label="Country"
            formik={formik}
            width={6}
          />
          <FormikTextField
            id="postal_code"
            label="Postal Code"
            formik={formik}
            width={6}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!inputIsValid()}
          onClick={formik.handleSubmit}
        >
          {props.update ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteAddressDialog = (props) => {
  const [deleteInventoryAddress] = useAccountState((state) => [
    state.deleteInventoryAddress,
  ]);
  const handleSubmit = () => {
    deleteInventoryAddress(props.inventory_address.uuid);
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Inventory Address</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the inventory address with name "
          {props.inventory_address.name}"? This will also cause all of its
          locations to be deleted. Any inventory items that reference this
          location will no longer have a location listed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddEditLocationDialog = (props) => {
  const [addInventoryLocation, inventoryAddresses, updateInventoryLocation] =
    useAccountState((state) => [
      state.addInventoryLocation,
      state.inventoryAddresses,
      state.updateInventoryLocation,
    ]);
  const handleSubmit = props.update
    ? updateInventoryLocation
    : addInventoryLocation;
  const [inventoryAddress, setInventoryAddress] = React.useState(null);
  const formik = useFormik({
    initialValues: {
      name: null,
      inventory_address_id: props.inventory_address
        ? props.inventory_address.uuid
        : null,
      ...props.initialValues,
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, props.cb);
      props.handleCancel();
      resetForm();
      setInventoryAddress(null);
    },
  });
  const inputIsValid = () => {
    return (
      formik.values.name !== null &&
      formik.values.name !== "" &&
      formik.values.inventory_address_id !== null &&
      formik.values.inventory_address_id !== ""
    );
  };
  const updateAddress = (newValue) => {
    setInventoryAddress(newValue);
    formik.setFieldValue("inventory_address_id", newValue.uuid);
  };
  const [addingAddress, setAddingAddress] = React.useState(false);
  const dialogTitle = () => {
    if (props.update) {
      return `Edit ${props.initialValues?.name}`;
    } else if (props.inventory_address) {
      return `Add Inventory Location To ${props.inventory_address?.name}`;
    } else {
      return "Add Inventory Location";
    }
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>{dialogTitle()}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: ".5rem" }}>
          <FormikTextField id="name" label="Name" formik={formik} />
          {!props.inventory_address && (
            <Grid item xs={12}>
              <Autocomplete
                id="inventory_address"
                options={inventoryAddresses}
                value={inventoryAddress}
                onChange={(event, newValue) => {
                  updateAddress(newValue);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Inventory Address"
                    variant="outlined"
                    fullWidth
                  />
                )}
                noOptionsText={
                  <Typography>
                    {`No inventory addresses match your search. `}
                    <Link
                      onClick={() => {
                        setAddingAddress(true);
                      }}
                    >
                      {`Create a new inventory address?`}
                    </Link>
                  </Typography>
                }
              />
              <AddEditAddressDialog
                open={addingAddress}
                handleCancel={() => {
                  setAddingAddress(false);
                }}
                cb={updateAddress}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!inputIsValid()}
          onClick={formik.handleSubmit}
        >
          {props.update ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormikTextField = (props) => {
  return (
    <Grid item xs={props.width ? props.width : 12}>
      <TextField
        {...props}
        variant="outlined"
        fullWidth
        value={props.formik.values[props.id]}
        onChange={props.formik.handleChange}
      />
    </Grid>
  );
};

const DeleteInventoryLocationDialog = (props) => {
  const [deleteInventoryLocation] = useAccountState((state) => [
    state.deleteInventoryLocation,
  ]);
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Inventory Location</DialogTitle>
      <DialogContent>
        <Typography>
          {`Are you sure you want to delete the inventory location `}
          <strong>{`${props.inventory_location.inventory_address.name} ${props.inventory_location.name}`}</strong>
          {`? Any inventory items associated with this location will have their locations set to Unknown.`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            deleteInventoryLocation(props.inventory_location.uuid);
            props.handleCancel();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(InventoryLocations);
