import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PersonalSettings from "./PersonalSettings";

import {
  Box,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Divider,
  Button,
} from "@mui/material";

import { PlanSelection } from "./PlanSelection";
import { PaymentHistory } from "./PaymentHistory";
import { BalanceTransactions } from "./BalanceTransactions";
import PaymentsIntegration from "./PaymentsIntegration";
import NotificationSettings from "./NotificationSettings";
import { useAccountState } from "../state/store";
import ReferralCode from "./ReferralCode";

const AccountSettings = (props) => {
  const [initializeUser] = useAccountState((state) => [state.initializeUser]);
  React.useEffect(() => {
    initializeUser(props.user);
    document.title = "Account";
  }, [initializeUser, props.user]);
  let qs = new URLSearchParams(props.location.search);
  let initial_section = qs.has("active_section")
    ? qs.get("active_section")
    : "account";
  const [tabsValue, setTabsValue] = React.useState(initial_section);
  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const setStripeCustomer = (data) => {
    props.updateUserInState({ stripe_customer: data });
  };

  return (
    <Box>
      <Box
        sx={{
          maxWidth: "800px",
          width: "100%",
          ml: "auto",
          mr: "auto",
          mt: "1rem",
          overflow: "auto",
          overflowX: "hidden",
          scrollMargin: "50vh",
          height: "92vh",
        }}
      >
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={fullScreen ? { ml: "0.5rem" } : null}>
              Account Settings
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={tabsValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
              id="account-settings-tabs"
            >
              <Tab
                label="Account"
                value="account"
                id="account-settings-tab-account"
              />
              <Tab
                label="Notifications"
                value="notifications"
                id="account-settings-tab-notifications"
              />
              <Tab
                label="Referral Code"
                value="referral_code"
                id="account-settings-tab-referral-code"
              />
              <Tab
                label="Subscription"
                value="plan_selection"
                id="account-settings-tab-subscription"
              />
              <Tab
                label="Payment History"
                value="payment_history"
                id="account-settings-tab-payment-history"
              />
              <Tab
                label="Payments Integration"
                value="payments_integration"
                id="account-settings-tab-payments-integration"
              />
              <Tab
                label="User Agreement & Consent"
                value="user_agreement"
                id="account-settings-tab-user-agreement"
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Box
              sx={{
                overflow: "auto",
                height: "calc(100vh - 225px)",
              }}
            >
              <Box
                sx={{
                  mb: "calc(100vh/2)",
                }}
              >
                <ProfileSettingsSection value={tabsValue} index="account">
                  <PersonalSettings
                    user={props.user}
                    updatePersonalSettings={props.updateUser}
                    handlePasswordReset={props.handlePasswordReset}
                  />
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="notifications">
                  <NotificationSettings
                    user={props.user}
                    updatePersonalSettings={props.updateUser}
                  />
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="referral_code">
                  <ReferralCode />
                </ProfileSettingsSection>
                <ProfileSettingsSection
                  value={tabsValue}
                  index="user_agreement"
                >
                  <Typography variant="h6">
                    View or modify your{" "}
                    <Button
                      color="info"
                      class="termly-display-preferences"
                      size="small"
                    >
                      Consent Preferences
                    </Button>
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "350px",
                      p: "1rem",
                    }}
                    // display="flex"
                  >
                    <iframe
                      src="https://app.termly.io/document/privacy-policy/93fadf33-4bbc-49f5-8aeb-c674ce66edab"
                      width="95%"
                      height="100%"
                      style={{ margin: "auto" }}
                      title="Privacy Policy"
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "350px",
                      p: "1rem",
                    }}
                    // display="flex"
                  >
                    <iframe
                      src="https://app.termly.io/document/terms-of-use-for-saas/45af22bc-1d1e-4709-850f-d26c279a1df5"
                      width="95%"
                      height="100%"
                      style={{ margin: "auto" }}
                      title="Terms of Use"
                    />
                  </Box>
                </ProfileSettingsSection>
                <ProfileSettingsSection
                  value={tabsValue}
                  index="plan_selection"
                >
                  {props.user.stripe_customer ? (
                    <PlanSelection
                      user={props.user}
                      stripe_customer={props.user.stripe_customer}
                      setStripeCustomer={setStripeCustomer}
                      setTabsValue={setTabsValue}
                    />
                  ) : (
                    <CircularProgress />
                  )}
                </ProfileSettingsSection>
                <ProfileSettingsSection
                  value={tabsValue}
                  index="payment_history"
                >
                  <PaymentHistory
                    user={props.user}
                    updateUserInState={props.updateUserInState}
                    setStripeCustomer={setStripeCustomer}
                  />
                  <Divider sx={{ mt: "1rem", mb: "1rem" }} />
                  <BalanceTransactions
                    user={props.user}
                    updateUserInState={props.updateUserInState}
                    setStripeCustomer={setStripeCustomer}
                  />
                </ProfileSettingsSection>
                <ProfileSettingsSection
                  value={tabsValue}
                  index="payments_integration"
                >
                  <PaymentsIntegration user={props.user} />
                </ProfileSettingsSection>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const ProfileSettingsSection = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-TabPanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper sx={{ p: "1rem" }} id={props.index + "-paper"}>
          {children}
        </Paper>
      )}
    </div>
  );
};

export default withRouter(AccountSettings);
