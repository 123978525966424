import { updateImageApi } from "../api/DataApi";
import { deleteImageApi } from "../api/ImageApi";

export const imagesSlice = (set, get) => ({
  images: [],
  addImage: (data) => {
    let new_images = [...get().images, data];
    set({ images: new_images });
  },
  deleteImage: (uuid) => {
    get().deleteImageFromState(uuid);
    deleteImageApi(uuid).then((res) => {
      console.log(res.data);
    });
  },
  deleteImageFromState: (uuid) => {
    let images = get().images;
    let idx = images.findIndex((img) => img.uuid === uuid);
    let new_images = [...images.slice(0, idx), ...images.slice(idx + 1)];
    set({ images: new_images });
  },
  initializeImages: (images) => {
    set({ images });
  },
  updateImage: (data) => {
    get().updateImageInState(data);
    updateImageApi(data).then((res) => {
      console.log(res.data);
    });
  },
  updateImageInState: (data) => {
    console.log("updateImageInState", data);
    let images = get().images;
    let idx = images.findIndex((img) => img.uuid === data.uuid);
    let image = images[idx];
    let new_image = { ...image, ...data };
    let new_images = [
      ...images.slice(0, idx),
      new_image,
      ...images.slice(idx + 1),
    ];
    set({ images: new_images });
  },
});
