export const getDueDate = (
  due_date_offset_days,
  due_date_offset_direction,
  event_date
) => {
  var date = new Date(event_date);
  var direction = due_date_offset_direction === "BE" ? -1 : 1;
  date.setDate(
    date.getDate() +
      parseInt(due_date_offset_days ? due_date_offset_days : 0) * direction
  );
  return date;
};

export const taskSorter = (a, b) => {
  if (a.due_date_offset_direction < b.due_date_offset_direction) {
    return 1;
  } else if (a.due_date_offset_direction > b.due_date_offset_direction) {
    return -1;
  } else {
    let offset_direction_constant =
      a.due_date_offset_direction === "BE" ? -1 : 1;
    if (
      a.due_date_offset_days * offset_direction_constant >
      b.due_date_offset_days * offset_direction_constant
    ) {
      return 1;
    } else if (
      a.due_date_offset_days * offset_direction_constant <
      b.due_date_offset_days * offset_direction_constant
    ) {
      return -1;
    } else {
      return 0;
    }
  }
};

export const numTasksDue = (tasks, event_date) => {
  let incomplete_tasks = tasks.filter((task) => task.status === "IN");
  return incomplete_tasks.reduce((sum, task) => {
    let today = new Date().toISOString();
    let due_date = getDueDate(
      task.due_date_offset_days,
      task.due_date_offset_direction,
      event_date
    );
    if (due_date < today) {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);
};
