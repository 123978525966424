export function vendor_location_display_name(vendor_location) {
  if (vendor_location.online_only) {
    var address = [
      vendor_location.vendor.name,
      vendor_location.url ? vendor_location.url : "Website",
    ];
    return address.join(", ");
  } else {
    var address_fields = ["city", "state", "country"];
    var address = address_fields
      .filter((field) => vendor_location[field])
      .map((field) => vendor_location[field]);
    address = [vendor_location.vendor.name, ...address];
    return address.join(", ");
  }
}

export function short_vendor_location_display_name(vendor_location) {
  if (vendor_location?.online_only) {
    var address = [
      vendor_location.vendor.name,
      vendor_location.url
        ? "(" +
          vendor_location.url
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "") +
          ")"
        : "Website",
    ];
    return address.join(" ");
  } else {
    var address = [
      vendor_location?.vendor.name,
      "(" + vendor_location?.city + ")",
    ];
    return address.join(" ");
  }
}
