import { Decimal } from "decimal.js";
import _, { update } from "lodash";
import { decimalConfig } from "../utils/utils";
import {
  addInventoryItemApi,
  deleteInventoryItemApi,
  updateInventoryItemApi,
} from "../api/InventoryApi";
import { updateItemApi } from "../api/EventsApi";

Decimal.set(decimalConfig);

export const inventoryItemsSlice = (set, get) => ({
  inventoryItems: [],
  initializeInventoryItems: (inventoryItems) => {
    set({ inventoryItems: inventoryItems });
  },
  addInventoryItem: (data, cb) => {
    addInventoryItemApi(data).then((res) => {
      let inventory_item = res.data.inventory_item;
      let set_data = {
        inventoryItems: [...get().inventoryItems, inventory_item],
      };
      let inventory_transaction = res.data.inventory_transaction;
      if (inventory_transaction) {
        let inventory_transactions = [
          ...get().inventoryTransactions,
          inventory_transaction,
        ];
        set_data.inventoryTransactions = inventory_transactions;
      }
      set(set_data);
      if (cb) cb(inventory_item);
    });
  },
  deleteInventoryItem: (inventory_item_uuid) => {
    const inventoryItems = get().inventoryItems;
    const index = _.findIndex(inventoryItems, { uuid: inventory_item_uuid });
    set({
      inventoryItems: [
        ...inventoryItems.slice(0, index),
        ...inventoryItems.slice(index + 1),
      ],
    });
    deleteInventoryItemApi(inventory_item_uuid).then((resp) => {
      console.log(resp);
    });
  },
  getInventoryItemForItem: (item) => {
    let inventoryItems = get().inventoryItems;
    let inventoryItem = inventoryItems.find((ii) => ii.item.uuid === item.uuid);
    return inventoryItem;
  },
  updateInventoryItem: (data) => {
    const inventoryItems = get().inventoryItems;
    const index = _.findIndex(inventoryItems, { uuid: data.uuid });
    let inventory_item = inventoryItems[index];
    set({
      inventoryItems: [
        ...inventoryItems.slice(0, index),
        { ...inventory_item, ...data },
        ...inventoryItems.slice(index + 1),
      ],
    });
    updateInventoryItemApi(data).then((resp) => {
      console.log(resp);
    });
  },
  updateItem: (data) => {
    const inventory_items = get().inventoryItems;
    const index = inventory_items.findIndex((ii) => ii.item.uuid === data.uuid);
    updateItemApi(data).then((resp) => {
      set({
        inventoryItems: [
          ...inventory_items.slice(0, index),
          { ...inventory_items[index], item: resp.data },
          ...inventory_items.slice(index + 1),
        ],
      });
    });
  },
});
