import { recipes } from "./event_items_utils";

export const AmendableRecipeFields = [
  "quantity",
  "price",
  "is_optional",
  "is_selected",
];

export function recipeHasAmendments(recipe) {
  var res = false;
  AmendableRecipeFields.forEach((field) => {
    if (field == "price") {
      if (
        recipe["amend_" + field] !== null &&
        parseFloat(recipe[field]) !== parseFloat(recipe["amend_" + field])
      ) {
        res = true;
      }
    } else {
      if (
        recipe["amend_" + field] !== null &&
        recipe[field] !== recipe["amend_" + field]
      ) {
        res = true;
      }
    }
  });
  return res;
}

export function recipesWithAmendments(event_items) {
  var recipes_with_amendments = recipes(event_items).filter((recipe) =>
    recipeHasAmendments(recipe),
  );
  return recipes_with_amendments.length;
}

export function recipesHaveAmendments(event_items) {
  return recipesWithAmendments(event_items) > 0;
}
