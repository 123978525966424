import React from "react";
import { withRouter } from "react-router-dom";

import { Paper } from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-TabPanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Paper sx={{ p: "1rem" }}>{children}</Paper>}
    </div>
  );
};

export default withRouter(TabPanel);
