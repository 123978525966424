import React, { useRef } from "react";

import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Grow, IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import { useAccountState } from "../state/store";
import { RecipeTemplateSelector } from "./RecipeTemplates";

const EventItemDividerButtons = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: props.hidden ? "none" : "block",
        position: "relative", // Container for the absolute positioned buttons
        mt: "1rem",
        mb: "1rem",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Divider
        sx={{
          width: "100%",
        }}
      />
      <Box
        sx={{
          visibility: isHovered ? "visible" : "hidden",
          position: "absolute",
          top: "50%",
          left: 0,
          width: "100%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <AddEventItemButtonMenu
          index={props.index}
          backgroundColor={props.backgroundColor}
          disableAddSection={props.disableAddSection}
          disableAddGroup={props.disableAddGroup}
          eventItemGroup={props.eventItemGroup}
        />
      </Box>
      {/* </Divider> */}
    </Box>
  );
};

const AddEventItemButtonMenu = (props) => {
  const [eventLocked] = useAccountState((state) => [state.eventLocked]);

  const [open, setOpen] = React.useState(true);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : "#F7F7F7",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "hidden",
          width: open ? "auto" : 0,
        }}
      >
        {getButtonList(
          props.disableAddSection,
          props.disableAddGroup,
          props.eventItemGroup ? true : false
        ).map((IconComponent, i) => (
          <Grow
            in={open}
            style={{ transformOrigin: "0 50% 0" }}
            timeout={(i + 1) * 500}
            key={i}
          >
            <div>
              <IconComponent
                index={props.index}
                handleClose={handleToggle}
                eventItemGroup={props.eventItemGroup}
                disabled={eventLocked()}
                handleAddEventItem={props.handleAddEventItem}
              />
            </div>
          </Grow>
        ))}
      </Box>
    </Box>
  );
};

const getButtonList = (
  disableAddSection,
  disableAddGroup,
  disableAddPercentCost
) => {
  let buttons = [
    AddRecipeButton,
    AddOtherCostButton,
    AddOtherCostPercentButton,
    AddRecipeTemplateButton,
  ];
  if (disableAddPercentCost) {
    buttons.splice(2, 1);
  }
  if (!disableAddGroup) {
    buttons.push(AddRecipeGroupButton);
  }
  if (!disableAddSection) {
    buttons.push(AddRecipeProposalSectionButton);
  }

  return buttons;
};

const AddRecipeButton = (props) => {
  const [addRecipe] = useAccountState((state) => [state.addRecipe]);

  const handleAddRecipe = () => {
    addRecipe(
      "New Recipe",
      props.eventItemGroup,
      props.index,
      props.handleAddEventItem
    );
  };

  return (
    <Tooltip title="Add Recipe">
      <IconButton
        onClick={handleAddRecipe}
        size="small"
        disabled={props.disabled}
      >
        <AddCircleOutlineOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

const AddOtherCostButton = (props) => {
  const [addOtherCost] = useAccountState((state) => [state.addOtherCost]);

  const handleAddOtherCost = () => {
    addOtherCost(
      "New Other Cost",
      props.eventItemGroup,
      props.index,
      props.handleAddEventItem
    );
  };

  return (
    <Tooltip title="Add Fixed Cost">
      <IconButton
        onClick={handleAddOtherCost}
        size="small"
        disabled={props.disabled}
      >
        <AttachMoneyOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

const AddOtherCostPercentButton = (props) => {
  const [addOtherCost] = useAccountState((state) => [state.addOtherCost]);

  const handleAddOtherCost = () => {
    addOtherCost(
      "New Other Cost",
      props.eventItemGroup,
      props.index,
      props.handleAddEventItem,
      true
    );
  };

  return (
    <Tooltip title="Add Percent Cost">
      <IconButton
        onClick={handleAddOtherCost}
        size="small"
        disabled={props.disabled}
      >
        <PercentOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

const AddRecipeProposalSectionButton = (props) => {
  const [addRecipeProposalSection] = useAccountState((state) => [
    state.addRecipeProposalSection,
  ]);

  const handleAddRecipeProposalSection = () => {
    console.log("ADDING SECTION", props.index);
    addRecipeProposalSection("New Proposal Section", props.index);
  };
  return (
    <Tooltip title="Add Recipe Section">
      <IconButton
        size="small"
        onClick={handleAddRecipeProposalSection}
        disabled={props.disabled}
      >
        <SegmentOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

const AddRecipeGroupButton = (props) => {
  const [addRecipeGroup] = useAccountState((state) => [state.addRecipeGroup]);

  const handleAddRecipeGroup = () => {
    addRecipeGroup("New Group", undefined, props.index);
  };

  return (
    <Tooltip title="Add Group">
      <IconButton
        onClick={handleAddRecipeGroup}
        size="small"
        disabled={props.disabled}
      >
        <ListAltOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

const AddRecipeTemplateButton = (props) => {
  const [addRecipeTemplatesToEvent] = useAccountState((state) => [
    state.addRecipeTemplatesToEvent,
  ]);

  const [addingTemplate, setAddingTemplate] = React.useState(false);
  const [newTemplatesLoading, setNewTemplatesLoading] = React.useState(false);
  const toggleAddingTemplate = () => {
    setAddingTemplate(!addingTemplate);
  };
  const handleAddRecipeTemplates = (recipe_template_uuids) => {
    setNewTemplatesLoading(true);
    addRecipeTemplatesToEvent(
      recipe_template_uuids,
      props.index,
      props.eventItemGroup?.uuid,
      () => {
        setNewTemplatesLoading(false);
        toggleAddingTemplate();
      }
    );
  };

  return (
    <>
      <Tooltip title="Add Template">
        <IconButton
          onClick={toggleAddingTemplate}
          size="small"
          disabled={props.disabled}
        >
          <FolderOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <RecipeTemplateSelector
        open={addingTemplate}
        handleCancel={toggleAddingTemplate}
        handleSubmit={handleAddRecipeTemplates}
        noGroups={props.eventItemGroup ? true : false}
        noPercentCosts={props.eventItemGroup ? true : false}
      />
    </>
  );
};

export default withRouter(EventItemDividerButtons);
