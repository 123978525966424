import React from "react";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";

import { sort } from "fast-sort";
import { Decimal } from "decimal.js";
import { getUserEvents } from "../api/EventsApi";
import { itemDisplayName, materialTypeName } from "../utils/item_utils";

import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { decimalConfig } from "../utils/utils";
import {
  TransactionName,
  sortInventoryItemOptions,
} from "../utils/inventory_utils";
import { getInventoryItemInfoApi } from "../api/InventoryApi";
import { useAccountState } from "../state/store";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { eventDateFormatted } from "../utils/event_utils";
Decimal.set(decimalConfig);
const InventoryLogDialog = (props) => {
  const [
    back_office,
    createInventoryTransaction,
    getCurrencySymbol,
    initializeBackOffice,
    inventoryItems,
  ] = useAccountState((state) => [
    state.back_office,
    state.createInventoryTransaction,
    state.getCurrencySymbol,
    state.initializeBackOffice,
    state.inventoryItems,
  ]);
  const [auto, setAuto] = React.useState(props.auto);
  const [event_options, setEventOptions] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      quantity: props.initialValues?.quantity
        ? props.initialValues.quantity
        : 0,
      lost: 0,
      sold: 0,
      adjust_total: 0,
      adjust_in_stock: 0,
      adjust_pulled: 0,
      adjust_receiving: 0,
      adjust_purchased: 0,
      ...props.initialValues,
      inventory_item_id: props.inventory_item
        ? props.inventory_item.uuid
        : null,
      transaction_type: props.transaction_type,
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      const cb = () => {
        props.handleCancel();
        resetForm();
        setSubmitting(false);
      };
      createInventoryTransaction(values, cb);
    },
  });

  const handleCancel = () => {
    props.handleCancel();
    formik.resetForm();
    setSubmitting(false);
    setAuto(props.auto);
  };

  React.useEffect(() => {
    getUserEvents(true, false, props.inventory_item?.item?.uuid).then(
      (resp) => {
        setEventOptions(resp.data.events);
        if (!back_office) {
          initializeBackOffice(resp.data.back_office);
        }
      }
    );
  }, []);

  const inventoryItem = () => {
    if (props.inventory_item) {
      return props.inventory_item;
    } else if (formik.values.inventory_item_id) {
      return inventoryItems.find(
        (ii) => ii.uuid === formik.values.inventory_item_id
      );
    } else {
      return null;
    }
  };

  const [hint_info, setHintInfo] = React.useState(props.hint_info);

  React.useEffect(() => {
    setHintInfo(props.hint_info);
  }, [props.hint_info]);

  return (
    <Dialog open={props.open} onClose={handleCancel} fullWidth>
      <DialogTitle>
        {inventoryItem()
          ? TransactionName(formik.values.transaction_type) +
            " " +
            itemDisplayName(inventoryItem().item)
          : TransactionName(formik.values.transaction_type)}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: ".5rem" }}>
          {!auto && (
            <Grid item xs={12}>
              <QuantitiesTable
                before={inventoryItem()}
                after={After(formik.values, inventoryItem())}
              />
            </Grid>
          )}
          {!props.inventory_item && <InventoryItemField formik={formik} />}
          {formik.values.transaction_type === "PU" && !auto && (
            <>
              <EventField
                formik={formik}
                event_options={event_options}
                inventory_item={inventoryItem()}
                initialValues={props.initialValues}
                hint_info={hint_info}
                setHintInfo={setHintInfo}
                disableFirstRenderUpdate
              />
              <QuantityField
                formik={formik}
                label={
                  "Qty. to " + TransactionName(formik.values.transaction_type)
                }
                tooltip="The total number of items to pull (including items you intend to sell)"
              />
              <SoldField formik={formik} />
            </>
          )}
          {formik.values.transaction_type === "RS" && !auto && (
            <>
              <EventField
                formik={formik}
                event_options={event_options}
                inventory_item={inventoryItem()}
                initialValues={props.initialValues}
                hint_info={hint_info}
                setHintInfo={setHintInfo}
                disableFirstRenderUpdate
              />

              <QuantityField
                formik={formik}
                label={
                  "Qty. to " + TransactionName(formik.values.transaction_type)
                }
                tooltip="The number of items to restock (not including lost or stolen items)"
              />
              <LostField formik={formik} disableCallback />
            </>
          )}
          {formik.values.transaction_type === "ST" && !auto && (
            <>
              <QuantityField
                formik={formik}
                label={
                  "Qty. to " + TransactionName(formik.values.transaction_type)
                }
                tooltip="The number of items to stock"
              />
            </>
          )}
          {formik.values.transaction_type === "PC" && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Quantity on Shopping List: {inventoryItem()?.quantity_needed}
                </Typography>
              </Grid>
              <QuantityField
                formik={formik}
                label={"Qty. Purchased"}
                tooltip="The number of items purchased"
              />
            </>
          )}
          {formik.values.transaction_type === "RE" && !auto && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Quantity Purchased: {inventoryItem().quantity_purchased}
                </Typography>
              </Grid>
              <QuantityField
                formik={formik}
                label={"Qty. Received"}
                tooltip="The number of items received"
              />
              <LostField
                formik={formik}
                inventory_item={inventoryItem()}
                disableCallback
              />
            </>
          )}
          {["AD"].includes(formik.values.transaction_type) && (
            <>
              <Grid item xs={12}>
                <Typography>Adjustments</Typography>
              </Grid>
              <AdjustInventoryQuantityFields formik={formik} auto={auto} />
            </>
          )}
          {!auto && <NotesField formik={formik} />}
        </Grid>
        {auto && (
          <Grid item xs={12}>
            <AutoInfo
              formik={formik}
              event_options={event_options}
              inventory_item={inventoryItem()}
              hint_info={props.hint_info}
            />
          </Grid>
        )}
        {GetErrors(formik.values, inventoryItem()).map((error) => (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item xs="auto">
            {auto && (
              <Button
                variant="outlined"
                color="info"
                onClick={() => setAuto(false)}
              >
                Advanced
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs="auto"
            container
            spacing={1}
            justifyContent={"flex-end"}
          >
            <Grid item xs="auto">
              <Button variant="outlined" color="info" onClick={handleCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              {submitting ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={formik.handleSubmit}
                  disabled={!InputIsValid(formik.values, inventoryItem())}
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const AutoInfo = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  const [hint_info, setHintInfo] = React.useState(props.hint_info);
  const [pullToRent, setPullToRent] = React.useState(false);
  const [pullToSell, setPullToSell] = React.useState(false);
  const [restockWithoutEvent, setRestockWithoutEvent] = React.useState(false);
  React.useEffect(() => {
    setHintInfo(props.hint_info);
    updateFieldsWithHint(props.formik, props.hint_info);
  }, [props.hint_info]);
  return (
    <Grid container spacing={1}>
      {props.formik.values.transaction_type === "RS" && (
        <>
          {!restockWithoutEvent && !props.formik.initialValues.event_id && (
            <>
              <Grid item xs={12}>
                <Typography>Restock from event:</Typography>
              </Grid>
              <EventField
                formik={props.formik}
                event_options={props.event_options}
                inventory_item={props.inventory_item}
                hint_info={props.hint_info}
                setHintInfo={setHintInfo}
              />
            </>
          )}
          {!props.formik.values.event_id && !restockWithoutEvent && (
            <>
              <Grid item xs={12}>
                <Typography sx={{ mt: "1rem" }}>
                  Restock for another reason:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setRestockWithoutEvent(true)}
                >
                  Restock Without Event
                </Button>
              </Grid>
            </>
          )}
          {!restockWithoutEvent && props.formik.values.event_id && (
            <>
              <Grid item xs={12}>
                <LostField formik={props.formik} />
              </Grid>
              {hint_info?.total_quantity_in_event > 0 && (
                <AutoInfoRow>
                  <InfoOutlinedIcon />
                  <Typography>
                    {hint_info?.total_quantity_in_event} items in event
                  </Typography>
                </AutoInfoRow>
              )}
              {hint_info?.total_quantity_sold > 0 && (
                <AutoInfoRow>
                  <SellOutlinedIcon />
                  <Typography>
                    {hint_info?.total_quantity_sold}
                    {" sold "}
                  </Typography>
                </AutoInfoRow>
              )}
              <AutoInfoRow>
                <CheckCircleOutlineOutlinedIcon />
                <Typography>{`Restocking ${
                  props.formik.values.quantity
                } ${itemDisplayName(props.inventory_item.item)}`}</Typography>
              </AutoInfoRow>
            </>
          )}
          {restockWithoutEvent && (
            <>
              <QuantityField
                formik={props.formik}
                label="Quantity to Restock"
              />
              <LostField formik={props.formik} disableCallback />
              <NotesField formik={props.formik} />
            </>
          )}
        </>
      )}
      {props.formik.values.transaction_type === "PU" && (
        <>
          {!pullToRent &&
            !pullToSell &&
            !props.formik.initialValues.event_id && (
              <>
                <Grid item xs={12}>
                  <Typography>Pull for event:</Typography>
                </Grid>
                <EventField
                  formik={props.formik}
                  event_options={props.event_options}
                  inventory_item={props.inventory_item}
                  hint_info={hint_info}
                  setHintInfo={setHintInfo}
                />
              </>
            )}
          {!props.formik.values.event_id && !pullToRent && !pullToSell && (
            <>
              <Grid item xs={12}>
                <Typography sx={{ mt: "1rem" }}>
                  Pull for another reason:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setPullToRent(true)}
                >
                  Pull to Rent
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setPullToSell(true)}
                >
                  Pull to Sell
                </Button>
              </Grid>
            </>
          )}
          {props.formik.values.event_id && (
            <>
              {hint_info?.total_quantity_in_event > 0 && (
                <AutoInfoRow>
                  <InfoOutlinedIcon />
                  <Typography>
                    {hint_info?.total_quantity_in_event} items in event
                  </Typography>
                </AutoInfoRow>
              )}
              {hint_info?.quantity_in_stock <
                hint_info?.quantity_need_to_pull && (
                <AutoInfoRow>
                  <WarningAmberOutlinedIcon />
                  <Typography>
                    Only {hint_info.quantity_in_stock} in stock
                  </Typography>
                </AutoInfoRow>
              )}
              {props.formik.values.sold > 0 && (
                <AutoInfoRow>
                  <SellOutlinedIcon />
                  <Typography>Selling {props.formik.values.sold}</Typography>
                </AutoInfoRow>
              )}
              {props.formik.values.quantity - props.formik.values.sold > 0 && (
                <AutoInfoRow>
                  <UndoOutlinedIcon />
                  <Typography>
                    Renting{" "}
                    {props.formik.values.quantity - props.formik.values.sold}
                  </Typography>
                </AutoInfoRow>
              )}
              {props.formik.values.quantity > 0 && (
                <AutoInfoRow>
                  <CheckCircleOutlineOutlinedIcon />
                  <Typography>{`Pulling ${
                    props.formik.values.quantity
                  } ${itemDisplayName(props.inventory_item.item)}`}</Typography>
                </AutoInfoRow>
              )}
            </>
          )}
          {pullToRent && (
            <>
              <QuantityField formik={props.formik} label="Quantity to Pull" />
              <NotesField formik={props.formik} />
            </>
          )}
          {pullToSell && (
            <>
              <SoldField formik={props.formik} label="Quantity to Sell" />
              <NotesField formik={props.formik} />
            </>
          )}
        </>
      )}
      {props.formik.values.transaction_type === "RE" && (
        <AutoInfoRow>
          <CheckCircleOutlineOutlinedIcon />
          <Typography>{`Received ${
            props.formik.values.quantity
          } ${itemDisplayName(props.inventory_item.item)}`}</Typography>
        </AutoInfoRow>
      )}
      {props.formik.values.transaction_type === "ST" && (
        <AutoInfoRow>
          <CheckCircleOutlineOutlinedIcon />
          <Typography>{`Stocking ${
            props.formik.values.quantity
          } ${itemDisplayName(props.inventory_item.item)}`}</Typography>
        </AutoInfoRow>
      )}
    </Grid>
  );
};

const AutoInfoRow = (props) => {
  return (
    <Grid item xs={12} container>
      <Grid item xs={1}>
        {props.children[0]}
      </Grid>
      <Grid item xs={11}>
        {props.children[1]}
      </Grid>
    </Grid>
  );
};

const After = (transaction, inventory_item) => {
  var after = inventory_item
    ? { ...inventory_item }
    : {
        quantity_total: 0,
        quantity_in_stock: 0,
        quantity_pulled: 0,
        quantity_purchased: 0,
        quantity_receiving: 0,
      };

  if (transaction.transaction_type === "PU") {
    after = {
      ...after,
      quantity_in_stock:
        parseInt(after.quantity_in_stock) - parseInt(transaction.quantity),
      quantity_pulled:
        parseInt(after.quantity_pulled) +
        parseInt(transaction.quantity) -
        parseInt(transaction.sold),
      quantity_total:
        parseInt(after.quantity_total) - parseInt(transaction.sold),
    };
  } else if (transaction.transaction_type === "RS") {
    after = {
      ...after,
      quantity_in_stock:
        parseInt(after.quantity_in_stock) + parseInt(transaction.quantity),
      quantity_pulled:
        parseInt(after.quantity_pulled) -
        parseInt(transaction.quantity) -
        parseInt(transaction.lost),
      quantity_total:
        parseInt(after.quantity_total) - parseInt(transaction.lost),
    };
  } else if (transaction.transaction_type === "ST") {
    after = {
      ...after,
      quantity_in_stock:
        parseInt(after.quantity_in_stock) + parseInt(transaction.quantity),
      quantity_receiving:
        parseInt(after.quantity_receiving) - parseInt(transaction.quantity),
    };
  } else if (transaction.transaction_type === "PC") {
    after = {
      ...after,
      quantity_purchased:
        parseInt(after.quantity_purchased) + parseInt(transaction.quantity),
      quantity_total:
        parseInt(after.quantity_total) + parseInt(transaction.quantity),
    };
  } else if (transaction.transaction_type === "RE") {
    after = {
      ...after,
      quantity_purchased:
        parseInt(after.quantity_purchased) -
        parseInt(transaction.quantity) -
        parseInt(transaction.lost),
      quantity_receiving:
        parseInt(after.quantity_receiving) + parseInt(transaction.quantity),
      quantity_total:
        parseInt(after.quantity_total) - parseInt(transaction.lost),
    };
  } else if (transaction.transaction_type === "AD") {
    after = {
      ...after,
      quantity_total: parseInt(transaction.adjust_total),
      quantity_in_stock: parseInt(transaction.adjust_in_stock),
      quantity_pulled: parseInt(transaction.adjust_pulled),
      quantity_receiving: parseInt(transaction.adjust_receiving),
      quantity_purchased: parseInt(transaction.adjust_purchased),
    };
  }

  return after;
};

const InputIsValid = (transaction, inventory_item) => {
  let after = After(transaction, inventory_item);
  return (
    after.quantity_in_stock >= 0 &&
    after.quantity_pulled >= 0 &&
    after.quantity_receiving >= 0 &&
    after.quantity_total >= 0 &&
    after.quantity_purchased >= 0 &&
    (transaction.quantity > 0 ||
      transaction.lost > 0 ||
      transaction.damaged > 0 ||
      transaction.sold > 0 ||
      transaction.adjust_total > 0 ||
      transaction.adjust_in_stock > 0 ||
      transaction.adjust_pulled > 0 ||
      transaction.adjust_receiving > 0 ||
      transaction.adjust_purchased > 0) &&
    after.quantity_in_stock +
      after.quantity_pulled +
      after.quantity_receiving +
      after.quantity_purchased ===
      after.quantity_total
  );
};

const GetErrors = (transaction, inventory_item) => {
  let after = After(transaction, inventory_item);
  let errors = [];
  if (transaction.transaction_type === "AD") {
    if (
      after.quantity_total !==
      after.quantity_in_stock +
        after.quantity_pulled +
        after.quantity_receiving +
        after.quantity_purchased
    ) {
      errors.push(
        "Total quantity must equal sum of in stock, pulled, and receiving"
      );
    }
  }
  if (transaction.transaction_type === "PU") {
    if (after.quantity_in_stock < 0) {
      errors.push("Not enough in stock");
    }
  }
  return errors;
};

const QuantitiesTable = (props) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Current</TableCell>
          <TableCell>After</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[
          { label: "Total", id: "quantity_total" },
          { label: "In Stock", id: "quantity_in_stock" },
          { label: "Rented", id: "quantity_pulled" },
          { label: "Receiving", id: "quantity_receiving" },
          { label: "Purchased", id: "quantity_purchased" },
        ].map((row) => (
          <QuantitiesTableRow
            key={row.id}
            label={row.label}
            id={row.id}
            before={props.before}
            after={props.after}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const QuantitiesTableRow = (props) => {
  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>{props.before ? props.before[props.id] : 0}</TableCell>
      <TableCell
        sx={{
          color: props.after[props.id] < 0 ? "error.main" : undefined,
        }}
      >
        {props.after[props.id]}
      </TableCell>
    </TableRow>
  );
};

const InventoryLogTextField = (props) => {
  return (
    <Grid item xs={12}>
      <TextField
        id={props.id}
        label={props.label}
        variant="outlined"
        value={props.formik.values[props.id]}
        onChange={props.formik.handleChange}
        fullWidth
      />
    </Grid>
  );
};

const InventoryLogNumberField = (props) => {
  const allowed_chars = props.currency ? /[^0-9.-]/ : /[^0-9-]/;
  const parser = props.currency ? parseFloat : parseInt;

  const [value, setValue] = React.useState(props.formik.values[props.id]);
  const handleChange = (event) => {
    let original_values = props.formik.values;
    if (!allowed_chars.test(event.target.value)) {
      setValue(event.target.value);
      try {
        if (event.target.value === "") {
          var new_value = 0;
        } else {
          var new_value = parser(event.target.value);
        }
        if (isNaN(new_value)) {
          new_value = 0;
        }
      } catch (error) {
        new_value = 0;
      }
      props.formik.setFieldValue(props.id, new_value);
      if (props.handleChangeCb)
        props.handleChangeCb(
          { ...props.formik.values, [props.id]: new_value },
          original_values
        );
    }
  };

  const handleBlur = () => {
    if (value === "") {
      setValue(0);
      props.formik.setFieldValue(props.id, 0);
    } else {
      try {
        let new_value = parser(value);
        if (isNaN(new_value)) {
          new_value = 0;
        }
        setValue(new_value);
        props.formik.setFieldValue(props.id, new_value);
      } catch (error) {
        setValue(0);
        props.formik.setFieldValue(props.id, 0);
      }
    }
  };

  React.useEffect(() => {
    setValue(props.formik.values[props.id]);
  }, [props.formik.values[props.id]]);

  return (
    <Grid item xs={12}>
      <TextField
        id={props.id}
        label={props.label}
        variant="outlined"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        InputProps={
          props.tooltip && {
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={props.tooltip}>
                  <HelpOutlineOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }
        }
      />
    </Grid>
  );
};

const QuantityField = (props) => {
  return (
    <InventoryLogNumberField
      id="quantity"
      label={props.label ? props.label : "Quantity *"}
      formik={props.formik}
      tooltip={props.tooltip}
    />
  );
};

const AdjustTotalField = (props) => {
  return (
    <InventoryLogNumberField
      id="adjust_total"
      label={props.label ? props.label : "Total"}
      formik={props.formik}
      tooltip={"Total inventory quantity"}
      handleChangeCb={props.handleChangeCb}
    />
  );
};

const AdjustInStockField = (props) => {
  return (
    <InventoryLogNumberField
      id="adjust_in_stock"
      label={props.label ? props.label : "In Stock"}
      formik={props.formik}
      tooltip={"In stock quantity"}
      handleChangeCb={props.handleChangeCb}
    />
  );
};

const AdjustPulledField = (props) => {
  return (
    <InventoryLogNumberField
      id="adjust_pulled"
      label={props.label ? props.label : "Rented"}
      formik={props.formik}
      tooltip={"Rented quantity"}
      handleChangeCb={props.handleChangeCb}
    />
  );
};

const AdjustReceivingField = (props) => {
  return (
    <InventoryLogNumberField
      id="adjust_receiving"
      label={props.label ? props.label : "Receiving"}
      formik={props.formik}
      tooltip={"Receiving quantity"}
      handleChangeCb={props.handleChangeCb}
    />
  );
};

const AdjustPurchasedField = (props) => {
  return (
    <InventoryLogNumberField
      id="adjust_purchased"
      label={props.label ? props.label : "Purchased"}
      formik={props.formik}
      tooltip={"Purchased quantity"}
      handleChangeCb={props.handleChangeCb}
    />
  );
};

const LostField = (props) => {
  const lostCb = (values, original_values) => {
    if (values.transaction_type === "RE") {
      props.formik.setFieldValue(
        "quantity",
        props.inventory_item.quantity_purchased - values.lost
      );
    } else if (values.transaction_type === "RS") {
      let lost_diff = values.lost - original_values.lost;
      props.formik.setFieldValue("quantity", values.quantity - lost_diff);
    }
  };
  return (
    <InventoryLogNumberField
      id="lost"
      label={props.label ? props.label : "Lost / Stolen / Damaged"}
      formik={props.formik}
      tooltip={
        "Number of items lost, stolen, or damaged as part of this inventory transaction"
      }
      handleChangeCb={!props.disableCallback ? lostCb : undefined}
    />
  );
};

const SoldField = (props) => {
  const soldCb = (values, original_values) => {
    if (values.transaction_type === "PU") {
      let sold_diff = values.sold - original_values.sold;
      props.formik.setFieldValue("quantity", values.quantity + sold_diff);
    }
  };
  return (
    <InventoryLogNumberField
      id="sold"
      label={props.label ? props.label : "Sold"}
      formik={props.formik}
      tooltip={"Number of items sold as part of this inventory transaction"}
      handleChangeCb={!props.disableCallback ? soldCb : undefined}
    />
  );
};

const NotesField = (props) => {
  return (
    <InventoryLogTextField
      id="notes"
      label={props.label ? props.label : "Notes"}
      formik={props.formik}
    />
  );
};

const updateFieldsWithHint = (formik, hint_info) => {
  if (hint_info && hint_info.total_quantity_in_event > 0) {
    if (
      formik.values.transaction_type === "PU" ||
      formik.values.transaction_type === "RS"
    ) {
      if (formik.values.transaction_type === "PU") {
        let quantity =
          hint_info.quantity_need_to_pull <= hint_info.quantity_in_stock
            ? hint_info.quantity_need_to_pull
            : hint_info.quantity_in_stock;
        formik.setFieldValue("quantity", quantity);
        console.log("setting quantity to ", quantity);
        if (hint_info.quantity_need_to_sell > 0) {
          formik.setFieldValue(
            "sold",
            hint_info.quantity_need_to_sell > quantity
              ? quantity
              : hint_info.quantity_need_to_sell
          );
          console.log(
            "setting sold to ",
            hint_info.quantity_need_to_sell > quantity
              ? quantity
              : hint_info.quantity_need_to_sell
          );
        }
      } else if (formik.values.transaction_type === "RS") {
        if (hint_info.quantity_need_to_restock > 0) {
          formik.setFieldValue("quantity", hint_info.quantity_need_to_restock);
        }
      } else {
        formik.setFieldValue("quantity", 0);
        formik.setFieldValue("sold", 0);
      }
    }
  } else {
    formik.setFieldValue("quantity", formik.initialValues.quantity);
    formik.setFieldValue("sold", 0);
    formik.setFieldValue("sold_value", 0);
  }
};

const EventField = (props) => {
  const [firstRender, setFirstRender] = React.useState(true);
  const [back_office] = useAccountState((state) => [state.back_office]);
  const [event, setEvent] = React.useState(null);
  const [hint, setHint] = React.useState(undefined);
  const updateHint = (event, hint_info) => {
    if (!(props.disableFirstRenderUpdate && firstRender)) {
      updateFieldsWithHint(props.formik, hint_info);
    }
    if (hint_info && hint_info.total_quantity_in_event > 0) {
      setHint(undefined);
      if (
        event &&
        (props.formik.values.transaction_type === "PU" ||
          props.formik.values.transaction_type === "RS")
      ) {
        let hint = `${event.name} includes ${
          hint_info.total_quantity_in_event
        } "${itemDisplayName(props.inventory_item.item)}" total`;
        if (props.formik.values.transaction_type === "PU") {
          if (
            hint_info.quantity_need_to_pull > 0 ||
            hint_info.quantity_need_to_sell > 0
          ) {
            if (hint_info.quantity_need_to_pull > 0) {
              hint += `, ${hint_info.quantity_need_to_pull} left to pull`;
            }
            if (hint_info.quantity_need_to_sell > 0) {
              hint += `, ${hint_info.quantity_need_to_sell} left to sell`;
            }
          } else {
            hint += `, all items already pulled`;
          }
        } else if (props.formik.values.transaction_type === "RS") {
          if (hint_info.quantity_need_to_restock > 0) {
            hint += `, ${hint_info.quantity_need_to_restock} left to restock`;
          } else {
            hint += `, all item already restocked`;
          }
        }
        setHint(hint);
      }
    } else {
      setHint(
        `${event.name} does not include any "${itemDisplayName(
          props.inventory_item.item
        )}"`
      );
    }
  };

  const handleChange = (event, value) => {
    setEvent(value);
    if (value) {
      props.formik.setFieldValue("event_id", value.uuid);
      if (props.hint_info) {
        updateHint(value, props.hint_info);
      } else {
        getInventoryItemInfoApi(value.uuid, props.inventory_item.uuid).then(
          (response) => {
            updateHint(value, response.data[props.inventory_item.item.uuid]);
            props.setHintInfo(response.data[props.inventory_item.item.uuid]);
          }
        );
      }
    } else {
      setHint(undefined);
      if (!(props.disableFirstRenderUpdate && firstRender)) {
        props.formik.resetForm();
      }
    }
  };

  React.useEffect(() => {
    if (props.initialValues) {
      let event = props.event_options.find(
        (event) => event.uuid === props.initialValues.event_id
      );
      handleChange(null, event);
    }
  }, [props.initialValues]);

  React.useEffect(() => {
    let event = props.event_options.find(
      (event) => event.uuid === props.formik.values.event_id
    );
    handleChange(null, event);
    setFirstRender(false);
  }, [props.formik.values.event_id]);

  return (
    <>
      <Grid item xs={12}>
        {!props.initialValues?.event_id && (
          <Autocomplete
            id="event"
            options={sort(props.event_options).by([{ desc: "has_item" }])}
            groupBy={(option) =>
              option.has_item ? "Events With Item" : "Other Events"
            }
            getOptionLabel={(option) =>
              option.name +
              " " +
              eventDateFormatted(
                option.date,
                option.timezone,
                back_office.date_format
              )
            }
            onChange={handleChange}
            value={event}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Associated Event"
                variant="outlined"
              />
            )}
          />
        )}
      </Grid>
      {hint && (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {hint}
          </Typography>
        </Grid>
      )}
    </>
  );
};

const InventoryItemField = (props) => {
  const [inventoryItems] = useAccountState((state) => [state.inventoryItems]);
  const handleChange = (event, value) => {
    props.formik.setFieldValue("inventory_item_id", value ? value.uuid : null);
    setValue(value);
    if (props.formik.values.transaction_type === "PC") {
      props.formik.setFieldValue(
        "quantity",
        value?.quantity_needed ? value?.quantity_needed : 1
      );
    }
  };
  const [value, setValue] = React.useState(null);
  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          id="inventory_item"
          options={sortInventoryItemOptions(inventoryItems)}
          value={value}
          getOptionLabel={(option) => itemDisplayName(option.item)}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} label="Item" variant="outlined" />
          )}
          groupBy={(option) => materialTypeName(option.item.type)}
        />
      </Grid>
    </>
  );
};

export const AdjustInventoryQuantityFields = (props) => {
  const totalCb = (values) => {
    let in_stock =
      values.adjust_total -
      values.adjust_pulled -
      values.adjust_receiving -
      values.adjust_purchased;
    if (in_stock < 0) {
      in_stock = 0;
    }
    props.formik.setFieldValue("adjust_in_stock", in_stock);
  };
  const otherCb = (values) => {
    let total =
      values.adjust_in_stock +
      values.adjust_pulled +
      values.adjust_receiving +
      values.adjust_purchased;
    if (total > 0) {
      props.formik.setFieldValue("adjust_total", total);
    }
  };
  return (
    <>
      <AdjustInStockField formik={props.formik} handleChangeCb={otherCb} />
      {!props.auto && (
        <>
          <AdjustPulledField formik={props.formik} handleChangeCb={otherCb} />
          <AdjustReceivingField
            formik={props.formik}
            handleChangeCb={otherCb}
          />
          <AdjustPurchasedField
            formik={props.formik}
            handleChangeCb={otherCb}
          />
          <AdjustTotalField formik={props.formik} handleChangeCb={totalCb} />
        </>
      )}
    </>
  );
};

export default withRouter(InventoryLogDialog);
