import React from "react";
import { withRouter } from "react-router-dom";

import { sort } from "fast-sort";

import { getVendorDefaults } from "../api/EventsApi";
import {
  deleteDefaultVendorApi,
  addDefaultVendorApi,
  updateDefaultVendorApi,
} from "../api/ProfileApi";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
  CircularProgress,
  Divider,
} from "@mui/material";

import VendorDialog from "./VendorDialog";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const VendorSettings = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [vendor_defaults, setVendorDefaults] = React.useState([]);
  const [adding_vendor_default, setAddingVendorDefault] = React.useState(false);

  const deleteVendorDefault = (vendor_default) => {
    const new_vendor_defaults = vendor_defaults.filter(
      (vd) => vd.uuid !== vendor_default.uuid,
    );
    setVendorDefaults(new_vendor_defaults);
    deleteDefaultVendorApi(vendor_default).then((resp) => {
      console.log(resp);
    });
  };

  const addVendorDefault = (vendor_location) => {
    addDefaultVendorApi({ vendor_location: vendor_location }).then((resp) => {
      setVendorDefaults([...vendor_defaults, resp.data]);
    });
  };

  const updateDefaultVendor = (vendor_default) => {
    var new_vendor_defaults = [
      vendor_default,
      ...vendor_defaults.filter((vd) => vd.uuid !== vendor_default.uuid),
    ];
    setVendorDefaults(new_vendor_defaults);
    updateDefaultVendorApi(vendor_default).then((resp) => console.log(resp));
  };

  const sortVendorDefaults = (vendor_defaults) => {
    return sort(vendor_defaults).by((vd) =>
      vendorLocationLabel(vd.vendor_location),
    );
  };

  React.useEffect(() => {
    getVendorDefaults().then((resp) => {
      setVendorDefaults(resp.data);
      setLoaded(true);
    });
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item xs={12} display="flex">
          <Button
            sx={{ ml: "auto", mb: "1rem" }}
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setAddingVendorDefault(true)}
            id="add-vendor-default"
          >
            Add Favorite Wholesaler
          </Button>
          <VendorDialog
            open={adding_vendor_default}
            handleCancel={() => setAddingVendorDefault(false)}
            handleSubmit={addVendorDefault}
            filterVendorLocations={vendor_defaults.map(
              (vd) => vd.vendor_location,
            )}
            disableDefaultVendors
          />
        </Grid>
        {vendor_defaults.length > 0 ? (
          <Box display="contents">
            <Grid item xs={12}>
              <Typography variant="h6">Default Wholesaler</Typography>
            </Grid>
            <Divider />
            <VendorLocationLine
              vendor_default={vendor_defaults[0]}
              deleteVendorDefault={deleteVendorDefault}
              disableFavorite
            />
            {vendor_defaults.length > 1 ? (
              <Grid item xs={12} sx={{ mt: "2rem" }}>
                <Typography variant="h6">My Other Wholesalers</Typography>
              </Grid>
            ) : null}
            <Divider />
            {sortVendorDefaults(
              vendor_defaults.slice(1, vendor_defaults.length),
            ).map((vd) => (
              <VendorLocationLine
                key={vd.uuid}
                vendor_default={vd}
                deleteVendorDefault={deleteVendorDefault}
                updateDefaultVendor={updateDefaultVendor}
              />
            ))}
          </Box>
        ) : loaded ? (
          <Box
            sx={{
              borderStyle: "dotted",
              borderColor: "info.light",
              p: "2rem",
              borderRadius: "7.5px",
              mt: "1rem",
            }}
          >
            <Typography
              sx={{
                color: "info.light",
                fontWeight: "normal",
              }}
              variant="h6"
            >
              No favorited wholesalers have been added yet.{" "}
              <Link
                onClick={() => setAddingVendorDefault(true)}
                color="inherit"
                sx={{
                  "&:hover": { cursor: "pointer" },
                }}
              >
                Add Favorite Wholesaler
              </Link>
            </Typography>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};

const VendorLocationLine = (props) => {
  const [deleting, setDeleting] = React.useState(false);
  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      justifyContent="space-between"
      key={props.vendor_default.uuid}
    >
      <Grid item xs={8}>
        <Typography>
          {vendorLocationLabel(props.vendor_default.vendor_location)}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Box>
          {!props.disableFavorite ? (
            <Tooltip title="Make Default Wholesaler">
              <IconButton
                sx={{ ml: "auto" }}
                onClick={() => props.updateDefaultVendor(props.vendor_default)}
                id={props.vendor_default.uuid + "-make-default"}
              >
                <StarBorderOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="Remove Favorited Wholesaler">
            <IconButton
              sx={{ ml: "auto" }}
              onClick={() => setDeleting(true)}
              id={props.vendor_default.uuid + "-delete"}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
          <DeleteVendorDefaultDialog
            open={deleting}
            handleCancel={() => setDeleting(false)}
            handleSubmit={() => {
              props.deleteVendorDefault(props.vendor_default);
            }}
            vendor_location={props.vendor_default.vendor_location}
          />
        </Box>
      </Grid>
      <Divider sx={{ width: "100%" }} />
    </Grid>
  );
};

const vendorLocationLabel = (vendor_location) => {
  if (vendor_location) {
    let location = vendor_location.online_only
      ? vendor_location.url
          .replace("https://", "")
          .replace("/", "")
          .replace("http://", "")
          .replace("www.", "")
      : vendor_location.city;
    return vendor_location.vendor.name + " (" + location + ")";
  } else {
    return "No vendor selected.";
  }
};

const DeleteVendorDefaultDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Favorited Wholesaler?</DialogTitle>
      <DialogContent>
        <Typography component="div">
          Are you sure you want to remove{" "}
          <Box fontWeight="fontWeightMedium" display="inline">
            {vendorLocationLabel(props.vendor_location)}
          </Box>{" "}
          from your favorited wholesalers?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="cancel-delete-vendor-default"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            props.handleCancel();
            props.handleSubmit();
          }}
          jid="confirm-delete-vendor-default"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(VendorSettings);
