import React, { useRef } from "react";

import { Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { useAccountState } from "../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { formatStringForId } from "../utils/utils";
import EventItemDividerButtons from "./EventItemDividerButtons";

const RecipeProposalSection = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [deleteRecipeProposalSection, updateRecipeProposalSection] =
    useAccountState((state) => [
      state.deleteRecipeProposalSection,
      state.updateRecipeProposalSection,
    ]);

  const [description, setDescription] = React.useState(
    props.recipe_proposal_section.description,
  );

  React.useEffect(() => {
    setDescription(props.recipe_proposal_section.description);
  }, [props.recipe_proposal_section.description]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDescriptionBlur = (e) => {
    updateRecipeProposalSection({
      ...props.recipe_proposal_section,
      description: description,
    });
  };

  const handleDelete = () => {
    deleteRecipeProposalSection(props.recipe_proposal_section.uuid);
  };

  return (
    <Draggable
      draggableId={props.recipe_proposal_section.uuid}
      index={props.index}
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{ p: "1rem", border: "1px solid black" }}
          >
            <EventItemDividerButtons
              index={props.index}
              hidden={snapshot.isDragging}
            />
            <Paper
              sx={{
                p: ".5rem",
                borderLeft: "3px solid",
                borderLeftColor: "primary.main",
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Grid item xs="auto" container>
                    <Grid item xs={"auto"}>
                      <div {...provided.dragHandleProps}>
                        <DragIndicatorIcon
                          sx={{ color: "info.light", mr: ".25rem" }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={"auto"}>
                      <Typography variant="h6">
                        <RecipeProposalSectionName
                          recipe_proposal_section={
                            props.recipe_proposal_section
                          }
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton onClick={handleDelete} size="small">
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={description}
                    onChange={handleDescriptionChange}
                    label="Description"
                    onBlur={handleDescriptionBlur}
                    fullWidth
                    size="small"
                    multiline
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
      }}
    </Draggable>
  );
};

const RecipeProposalSectionName = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [updateRecipeProposalSection] = useAccountState((state) => [
    state.updateRecipeProposalSection,
  ]);
  const [editing_name, setEditingName] = React.useState(false);
  const name_input = useRef(null);
  const updateName = () => {
    toggleEditingName();
    updateRecipeProposalSection({
      uuid: props.recipe_proposal_section.uuid,
      name: name_input.current.value,
    });
  };
  const toggleEditingName = () => {
    setEditingName(!editing_name);
  };
  return (
    <Box sx={{ width: "100%" }}>
      {editing_name ? (
        <OutlinedInput
          onClick={stopClickProp}
          inputRef={name_input}
          onBlur={updateName}
          defaultValue={props.recipe_proposal_section.name}
          label="Recipe Name"
          id={props.recipe_proposal_section.uuid + "-name-input"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEditingName();
                }}
                edge="end"
              >
                <CloseOutlinedIcon fontSize=".25rem" />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  updateName();
                }}
              >
                <CheckOutlinedIcon fontSize=".25rem" />
              </IconButton>
            </InputAdornment>
          }
        />
      ) : (
        <Tooltip
          title={props.recipe_proposal_section.name}
          placement="bottom"
          enterDelay={750}
        >
          <Typography
            onClick={
              !props.disabled
                ? (e) => {
                    e.stopPropagation();
                    toggleEditingName();
                  }
                : undefined
            }
            variant="h6"
            noWrap
            sx={props.typographySx}
          >
            {props.recipe_proposal_section.name}
            {!props.disabled ? (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEditingName();
                }}
                id={formatStringForId(
                  props.recipe_proposal_section.uuid + "-name-edit-button",
                )}
              >
                <EditOutlinedIcon fontSize=".25rem" />
              </IconButton>
            ) : null}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

const stopClickProp = (e) => {
  e.stopPropagation();
};

export default withRouter(RecipeProposalSection);
