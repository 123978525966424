import React from "react";
import { withRouter } from "react-router-dom";

import { Grid, Box, IconButton } from "@mui/material";

import CroppedImg from "./CroppedImg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { sort_by_order } from "../utils/utils";
import { Cropper, FileDropZone } from "./FileUploader";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const RecipeImgs = (props) => {
  const handleUploadedFile = (images) => {
    var data = {
      cropped_image: images,
    };
    props.addImg(data);
  };

  const max_photos = 25;

  return (
    <Grid container spacing={0.5}>
      {props.imgs.sort(sort_by_order).map((img, ind) => (
        <InspirationImg
          img={img}
          removeImg={props.removeImg}
          addImg={props.addImg}
          updateImg={props.updateImg}
          key={ind}
          display="contents"
          ind={ind}
          id={props.id + "-" + ind}
          displayOnly={props.displayOnly}
          zoom={props.zoom}
          height={props.height}
        />
      ))}
      {!props.displayOnly && props.imgs.length < max_photos ? (
        <Grid item xs={"auto"}>
          <Box
            sx={{
              p: "1rem",
              borderStyle: "dotted",
              borderColor: "lightgrey",
              display: "flex",
              overflow: "visible",
            }}
          >
            <Box sx={{ m: "auto", width: "100%", height: "100%" }}>
              <FileDropZone
                hideBorder
                width="100%"
                height="100%"
                id={props.id + "-dropzone"}
                handleSubmit={handleUploadedFile}
                maxSelections={max_photos - props.imgs.length}
              />
            </Box>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

const InspirationImg = (props) => {
  const [img, setImg] = React.useState(props.img);
  const [isHovering, setIsHovering] = React.useState(false);
  const [cropping, setCropping] = React.useState(false);
  const toggleCropping = () => setCropping(!cropping);

  const handleCroppingSubmit = (cropped_images) => {
    props.updateImg({
      uuid: img.uuid,
      cropped_image: { ...img.cropped_image, ...cropped_images[0] },
    });
  };

  const handleDelete = () => {
    props.removeImg(img.uuid);
    setImg(undefined);
  };

  React.useEffect(() => {
    setImg(props.img);
  }, [props.img]);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return img ? (
    <Grid
      item
      xs={"auto"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ position: "relative" }}>
        <CroppedImg
          img={img.cropped_image}
          zoom={props.zoom}
          height={props.height ? props.height : 100}
        />
        <Cropper
          image={img.cropped_image}
          open={cropping}
          handleCancel={toggleCropping}
          handleSubmit={handleCroppingSubmit}
        />
        {isHovering && !props.displayOnly && (
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              color: "white",
              // backgroundColor: "black", // You can adjust the styles as needed
              borderRadius: "50%",
            }}
            onClick={handleDelete}
            size="small"
          >
            <CloseOutlinedIcon />
          </IconButton>
        )}
        {isHovering && !props.displayOnly && (
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "white",
              // backgroundColor: "black", // You can adjust the styles as needed
              borderRadius: "50%",
            }}
            onClick={toggleCropping}
            size="small"
          >
            <EditOutlinedIcon />
          </IconButton>
        )}
      </div>
    </Grid>
  ) : null;
};

export default withRouter(RecipeImgs);
