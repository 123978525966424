import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";

import { verifyUserHash } from "../api/UserVerification";
import { Grid, Typography } from "@mui/material";
import { LogoBeeWithText } from "../components/Navbar";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    let qs = new URLSearchParams(props.location.search);
    this.state = {
      redirect: false,
      email_verified: this.props.email_verified,
    };
  }
  componentDidMount() {
    let hash = this.props.match.params.hash;
    verifyUserHash(hash)
      .then((resp) => {
        this.props.setUser(resp.data);
        this.props.setEmailVerifiedTrue();
        localStorage.setItem("token", resp.data.token);
        this.props.setLoggedInTrue();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          this.setState({ logged_in: false, redirect: true });
        }
      });
  }

  render() {
    if (this.props.email_verified) {
      return <Redirect to="/" />;
    } else {
      if (this.state.redirect) {
        return <Redirect to="/" />;
      } else {
        return (
          <Grid container justifyContent="center" sx={{ mt: "5rem" }}>
            <Grid item xs="auto">
              <LogoBeeWithText />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center">
                Redirecting... If you are not redirected soon, please refresh.
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }
  }
}

export default withRouter(VerifyEmail);
