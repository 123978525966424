import { unsecureAPI } from "../api";

export const getReportsApi = (limit, offset) => {
  return unsecureAPI.get(`api/report/?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const generateReportApi = (data) => {
  return unsecureAPI.post("api/report/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getReportStatusApi = (uuid) => {
  return unsecureAPI.get(`api/report/${uuid}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteReportApi = (uuid) => {
  return unsecureAPI.delete(`api/report/${uuid}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};
