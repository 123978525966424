import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import async from "async";

import {
  Typography,
  Box,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  Autocomplete,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableBody,
  DialogContent,
  TableCell,
  Radio,
  DialogActions,
  Alert,
  AlertTitle,
} from "@mui/material";

import {
  addEventItemApi,
  bulkUpdateItemPricesApi,
  debouncedUpdateRecipeItemApi,
  deleteEventAttachmentApi,
  deleteUserEvent,
  getEventOrderDataApi,
  getSelectableItems,
  getUserEvents,
  removeEventItemApi,
  removeItemApi,
  swapEventItemApi,
  updateEventItemApi,
  updateItemApi,
  updateItemPriceApi,
} from "../api/EventsApi";
import TabPanel from "./TabPanel";
import EventTemplates from "./EventTemplates";
import CustomItems from "./CustomItems";
import TaxProfileSettings from "./TaxProfileSettings";
import { useAccountState } from "../state/store";
import Forms from "./Forms";
import FormSubmissions from "./FormSubmissions";
import RecipeTemplates from "./RecipeTemplates";
import { sort } from "fast-sort";
import { eventDateFormatted } from "../utils/event_utils";
import OrderItems from "./OrderItems";
import itemCost, {
  _itemCounts,
  combineItemCounts,
  compareItemPrices,
  itemCounts,
  itemDisplayName,
  itemPriceInList,
  itemPricesDictFromList,
  sumCounts,
} from "../utils/item_utils";
import { AccountStateOverridesProvider } from "./AccountStateOverridesContext";
import { LoadingButton } from "@mui/lab";
import {
  short_vendor_location_display_name,
  vendor_location_display_name,
} from "../utils/vendor_utils";

const Orders = (props) => {
  const [
    initializeBackOffice,
    initializeEvents,
    initializeSelectableItems,
    initializeUser,
  ] = useAccountState((state) => [
    state.initializeBackOffice,
    state.initializeEvents,
    state.initializeSelectableItems,
    state.initializeUser,
  ]);
  const [loaded, setLoaded] = React.useState(false);
  const [loadingOrderData, setLoadingOrderData] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedEvents, _setSelectedEvents] = React.useState([]);
  const setSelectedEvents = (events) => {
    _setSelectedEvents(events);
    setOrderEvents([]);
  };
  const [orderEvents, setOrderEvents] = React.useState([]);

  const updateRecipeItem = (event_item, data) => {
    let event = orderEvents.find(
      (e) => e.event_version.uuid === event_item.event_version
    );
    let event_item_index = event.event_version.event_items.findIndex(
      (ei) => ei.uuid === event_item.uuid
    );
    let recipe_item_index = event_item.recipe_items.findIndex(
      (ri) => ri.uuid === data.uuid
    );
    let recipe_item = event_item.recipe_items[recipe_item_index];
    let new_recipe_item = { ...recipe_item, ...data };
    let new_event_item = {
      ...event_item,
      recipe_items: [
        ...event_item.recipe_items.slice(0, recipe_item_index),
        new_recipe_item,
        ...event_item.recipe_items.slice(recipe_item_index + 1),
      ],
    };
    let new_event = {
      ...event,
      event_version: {
        ...event.event_version,
        event_items: [
          ...event.event_version.event_items.slice(0, event_item_index),
          new_event_item,
          ...event.event_version.event_items.slice(event_item_index + 1),
        ],
      },
    };
    let new_order_events = [
      ...orderEvents.slice(0, orderEvents.indexOf(event)),
      new_event,
      ...orderEvents.slice(orderEvents.indexOf(event) + 1),
    ];
    setOrderEvents(new_order_events);
    debouncedUpdateRecipeItemApi(data);
  };

  const removeItem = (event_recipe_uuid, recipe_item_uuid) => {
    let new_events = orderEvents.map((event) => ({
      ...event,
      event_version: {
        ...event.event_version,
        event_items: event.event_version.event_items.map((event_item) => ({
          ...event_item,
          recipe_items: event_item.recipe_items.filter(
            (recipe_item) => recipe_item.uuid !== recipe_item_uuid
          ),
        })),
      },
    }));
    setOrderEvents(new_events);
    removeItemApi(recipe_item_uuid).then((resp) => {
      if (!resp.data.success) {
        console.log(resp.data);
      }
    });
  };

  const updateItemPriceInState = (data, cb) => {
    const new_order_events = orderEvents.map((event) => ({
      ...event,
      event_version: {
        ...event.event_version,
        item_prices: event.event_version.item_prices.map((item_price) =>
          item_price.uuid === data.uuid
            ? { ...item_price, ...data }
            : item_price
        ),
      },
    }));
    setOrderEvents(new_order_events);
    if (cb) {
      cb();
    }
  };

  const updateItemPrice = (data, cb) => {
    updateItemPriceInState(data, cb);
    bulkUpdateItemPricesApi({
      item_prices: { [data.item]: data },
      event_version_uuids: orderEvents.map((event) => event.event_version.uuid),
    });
  };

  const swapEventItem = (new_item, old_item, cb) => {
    var requests;
    requests = orderEvents.map((event) => {
      let item_price = event.event_version.item_prices.find(
        (item_price) => item_price.item === old_item.uuid
      );
      let data = {
        new_item_uuid: new_item.uuid,
        old_item_uuid: old_item.uuid,
        event_version_uuid: event.event_version.uuid,
        vendor_location_uuid: item_price?.vendor_location
          ? item_price.vendor_location.uuid
          : undefined,
      };
      return data;
    });
    requests = requests.map((request) => (cb) => {
      swapEventItemApi(request)
        .then((resp) => {
          cb(null, resp.data);
        })
        .catch((err) => {
          cb(err);
        });
    });
    let event_order_updates = [];
    async.parallel(requests, (err, results) => {
      results.forEach((resp, i) => {
        if (resp.event_items?.length > 0) {
          let event = orderEvents.find(
            (e) => e.event_version.uuid === resp.event_items[0].event_version
          );

          let event_index = orderEvents.findIndex(
            (e) => e.event_version.uuid === resp.event_items[0].event_version
          );
          let item_prices = [
            ...event.event_version.item_prices.filter(
              (item_price) => item_price.item !== old_item.uuid
            ),
            resp.item_price,
          ];
          event_order_updates.push({
            ...event,
            event_version: {
              ...event.event_version,
              event_items: resp.event_items,
              item_prices: item_prices,
            },
          });
        }
      });
      let new_order_events = orderEvents.map((event) => {
        let update = event_order_updates.find(
          (e) => e.event_version.uuid === event.event_version.uuid
        );
        return update ? update : event;
      });
      setOrderEvents(new_order_events);
      if (cb) {
        cb();
      }
    });
  };

  const [itemsWithPriceConflicts, setItemsWithPriceConflicts] = React.useState(
    []
  );
  const [resolvingConflicts, setResolvingConflicts] = React.useState(false);
  const [itemsInEvents, setItemsInEvents] = React.useState({});

  const findPriceConflicts = (events) => {
    let item_prices = {};
    events.forEach((event) => {
      event.event_version.item_prices.forEach((item_price) => {
        if (item_prices[item_price.item]) {
          item_prices[item_price.item].push(item_price);
        } else {
          item_prices[item_price.item] = [item_price];
        }
      });
    });
    let conflicts = {};
    Object.entries(item_prices).forEach(([item_uuid, item_prices]) => {
      if (item_prices.length > 1) {
        let key_item_price = item_prices[0];
        item_prices.slice(1).forEach((item_price) => {
          if (!compareItemPrices(key_item_price, item_price)) {
            if (
              conflicts[item_uuid] &&
              !itemPriceInList(item_price, conflicts[item_uuid])
            ) {
              conflicts[item_uuid].push(item_price);
            } else {
              conflicts[item_uuid] = [key_item_price, item_price];
            }
          }
        });
      }
    });
    return conflicts;
  };

  const itemsFromEvents = (events) => {
    let items = {};
    events.forEach((event) => {
      event.event_version.event_items.forEach((event_item) => {
        event_item.recipe_items.forEach((recipe_item) => {
          if (!items[recipe_item.item.uuid]) {
            items[recipe_item.item.uuid] = recipe_item.item;
          }
        });
      });
    });
    return items;
  };

  const getEventOrderData = () => {
    setLoadingOrderData(true);
    setOrderEvents([]);
    getEventOrderDataApi(selectedEvents.map((event) => event.uuid)).then(
      (resp) => {
        setOrderEvents(resp.data);
        let item_price_conflicts = findPriceConflicts(resp.data);
        if (Object.keys(item_price_conflicts).length > 0) {
          setItemsInEvents(itemsFromEvents(resp.data));
          setItemsWithPriceConflicts(item_price_conflicts);
          setResolvingConflicts(true);
        } else {
          setLoadingOrderData(false);
        }
      }
    );
  };

  React.useEffect(() => {
    initializeUser(props.user);
    getUserEvents(true, false, undefined, "BO").then((resp) => {
      initializeEvents(resp.data.events);
      initializeBackOffice(resp.data.back_office);
      setLoaded(true);
    });
    getSelectableItems().then((resp) => {
      initializeSelectableItems(resp.data);
    });
  }, []);

  const combinedItemCounts = (item_type) => {
    let itemCountsList = orderEvents.map((orderEvent) => {
      return _itemCounts(
        item_type,
        true,
        orderEvent.event_version.event_items,
        itemPricesDictFromList(orderEvent.event_version.item_prices)
      );
    });
    let combined_item_counts = combineItemCounts(itemCountsList);
    let summed_counts = Object.entries(combined_item_counts).map(
      ([item_uuid, item_count]) => {
        return sumCounts(item_count);
      }
    );
    return summed_counts;
  };

  const bulkUpdateItemPrices = (data) => {
    let new_order_events = orderEvents.map((event) => ({
      ...event,
      event_version: {
        ...event.event_version,
        item_prices: event.event_version.item_prices.map((item_price) =>
          data[item_price.item]
            ? {
                ...item_price,
                ...data[item_price.item],
                uuid: item_price.uuid,
                event_version: event.event_version.uuid,
              }
            : item_price
        ),
      },
    }));
    setOrderEvents(new_order_events);
    bulkUpdateItemPricesApi({
      item_prices: data,
      event_version_uuids: orderEvents.map((event) => event.event_version.uuid),
    });
  };

  const handleConflictResolutionSubmit = (item_prices) => {
    bulkUpdateItemPrices(item_prices);
    setResolvingConflicts(false);
    setLoadingOrderData(false);
  };

  const getAllAttachments = () => {
    let attachments = orderEvents.reduce((acc, event) => {
      return acc.concat(
        event.event_attachments.filter((attachment) => attachment.is_order)
      );
    }, []);
    return attachments;
  };

  const getUniqueAttachments = () => {
    let attachments = getAllAttachments();
    let attachmentMap = {};
    attachments.forEach((attachment) => {
      attachmentMap[attachment.attachment_url] = attachment;
    });
    attachments = Object.values(attachmentMap);
    return attachments;
  };

  const uniqueAttachmentsWithEvents = () => {
    return getUniqueAttachments().map((attachment) => {
      let events = orderEvents.filter((event) =>
        event.event_attachments.some(
          (event_attachment) =>
            event_attachment.attachment_url === attachment.attachment_url
        )
      );
      return { ...attachment, events };
    });
  };

  const addEventAttachmentsInState = (data) => {
    if (!Array.isArray(data)) {
      data = [data];
    }
    let new_order_events = orderEvents.map((event) => {
      return {
        ...event,
        event_attachments: [
          ...event.event_attachments,
          ...data.filter((attachment) => attachment.event === event.uuid),
        ],
      };
    });
    setOrderEvents(new_order_events);
  };

  const deleteEventAttachment = (attachment_uuid) => {
    let attachments = getAllAttachments();
    let attachment = attachments.find(
      (attachment) => attachment.uuid === attachment_uuid
    );
    let attachment_url_to_delete = attachment.attachment_url;
    let attachments_to_delete = attachments.filter(
      (attachment) => attachment.attachment_url === attachment_url_to_delete
    );
    let attachment_uuids_to_delete = attachments_to_delete.map(
      (attachment) => attachment.uuid
    );
    let new_order_events = orderEvents.map((event) => {
      return {
        ...event,
        event_attachments: event.event_attachments.filter(
          (attachment) => !attachment_uuids_to_delete.includes(attachment.uuid)
        ),
      };
    });
    setOrderEvents(new_order_events);
    deleteEventAttachmentApi({ uuid: attachment_uuids_to_delete });
  };

  return loaded ? (
    <Box>
      <Box
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",
          mt: "1rem",
          overflow: "auto",
          scrollMargin: "50vh",
          height: "92vh",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={fullScreen ? { ml: ".5rem" } : null}>
              Multi-Event Orders
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <OrdersEventPicker
              selectedEvents={selectedEvents}
              setSelectedEvents={setSelectedEvents}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="secondary"
              fullWidth
              disabled={selectedEvents.length === 0 || loadingOrderData}
              onClick={getEventOrderData}
              loading={loadingOrderData}
            >
              Generate Multi-Event Order
            </LoadingButton>
          </Grid>
          {loadingOrderData && (
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>Tabulating Orders</AlertTitle>This process can take
                up to 30 seconds. If the data has not loaded after 30 seconds,
                refresh your browser. If the issue persists, please contact
                support.
              </Alert>
            </Grid>
          )}
          <ItemPriceConflictResolutionDialog
            open={resolvingConflicts}
            handleCancel={() => {
              setOrderEvents([]);
              setLoadingOrderData(false);
              setResolvingConflicts(false);
            }}
            itemsWithPriceConflicts={itemsWithPriceConflicts}
            itemsInEvents={itemsInEvents}
            handleSubmit={handleConflictResolutionSubmit}
          />
          {orderEvents.length > 0 && !loadingOrderData && (
            <Grid item xs={12}>
              <Box
                sx={{
                  overflow: "auto",
                  height: "calc(100vh - 125px)",
                }}
              >
                <Box
                  sx={{
                    mb: "calc(100vh/2)",
                    mt: "1rem",
                  }}
                >
                  <AccountStateOverridesProvider
                    updateRecipeItem={updateRecipeItem}
                    removeItem={removeItem}
                    updateItemPrice={updateItemPrice}
                    swapEventItem={swapEventItem}
                    addEventAttachmentsInState={addEventAttachmentsInState}
                    deleteEventAttachment={deleteEventAttachment}
                  >
                    <OrderItems
                      flowerCounts={combinedItemCounts("FL")}
                      materialCounts={combinedItemCounts("MA")}
                      events={orderEvents}
                      attachments={uniqueAttachmentsWithEvents()}
                    />
                  </AccountStateOverridesProvider>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: "flex", pt: "30%" }}>
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  );
};

const OrdersEventPicker = (props) => {
  const [back_office, events] = useAccountState((state) => [
    state.back_office,
    state.events,
  ]);
  const date_window = 3;
  const recommended = () => {
    if (props.selectedEvents.length === 0) {
      return [];
    } else {
      const dates = props.selectedEvents.map((event) => event.date);
      const avgDate = averageDate(dates);
      const recommended_events = events.filter((event) =>
        isWithinNDays(avgDate, event.date, date_window)
      );
      return recommended_events;
    }
  };

  const handleChange = (event, value) => {
    props.setSelectedEvents(value);
  };

  const optionsWithGroup = () => {
    const recommended_events = recommended();
    const recommended_events_with_group = recommended_events.map((event) => ({
      group: "Similar Events (+/- 3 days)",
      ...event,
    }));
    const all_events_with_group = events?.map((event) => ({
      group: "All Events",
      ...event,
    }));
    let options = sort([
      ...recommended_events_with_group,
      ...all_events_with_group,
    ])
      .by([
        { desc: (option) => option.group },
        { asc: (option) => option.date },
      ])
      .filter((event) => {
        return !props.selectedEvents.some(
          (selectedEvent) => selectedEvent.uuid === event.uuid
        );
      });
    return options;
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      value={props.selectedEvents}
      onChange={handleChange}
      options={optionsWithGroup()}
      groupBy={(option) => option.group}
      getOptionLabel={(option) =>
        `${eventDateFormatted(
          option.date,
          option.timezone,
          back_office.date_format
        )} | ${option.name}`
      }
      renderInput={(params) => (
        <TextField {...params} label="Events" fullWidth />
      )}
      fullWidth
    />
  );
};

function averageDate(dates) {
  if (dates.length === 0) {
    return null;
  }

  // Convert each date to a timestamp
  const timestamps = dates.map((date) => new Date(date).getTime());

  // Calculate the average timestamp
  const averageTimestamp =
    timestamps.reduce((acc, curr) => acc + curr, 0) / timestamps.length;

  // Convert the average timestamp back to a Date object
  return new Date(averageTimestamp);
}

function isWithinNDays(keyDate, inputDate, N) {
  // Convert both dates to Date objects if they aren't already
  const key = new Date(keyDate);
  const input = new Date(inputDate);

  // Calculate the difference in time (milliseconds) between the two dates
  const differenceInTime = Math.abs(key.getTime() - input.getTime());

  // Convert the difference from milliseconds to days
  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

  // Check if the difference in days is within the specified range N
  return differenceInDays <= N;
}

const ItemPriceConflictResolutionDialog = (props) => {
  const getSelections = () => {
    let selections = {};
    Object.entries(props.itemsWithPriceConflicts).forEach(
      ([item_uuid, prices]) => {
        selections[item_uuid] = prices[0];
      }
    );
    return selections;
  };

  const [selections, setSelections] = React.useState([]);

  // React.useEffect(() => {
  //   setSelections(getSelections());
  // }, [props.itemsWithPriceConflicts]);

  const submitDisabled = () => {
    return (
      Object.values(selections).length !==
      Object.keys(props.itemsWithPriceConflicts).length
    );
  };

  const handleCancel = () => {
    props.handleCancel();
    setSelections([]);
  };

  const handleSubmit = () => {
    props.handleSubmit(selections);
    setSelections([]);
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Price Conflicts Detected</DialogTitle>
      <DialogContent>
        <Typography>
          The following items have price conflicts between events. Please
          resolve these conflicts before proceeding.
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Price Ea.</TableCell>
              <TableCell>Group Price (Qty.)</TableCell>
              <TableCell>Wholesaler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(props.itemsWithPriceConflicts).map(
              ([item_uuid, item_prices]) => {
                return (
                  <ItemConflictRowBlock
                    conflicts={item_prices}
                    itemsInEvents={props.itemsInEvents}
                    selections={selections}
                    setSelections={setSelections}
                  />
                );
              }
            )}
          </TableBody>
        </Table>
        <Alert severity="warning" sx={{ mt: ".5rem" }}>
          This action will update the selected item prices for all selected
          events.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} color="info" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={submitDisabled()}
          onClick={handleSubmit}
        >
          Update Event Prices
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ItemConflictRowBlock = (props) => {
  return (
    <>
      <TableRow>
        <TableCell></TableCell>
        <TableCell colSpan={4} sx={{ fontWeight: "bold" }}>
          {itemDisplayName(props.itemsInEvents[props.conflicts[0].item])}
        </TableCell>
      </TableRow>
      {props.conflicts.map((item_price) => (
        <ItemConflictRow
          item_price={item_price}
          selections={props.selections}
          setSelections={props.setSelections}
        />
      ))}
    </>
  );
};
const ItemConflictRow = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);

  const isSelected = () => {
    return (
      props.selections[props.item_price.item]?.uuid === props.item_price.uuid
    );
  };

  const handleChange = () => {
    let new_selections = { ...props.selections };
    new_selections[props.item_price.item] = props.item_price;
    props.setSelections(new_selections);
  };
  return (
    <TableRow>
      <TableCell>
        <Radio
          size="small"
          checked={isSelected()}
          onChange={handleChange}
          sx={{ cursor: "pointer" }}
        />
      </TableCell>
      <TableCell>{formatCurrency(itemCost(props.item_price))}</TableCell>
      <TableCell>
        {`${formatCurrency(props.item_price.price_per_group)} (${
          props.item_price.individual_per_group
        })`}
      </TableCell>
      <TableCell
        style={{
          // whiteSpace: "nowrap",
          // textOverflow: "ellipsis",
          // overflow: "hidden",
          maxWidth: "200px",
          fontSize: "0.8rem",
        }}
      >
        {props.item_price.vendor_location
          ? short_vendor_location_display_name(props.item_price.vendor_location)
          : "No Vendor"}
      </TableCell>
    </TableRow>
  );
};

export default withRouter(Orders);
