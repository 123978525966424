import { Decimal } from "decimal.js";
import {
  createItemApi,
  removeItemApi,
  swapEventItemApi,
  updateItemApi,
} from "../api/EventsApi";
import itemCost, { itemCounts } from "../utils/item_utils";
import _ from "lodash";
import { decimalConfig, quantityField } from "../utils/utils";
import { addCustomItemApi } from "../api/DataApi";

Decimal.set(decimalConfig);

export const itemsSlice = (set, get) => ({
  customItems: [],
  selectableFlowers: [],
  selectableMaterials: [],
  customItemsByType: (type) => {
    return get().customItems.filter((i) => i.item_type === type);
  },
  deleteCustomItem: (uuid) => {
    set({
      customItems: get().customItems.filter((i) => i.uuid !== uuid),
    });
    updateItemApi({ uuid: uuid, deleted: true }).then((resp) => {
      console.log(resp);
    });
  },
  updateCustomItem: (data) => {
    updateItemApi(data).then((resp) => {
      const new_items = [
        ...get().customItems.filter((item) => item.uuid !== resp.data.uuid),
        resp.data,
      ];
      set({
        customItems: new_items,
      });
    });
  },
  addCustomItems: (items, cb) => {
    addCustomItemApi({ items: items }).then((resp) => {
      const new_items = [...get().customItems, ...resp.data];
      set({
        customItems: new_items,
      });
      if (cb) {
        cb(resp.data);
      }
    });
  },
  initializeCustomItems: (items) => {
    set({
      customItems: items,
    });
  },
  initializeSelectableItems: (items) => {
    set({
      selectableFlowers: items?.filter((i) => i.item_type === "FL"),
      selectableMaterials: items?.filter((i) => i.item_type === "MA"),
    });
  },
  createAndSwapItem: (data, old_item) => {
    get().createItem(data, (data) => {
      get().addItemPriceState(data.item_price);
      get().swapEventItem(data.item, old_item);
    });
  },
  createItem: (data, cb) => {
    createItemApi({
      event_version_uuid: get().event_version.uuid,
      ...data,
    }).then((resp) => {
      if (resp.data.item_type === "FL") {
        set({
          selectableFlowers: [...get().selectableFlowers, resp.data],
        });
      } else if (resp.data.item_type === "MA") {
        set({
          selectableMaterials: [...get().selectableMaterials, resp.data],
        });
      }
      if (cb) {
        cb(resp.data);
      }
    });
  },
  createCustomItem: (data, cb) => {
    addCustomItemApi(data).then((resp) => {
      if (resp.data.item_type === "FL") {
        set({
          selectableFlowers: [...get().selectableFlowers, resp.data],
        });
      } else if (resp.data.item_type === "MA") {
        set({
          selectableMaterials: [...get().selectableMaterials, resp.data],
        });
      }
      if (cb) {
        cb(resp.data);
      }
    });
  },
  flowerCounts: () => {
    return get().itemCounts("FL");
  },
  itemCounts: (item_type, filter_inventory) => {
    let event_recipes = get().allRecipesAndCosts(true);
    let item_prices = get().item_prices;
    return itemCounts(item_type, filter_inventory, event_recipes, item_prices);
  },
  itemsInEvent: (item_type) => {
    let items = [];
    let item_uuids = [];
    for (const recipe of get().allEventItems()) {
      for (const recipe_item of recipe.recipe_items) {
        if (!item_uuids.includes(recipe_item.item.uuid)) {
          item_uuids.push(recipe_item.item.uuid);
          items.push(recipe_item.item);
        }
      }
    }
    if (item_type) {
      items = items.filter((item) => item.item_type === item_type);
    }
    return items;
  },
  itemCountsTotalCost: () => {
    let item_counts = get().itemCounts();
    if (item_counts.length > 0) {
      return new Decimal(
        item_counts
          .map((ic) => {
            return ic.item.item_type === "FL"
              ? ic.groups_cost
              : ic.individuals_cost;
          })
          .reduce((p, c) => p.plus(c), new Decimal(0))
      );
    } else {
      return new Decimal(0);
    }
  },
  itemLineCost: (recipe_item, sale_cost) => {
    var item_price;
    let event_item = get().getEventItemByUuid(recipe_item.event_item);
    if (!event_item) {
      return new Decimal(0);
    }
    if (event_item.is_template) {
      let template_prices = event_item.template_item_prices;
      item_price = template_prices.find(
        (tp) => tp.item === recipe_item.item.uuid
      );
    } else {
      item_price = get().item_prices[recipe_item.item.uuid];
    }
    let item_cost = itemCost(item_price);
    if (
      recipe_item.item.item_type === "MA" &&
      recipe_item.item_source_type === "IN" &&
      !sale_cost
    ) {
      return new Decimal(0);
    } else {
      return item_cost.times(recipe_item.quantity);
    }
  },
  materialCounts: (filter_inventory) => {
    return get().itemCounts("MA", filter_inventory);
  },
  removeItem: (event_recipe_uuid, recipe_item_uuid) => {
    let event_items = get().event_items;
    let recipe_ind = event_items.findIndex(
      (recipe) => recipe.uuid === event_recipe_uuid
    );
    let recipe_item_ind = event_items[recipe_ind].recipe_items.findIndex(
      (recipe_item) => recipe_item.uuid === recipe_item_uuid
    );
    let recipe_item = event_items[recipe_ind].recipe_items[recipe_item_ind];
    set({
      event_items: [
        ...event_items.slice(0, recipe_ind),
        {
          ...event_items[recipe_ind],
          recipe_items: [
            ...event_items[recipe_ind].recipe_items.slice(0, recipe_item_ind),
            ...event_items[recipe_ind].recipe_items.slice(recipe_item_ind + 1),
          ],
        },
        ...event_items.slice(recipe_ind + 1),
      ],
    });
    let item_in_event = false;
    for (const event_recipe of event_items) {
      for (const rf of event_recipe.recipe_items) {
        if (
          rf.item.uuid === recipe_item.item.uuid &&
          rf.uuid !== recipe_item.uuid
        ) {
          item_in_event = true;
        }
      }
    }
    if (!item_in_event) {
      set({
        item_prices: _.omit(get().item_prices, recipe_item.item.uuid),
      });
    }
    removeItemApi(recipe_item.uuid).then((resp) => {
      if (!resp.data.success) {
        console.log(resp.data);
      }
    });
  },
  swapEventItem: (new_item, old_item, cb) => {
    var data = {
      new_item_uuid: new_item.uuid,
      old_item_uuid: old_item.uuid,
      event_version_uuid: get().event_version.uuid,
      vendor_location_uuid: get().item_prices[old_item.uuid].vendor_location
        ? get().item_prices[old_item.uuid].vendor_location.uuid
        : undefined,
    };
    swapEventItemApi(data).then((resp) => {
      get().addItemPriceState(resp.data.item_price);
      set({
        event_items: resp.data.event_items,
      });
      if (cb) {
        cb(resp.data);
      }
    });
  },
});
