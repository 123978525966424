import { getCurrenciesApi } from "../api/ProfileApi";

export const currenciesSlice = (set, get) => ({
  currencies: [],
  currencies_initialized: false,

  getCurrencies: () => {
    if (!get().currencies_initialized) {
      getCurrenciesApi().then((resp) => {
        get().initializeCurrencies(resp.data);
      });
    }
  },
  initializeCurrencies: (data) =>
    set({ currencies: data, currencies_initialized: true }),
});
