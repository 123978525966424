import {
  addContractSectionApi,
  deleteContractSectionApi,
  orderContractSectionApi,
  updateContractSectionApi,
} from "../api/ContractsApi";

import { byUUID } from "../utils/utils";

export const contractSectionsSlice = (set, get) => ({
  contract_sections: [],
  addContractSection: () => {
    addContractSectionApi({
      event_version_uuid: get().event_version.uuid,
    }).then((resp) => {
      let contract_sections = get().contract_sections;
      set({ contract_sections: [...contract_sections, resp.data] });
    });
  },
  contractSectionOrderDown: (uuid) => {
    orderContractSectionApi({ action: "down", uuid: uuid }).then((resp) => {
      set({ contract_sections: resp.data });
    });
  },
  contractSectionOrderUp: (uuid) => {
    orderContractSectionApi({ action: "up", uuid: uuid }).then((resp) => {
      set({ contract_sections: resp.data });
    });
  },
  deleteContractSection: (uuid) => {
    let contract_sections = get().contract_sections;
    let contract_section = contract_sections.find(byUUID(uuid));
    let index = contract_sections.findIndex(byUUID(uuid));
    set({
      contract_sections: [
        ...contract_sections.slice(0, index),
        ...contract_sections.slice(index + 1),
      ],
    });
    deleteContractSectionApi({ uuid: uuid }).then((resp) => {
      if (!resp.data.success) {
        set({
          contract_sections: [
            ...contract_sections.slice(0, index),
            contract_section,
            ...contract_sections.slice(index + 1),
          ],
        });
      }
    });
  },
  initializeContractSections: (data) => set({ contract_sections: data }),
  updateContractSection: (data) => {
    let contract_sections = get().contract_sections;
    let contract_section = contract_sections.find(byUUID(data.uuid));
    let index = contract_sections.findIndex(byUUID(data.uuid));
    set({
      contract_sections: [
        ...contract_sections.slice(0, index),
        { ...contract_section, ...data },
        ...contract_sections.slice(index + 1),
      ],
    });
    updateContractSectionApi(data);
  },
});
