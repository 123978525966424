import React, { useRef } from "react";

import { matchSorter } from "match-sorter";
import { sort } from "fast-sort";
import debounce from "lodash.debounce";

import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Typography,
  Link,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import {
  getSelectableVendors,
  createVendorApi,
  createVendorLocationApi,
  getVendorLocations,
} from "../api/EventsApi.js";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAccountState } from "../state/store.js";

const VendorDialog = (props) => {
  const [vendors, setVendors] = React.useState([]);
  const [vendorOptions, setVendorOptions] = React.useState([]);
  const [item_prices, setItemPrices] = React.useState([]);
  const [creating_new_vendor, setCreatingNewVendor] = React.useState(false);
  const [creating_new_vendor_location, setCreatingNewVendorLocation] =
    React.useState(false);
  const [vendor_location_options, setVendorLocationOptions] = React.useState(
    [],
  );
  const [selected_vendor, setSelectedVendor] = React.useState(undefined);
  const [selected_vendor_location, setSelectedVendorLocation] =
    React.useState(undefined);
  const [loaded, setLoaded] = React.useState(false);
  const validationSchema = Yup.object().shape({
    vendor: Yup.object().nullable(true),
    vendor_location: Yup.object()
      .nullable(true)
      .when("vendor", {
        is: true,
        then: Yup.object().required("Must specify vendor location"),
      }),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: { vendor: null, vendor_location: null },
    onSubmit: (values, { resetForm }) => {
      props.handleSubmit(selected_vendor_location);
      resetForm();
      setSelectedVendorLocation(undefined);
      setSelectedVendor(undefined);
      props.handleCancel();
    },
  });

  const sortVendorLocations = (vendor_locations) => {
    return sort(vendor_locations).by([
      { asc: (v) => v.state },
      { asc: (v) => v.address_line_1 },
    ]);
  };

  const getVendorLocationOptions = (vendor) => {
    if (vendor) {
      getVendorLocations(vendor.uuid).then((resp) => {
        var locations = resp.data;
        var filtered_locations;
        if (
          props.filterVendorLocations &&
          props.filterVendorLocations.length > 0
        ) {
          var filter_uuids = props.filterVendorLocations.map((vl) => vl.uuid);
          filtered_locations = locations.filter(
            (vl) => !filter_uuids.includes(vl.uuid),
          );
        } else {
          filtered_locations = locations;
        }
        if (vendor) {
          let online_vendors = sortVendorLocations(
            filtered_locations
              .filter((location) => location !== null)
              .filter(
                (location) =>
                  location &&
                  location.vendor.uuid === vendor.uuid &&
                  location.online_only,
              )
              .map((location) => ({
                ...location,
                state: "Online",
              })),
          );
          let bam_vendors = sortVendorLocations(
            filtered_locations.filter(
              (location) =>
                location &&
                location.vendor.uuid === vendor.uuid &&
                !location.online_only,
            ),
          );
          setVendorLocationOptions([...online_vendors, ...bam_vendors]);
        } else {
          setVendorLocationOptions([]);
        }
      });
    }
  };

  const sortVendors = (vendors) => {
    return sort(vendors).by([{ asc: (v) => v.group }, { asc: (v) => v.name }]);
  };

  React.useEffect(() => {
    getSelectableVendors(props.item_uuid, props.event_version_uuid).then(
      (resp) => {
        var data = resp.data;
        var vendors;
        if (!props.disableDefaultVendors) {
          var default_vendor_uuids = data.default_vendors.map((v) => v.uuid);
          vendors = [
            ...data.default_vendors.map((v) => ({
              ...v,
              group: "My Vendors",
            })),
            ...data.vendors
              .filter((v) => !default_vendor_uuids.includes(v.uuid))
              .map((v) => ({
                ...v,
                group: "Other Vendors",
              })),
          ];
        } else {
          vendors = data.vendors;
        }
        setVendors(sortVendors(vendors));
        setVendorOptions(sortVendors(vendors));
        setItemPrices(data.item_prices);
        if (
          data.default_vendor_locations &&
          data.default_vendor_locations.length > 0 &&
          !props.disableDefaultVendors
        ) {
          setSelectedVendor(data.default_vendor_locations[0].vendor);
          setSelectedVendorLocation(data.default_vendor_locations[0]);
          formik.setFieldValue(
            "vendor",
            data.default_vendor_locations[0].vendor,
          );
          formik.setFieldValue(
            "vendor_location",
            data.default_vendor_locations[0],
          );
          getVendorLocationOptions(data.default_vendor_locations[0].vendor);
        }
        setLoaded(true);
      },
    );
  }, []);

  const handleCreateNewVendor = (data) => {
    createVendorApi(data).then((resp) => {
      setVendors([...vendors, resp.data]);
      setVendorOptions(sortVendors(vendors));
      setSelectedVendor(resp.data);
      formik.setFieldValue("vendor", resp.data);
      setVendorLocationOptions([]);
      setSelectedVendorLocation(undefined);
      formik.setFieldValue("vendor_location", undefined);
      setCreatingNewVendor(false);
    });
  };

  const handleCreateNewVendorLocation = (data) => {
    createVendorLocationApi(data).then((resp) => {
      setSelectedVendorLocation(resp.data);
      formik.setFieldValue("vendor_location", resp.data);
      getVendorLocationOptions(selected_vendor);
      setCreatingNewVendorLocation(false);
    });
  };

  const filterOptions = (options, { inputValue }) =>
    sortVendors(matchSorter(options, inputValue, { keys: ["name"] }));

  const filterVendorOptions = (e, val, reason) => {
    var res = matchSorter(vendors, val, { keys: ["name"] });

    setVendorOptions(sortVendors(res));
  };

  const debouncedFilterVendors = debounce(filterVendorOptions, 500);

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <Box sx={{ minWidth: "350px" }}>
        <DialogTitle>Vendor</DialogTitle>
        <DialogContent>
          {loaded ? (
            <Grid container spacing={1} sx={{ p: "1rem" }}>
              <Grid item xs={12}>
                <Autocomplete
                  value={formik.values.vendor}
                  id="vendor"
                  key="vendor"
                  options={vendorOptions}
                  groupBy={(option) => option.group}
                  renderInput={(params) => (
                    <TextField {...params} label="Vendor" />
                  )}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.uuid}>
                      {option.name}
                    </li>
                  )}
                  //   filterOptions={filterOptions}
                  onInputChange={debouncedFilterVendors}
                  onChange={(e, val) => {
                    setSelectedVendorLocation(null);
                    formik.setFieldValue("vendor", val);
                    setSelectedVendor(val);
                    formik.setFieldValue("vendor_location", null);
                    getVendorLocationOptions(val);
                  }}
                  noOptionsText={
                    <Typography>
                      No vendors match your search.{" "}
                      <Link onClick={() => setCreatingNewVendor(true)}>
                        Add vendor?
                      </Link>
                    </Typography>
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.uuid === value.uuid
                  }
                />
              </Grid>
              {selected_vendor ? (
                <Grid item xs={12}>
                  <Autocomplete
                    value={formik.values.vendor_location}
                    key="vendor_location"
                    id="vendor_location"
                    options={vendor_location_options}
                    renderInput={(params) => (
                      <TextField {...params} label="Location" />
                    )}
                    getOptionLabel={(option) => {
                      return option.online_only
                        ? option.url
                        : [
                            option.address_line_1,
                            option.city,
                            option.state,
                          ].join(", ");
                    }}
                    groupBy={(option) => option.state}
                    onChange={(e, val) => {
                      formik.setFieldValue("vendor_location", val);
                      setSelectedVendorLocation(val);
                    }}
                    noOptionsText={
                      <Typography>
                        No locations match your search.{" "}
                        <Link
                          onClick={() => setCreatingNewVendorLocation(true)}
                        >
                          Add location?
                        </Link>
                      </Typography>
                    }
                  />
                </Grid>
              ) : null}
              {item_prices.length > 0 ? (
                <Grid item xs={12} conainer>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Your vendors' pricing for reference:
                    </Typography>
                  </Grid>
                  {/* <ItemPriceHeadline /> */}
                  {sort(item_prices)
                    .by((p) => p.vendor_location.vendor.name)
                    .map((price) => (
                      <ItemPriceLine
                        price={price}
                        handleSelect={(vendor_location) => {
                          formik.setFieldValue(
                            "vendor",
                            vendor_location.vendor,
                          );
                          setSelectedVendor(vendor_location.vendor);
                          formik.setFieldValue(
                            "vendor_location",
                            vendor_location,
                          );
                          setSelectedVendorLocation(vendor_location);
                          formik.handleSubmit();
                        }}
                      />
                    ))}
                </Grid>
              ) : null}
              {creating_new_vendor ? (
                <CreateNewVendorDialog
                  open={creating_new_vendor}
                  handleCancel={() => setCreatingNewVendor(false)}
                  handleSubmit={handleCreateNewVendor}
                />
              ) : null}
              {creating_new_vendor_location ? (
                <CreateNewVendorLocationDialog
                  open={creating_new_vendor_location}
                  handleCancel={() => setCreatingNewVendorLocation(false)}
                  handleSubmit={handleCreateNewVendorLocation}
                  vendor={selected_vendor}
                />
              ) : null}
            </Grid>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }}
              >
                <CircularProgress
                  sx={{
                    m: "auto",
                  }}
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                onClick={props.handleCancel}
                color="info"
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={formik.handleSubmit}
                color="secondary"
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const ItemPriceHeadline = (props) => {
  const sx = { textDecoration: "underline" };
  return (
    <Grid item xs={12} container>
      <Grid item xs={6}>
        <Typography sx={sx}>Vendor</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={sx}>$/Stem</Typography>
      </Grid>
    </Grid>
  );
};

const ItemPriceLine = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={6}>
        <Typography noWrap>
          {props.price.vendor_location.vendor.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs="auto">
          <Typography>
            {formatCurrency(
              props.price.price.price_per_group /
                props.price.price.individual_per_group,
            )}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button
            variant="outlined"
            color="info"
            size="small"
            onClick={() => props.handleSelect(props.price.vendor_location)}
            sx={{ m: ".125rem" }}
          >
            Select
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CreateNewVendorDialog = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters")
      .required(),
    url: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters"),
    email: Yup.string().email("Email is not valid"),
    phone_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid",
    ),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {},
    onSubmit: (values) => {
      props.handleCancel();
      props.handleSubmit(values);
    },
  });

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        <Typography>Create Vendor</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ p: "1rem" }}>
          <CustomTextField name="name" label="Name" formik={formik} />
          <CustomTextField name="email" label="Email" formik={formik} />
          <CustomTextField name="phone_number" label="Phone" formik={formik} />
          <CustomTextField name="url" label="URL" formik={formik} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs="auto">
            <Button
              color="info"
              variant="outlined"
              onClick={props.handleCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const CreateNewVendorLocationDialog = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const bamRequired = {
    is: (online_only) => !online_only,
    then: Yup.string().required("Required"),
  };
  const onlineRequired = {
    is: (online_only) => online_only,
    then: Yup.string().required("Required"),
  };
  const validationSchema = Yup.object().shape({
    address_line_1: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters")
      .when("online_only", bamRequired),
    address_line_2: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters"),
    city: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters")
      .when("online_only", bamRequired),
    state: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters"),
    country: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters")
      .when("online_only", bamRequired),
    postal_code: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(10, "Must be less than 85 characters"),
    url: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters")
      .when("online_only", onlineRequired),
    email: Yup.string().email("Email is not valid"),
    phone_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid",
    ),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: { online_only: false, vendor_uuid: props.vendor.uuid },
    onSubmit: (values) => {
      props.handleCancel();
      props.handleSubmit(values);
    },
  });

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        <Typography>Create Vendor Location for {props.vendor.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ p: "1rem" }}>
          <Grid item xs={12}>
            <InputLabel sx={{ fontSize: ".75rem" }}>Location Type</InputLabel>
            <Select
              name="online_only"
              label="Location Type"
              id="online_only"
              value={formik.values.online_only}
              onChange={formik.handleChange}
              error={
                formik.touched.online_only && Boolean(formik.errors.online_only)
              }
              helperText={
                formik.touched.online_only && formik.errors.online_only
              }
              variant="outlined"
              fullWidth
            >
              <MenuItem value={true}>Online Only</MenuItem>
              <MenuItem value={false}>Brick & Mortar</MenuItem>
            </Select>
          </Grid>
          {!formik.values.online_only ? (
            <Grid item xs={12} container spacing={1}>
              <CustomTextField
                name="address_line_1"
                label="Address Line 1*"
                formik={formik}
              />
              <CustomTextField
                name="address_line_2"
                label="Address Line 2"
                formik={formik}
              />
              <CustomTextField name="city" label="City*" formik={formik} />
              <CustomTextField name="state" label="State" formik={formik} />
              <CustomTextField
                name="country"
                label="Country*"
                formik={formik}
              />
              <CustomTextField
                name="postal_code"
                label="Postal Code"
                formik={formik}
              />
            </Grid>
          ) : null}
          <CustomTextField name="email" label="Email" formik={formik} />
          <CustomTextField name="phone_number" label="Phone" formik={formik} />
          <CustomTextField
            name="url"
            label={formik.values.online_only ? "URL*" : "URL"}
            formik={formik}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs="auto">
            <Button
              color="info"
              variant="outlined"
              onClick={props.handleCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const CustomTextField = (props) => {
  return (
    <Grid item xs={12} sm={6}>
      <TextField
        label={props.label}
        id={props.name}
        value={props.formik.values[props.name]}
        onChange={props.formik.handleChange}
        error={
          props.formik.touched[props.name] &&
          Boolean(props.formik.errors[props.name])
        }
        helperText={
          props.formik.touched[props.name] && props.formik.errors[props.name]
        }
        variant="outlined"
        fullWidth
      />
    </Grid>
  );
};

export default VendorDialog;
