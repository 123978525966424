import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { addUserEvent } from "../api/EventsApi";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Link,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { NewEventDialog } from "./Dashboard";

const EventTemplates = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleAddEvent = (data) => {
    addUserEvent(data).then((resp) => {
      window.location.href = "/event/" + resp.data.uuid;
    });
  };
  const [creating, setCreating] = React.useState(false);
  const columns = [
    {
      field: "name",
      headerName: "Template Name",
      flex: 1,
      renderCell: RenderName,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      renderCell: RenderDescription,
      hide: fullScreen,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: RenderActions,
      deleteEventTemplate: props.deleteEventTemplate,
      templates: props.templates,
      handleAddEvent: handleAddEvent,
      flex: 1,
    },
  ];

  const getTemplateId = (template) => {
    return template.uuid;
  };
  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item xs="auto">
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setCreating(true)}
          id="new-template-button"
        >
          New Template
        </Button>
        <NewEventDialog
          open={creating}
          handleCancel={() => setCreating(false)}
          handleSubmit={handleAddEvent}
          templates={props.templates}
          is_template={true}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={props.templates}
          columns={columns}
          getRowId={getTemplateId}
          autoHeight
          disableSelectionOnClick
          sx={{ border: "none" }}
        />
      </Grid>
    </Grid>
  );
};

const RenderName = (props) => {
  return (
    <Link color="common.black" href={"/event/" + props.row.uuid}>
      {props.row.name}
    </Link>
  );
};

const RenderDescription = (props) => {
  return (
    <Tooltip title={props.row.notes ? props.row.notes : ""}>
      <Typography noWrap>{props.row.notes}</Typography>
    </Tooltip>
  );
};

const RenderActions = (props) => {
  const [deleting, setDeleting] = React.useState(false);
  const [creating, setCreating] = React.useState(false);

  return (
    <Box key={props.row.uuid}>
      <Tooltip title="Create Event From Template">
        <IconButton
          onClick={() => setCreating(true)}
          id={props.row.uuid + "-create-event-button"}
        >
          <NoteAddOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit Template">
        <IconButton
          href={"/event/" + props.row.uuid}
          id={props.row.uuid + "-edit-template-button"}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Template">
        <IconButton
          onClick={() => setDeleting(true)}
          id={props.row.uuid + "-delete-template-button"}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      <NewEventDialog
        open={creating}
        handleCancel={() => setCreating(false)}
        handleSubmit={props.colDef.handleAddEvent}
        templates={props.colDef.templates}
        event_template={props.row}
      />
      <DeleteTemplateDialog
        template={props.row}
        open={deleting}
        handleCancel={() => setDeleting(false)}
        handleSubmit={() => {
          props.colDef.deleteEventTemplate(props.row.uuid);
          setDeleting(false);
        }}
      />
    </Box>
  );
};

const DeleteTemplateDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Template</DialogTitle>
      <DialogContent>
        <Typography>
          {"Are you sure you want to delete the event template called " +
            props.template.name +
            "?"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="delete-template-cancel-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={props.handleSubmit}
          id="delete-template-confirm-button"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(EventTemplates);
