import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const NotificationSettings = (props) => {
  const updateSetting = (e) => {
    let data = {
      [e.target.id]: e.target.value,
    };
    props.updatePersonalSettings(data);
  };

  return (
    <Grid container justifyContent="center" spacing={2} display="flex">
      <Grid item xs={12}>
        <Typography variant="h6">Notifications</Typography>
      </Grid>
      <SettingsSwitch
        label="Contract Signed"
        tooltip={"Receive a notification when a contract is signed."}
        id="notification_contract_signed"
        user={props.user}
        updateSetting={updateSetting}
      />
      <SettingsSwitch
        label="Payment Received"
        tooltip={"Receive a notification when a payment is received."}
        id="notification_payment_received"
        user={props.user}
        updateSetting={updateSetting}
      />
      <SettingsSwitch
        label="Credit Received"
        tooltip={"Receive a notification when you receive FlowerBuddy credit."}
        id="notification_credit_received"
        user={props.user}
        updateSetting={updateSetting}
      />
      <SettingsSwitch
        label="Payment Overdue: 3 Days"
        tooltip={"Receive a notification when a payment is 3 days overdue."}
        id="notification_payment_overdue_3_days"
        user={props.user}
        updateSetting={updateSetting}
      />
      <SettingsSwitch
        label="Payment Overdue: 7 Days"
        tooltip={"Receive a notification when a payment is 7 days overdue."}
        id="notification_payment_overdue_7_days"
        user={props.user}
        updateSetting={updateSetting}
      />
    </Grid>
  );
};

const SettingsSwitch = (props) => {
  const handleChange = (event) => {
    props.updateSetting({
      target: { value: event.target.checked, id: props.id },
    });
  };
  return (
    <Grid item xs={12}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={props.user[props.id]}
              onChange={handleChange}
              disabled={props.disabled}
              id={props.id + "-switch"}
            />
          }
          label={
            <Typography>
              {props.label}{" "}
              <Tooltip title={props.tooltip}>
                <HelpOutlineOutlinedIcon fontSize="inherit" />
              </Tooltip>
            </Typography>
          }
        />
      </FormGroup>
    </Grid>
  );
};

export default withRouter(NotificationSettings);
