import { updateBackOfficeApi } from "../api/EventsApi";
import _ from "lodash";
import {
  addFormApi,
  deleteFormApi,
  deleteFormSubmissionApi,
  updateFormApi,
} from "../api/FormsApi";
import { generateRandomString } from "../utils/utils";

export const formsSlice = (set, get) => ({
  form: {},
  forms: [],
  form_submissions: [],
  addForm: (data) => {
    let forms = get().forms;
    addFormApi(data).then((resp) => {
      let newForms = [...forms, resp.data];
      set({ forms: newForms });
    });
  },
  addFormField: (type, index) => {
    let form = get().form;
    let fields = form.fields ? form.fields : [];
    let field = { type, uuid: generateRandomString(6) };
    if (type === "GT") {
      field.text_type = "Single Line";
      field.destination = "Event Details";
    } else if (type === "GN") {
      field.number_type = "Integer";
      field.destination = "Event Details";
    }
    var new_fields;
    if (index === undefined) {
      new_fields = [...fields, field];
    } else {
      new_fields = [...fields.slice(0, index), field, ...fields.slice(index)];
    }
    get().updateForm({ fields: new_fields });
  },

  deleteForm: (form_uuid) => {
    let forms = get().forms;
    forms = forms.filter((form) => form.uuid !== form_uuid);
    set({ forms });
    deleteFormApi({ uuid: form_uuid }).then((resp) => {
      console.log(resp);
    });
  },
  deleteFormSubmission: (form_submission_uuid) => {
    let form_submissions = get().form_submissions;
    form_submissions = form_submissions.filter(
      (form_submission) => form_submission.uuid !== form_submission_uuid,
    );
    set({ form_submissions });
    deleteFormSubmissionApi({ uuid: form_submission_uuid }).then((resp) => {
      console.log(resp);
    });
  },
  incrementFormFieldOrder: (index) => {
    let dest_index = index - 1;
    if (dest_index < 0) {
      return;
    } else {
      get().updateFormFieldOrder(index, dest_index);
    }
  },
  deleteFormField: (index) => {
    let form = get().form;
    let fields = form.fields ? form.fields : [];
    let new_fields = [...fields.slice(0, index), ...fields.slice(index + 1)];
    get().updateForm({ fields: new_fields });
  },
  decrementFormFieldOrder: (index) => {
    let dest_index = index + 1;
    let form = get().form;
    let fields = form.fields ? form.fields : [];
    if (dest_index >= fields.length) {
      return;
    } else {
      get().updateFormFieldOrder(index, dest_index);
    }
  },
  initializeForm: (data) => set({ form: data }),
  initializeForms: (data) => set({ forms: data }),
  initializeFormSubmissions: (data) => set({ form_submissions: data }),
  updateForm: (data) => {
    let new_form = { ...get().form, ...data };
    set({ form: new_form });
    updateFormApi({ ...data, uuid: get().form.uuid }).then((resp) => {
      console.log(resp);
    });
  },
  updateFormField: (data, index) => {
    let form = get().form;
    let fields = form.fields ? form.fields : [];
    let field = fields[index];
    let new_fields = [
      ...fields.slice(0, index),
      { ...field, ...data },
      ...fields.slice(index + 1),
    ];
    get().updateForm({ fields: new_fields });
  },
  updateFormFieldOrder: (src_index, dest_index) => {
    let form = get().form;
    let fields = form.fields ? form.fields : [];
    console.log("src / dest", src_index, dest_index);
    console.log("fields", fields);
    let field = fields[src_index];
    console.log("field", field);
    var filtered_fields = fields.filter((f, idx) => idx !== src_index);
    console.log("filtered_fields", filtered_fields);
    var new_fields;
    if (dest_index === 0) {
      new_fields = [field, ...filtered_fields];
      console.log("0 new_fields", new_fields);
    } else if (dest_index === fields.length - 1) {
      new_fields = [...filtered_fields, field];
      console.log("1 new_fields", new_fields);
    } else {
      new_fields = [
        ...filtered_fields.slice(0, dest_index),
        field,
        ...filtered_fields.slice(dest_index),
      ];
      console.log("2 new_fields", new_fields);
    }
    get().updateForm({ fields: new_fields });
  },
});
