import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAccountState } from "../state/store";
import CroppedImg from "./CroppedImg";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { set } from "lodash";
import Tags, { TagFilterAndSearch } from "./Tags";
import { sortByCreated, sortByUuid } from "../utils/utils";
import { FileDropZone, FileDropZoneDialog } from "./FileUploader";
import {
  eventDateFormatted,
  eventDateTimeFormatted,
} from "../utils/event_utils";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

export const ImageLibrary = (props) => {
  const [addImage, images] = useAccountState((state) => [
    state.addImage,
    state.images,
  ]);
  const [filteredImages, setFilteredImages] = useState(images);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const [selectedImageUuid, setSelectedImageUuid] = useState(null);
  const handleOpen = (cropped_image) => {
    setSelectedImageUuid(cropped_image.uuid);
    toggleOpen();
  };
  const next = () => {
    const index = filteredImages
      .sort(sortByCreated)
      .findIndex((img) => img.uuid === selectedImageUuid);
    if (index < filteredImages.length - 1) {
      setSelectedImageUuid(filteredImages[index + 1].uuid);
    }
  };

  const previous = () => {
    const index = filteredImages
      .sort(sortByCreated)
      .findIndex((img) => img.uuid === selectedImageUuid);
    if (index > 0) {
      setSelectedImageUuid(filteredImages[index - 1].uuid);
    }
  };

  const selectedImage = images.find((img) => img.uuid === selectedImageUuid);
  const [addingImage, setAddingImage] = useState(false);
  const toggleAddingImage = () => setAddingImage(!addingImage);
  const handleAddImage = (cropped_images) => {
    addImage(cropped_images[0]);
    setSelectedImageUuid(cropped_images[0].uuid);
    setOpen(true);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container justifyContent={"flex-end"}>
        <Grid item xs="auto">
          <Button
            onClick={toggleAddingImage}
            variant="contained"
            color="secondary"
            size="small"
          >
            Add Image
          </Button>
        </Grid>
        <FileDropZoneDialog
          handleSubmit={handleAddImage}
          disableSelector
          imageUploadData={{ library_image: true }}
          open={addingImage}
          handleCancel={toggleAddingImage}
        />
      </Grid>
      <Grid item xs={12}>
        <TagFilterAndSearch
          list={images.sort(sortByCreated)}
          setFilteredList={setFilteredImages}
          searchKeys={["name", "description"]}
        />
      </Grid>
      <Grid item xs={12} container spacing={1} justifyContent={"center"}>
        {filteredImages.map((cropped_image) => (
          <LibraryImage
            key={cropped_image.uuid}
            cropped_image={cropped_image}
            handleOpen={handleOpen}
          />
        ))}
      </Grid>
      {selectedImage && (
        <LibraryDialog
          open={open}
          handleCancel={toggleOpen}
          cropped_image={selectedImage}
          next={next}
          previous={previous}
        />
      )}
    </Grid>
  );
};

const LibraryImage = (props) => {
  const [hovering, setHovering] = useState(false);
  const setHoveringTrue = () => {
    setHovering(true);
  };
  const setHoveringFalse = () => setHovering(false);

  const handleClick = () => {
    props.handleOpen(props.cropped_image);
  };

  return (
    <Grid
      item
      xs="auto"
      key={props.cropped_image.uuid}
      id={props.cropped_image.uuid}
      onMouseEnter={setHoveringTrue}
      onMouseLeave={setHoveringFalse}
      onClick={handleClick}
    >
      <CroppedImg
        img={props.cropped_image}
        // width="100%"
        contain={"100px150"}
        height={150}
      />
    </Grid>
  );
};

const LibraryDialog = (props) => {
  const [deleting, setDeleting] = useState(false);
  const toggleDeleting = () => setDeleting(!deleting);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs="auto">
            <LibraryImageTextField
              cropped_image={props.cropped_image}
              id="name"
              placeholder="Click to Add Title"
              sx={{ fontWeight: "bold" }}
            />
          </Grid>
          <Grid
            item
            xs="auto"
            container
            justifyContent={"flex-end"}
            spacing={1}
            alignItems={"center"}
          >
            <Grid item xs="auto">
              <Tooltip title="Image titles, descriptions, and tags are generated with FlowerBuddy AI and may not be completely accurate.">
                <IconButton size="small">
                <AutoAwesomeOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <IconButton size="small" onClick={toggleDeleting}>
                <DeleteOutlineOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ height: 400 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} container justifyContent={"center"}>
              <Grid item xs={"auto"}>
                <CroppedImg
                  img={props.cropped_image}
                  contain="400x400"
                  zoom
                  maxWidth={400}
                  maxHeight={400}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="caption">Description</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LibraryImageTextField
                    cropped_image={props.cropped_image}
                    id="description"
                    placeholder="Click to Add Description"
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mt: "1rem" }}>
                    <Typography variant="caption">Tags</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Tags cropped_image={props.cropped_image} />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mt: "1rem" }}>
                    <Typography variant="caption">Created</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {eventDateFormatted(props.cropped_image.created)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs="auto">
            <IconButton size="large" color="info" onClick={props.previous}>
              <ChevronLeftOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <IconButton size="large" color="info" onClick={props.next}>
              <ChevronRightOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogActions>
      <DeleteLibraryImageDialog
        open={deleting}
        handleCancel={toggleDeleting}
        handleDelete={props.handleCancel}
        cropped_image={props.cropped_image}
      />
    </Dialog>
  );
};

const LibraryImageTextField = (props) => {
  const [updateImage] = useAccountState((state) => [state.updateImage]);

  const [value, setValue] = useState(
    props.cropped_image ? props.cropped_image[props.id] : undefined
  );

  React.useEffect(() => {
    setValue(props.cropped_image[props.id]);
  }, [props.cropped_image[props.id]]);

  const [editing, setEditing] = useState(false);
  const handleClick = () => {
    setEditing(true);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleBlur = () => {
    updateImage({ uuid: props.cropped_image.uuid, [props.id]: value });
    setEditing(false);
  };
  return editing ? (
    <TextField
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus
      fullWidth={props.fullWidth}
      size="small"
      multiline={props.multiline}
    />
  ) : (
    <Typography
      onClick={handleClick}
      sx={value ? props.sx : { color: "info.light" }}
    >
      {value ? value : props.placeholder}
    </Typography>
  );
};

const DeleteLibraryImageDialog = (props) => {
  const [deleteImage] = useAccountState((state) => [state.deleteImage]);
  const handleDelete = () => {
    props.handleDelete();
    deleteImage(props.cropped_image.uuid);
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Image</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this image? This action cannot be
          undone.{" "}
        </Typography>
        <Alert severity="info" sx={{ mt: ".5rem", mb: ".5rem" }}>
          Note: This will only remove this image from your image library. No
          events will be affected by this action.
        </Alert>
        <Grid container justifyContent={"flex-end"} spacing={1}>
          <Grid item xs="auto">
            <Button onClick={props.handleCancel} color="info">
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button onClick={handleDelete} color="error" variant="contained">
              Delete
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
