import React from "react";
import {
  CircularProgress,
  Box,
  Typography,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";

import { Task } from "./Tasks";
import { getDueDate } from "../utils/task_utils";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { getTasksApi } from "../api/EventsApi";

import { useFormik } from "formik";

import { formatDate } from "../utils/utils";
import { useAccountState } from "../state/store";
import { useLocaleContext } from "./locale";

export const UserTasks = (props) => {
  const [addTask, initializeTasks, tasks] = useAccountState((state) => [
    state.addTask,
    state.initializeTasks,
    state.tasks,
  ]);

  const [events, setEvents] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [addingTask, setAddingTask] = React.useState(false);
  const [filter, setFilter] = React.useState("All");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const taskSorter = (a, b) => {
    if (a.due_date > b.due_date) {
      return 1;
    } else if (a.due_date < b.due_date) {
      return -1;
    } else {
      return 0;
    }
  };

  const addNewTask = (data) => {
    console.log("ADD NEW TASK DATA", data);
    addTask({
      name: data.name,
      event_uuid: data.event.uuid,
      due_date_offset_days: data.due_date_offset_days,
      due_date_offset_direction: data.due_date_offset_direction,
    });
  };

  React.useEffect(() => {
    getTasksApi().then((resp) => {
      initializeTasks(
        resp.data.tasks.map((task) => {
          return {
            ...task,
            due_date: getDueDate(
              task.due_date_offset_days,
              task.due_date_offset_direction,
              task.event.date
            ),
          };
        })
      );
      setEvents(resp.data.events);
      setLoaded(true);
      document.title = "Tasks";
    });
  }, []);

  const filteredTasks = (task_status) => {
    let filtered_tasks = tasks;
    if (filter !== "All") {
      filtered_tasks = filtered_tasks.filter(
        (task) => task.event.status == filter
      );
    }
    filtered_tasks = filtered_tasks.filter((task) =>
      task_status.includes(task.status)
    );
    return filtered_tasks.sort(taskSorter);
  };

  return (
    <Box sx={{ maxWidth: "810px", m: "auto", p: "1rem" }}>
      {loaded ? (
        <Box sx={{ height: "calc(100vh - 75px)", overflow: "auto" }}>
          <Box sx={{ mb: "calc(100vh/2)" }}>
            <Grid
              container
              sx={{ width: "100%", maxWidth: "800px", m: "auto" }}
            >
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Typography variant="h5">Tasks</Typography>
                </Grid>

                <Grid item xs="auto" container alignItems={"center"}>
                  <Grid item xs="auto">
                    <FormControl sx={{ m: 1, minWidth: 100 }}>
                      <InputLabel id="event-status-filter-label">
                        Event Status
                      </InputLabel>
                      <Select
                        labelId="event-status-filter-label"
                        id="event-status-filter"
                        value={filter}
                        label="Event Status"
                        onChange={handleFilterChange}
                        autoWidth
                        size="small"
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"IN"}>Inquiry</MenuItem>
                        <MenuItem value={"PR"}>Proposal</MenuItem>
                        <MenuItem value={"BO"}>Booked</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="secondary"
                      aria-label="add"
                      sx={{ mb: ".25rem" }}
                      onClick={() => setAddingTask(true)}
                      size="small"
                    >
                      <AddOutlinedIcon />
                      Add Task
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {tasks.length > 0 ? (
                  <>
                    {filteredTasks(["IN", "IP"]).map((task) => (
                      <Task key={task.uuid} task={task} task_list />
                    ))}

                    {tasks.filter((task) => task.status == "CO").length > 0 ? (
                      <>
                        <Typography variant="h6">Completed</Typography>
                        {filteredTasks(["CO"]).map((task) => (
                          <Task key={task.uuid} task={task} task_list />
                        ))}
                      </>
                    ) : null}
                  </>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        borderStyle: "dotted",
                        borderColor: "info.light",
                        p: "2rem",
                        borderRadius: "7.5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "info.light",
                          fontWeight: "normal",
                        }}
                        variant="h6"
                      >
                        No incomplete tasks. Add new tasks on the 'Task' tab of
                        an event or event template.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          <AddTaskDialog
            open={addingTask}
            handleCancel={() => setAddingTask(false)}
            events={events}
            addTask={addNewTask}
          />
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

const AddTaskDialog = (props) => {
  const locale = useLocaleContext();
  const formik = useFormik({
    initialValues: {
      name: "",
      event: {},
      due_date_offset_days: 0,
      due_date_offset_direction: "BE",
    },
    onSubmit: (values, { resetForm }) => {
      props.addTask(values);
      resetForm();
      setDueDate(null);
      props.handleCancel();
    },
  });
  const eventSorter = (a, b) => {
    if (a.date > b.date) {
      return 1;
    } else if (a.date < b.date) {
      return -1;
    } else {
      return 0;
    }
  };

  const [due_date, setDueDate] = React.useState(null);

  const handleDateChange = (val) => {
    setDueDate(val);
    updateDueDateValues(val, formik.values.event);
  };
  const updateDueDateValues = (due_date, event) => {
    if (event && due_date) {
      due_date = new Date(due_date);
      let event_date = new Date(event.date);
      let offset = due_date.getTime() - event_date.getTime();
      let direction = offset < 0 ? "BE" : "AF";
      offset /= 1000 * 3600 * 24;
      formik.setFieldValue("due_date_offset_days", Math.abs(offset.toFixed(0)));
      formik.setFieldValue("due_date_offset_direction", direction);
    }
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Task</DialogTitle>
      <DialogContent>
        <form>
          <Grid container sx={{ p: "1rem", maxWidth: "300px" }} spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Task Name"
                value={formik.values.name}
                onChange={(e) => {
                  formik.setFieldValue("name", e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={props.events.sort(eventSorter)}
                getOptionLabel={(option) =>
                  option.name + " " + formatDate(option.date, locale)
                }
                value={formik.values.event_uuid}
                renderInput={(params) => (
                  <TextField {...params} label="Event" />
                )}
                placeholder="Event"
                onChange={(e, val) => {
                  formik.setFieldValue("event", val);
                  updateDueDateValues(due_date, val);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.uuid === value.uuid
                }
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Due Date"
                value={due_date}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="info" variant="outlined" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          Add Task
        </Button>
      </DialogActions>
    </Dialog>
  );
};
