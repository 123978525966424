/* global analytics */
import React, { useRef } from "react";
import { withRouter } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { IdleTimerProvider } from "react-idle-timer";

import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import { getProposalView } from "../api/EventsApi";
import Moment from "moment";

import { AppBar, Toolbar, Button, Box, Tabs, Tab, Paper } from "@mui/material";
import Image from "react-graceful-image";
import ProposalView from "./ProposalView.js";
import Documents from "./Documents";
import Contract from "./Contract";
import SignContractDialog from "./Sign";
import { PaymentSchedule } from "./PaymentSchedule";
import { contractIsEnabled } from "../utils/event_utils";
import { documentIsSigned } from "../utils/document_utils";
import { useAccountState } from "../state/store";
import { EventSockets } from "./Sockets";
import { useReactToPrint } from "react-to-print";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

const ClientView = (props) => {
  const [
    back_office,
    floral_event,
    eventHasAmendments,
    initializeBackOffice,
    initializeClients,
    initializeContractSections,
    initializeEvent,
    initializeEventItems,
    initializeEventVersion,
    initializeItemPrices,
    initializePayments,
    initializePaymentSchedule,
    initializeProposal,
    initializeRecipeProposalSections,
    initializeUser,
    lockEventItemPrices,
    payment_schedule,
    proposal,
    user,
    userPlanType,
  ] = useAccountState((state) => [
    state.back_office,
    state.event,
    state.eventHasAmendments,
    state.initializeBackOffice,
    state.initializeClients,
    state.initializeContractSections,
    state.initializeEvent,
    state.initializeEventItems,
    state.initializeEventVersion,
    state.initializeItemPrices,
    state.initializePayments,
    state.initializePaymentSchedule,
    state.initializeProposal,
    state.initializeRecipeProposalSections,
    state.initializeUser,
    state.lockEventItemPrices,
    state.payment_schedule,
    state.proposal,
    state.user,
    state.userPlanType,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [proposal_uuid] = React.useState(
    props.proposal_uuid ? props.proposal_uuid : props.match.params.proposal_uuid
  );
  const [preview, setPreview] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [preSigning, setPreSigning] = React.useState(false);
  const [signing, setSigning] = React.useState(false);
  const [idle, setIdle] = React.useState(false);

  const itemPricesDictFromList = (item_prices_list) => {
    let item_prices_dict = {};
    item_prices_list.forEach(
      (item_price, i) => (item_prices_dict[item_price.item] = item_price)
    );
    return item_prices_dict;
  };

  const updateState = () => {
    getProposalView(proposal_uuid).then((resp) => {
      initializeProposal(resp.data.proposal);
      initializeContractSections(resp.data.contract_sections);
      initializeEvent(resp.data.event);
      initializeClients(resp.data.event.clients);
      initializeEventItems(resp.data.event_items);
      initializeEventVersion(resp.data);
      initializeItemPrices(itemPricesDictFromList(resp.data.item_prices));
      initializeBackOffice(resp.data.back_office);
      initializeUser(resp.data.user);
      initializePayments(resp.data.event.payment_schedule.payments);
      initializePaymentSchedule(resp.data.event.payment_schedule);
      initializeRecipeProposalSections(resp.data.recipe_proposal_sections);
      setLoaded(true);
      analytics.identify(resp.data.user.id);
    });
  };

  React.useEffect(() => {
    let qs = new URLSearchParams(props.location.search);
    setPreview(qs.has("preview") ? qs.get("preview") : false);
    if (preview) {
      setLoaded(true);
    } else {
      updateState();
    }
  }, [props.location.search]);

  Moment.locale("en");

  let qs = new URLSearchParams(props.location.search);
  let initial_tab = qs.has("tab") ? qs.get("tab") : "proposal";
  const [tabValue, setTabValue] = React.useState(initial_tab);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //TEMP CODE
  const [tmpSignDialogOpen, setTmpSignDialogOpen] = React.useState(false);
  //TEMP CODE

  const Sign = () => {
    lockEventItemPrices();
    if (
      proposal.contracts.length === 0 ||
      (proposal.contracts.filter((contract) => !documentIsSigned(contract))
        .length === 0 &&
        proposal.amendments_published &&
        eventHasAmendments())
    ) {
      //TEMP CODE
      // setTmpSignDialogOpen(true);
      //TEMP CODE
      setTabValue("contract");
      setPreSigning(true);
    } else {
      setTabValue("documents");
      setSigning(true);
    }
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const CloseEmbeddedSign = () => {
    updateState();
    setSigning(false);
    sleep(5000).then(() => {
      updateState();
    });
  };

  const isSigned = (contracts) => {
    if (contracts.length === 0) {
      return false;
    } else {
      return contracts[contracts.length - 1].status === "signed";
    }
  };

  const getClient = () => {
    if (floral_event.clients && floral_event.clients.length > 0) {
      return floral_event.clients[0];
    } else {
      return undefined;
    }
  };

  const expired = () => {
    return proposal.expires && new Date(proposal.expiration_date) < new Date();
  };

  const displayProposal = () => {
    return (
      ((proposal.published && !expired()) ||
        preview ||
        floral_event.status === "BO") &&
      floral_event.status !== "AR" &&
      floral_event.status !== "CA" &&
      floral_event.status !== "LO" &&
      !floral_event.deleted
    );
  };

  const [printing, setPrinting] = React.useState(false);

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * process.env.REACT_APP_IDLE_TIMEOUT_MINUTES}
      onIdle={() => setIdle(true)}
    >
      <IdleDialog open={idle} />
      {loaded ? (
        <Box sx={{ height: "100vh" }}>
          <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
            <Toolbar disableGutters>
              <Grid
                container
                spacing={1}
                sx={{ m: ".25rem" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8} container>
                  <Grid item xs={12}>
                    <Tooltip
                      title={floral_event.name + " Proposal"}
                      enterDelay={1000}
                    >
                      <Typography variant="h5" noWrap>
                        {floral_event.name} Proposal
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ ml: ".25rem" }}>
                      {user.company}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid item xs="auto"> */}
                {/* <ReactToPrint 
                                trigger={() => {return <IconButton><PrintOutlinedIcon /></IconButton>}}
                                content={() => printRef.current}
                            /> */}
                {/* </Grid> */}
                {contractIsEnabled(back_office, user) && displayProposal() ? (
                  <Grid item xs="auto" container justifyContent={"flex-end"}>
                    {/* <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setPrinting(true)}
                        size="large"
                        sx={{ mr: "1rem", ml: "auto" }}
                      >
                        Print Proposal
                      </Button>
                      <PrintProposalDialog
                        open={printing}
                        viewDocuments={() => setTabValue("documents")}
                        handleCancel={() => setPrinting(false)}
                      />
                    </Grid> */}
                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={Sign}
                        size="large"
                        sx={{ mr: "1rem", ml: "auto" }}
                      >
                        {!isSigned(proposal.contracts)
                          ? "Sign"
                          : eventHasAmendments() &&
                            proposal.amendments_published
                          ? fullScreen
                            ? "Sign"
                            : "Sign Amendments"
                          : fullScreen
                          ? "Docs"
                          : "Documents"}
                      </Button>
                      {/* TMP CODE */}
                      <TempSignDialog
                        open={tmpSignDialogOpen}
                        handleCancel={() => setTmpSignDialogOpen(false)}
                      />
                      {/* TMP CODE */}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Toolbar>
          </AppBar>
          <Toolbar />
          {displayProposal() ? (
            <Box sx={{ mt: "1rem" }}>
              <Grid
                container
                justifyContent="center"
                sx={{ maxWidth: "1250px", m: "auto" }}
              >
                {/* <Grid item xs={12} sm={3} md={2}> */}
                <Grid item xs={12} sm="auto">
                  <Tabs
                    orientation={fullScreen ? "horizontal" : "vertical"}
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                      color: "info",
                      mt: "1rem",
                      ".MuiTabs-flexContainer": {
                        justifyContent: "center",
                      },
                    }}
                    TabIndicatorProps={{
                      style: { background: "gray" },
                    }}
                  >
                    <StyledTab
                      label="Proposal"
                      value="proposal"
                      tabValue={tabValue}
                    />
                    {(proposal.contracts.length === 0 ||
                      (eventHasAmendments() &&
                        proposal.amendments_published)) &&
                    contractIsEnabled(back_office, user) ? (
                      <StyledTab
                        label="Contract"
                        value="contract"
                        tabValue={tabValue}
                        disabled={!contractIsEnabled(back_office, user)}
                      />
                    ) : null}
                    {payment_schedule.enabled ? (
                      <StyledTab
                        label="Payments"
                        value="payments"
                        tabValue={tabValue}
                        disabled={!payment_schedule.enabled}
                      />
                    ) : null}
                    {!contractIsEnabled(back_office, user) ? null : (
                      <StyledTab
                        label="Documents"
                        value="documents"
                        tabValue={tabValue}
                        disabled={!contractIsEnabled(back_office, user)}
                      />
                    )}
                  </Tabs>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <TabPanel value={tabValue} index={"proposal"}>
                    <Grid container justifyContent={"flex-end"}>
                      {userPlanType() !== "FRE" && (
                        <Grid item xs="auto">
                          <Tooltip title={"Print Proposal"}>
                            <IconButton onClick={() => setPrinting(true)}>
                              <LocalPrintshopOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <PrintProposalDialog
                            open={printing}
                            viewDocuments={() => setTabValue("documents")}
                            handleCancel={() => setPrinting(false)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <ProposalView
                          viewDocuments={() => setTabValue("documents")}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={"contract"}>
                    <Paper sx={{ m: "1rem", p: "1.5rem" }}>
                      <Contract Sign={Sign} />
                    </Paper>
                  </TabPanel>
                  <TabPanel value={tabValue} index={"payments"}>
                    <Paper sx={{ m: "1rem" }}>
                      <Box sx={{ p: "1rem" }}>
                        <PaymentSchedule
                          client
                          client_details={getClient()}
                          event_has_amendments={
                            eventHasAmendments() &&
                            proposal.amendments_published
                          }
                          ignore_amendments={!proposal.amendments_published}
                        />
                      </Box>
                    </Paper>
                  </TabPanel>
                  <TabPanel value={tabValue} index={"documents"}>
                    <Paper sx={{ m: "1rem" }}>
                      <Documents
                        Sign={Sign}
                        signing={signing}
                        CloseEmbeddedSign={CloseEmbeddedSign}
                      />
                    </Paper>
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                border: "dashed lightgrey 2px",
                borderRadius: "5px",
                p: "1rem",
                m: "auto",
                mt: "1rem",
                maxWidth: "850px",
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Typography align="center">
                    This proposal is not published. To view the proposal, please
                    contact {user.company}.
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Paper sx={{ maxWidth: "350px", p: "1rem" }}>
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item xs={12}>
                        <Box display="flex">
                          <Image
                            src={proposal.logo}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              maxWidth: "70%",
                              maxHeight: "400px",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align="center" variant="h6">
                          {user.company}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align="center" variant="h6">
                          {user.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
          <SignContractDialog
            open={preSigning}
            handleCancel={() => setPreSigning(false)}
            Sign={() => {
              setSigning(true);
              setTabValue("documents");
            }}
          />
          <EventSockets />
        </Box>
      ) : (
        <Box sx={{ width: "100%", height: "100%" }} display="flex">
          <CircularProgress sx={{ m: "auto" }} color="info" />
        </Box>
      )}
    </IdleTimerProvider>
  );
};

export const PrintProposalDialog = (props) => {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: props.handleCancel,
  });
  const [backgroundColorEnabled, setBackgroundColorEnabled] =
    React.useState(false);
  const toggleBackgroundColorEnabled = () => {
    setBackgroundColorEnabled(!backgroundColorEnabled);
  };
  const [backgroundTextureEnabled, setBackgroundTextureEnabled] =
    React.useState(false);
  const toggleBackgroundTextureEnabled = () => {
    setBackgroundTextureEnabled(!backgroundTextureEnabled);
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel} fullScreen>
      <DialogTitle>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs="auto">
            Print Preview
          </Grid>
          <Grid
            item
            xs="auto"
            container
            justifyContent={"flex-end"}
            spacing={1}
          >
            <Grid item xs="auto">
              {" "}
              <FormControlLabel
                control={
                  <Switch
                    checked={backgroundColorEnabled}
                    onChange={toggleBackgroundColorEnabled}
                  />
                }
                label="Enable Background Color"
              />
            </Grid>
            <Grid item xs="auto">
              {" "}
              <FormControlLabel
                control={
                  <Switch
                    checked={backgroundTextureEnabled}
                    onChange={toggleBackgroundTextureEnabled}
                  />
                }
                label="Enable Background Texture"
              />
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={handlePrint}
                variant="contained"
                color="secondary"
              >
                Print
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                onClick={props.handleCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ProposalView
          viewDocuments={props.viewDocuments}
          ref={printRef}
          print
          disableBackgroundColor={!backgroundColorEnabled}
          disableBackgroundTexture={!backgroundTextureEnabled}
        />
      </DialogContent>
    </Dialog>
  );
};

const TempSignDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>E-Signing Under Maintenance</DialogTitle>
      <DialogContent>
        <Typography>
          Our e-signature partner is undergoing maintenance. Please check back
          again soon. We apologize for any inconvenience.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledTab = (props) => {
  return (
    <Tab
      {...props}
      {...a11yProps(props.value)}
      style={{
        color: props.disabled ? "lightgray" : "gray",
        fontWeight: props.tabValue === props.value ? "bold" : "normal",
      }}
    />
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ maxWidth: "1250px" }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            overflow: "auto",
            height: "calc(100vh - 125px)",
            // width: "100%",
          }}
        >
          <Box
            sx={{
              mb: "calc(100vh/2)",
            }}
          >
            <Typography sx={{ pb: "100px" }}>{children}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const IdleDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        window.location.reload(false);
      }}
    >
      <DialogTitle>Welcome Back!</DialogTitle>
      <DialogContent>
        <Typography>
          Please refresh your page to ensure you have the latest data.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            window.location.reload(false);
          }}
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(ClientView);
