import React from "react";
import { CroppedAvatar } from "./CroppedImg";

import FilterVintageIcon from "@mui/icons-material/FilterVintage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { itemColors, itemDisplayName } from "../utils/item_utils";
import { Tooltip } from "@mui/material";

const item_colors = itemColors("MA"); // material colors includes all colors (as opposed to flower colors which is a subset of all colors)

export const ProfilePicture = (props) => {
  return (
    <CroppedAvatar
      id={props.id}
      img={props.user?.profile_picture}
      sx={{ ...props.sx, "object-fit": undefined }}
      onClick={props.onClick}
      width={props.width}
      height={props.height}
    >
      <AccountCircleIcon id={props.id} />
    </CroppedAvatar>
  );
};

export const ItemIcon = (props) => {
  const isFlower = props.item.item_type === "FL";
  const [item_color, setItemColor] = React.useState(undefined);

  const getItemColor = () => {
    var color;
    for (color of item_colors) {
      if (props.item[color.value]) {
        setItemColor(color.color_code);
      }
    }
  };

  React.useEffect(getItemColor, [props.item]);
  return (
    <Tooltip title={itemDisplayName(props.item)}>
      <span>
        <CroppedAvatar
          img={
            props.item.thumbnail
              ? props.item.thumbnail
              : props.item.thumbnail_url
          }
          item={props.item}
          width={props.width ? props.width : 40}
          height={props.height ? props.height : 40}
          sx={{ backgroundColor: "white", ...props.sx }}
          key={props.key}
        >
          {isFlower ? (
            <FilterVintageIcon
              htmlColor={item_color}
              sx={props.sx}
              key={props.key}
            />
          ) : (
            <EmojiEventsIcon
              htmlColor={item_color}
              sx={props.sx}
              key={props.key}
            />
          )}
        </CroppedAvatar>
      </span>
    </Tooltip>
  );
};
