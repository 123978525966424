import React, { useEffect } from "react";

import { useAccountState } from "../state/store.js";
import {
  Button,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

export const EventSockets = (props) => {
  const [event, session_id, setStateFromSocket, setWs, ws] = useAccountState(
    (state) => [
      state.event,
      state.session_id,
      state.setStateFromSocket,
      state.setWs,
      state.ws,
    ]
  );

  const [attempts, setAttempts] = React.useState(0);
  const [warningClosed, setWarningClosed] = React.useState(false);

  const onMessage = (event) => {
    let data = JSON.parse(event.data);
    setStateFromSocket(data);
  };

  useEffect(() => {
    if (ws) {
      ws.close();
    }
    const new_ws = new ReconnectingWebSocket(
      `${process.env.REACT_APP_API_WS_URL}/ws/event/${
        event.uuid
      }/?token=${localStorage.getItem("token")}&session_id=${session_id}`,
      onMessage,
      attempts,
      setAttempts
    );
    setWs(new_ws);
  }, []);

  const Refresh = (
    <>
      <Button color="error" onClick={() => window.location.reload(false)}>
        Refresh
      </Button>
      <Tooltip title="Dismiss">
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setWarningClosed(true)}
        >
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );

  return (
    <>
      {attempts >= 5 ? (
        <Snackbar
          message={
            <Typography>
              Out of sync with server{" "}
              <Tooltip title="Data on this page may be out of sync with other tabs or collaborators working on the same event. Consider rereshing to fix the problem. If this warning continues to appear, contact Support@flowerbuddy.co.">
                <HelpOutlineOutlinedIcon fontSize="inherit" />
              </Tooltip>
            </Typography>
          }
          // onClose={() => setWarningClosed(true)}
          open={attempts > 5 && !warningClosed}
          action={Refresh}
          setWarningClosed={setWarningClosed}
        />
      ) : null}
    </>
  );
};

class ReconnectingWebSocket {
  constructor(url, onMessage, attempts, setAttempts) {
    this.url = url;
    this.onMessage = onMessage;
    this.attempts = 0;
    this.setAttempts = setAttempts;
    this.connecting = false;
    this.setup();
  }

  close() {
    this.ws.close();
  }

  incAttempts() {
    this.attempts += 1;
    this.setAttempts(this.attempts);
  }

  resetAttempts() {
    this.attempts = 0;
    this.setAttempts(this.attempts);
  }

  setup() {
    if (this.connecting) {
      return;
    }
    this.connecting = true;
    this.ws = new WebSocket(this.url);
    this.ws.onmessage = this.onMessage;

    this.ws.onerror = (error) => {
      console.log("WebSocket error: ", error);
    };

    this.ws.onclose = (event) => {
      console.log(
        "WebSocket connection closed: ",
        event.code,
        event.reason,
        "| Reconnecting ",
        this.attempts
      );
      setTimeout(() => {
        this.incAttempts();
        delete this.ws;
        // this.ws = new WebSocket(this.url);
        this.connecting = false;
        this.setup();
      }, 1000);
    };

    this.ws.onopen = () => {
      console.log("WebSocket connection established");
      this.connecting = false;
      this.resetAttempts();
    };
  }

  send(data) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(data);
    } else {
      console.log("Cannot send message, WebSocket connection is not open");
    }
  }
}
