import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Link, Box, CircularProgress } from "@mui/material";

const EventsSummaryTable = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (props.events ? props.events.count : 0 > 0) ? (
    <Grid container direction="row" justifyContent="flex-start" spacing={0}>
      <Grid item xs={2}>
        <Typography
          variant={fullScreen ? "h2" : "h4"}
          sx={{ textAlign: "right" }}
        >
          {props.events ? props.events.count : 0}
        </Typography>
      </Grid>
      <Grid item sx={{ mt: "1rem", ml: ".5rem" }} xs={9}>
        <Typography>{props.title}</Typography>
        {props.events
          ? props.events.events.map((event) => (
              <Typography noWrap key={event.uuid}>
                <Link
                  underline="none"
                  href={"/event/" + event.uuid}
                  sx={{
                    color: "text.secondary",
                  }}
                  id={event.name === "Example Event" ? "example-event" : null}
                >
                  {event.name}
                </Link>
              </Typography>
            ))
          : null}
        <Link
          href={props.href}
          sx={{
            color: "text.primary",
            fontFamily: "Roboto",
            fontWeight: "bold",
          }}
          underline="none"
          id={"see-all-" + props.id}
        >
          See All
        </Link>
      </Grid>
    </Grid>
  ) : (
    <Box alignItems="center" sx={{ mt: "30%" }}>
      {props.loaded ? (
        <Typography textAlign="center">No events in {props.title}</Typography>
      ) : (
        <Box display="flex">
          <CircularProgress sx={{ m: "auto" }} />
        </Box>
      )}
    </Box>
  );
};

export default EventsSummaryTable;
