import { updateBackOfficeApi } from "../api/EventsApi";
import _ from "lodash";

export const backOfficeSlice = (set, get) => ({
  back_office: {},
  contractIsEnabled: () => {
    return get().userPlanType() == "UNL"
      ? get().back_office.contract_is_enabled
      : true;
  },
  initializeBackOffice: (data) => set({ back_office: data, _INIT: true }),
  updateBackOffice: (data) => {
    let back_office = get().back_office;
    set({ back_office: { ...back_office, ...data } });
    let updateData = { ...data, uuid: back_office.uuid };
    updateBackOfficeApi(updateData);
  },
});
