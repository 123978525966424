import { addEventMiscellaneousItemApi } from "../api/DataApi";

export const eventMiscellaneousItemsSlice = (set, get) => ({
  event_miscellaneous_items: [],
  addEventMiscellaneousItem: (data) => {
    addEventMiscellaneousItemApi({
      event_version_uuid: get().event_version.uuid,
      item_uuid: data.item ? data.item.uuid : data.item_uuid,
    }).then((response) => {
      set({
        event_miscellaneous_items: [
          ...get().event_miscellaneous_items,
          response.data,
        ],
      });
    });
  },
  initializeEventMiscellaneousItems: (data) => {
    set({ event_miscellaneous_items: data });
  },
});
