import axios from "axios";

const unsecureAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
export { unsecureAPI };
axios.defaults.withCredentials = true;

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const put = (url, data) => {
  return unsecureAPI.put(url, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};
