import { enUS } from "date-fns/locale";
import { loadLocaleObj } from "../utils/utils";
import { createContext, useContext, useEffect, useState } from "react";

export function useLocale() {
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    loadLocaleObj(navigator.language).then((locale) => {
      setLocale(locale);
    });
  }, [navigator.language]);

  return locale;
}

const LocaleContext = createContext();

export function LocaleProvider({ children }) {
  const locale = useLocale();

  return (
    <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
  );
}

export function useLocaleContext() {
  return useContext(LocaleContext);
}
