import {
  addEventCoordinatorApi,
  createNewLocationApi,
  getEventCoordinatorsApi,
  getLocationsApi,
  updateLocationApi,
} from "../api/EventsApi";
import { byUUID } from "../utils/utils";

export const locationsSlice = (set, get) => ({
  locations: [],
  locations_initialized: false,

  addLocation: (data, cb) => {
    createNewLocationApi(data).then((resp) => {
      let locations = get().locations;
      set({ locations: [...locations, resp.data] });
      if (cb) {
        cb(resp.data);
      }
    });
  },
  getLocations: () => {
    if (!get().locations_initialized) {
      getLocationsApi().then((resp) => {
        get().initializeLocations(resp.data);
      });
    }
  },
  initializeLocations: (data) =>
    set({ locations: data, locations_initialized: true }),
  updateLocation: (data, cb) => {
    let locations = get().locations;
    let location = locations.find(byUUID(data.uuid));
    let index = locations.findIndex(byUUID(data.uuid));
    set({
      locations: [
        ...locations.slice(0, index),
        { ...location, ...data },
        locations.slice(index + 1),
      ],
    });
    updateLocationApi(data).then((resp) => {
      if (cb) {
        cb({ ...location, ...resp.data });
      }
    });
  },
});
