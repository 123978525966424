import { useState } from "react";
import axios from "axios";

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAccountState } from "../../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { PDFUploader } from "../FileUploader";
import {
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileImage,
  FaFileVideo,
  FaFile,
  FaFilePdf,
} from "react-icons/fa6";
import { sort_by_order, sortByCreated } from "../../utils/utils";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { eventDateTimeFormatted } from "../../utils/event_utils";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { PdfViewerDialog } from "../PdfViewerDialog";
import { useContextOrAccountState } from "../AccountStateOverridesContext";

export const OrderAttachments = (props) => {
  const [event] = useAccountState((state) => [state.event]);

  return (
    <Table size="small">
      {(props.attachments ? props.attachments : event.event_attachments)
        .filter((ea) => ea.is_order)
        .sort(sortByCreated)
        .map((attachment, index) => (
          <OrderAttachmentRow key={index} attachment={attachment} />
        ))}
    </Table>
  );
};

const OrderAttachmentRow = (props) => {
  const [back_office] = useAccountState((state) => [state.back_office]);
  const deleteEventAttachment = useContextOrAccountState(
    "deleteEventAttachment"
  );
  const [deleting, setDeleting] = useState(false);
  const extension = props.attachment.attachment_url.split(".").pop();
  const downloadFile = async () => {
    const url = props.attachment.attachment_url;
    const fileName = `${props.attachment.name}.${extension}`; // Specify the desired file name

    try {
      const response = await axios.get(url, {
        responseType: "blob",
        withCredentials: true,
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      const href = URL.createObjectURL(blob);
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };
  const [viewing, setViewing] = useState(false);
  const toggleViewing = () => setViewing(!viewing);
  return (
    <TableRow>
      <TableCell sx={{ minWidth: "120px" }}>{props.attachment.name}</TableCell>
      {props.attachment.events && (
        <TableCell>
          <Grid container spacing={1}>
            {props.attachment.events.map((event) => (
              <Grid item xs="auto">
                <Chip label={event.name} size="small" />
              </Grid>
            ))}
          </Grid>
        </TableCell>
      )}
      <TableCell sx={{ minWidth: "130px" }}>
        {eventDateTimeFormatted(
          props.attachment.created,
          undefined,
          back_office.date_format
        )}
      </TableCell>
      <TableCell sx={{ minWidth: "100px" }}>
        <Grid container spacing={1} justifyContent={"flex-end"}>
          {extension === "pdf" && (
            <Grid item xs="auto">
              <IconButton size="small" onClick={toggleViewing}>
                <VisibilityOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          )}
          <Grid item xs="auto">
            <IconButton size="small" onClick={downloadFile}>
              <FileDownloadOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <IconButton size="small" onClick={() => setDeleting(true)}>
              <DeleteOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
      <DeleteAttachmentDialog
        open={deleting}
        handleCancel={() => setDeleting(false)}
        attachment={props.attachment}
        deleteAttachment={deleteEventAttachment}
      />
      <PdfViewerDialog
        title={props.attachment.name}
        pdf_url={props.attachment.attachment_url}
        open={viewing}
        handleCancel={toggleViewing}
      />
    </TableRow>
  );
};

export const EventAttachments = (props) => {
  const [
    addEventAttachment,
    deleteEventAttachment,
    event,
    updateEventAttachment,
  ] = useAccountState((state) => [
    state.addEventAttachment,
    state.deleteEventAttachment,
    state.event,
    state.updateEventAttachment,
  ]);

  return (
    <Attachments
      attachments={event.event_attachments.filter((ea) => !ea.is_order)}
      addAttachment={addEventAttachment}
      deleteAttachment={deleteEventAttachment}
      updateAttachment={updateEventAttachment}
    />
  );
};

export const ProposalAttachments = (props) => {
  const [
    addProposalAttachment,
    deleteProposalAttachment,
    updateProposalAttachment,
  ] = useAccountState((state) => [
    state.addProposalAttachment,
    state.deleteProposalAttachment,
    state.updateProposalAttachment,
  ]);
  const addAttachment = (doc_url) => {
    addProposalAttachment(props.proposal_section.uuid, doc_url);
  };
  const updateAttachment = (data) => {
    updateProposalAttachment({
      ...data,
      proposal_section_uuid: props.proposal_section.uuid,
    });
  };
  const deleteAttachment = (uuid) => {
    deleteProposalAttachment(props.proposal_section.uuid, uuid);
  };
  return (
    <Attachments
      attachments={props.proposal_section.proposal_attachments}
      addAttachment={addAttachment}
      deleteAttachment={deleteAttachment}
      updateAttachment={updateAttachment}
      items_align={props.proposal_section.style?.items_align || "flex-start"}
    />
  );
};

export const Attachments = (props) => {
  return (
    <Grid
      container
      spacing={1}
      justifyContent={props.items_align}
      sx={{ pl: ".5rem", pt: ".5rem" }}
    >
      {props.attachments?.sort(sort_by_order).map((attachment, index) => (
        <Attachment
          key={index}
          attachment={attachment}
          updateAttachment={props.updateAttachment}
          deleteAttachment={props.deleteAttachment}
        />
      ))}
      <Grid item xs={4} sm={2} md={2}>
        <PDFUploader savePdfUrl={props.addAttachment} width={100} height={75} />
      </Grid>
    </Grid>
  );
};

const Attachment = (props) => {
  const [editingName, setEditingName] = useState(false);
  const [name, setName] = useState(props.attachment.name);
  const updateName = () => {
    props.updateAttachment({
      uuid: props.attachment.uuid,
      name: name,
    });
    setEditingName(false);
  };

  const [hover, setHover] = useState(false);
  const setHoverTrue = () => setHover(true);
  const setHoverFalse = () => setHover(false);

  const [deleting, setDeleting] = useState(false);

  return (
    <Grid item xs={4} sm={3} md={2}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Box
            id="attachment"
            onMouseEnter={setHoverTrue}
            onMouseLeave={setHoverFalse}
            sx={{
              position: "relative",
              width: 50,
              height: 75,
              borderRadius: "5px",
              boxShadow: 4,
              backgroundColor: "white",
            }}
          >
            <AttachmentThumbnail attachment={props.attachment} />

            {hover && (
              <IconButton
                size="small"
                onClick={() => {
                  setDeleting(true);
                }}
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 4,
                  backgroundColor: "rgba(255,255,255,0.8)",
                  zIndex: 1,
                }}
              >
                <CloseOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            <DeleteAttachmentDialog
              open={deleting}
              handleCancel={() => setDeleting(false)}
              attachment={props.attachment}
              deleteAttachment={props.deleteAttachment}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs={"auto"}>
          {editingName ? (
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={updateName}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  updateName();
                }
              }}
              autoFocus
              size="small"
              variant="outlined"
              label="Name"
              sx={{ maxWidth: 100, mt: ".35rem" }}
              inputProps={{ style: { fontSize: ".6rem" } }} // font size of input text
              // InputLabelProps={{ style: { fontSize: ".6rem" } }} // font size of input label
            />
          ) : (
            <Typography
              noWrap
              maxWidth={100}
              sx={{ fontSize: ".6rem", cursor: "pointer", pt: ".25rem" }}
              onClick={() => setEditingName(true)}
            >
              {props.attachment.name}
              <EditOutlinedIcon fontSize="inherit" />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const DeleteAttachmentDialog = (props) => {
  const handleDelete = () => {
    props.deleteAttachment(props.attachment.uuid);
    props.handleCancel();
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Attachment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete attachment "{props.attachment.name}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} variant="outlined" color="info">
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="contained" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AttachmentsPreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      spacing={0.5}
    >
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={50}
            height={5}
            sx={{ mt: 0.5 }}
            animation={false}
          />
        </Grid>
      </Grid>
      {[...Array(3).keys()].map((i) => (
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={14}
            height={20}
            animation={false}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const AttachmentThumbnail = (props) => {
  const extension = props.attachment.attachment_url
    .split(".")
    .pop()
    .toLowerCase();
  console.log("extension", extension);
  const doc_type = DocExtensionMap[extension];
  console.log("doc_type", doc_type);
  const AttachmentDocType = AttachmentDocTypes[doc_type];
  console.log("AttachmentDocType", AttachmentDocType);
  const PreviewComponent = AttachmentDocType.thumbnail_component;

  const downloadFile = async () => {
    const url = props.attachment.attachment_url;
    const fileName = `${props.attachment.name}.${extension}`; // Specify the desired file name

    try {
      const response = await axios.get(url, {
        responseType: "blob",
        withCredentials: true,
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      const href = URL.createObjectURL(blob);
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <Tooltip title={"Click to Download"}>
      <Box onClick={downloadFile} sx={{ cursor: "pointer" }}>
        <PreviewComponent />
      </Box>
    </Tooltip>
  );
};

const DocumentThumbnail = (props) => {
  return <IconThumbnail icon_component={FaFileWord} />;
};

const SpreadsheetThumbnail = (props) => {
  return <IconThumbnail icon_component={FaFileExcel} />;
};

const PowerPointThumbnail = (props) => {
  return <IconThumbnail icon_component={FaFilePowerpoint} />;
};

const ImageThumbnail = (props) => {
  return <IconThumbnail icon_component={FaFileImage} />;
};

const VideoThumbnail = (props) => {
  return <IconThumbnail icon_component={FaFileVideo} />;
};

const GenericThumbnail = (props) => {
  return <IconThumbnail icon_component={FaFile} />;
};

const PdfThumbnail = (props) => {
  return <IconThumbnail icon_component={FaFilePdf} />;
};

const IconThumbnail = (props) => {
  const IconComponent = props.icon_component;
  return (
    <Box sx={{ width: 50, height: 75, display: "flex" }}>
      <Box sx={{ m: "auto" }}>
        <IconComponent size={35} color="lightgray" />
      </Box>
    </Box>
  );
};

const DocExtensionMap = {
  doc: "document",
  docx: "document",
  csv: "spreadsheet",
  xls: "spreadsheet",
  xlsx: "spreadsheet",
  ppt: "powerpoint",
  pptx: "powerpoint",
  jpg: "image",
  jpeg: "image",
  png: "image",
  gif: "image",
  mp4: "video",
  mov: "video",
  m4v: "video",
  avi: "video",
  mkv: "video",
  wmv: "video",
  flv: "video",
  ogg: "video",
  webm: "video",
  pdf: "pdf",
};

const AttachmentDocTypes = {
  document: {
    label: "Document",
    thumbnail_component: DocumentThumbnail,
  },
  spreadsheet: {
    label: "Spreadsheet",
    thumbnail_component: SpreadsheetThumbnail,
  },
  powerpoint: {
    label: "Powerpoint",
    thumbnail_component: PowerPointThumbnail,
  },
  image: {
    label: "Image",
    thumbnail_component: ImageThumbnail,
  },
  video: {
    label: "Video",
    thumbnail_component: VideoThumbnail,
  },
  generic: {
    label: "File",
    thumbnail_component: GenericThumbnail,
  },
  pdf: {
    label: "PDF",
    thumbnail_component: PdfThumbnail,
  },
};

const AttachmentView = (props) => {
  const extension = props.attachment.attachment_url.split(".").pop();
  const [name, setName] = useState(props.attachment.name);

  return (
    <Grid item xs={4} sm={2} md={2}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Box
            sx={{
              borderRadius: "5px",
              boxShadow: 4,
            }}
          >
            <AttachmentThumbnail attachment={props.attachment} />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs={"auto"}>
          <Typography
            noWrap
            maxWidth={100}
            sx={{ fontSize: ".6rem", cursor: "pointer", pt: ".25rem" }}
          >
            {props.attachment.name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const AttachmentsView = (props) => {
  return (
    <Grid
      container
      spacing={1}
      justifyContent={props.proposal_section.style?.items_align || "flex-start"}
    >
      {props.proposal_section.proposal_attachments
        .sort(sort_by_order)
        .map((attachment, index) => (
          <AttachmentView
            key={index}
            attachment={attachment}
            proposal_section={props.proposal_section}
            displayOnly
          />
        ))}
    </Grid>
  );
};
