import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  Snackbar,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import { useAccountState } from "../state/store";
import { redeemReferralCodeApi } from "../api/ProfileApi";

const ReferralCode = (props) => {
  const [updateUserState, user] = useAccountState((state) => [
    state.updateUserState,
    state.user,
  ]);
  const [copied, setCopied] = React.useState(false);
  const [referralCode, setReferralCode] = React.useState("");
  const [OiDetected, setOiDetected] = React.useState(false);
  const [error, setError] = React.useState(undefined);
  const updateReferralCode = (e) => {
    var inputValue = e.target.value;
    if (inputValue.length === 5 && !inputValue.endsWith("-")) {
      inputValue = inputValue.slice(0, 4) + "-" + inputValue.slice(4);
    }
    inputValue = inputValue.replace(/[^a-zA-Z0-9-]/g, "");
    inputValue = inputValue.toUpperCase();
    // if (inputValue.includes("O") || inputValue.includes("I")) {
    //   setOiDetected(true);
    // }
    // inputValue = inputValue.replace(/[OI]/g, "");

    setReferralCode(inputValue);
  };
  const redeemReferralCode_ = () => {
    redeemReferralCodeApi(referralCode)
      .then((res) => {
        updateUserState(res.data);
        setReferralCode("");
        setError(undefined);
      })
      .catch((err) => {
        setError(err.response.data.error);
      });
  };
  return (
    <Grid container justifyContent="center" spacing={2} display="flex">
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        spaceing={1}
      >
        <Grid item xs="auto">
          <Typography variant="h6">Share Your Referral Code</Typography>
        </Grid>

        <Grid
          item
          xs="auto"
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Grid item xs="auto">
            <Tooltip title="Click to copy your referral code.">
              <Typography
                sx={{
                  color: "primary.main",
                  border: "solid 1px",
                  borderColor: "info.main",
                  p: ".5rem",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(user.referral_code);
                  setCopied(true);
                }}
                id="copy-referral-code-button"
              >
                {user.referral_code}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Click to copy a link to the FlowerBuddy signup page. When this link is used, the signup form will be prepopulated with your referral code.">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    process.env.REACT_APP_BASE_URL +
                      "/signup?referral_code=" +
                      user.referral_code
                  );
                  setCopied(true);
                }}
                id="copy-link-button"
              >
                <IosShareOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs="auto">
            <Typography sx={{ color: "info.main" }}>
              Share your referral code to earn $150 USD of FlowerBuddy credit!
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Share your referral code to earn $150 USD of FlowerBuddy credit after the redeeming account spends their first $150 USD.">
              <HelpOutlineOutlinedIcon
                fontSize="inherit"
                sx={{ color: "info.main" }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", mt: "1rem" }} />
      </Grid>
      {!user.signup_referral_code ? (
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          spaceing={1}
        >
          <Grid item xs="auto">
            <Typography variant="h6">Redeem A Referral Code</Typography>
          </Grid>
          <Grid item xs="auto">
            <TextField
              label="Referral Code"
              value={referralCode}
              onChange={updateReferralCode}
              InputProps={
                referralCode.length === 9
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Redeem! Once a valid referral code has been redeemed, it cannot be removed.">
                            <IconButton onClick={redeemReferralCode_}>
                              <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Input a referral code that was shared with you to redeem a $30 USD FlowerBuddy credit.">
                            <IconButton>
                              <HelpOutlineOutlinedIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="error" textAlign={"right"}>
              {error}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          spaceing={1}
        >
          <Grid item xs="auto">
            <Typography>
              You redeemed a referral code! You can view your credit on the
              Payment History tab.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={copied}
        autoHideDuration={3000}
        onClose={() => {
          setCopied(false);
        }}
        message="Copied!"
      />
      <Snackbar
        open={OiDetected}
        autoHideDuration={3000}
        onClose={() => {
          setOiDetected(false);
        }}
        message="Referral codes do not contain the letters 'O' or 'I'."
      />
    </Grid>
  );
};

export default withRouter(ReferralCode);
