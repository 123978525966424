import { Box, Grid, Skeleton } from "@mui/material";
import { useAccountState } from "../../state/store";
import RichTextField, {
  RichTextFieldEditable,
  RichTextFieldView,
} from "../RichTextField";
import { DefaultStyles } from "../../utils/proposal_utils";

export const Notes = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const save = (value) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      rich_text: value,
    });
  };
  return (
    <Box sx={{ p: "1rem" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RichTextFieldEditable
            id={props.proposal_section.uuid}
            value={props.proposal_section.style?.rich_text}
            updateValue={save}
            placeholder={"Notes..."}
            font={
              props.proposal_section.style?.font || DefaultStyles.fontFamily
            }
            font_color={
              props.proposal_section.style?.font_color ||
              DefaultStyles.fontColor
            }
            textAlign={
              props.proposal_section.style?.text_align ||
              DefaultStyles.textAlign
            }
            font_size={
              props.proposal_section.style?.font_size || DefaultStyles.fontSize
            }
            autofocus
            stacked
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const NotesPreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      spacing={0.5}
      height={"100%"}
    >
      {[...Array(7).keys()].map((i) => (
        <Grid item xs="auto">
          <Skeleton variant="rounded" width={50} height={5} animation={false} />
        </Grid>
      ))}
    </Grid>
  );
};

export const NotesView = (props) => {
  return (
    <RichTextFieldView
      id={props.proposal_section.uuid}
      value={props.proposal_section.style?.rich_text}
      font={props.proposal_section.style?.font || DefaultStyles.fontFamily}
      font_color={
        props.proposal_section.style?.font_color || DefaultStyles.fontColor
      }
      textAlign={
        props.proposal_section.style?.text_align || DefaultStyles.textAlign
      }
      font_size={
        props.proposal_section.style?.font_size || DefaultStyles.fontSize
      }
    />
  );
};
