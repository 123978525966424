import React from "react";
import { withRouter } from "react-router-dom";
import {
  TextField,
  Paper,
  Box,
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
  Link,
  InputAdornment,
  Tooltip,
  OutlinedInput,
  MenuList,
  ListItemIcon,
  ListItemText,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { DatePicker } from "@mui/x-date-pickers";
import { getDueDate, taskSorter } from "../utils/task_utils";
import { formatDate } from "../utils/utils";
import { useAccountState } from "../state/store";
import { useLocale, useLocaleContext } from "./locale";

const Tasks = (props) => {
  const locale = useLocale();

  const [addTask, event, tasks] = useAccountState((state) => [
    state.addTask,
    state.event,
    state.tasks,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getLastTaskDate = () => {
    let incomplete_tasks = tasks
      .filter((task) => task.status !== "CO")
      .sort(taskSorter);
    if (incomplete_tasks.length > 0) {
      let last_task = incomplete_tasks[incomplete_tasks.length - 1];
      return {
        due_date_offset_days: last_task.due_date_offset_days,
        due_date_offset_direction: last_task.due_date_offset_direction,
      };
    } else {
      return { due_date_offset_days: 0, due_date_offset_direction: "BE" };
    }
  };

  const addNewTask = () => {
    addTask({ ...getLastTaskDate(), name: "New Task" });
  };

  return (
    <Box sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
      <Box sx={{ mb: "calc(100vh/2)" }}>
        <Grid container sx={{ width: "100%", maxWidth: "800px", m: "auto" }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs="auto">
              <Typography variant="h6">
                {tasks.filter((task) => task.status !== "CO").length > 0
                  ? "Todo"
                  : "Completed"}
              </Typography>
            </Grid>

            <Grid item xs="auto">
              <Button
                variant="contained"
                color="secondary"
                aria-label="add"
                sx={{ mb: ".25rem" }}
                onClick={addNewTask}
                size="small"
                id="add-task-button"
              >
                <AddOutlinedIcon />
                Add Task
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {tasks.length > 0 ? (
              <>
                {tasks
                  .filter((task) => task.status !== "CO")
                  .sort(taskSorter)
                  .map((task) => (
                    <Task
                      key={task.uuid}
                      task={task}
                      id={"task-" + task.uuid}
                      // event={event}
                      // updateTask={updateTask}
                      // deleteTask={deleteTask}
                    />
                  ))}
                {tasks.filter((task) => task.status === "CO").length > 0 ? (
                  <>
                    {/* <Divider sx={{ width: "100%", m: "1rem" }} /> */}
                    {tasks.filter((task) => task.status !== "CO").length > 0 ? (
                      <Typography variant="h6">Completed</Typography>
                    ) : null}

                    {tasks
                      .filter((task) => task.status === "CO")
                      .sort(taskSorter)
                      .map((task) => (
                        <Task
                          key={task.uuid}
                          task={task}
                          id={"task-" + task.uuid}
                          // event={props.event}
                          // updateTask={props.updateTask}
                          // deleteTask={props.deleteTask}
                        />
                      ))}
                  </>
                ) : null}
              </>
            ) : (
              <Box>
                <Box
                  sx={{
                    borderStyle: "dotted",
                    borderColor: "info.light",
                    p: "2rem",
                    borderRadius: "7.5px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "info.light",
                      fontWeight: "normal",
                    }}
                    variant="h6"
                  >
                    No tasks have been added yet.{" "}
                    <Link
                      onClick={addNewTask}
                      color="inherit"
                      sx={{ "&:hover": { cursor: "pointer" } }}
                      id="add-task-link"
                    >
                      Add Task
                    </Link>
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export const Task = (props) => {
  const locale = useLocaleContext();
  const [deleteTask, event, updateTask] = useAccountState((state) => [
    state.deleteTask,
    state.event,
    state.updateTask,
  ]);
  const updateTask_ = (data) => {
    let d = { ...data, uuid: props.task.uuid };
    updateTask(d);
  };

  const [editingDueDate, setEditingDueDate] = React.useState(false);
  const [deletingTask, setDeletingTask] = React.useState(false);

  const isLate = () => {
    return (
      getDueDate(
        props.task.due_date_offset_days,
        props.task.due_date_offset_direction,
        props.task.event?.date ? props.task.event.date : event.date
      ) < new Date() && !event.is_template
    );
  };

  const borderStyle = () => {
    let is_late = isLate();
    switch (props.task.status) {
      case "IN":
        if (is_late) {
          return { borderLeftColor: "error.light" };
        } else {
          return { borderLeftColor: "info.light" };
        }
      case "IP":
        if (is_late) {
          return { borderLeftColor: "error.light" };
        } else {
          return {
            borderLeftColor: "secondary.lightest",
          };
        }
      case "CO":
        return { borderLeftColor: "secondary.main" };
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        m: "auto",
        mt: ".25rem",
        borderTop: "none",
        borderBottom: "none",
        borderRight: "none",
        borderLeft: "5px solid",
        ...borderStyle(),
      }}
    >
      <Box sx={{ p: ".125rem" }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs="auto">
            <TaskStatus
              task={props.task}
              updateTask={updateTask_}
              id={props.id + "-status"}
            />
          </Grid>
          {/* <Grid item xs={11} container alignItems="center" spacing={1}> */}
          <Grid item xs={3}>
            <TaskText id="name" task={props.task} updateTask={updateTask_} />
          </Grid>
          <Grid item xs={3} container alignItems="center">
            <Grid item xs="auto">
              <Typography
                color={
                  isLate() && props.task.status !== "CO" && !event.is_template
                    ? "error.main"
                    : undefined
                }
              >
                {event.is_template
                  ? parseInt(props.task.due_date_offset_days) +
                    " days " +
                    (props.task.due_date_offset_direction === "AF"
                      ? "after"
                      : "before")
                  : formatDate(
                      getDueDate(
                        props.task.due_date_offset_days,
                        props.task.due_date_offset_direction,
                        props.task.event?.date
                          ? props.task.event.date
                          : event.date
                      ),
                      locale
                    )}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                onClick={() => setEditingDueDate(true)}
                id={props.task.uuid + "-edit-date-button"}
              >
                <EventOutlinedIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={3} container alignItems="center">
            {props.task_list ? (
              <Typography>
                <Link
                  sx={{ color: "inherit" }}
                  href={
                    process.env.REACT_APP_BASE_URL +
                    "/event/" +
                    props.task.event.uuid
                  }
                  id={props.task.uuid + "-event-link"}
                >
                  {props.task.event.name}
                </Link>
              </Typography>
            ) : (
              <TaskText
                id="description"
                task={props.task}
                updateTask={updateTask_}
                placeholder="Description..."
              />
            )}
          </Grid>
          <Grid item xs="auto" container justifyContent="flex-end">
            <TaskNotificationSwitch
              task={props.task}
              updateTask={updateTask_}
            />
            <Grid item xs="auto">
              <IconButton
                onClick={() => setDeletingTask(true)}
                id={props.task.uuid + "-delete-button"}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <TaskDueDateDialog
        open={editingDueDate}
        handleCancel={() => {
          setEditingDueDate(false);
        }}
        task={props.task}
        event={event}
        updateTask={updateTask_}
      />
      <DeleteTaskDialog
        task={props.task}
        open={deletingTask}
        handleCancel={() => {
          setDeletingTask(false);
        }}
        handleSubmit={() => {
          setDeletingTask(false);
          deleteTask(props.task.uuid);
        }}
      />
    </Paper>
  );
};

const TaskText = (props) => {
  const [editing_name, setEditingName] = React.useState(false);
  const name_input = React.useRef(null);
  const updateName = () => {
    toggleEditingName();
    props.updateTask({
      [props.id]: name_input.current.value,
    });
  };
  const toggleEditingName = () => {
    setEditingName(!editing_name);
  };
  return (
    <Box sx={{ width: "100%" }}>
      {editing_name ? (
        <OutlinedInput
          id={props.task.uuid + "-" + props.id + "-input"}
          inputRef={name_input}
          onBlur={updateName}
          defaultValue={props.task[props.id]}
          label={props.label}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEditingName();
                }}
                edge="end"
                id={props.task.uuid + "-" + props.id + "-input-cancel"}
              >
                <CloseOutlinedIcon fontSize=".25rem" />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  updateName();
                }}
                id={props.task.uuid + "-" + props.id + "-input-submit"}
              >
                <CheckOutlinedIcon fontSize=".25rem" />
              </IconButton>
            </InputAdornment>
          }
        />
      ) : (
        <Tooltip
          title={props.task[props.id]}
          placement="bottom"
          enterDelay={750}
        >
          <Typography
            onClick={(e) => {
              e.stopPropagation();
              toggleEditingName();
            }}
            noWrap
            color={
              props.task.status === "CO" || !props.task[props.id]
                ? "text.secondary"
                : undefined
            }
            sx={{ fontStyle: !props.task[props.id] ? "italic" : undefined }}
          >
            {!props.task[props.id] && props.placeholder
              ? props.placeholder
              : props.task[props.id]}
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                toggleEditingName();
              }}
              id={props.task.uuid + "-" + props.id + "-edit-button"}
            >
              <EditOutlinedIcon fontSize=".25rem" />
            </IconButton>
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

const TaskStatus = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const tooltipTitle = () => {
    switch (props.task.status) {
      case "IN":
        return "Incomplete";
      case "IP":
        return "In Progress";
      case "CO":
        return "Complete";
    }
  };
  return (
    <>
      <Tooltip title={tooltipTitle()}>
        <IconButton onClick={handleClick} id={props.id + "-status-button"}>
          {props.task.status === "IN" ? (
            <CircleOutlinedIcon />
          ) : props.task.status === "IP" ? (
            <HourglassBottomOutlinedIcon />
          ) : (
            <CheckCircleOutlineOutlinedIcon />
          )}
        </IconButton>
      </Tooltip>
      <StatusMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        updateTask={props.updateTask}
        id={props.id + "-status-menu"}
      />
    </>
  );
};

const StatusMenu = (props) => {
  const handleClick = (val) => {
    props.updateTask({ status: val });
    props.onClose();
  };
  return (
    <Menu {...props}>
      <MenuList dense>
        <MenuItem
          onClick={() => {
            handleClick("IN");
          }}
          id={props.id + "menu-item-incomplete"}
        >
          <ListItemIcon>
            <CircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Incomplete</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClick("IP");
          }}
          id={props.id + "menu-item-in-progress"}
        >
          <ListItemIcon>
            <HourglassBottomOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>In Progress</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClick("CO");
          }}
          id={props.id + "menu-item-complete"}
        >
          <ListItemIcon>
            <CheckCircleOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Complete</ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const TaskDueDateDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Due Date</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ p: "1rem" }}>
          <Grid item xs={12} sm={4}>
            <TaskDueDateOffset
              task={props.task}
              updateTask={props.updateTask}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TaskDueDateDirection
              task={props.task}
              updateTask={props.updateTask}
            />
          </Grid>
          {!props.event.is_template ? (
            <Grid item xs={12} sm={4}>
              <TaskDueDate
                task={props.task}
                updateTask={props.updateTask}
                event={props.event}
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          variant="contained"
          color="secondary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TaskDueDateOffset = (props) => {
  const [local, setLocal] = React.useState(props.task.due_date_offset_days);
  const handleChange = (e) => {
    setLocal(e.target.value);
  };
  const handleBlur = () => {
    props.updateTask({
      due_date_offset_days: parseInt(local),
    });
  };
  React.useEffect(() => {
    if (props.task.due_date_offset_days != local) {
      setLocal(props.task.due_date_offset_days);
    }
  }, [props.task.due_date_offset_days]);
  return (
    <TextField
      label="Due Date Offset (days)"
      value={local}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        endAdornment: (
          <Tooltip title="Number of days before or after the event date.">
            <HelpOutlineOutlinedIcon fontSize="small" color="info" />
          </Tooltip>
        ),
      }}
    />
  );
};

const TaskDueDateDirection = (props) => {
  const handleChange = (e) => {
    props.updateTask({ due_date_offset_direction: e.target.value });
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="due-date-direction-label">Before/After Event</InputLabel>
      <Select
        labelId="due-date-direction-label"
        id="due-date-direction"
        value={props.task.due_date_offset_direction}
        label="Before/After Event"
        onChange={handleChange}
      >
        <MenuItem value={"BE"}>Before</MenuItem>
        <MenuItem value={"AF"}>After</MenuItem>
      </Select>
    </FormControl>
  );
};

const TaskDueDate = (props) => {
  const [dueDate, setDueDate] = React.useState(
    getDueDate(
      props.task.due_date_offset_days,
      props.task.due_date_offset_direction,
      props.task.event?.date ? props.task.event.date : props.event.date
    )
  );

  React.useEffect(() => {
    setDueDate(
      getDueDate(
        props.task.due_date_offset_days,
        props.task.due_date_offset_direction,
        props.task.event?.date ? props.task.event.date : props.event.date
      )
    );
  }, [props.task.due_date_offset_days, props.task.due_date_offset_direction]);

  const handleChange = (due_date) => {
    setDueDate(due_date);
    let event_date = new Date(
      props.event.date ? props.event.date : props.task.event.date
    );
    let offset = due_date.getTime() - event_date.getTime();
    let direction = offset < 0 ? "BE" : "AF";
    offset /= 1000 * 3600 * 24;
    props.updateTask({
      due_date_offset_days: Math.abs(offset.toFixed(0)),
      due_date_offset_direction: direction,
    });
  };
  return (
    <DatePicker
      label="Due Date"
      value={dueDate}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

const TaskNotificationSwitch = (props) => {
  const handleChange = () => {
    props.updateTask({
      notification_enabled: !props.task.notification_enabled,
    });
  };
  return (
    <>
      <Grid item xs="auto">
        <Tooltip
          title={
            props.task.notification_enabled
              ? "Notifications Are Enabled"
              : "Notifications Are Disabled"
          }
        >
          <IconButton
            onClick={handleChange}
            id={props.task.uuid + "-toggle-notification-button"}
          >
            {props.task.notification_enabled ? (
              <NotificationsActiveOutlinedIcon color="secondary" />
            ) : (
              <NotificationsNoneOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  );
};

const DeleteTaskDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Task</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete task "{props.task.name}"?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="delete-task-cancel-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={props.handleSubmit}
          id="delete-task-submit-button"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(Tasks);
