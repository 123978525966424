import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  getDataPageData,
  addTaxGroupApi,
  updateTaxGroupApi,
  deleteTaxGroupApi,
} from "../api/DataApi";

import {
  Typography,
  Box,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";

import {
  addEventItemApi,
  deleteUserEvent,
  removeEventItemApi,
  updateEventItemApi,
  updateItemApi,
} from "../api/EventsApi";
import TabPanel from "./TabPanel";
import EventTemplates from "./EventTemplates";
import CustomItems from "./CustomItems";
import TaxProfileSettings from "./TaxProfileSettings";
import { useAccountState } from "../state/store";
import Forms from "./Forms";
import FormSubmissions from "./FormSubmissions";
import RecipeTemplates from "./RecipeTemplates";
import { ImageLibrary } from "./ImageLibrary";
import { TagExplorer } from "./Tags";

const Data = (props) => {
  const [
    initializeCustomItems,
    initializeEventItems,
    initializeEventTemplates,
    initializeForms,
    initializeFormSubmissions,
    initializeImages,
    initializeSelectableItems,
    initializeTags,
    initializeUser,
  ] = useAccountState((state) => [
    state.initializeCustomItems,
    state.initializeEventItems,
    state.initializeEventTemplates,
    state.initializeForms,
    state.initializeFormSubmissions,
    state.initializeImages,
    state.initializeSelectableItems,
    state.initializeTags,
    state.initializeUser,
  ]);
  const [loaded, setLoaded] = React.useState(false);
  let qs = new URLSearchParams(props.location.search);
  const [formSubmissionUuid, setFormSubmissionUuid] = React.useState(
    qs.has("form_submission_uuid") ? qs.get("form_submission_uuid") : undefined
  );
  const initialTab = qs.has("initial_tab") ? qs.get("initial_tab") : undefined;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabsValue, setTabsValue] = React.useState(
    formSubmissionUuid
      ? "form-submissions"
      : initialTab
      ? initialTab
      : "event-templates"
  );
  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };
  const [customItems, setCustomItems] = React.useState([]);
  const [customFlowers, setCustomFlowers] = React.useState([]);
  const [customMaterials, setCustomMaterials] = React.useState([]);
  const [eventTemplates, setEventTemplates] = React.useState([]);
  const [recipeTemplates, setRecipeTemplates] = React.useState([]);
  const [taxProfiles, setTaxProfiles] = React.useState([]);
  const [taxGroups, setTaxGroups] = React.useState([]);

  const deleteEventTemplate = (template_uuid) => {
    deleteUserEvent(template_uuid).then((resp) => {
      let event_templates = eventTemplates.filter((template) => {
        return template.uuid !== template_uuid;
      });
      setEventTemplates(event_templates);
    });
  };

  const deleteTax = (tax_uuid) => {
    setTaxProfiles(taxProfiles.filter((tax) => tax.uuid !== tax_uuid));
    removeEventItemApi(tax_uuid).then((resp) => {
      console.log(resp);
    });
  };

  const addTax = (data) => {
    addEventItemApi({ ...data, event_item_type: "TA" }).then((resp) => {
      const new_tax_profiles = [...taxProfiles, resp.data];
      setTaxProfiles(new_tax_profiles);
    });
  };

  const addTaxGroup = (data) => {
    addTaxGroupApi(data).then((resp) => {
      const new_tax_groups = [...taxGroups, resp.data];
      setTaxGroups(new_tax_groups);
    });
  };

  const updateTaxGroup = (data) => {
    const ind = taxGroups.findIndex((tax) => tax.uuid === data.uuid);
    const new_tax_groups = [
      ...taxGroups.slice(0, ind),
      data,
      ...taxGroups.slice(ind + 1),
    ];
    setTaxGroups(new_tax_groups);
    updateTaxGroupApi(data).then((resp) => {
      console.log(resp);
    });
  };

  const deleteTaxGroup = (tax_uuid) => {
    setTaxGroups(taxGroups.filter((tax) => tax.uuid !== tax_uuid));
    deleteTaxGroupApi({ uuid: tax_uuid }).then((resp) => {
      console.log(resp);
    });
  };

  const updateTax = (data) => {
    updateEventItemApi(data).then((resp) => {
      const ind = taxProfiles.findIndex((tax) => tax.uuid === data.uuid);
      const new_tax_profiles = [
        ...taxProfiles.slice(0, ind),
        resp.data,
        ...taxProfiles.slice(ind + 1, taxProfiles.length),
      ];
      setTaxProfiles(new_tax_profiles);
    });
  };

  React.useEffect(() => {
    initializeUser(props.user);
    getDataPageData().then((resp) => {
      initializeCustomItems(resp.data.custom_items);
      setEventTemplates(resp.data.event_templates);
      initializeEventItems(resp.data.recipe_templates);
      initializeEventTemplates(resp.data.event_templates);
      setTaxProfiles(resp.data.tax_profiles);
      setTaxGroups(resp.data.tax_groups);
      setCustomFlowers(
        resp.data.custom_items.filter((item) => item.item_type === "FL")
      );
      setCustomMaterials(
        resp.data.custom_items.filter((item) => item.item_type === "MA")
      );
      initializeSelectableItems(resp.data.selectable_items);
      initializeForms(resp.data.forms);
      initializeFormSubmissions(resp.data.form_submissions);
      initializeImages(resp.data.library_images);
      initializeTags(resp.data.tags);
      setLoaded(true);
      document.title = "Data";
    });
  }, []);

  React.useEffect(() => {
    setCustomFlowers(customItems.filter((item) => item.item_type === "FL"));
    setCustomMaterials(customItems.filter((item) => item.item_type === "MA"));
  }, [customItems]);

  return loaded ? (
    <Box>
      <Box
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",
          mt: "1rem",
          overflow: "auto",
          scrollMargin: "50vh",
          height: "92vh",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={fullScreen ? { ml: ".5rem" } : null}>
              Data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={tabsValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons={false}
              id="data-tabs"
            >
              <Tab
                label="Event Templates"
                value="event-templates"
                id="event-templates-tab"
              />
              <Tab
                label="Recipe Templates"
                value="recipe-templates"
                id="recipe-templates-tab"
              />
              <Tab label="Images" value="images" id="images-tab" />
              <Tab label="Flowers" value="flowers" id="flowers-tab" />
              <Tab label="Materials" value="materials" id="materials-tab" />
              <Tab
                label="Tax Profiles"
                value="tax_profiles"
                id="tax-profiles-tab"
              />
              <Tab label="Forms" value="forms" id="forms-tab" />
              <Tab
                label="Form Submissions"
                value="form-submissions"
                id="form-submissions-tab"
              />
              <Tab label="Tags" value="tags" id="tags" />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                overflow: "auto",
                height: "calc(100vh - 125px)",
              }}
            >
              <Box
                sx={{
                  mb: "calc(100vh/2)",
                }}
              >
                <TabPanel value={tabsValue} index="event-templates">
                  <EventTemplates
                    templates={eventTemplates}
                    deleteEventTemplate={deleteEventTemplate}
                  />
                </TabPanel>
                <TabPanel value={tabsValue} index="recipe-templates">
                  <RecipeTemplates />
                </TabPanel>
                <TabPanel value={tabsValue} index="images">
                  <ImageLibrary />
                </TabPanel>
                <TabPanel value={tabsValue} index="flowers">
                  <CustomItems item_type="FL" />
                </TabPanel>
                <TabPanel value={tabsValue} index="materials">
                  <CustomItems item_type="MA" />
                </TabPanel>
                <TabPanel value={tabsValue} index="tax_profiles">
                  <TaxProfileSettings
                    tax_profiles={taxProfiles}
                    tax_groups={taxGroups}
                    updateTax={updateTax}
                    addTax={addTax}
                    deleteTax={deleteTax}
                    addTaxGroup={addTaxGroup}
                    updateTaxGroup={updateTaxGroup}
                    deleteTaxGroup={deleteTaxGroup}
                  />
                </TabPanel>
                <TabPanel value={tabsValue} index="forms">
                  <Forms />
                </TabPanel>
                <TabPanel value={tabsValue} index="form-submissions">
                  <FormSubmissions
                    initial_form_submission_uuid={formSubmissionUuid}
                    setFormSubmissionUuid={setFormSubmissionUuid}
                  />
                </TabPanel>
                <TabPanel value={tabsValue} index="tags">
                  <TagExplorer />
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: "flex", pt: "30%" }}>
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  );
};

export default withRouter(Data);
