import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Paper, Grid, Typography, Button, TextField } from "@mui/material";
import { Logo } from "./Navbar";
import { changePasswordApi } from "../api/ProfileApi";
import { withRouter } from "react-router-dom";

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters.")
    .max(50, "Password cannot contain more than 50 characters")
    .required("Must provide a password"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords do not match",
  ),
});

const ChangePasswordForm = (props) => {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [hash] = React.useState(props.match.params.hash);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    onSubmit: (values) => {
      changePasswordApi({
        password: values.password,
        verification_hash: hash,
      }).then((resp) => {
        if (resp.data.success) {
          setSuccess(true);
        } else {
          setError(true);
        }
      });
    },
  });

  return (
    <Box
      sx={{
        width: "300px",
        height: "450px",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginLeft: "-150px",
        marginTop: "-225px",
      }}
    >
      <Box>
        <Logo withText imgStyle={{ maxHeight: "45px", margin: "auto" }} />
      </Box>
      <Paper
        sx={{
          marginTop: "1rem",
          marginLeft: "auto",
          marginRight: "auto",
          width: "80%",
          padding: ".5rem",
        }}
      >
        {!success ? (
          <Grid container justifyContent="space-around" rowSpacing={2}>
            <Grid item>
              <Typography>Reset Password</Typography>
            </Grid>
            {error ? (
              <Grid item>
                <Typography color="error.main">Reset Password</Typography>
              </Grid>
            ) : null}
            <form
              style={{ display: "contents" }}
              onSubmit={formik.handleSubmit}
            >
              <Grid item xs={12}>
                <SignupLoginField
                  name="password"
                  type="password"
                  label="Password"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <SignupLoginField
                  name="passwordConfirmation"
                  type="password"
                  label="Repeat Password"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "100%",
                    marginTop: "1rem",
                  }}
                  color="secondary"
                >
                  Reset Password
                </Button>
              </Grid>
            </form>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Typography textAlign="center">Success!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  marginTop: "1rem",
                }}
                color="secondary"
                href="/"
              >
                Return to Login
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Box>
  );
};

export const SignupLoginField = (props) => {
  return (
    <TextField
      {...props}
      id={props.name}
      value={props.formik.values[props.name]}
      onChange={props.formik.handleChange}
      error={
        props.formik.touched[props.name] &&
        Boolean(props.formik.errors[props.name])
      }
      helperText={
        props.formik.touched[props.name] && props.formik.errors[props.name]
      }
      variant="standard"
      fullWidth
    />
  );
};

export const FormError = (props) => {
  return <Typography sx={{ color: "error.main" }}>{props.children}</Typography>;
};

export default withRouter(ChangePasswordForm);
