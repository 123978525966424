export const socketsSlice = (set, get) => ({
  session_id: makeid(6),
  setStateFromSocket: (data) => {
    // This logic would prevent an event from becoming locked via another tab but we aren't sure we want that behavior
    // if ('event' in data) {
    //     let current_event = get().event;
    //     data.event.locked = current_event.locked;
    // }
    set({ ...data, _FROM_SOCKET: true });
  },
  setWs: (ws) => set({ ws }),
  ws: undefined,
});

function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
