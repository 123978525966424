import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Box } from "@mui/material";
import LoginForm from "./LoginForm";
import PasswordResetForm from "./PasswordResetForm";
import SignupForm from "./SignupForm";

class SignupLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // login: props.referral_code ? false : true,
      login: props.login,
      forgot_password: false,
    };

    this.toggleLogin = this.toggleLogin.bind(this);
    this.toggleForgotPassowrd = this.toggleForgotPassowrd.bind(this);
  }

  toggleLogin() {
    this.setState({
      login: !this.state.login,
    });
  }

  toggleForgotPassowrd() {
    this.setState({
      forgot_password: !this.state.forgot_password,
    });
  }

  render() {
    return (
      <Box minHeight="inherit">
        {this.state.login ? (
          !this.state.forgot_password ? (
            <LoginForm
              handleLogin={this.props.handleLogin}
              bad_credentials={this.props.bad_credentials}
              toggle_login={this.toggleLogin}
              toggle_forgot_password={this.toggleForgotPassowrd}
              loading={this.props.loading}
            />
          ) : (
            <PasswordResetForm
              handlePasswordReset={this.props.handlePasswordReset}
              toggle_forgot_password={this.toggleForgotPassowrd}
              loading={this.props.loading}
            />
          )
        ) : (
          <SignupForm
            handleSignup={this.props.handleSignup}
            username_already_exists={this.props.username_already_exists}
            toggle_login={this.toggleLogin}
            signup_error={this.props.signup_error}
            loading={this.props.loading}
            referral_code={this.props.referral_code}
          />
        )}
      </Box>
    );
  }
}

export default withRouter(SignupLogin);
