import React from "react";

import {
  Select,
  MenuItem,
  Grid,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  FormLabel,
  Tooltip,
} from "@mui/material";

import {
  itemUnitPluralName,
  itemGroupNamePlural,
  flowerGroupOptions,
  materialGroupOptions,
  itemUnitName,
} from "../utils/item_utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VendorDialog from "./VendorDialog";
import { getItemPriceApi } from "../api/EventsApi";
import { vendor_location_display_name } from "../utils/vendor_utils";
import { useAccountState } from "../state/store";

const ItemPriceFields = (props) => {
  const isFlower = props.item.item.item_type === "FL";
  const formatPrice = (price) => {
    return Number.parseFloat(price).toFixed(2);
  };
  const [event_version] = useAccountState((state) => [state.event_version]);
  let initial_value =
    props.formik.values.price_per_group /
    props.formik.values.individual_per_group;
  const [price_per_individual, setPricePerIndividual] = React.useState(
    formatPrice(initial_value ? initial_value : 0),
  );
  const [changing_vendor_location, setChangingVendorLocation] = React.useState(
    props.updatingVendor,
  );

  const handleVendorLocationChange = (vendor_location) => {
    props.formik.setFieldValue("vendor_location", vendor_location);
    setChangingVendorLocation(false);
    if (props.item && props.item.uuid) {
      getItemPriceApi(
        props.item.item.uuid,
        vendor_location.uuid,
        event_version.uuid,
      ).then((resp) => {
        props.formik.setFieldValue("group_name", resp.data.group_name);
        props.formik.setFieldValue(
          "individual_per_group",
          resp.data.individual_per_group,
        );
        props.formik.setFieldValue(
          "price_per_group",
          resp.data.price_per_group,
        );
        props.formik.setFieldValue(
          "minimum_group_quantity",
          resp.data.minimum_group_quantity,
        );
        setPricePerIndividual(
          formatPrice(
            resp.data.individual_per_group > 0
              ? resp.data.price_per_group / resp.data.individual_per_group
              : 0,
          ),
        );
        setChangingVendorLocation(false);
      });
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-end" sx={{ m: "auto" }}>
      <Grid item xs={12} container alignItems="center">
        <Grid item xs={12}>
          <FormLabel>Vendor</FormLabel>
        </Grid>
        <Grid item xs={11}>
          <Tooltip
            title={
              props.formik.values.vendor_location
                ? vendor_location_display_name(
                    props.formik.values.vendor_location,
                  )
                : "No Vendor Selected"
            }
          >
            <Typography noWrap>
              {props.formik.values.vendor_location
                ? vendor_location_display_name(
                    props.formik.values.vendor_location,
                  )
                : "No Vendor Selected"}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => setChangingVendorLocation(true)}
            size="small"
          >
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
      <VendorDialog
        open={changing_vendor_location}
        handleCancel={() => setChangingVendorLocation(false)}
        handleSubmit={handleVendorLocationChange}
        item_uuid={props.item.item.uuid}
        event_version_uuid={event_version.uuid}
      />
      <Grid item xs={6}>
        <InputLabel sx={{ fontSize: ".75rem" }}>Group Name</InputLabel>
        <Select
          name="group_name"
          label="Group Name"
          id="group_name"
          value={props.formik.values.group_name}
          onChange={props.formik.handleChange}
          error={
            props.formik.touched.group_name &&
            Boolean(props.formik.errors.group_name)
          }
          helperText={
            props.formik.touched.group_name && props.formik.errors.group_name
          }
          variant="standard"
          fullWidth
        >
          {Object.entries(
            isFlower ? flowerGroupOptions : materialGroupOptions,
          ).map(([key, group]) => (
            <MenuItem key={key} value={key}>
              {group.label}
            </MenuItem>
          ))}

          {/* <MenuItem value="ST">Stems</MenuItem>
          <MenuItem value="BR">Branches</MenuItem>
          <MenuItem value="PL">Plants</MenuItem>
          <MenuItem value="BU">Bunches</MenuItem>
          <MenuItem value="BO">Boxes</MenuItem> */}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="individual_per_group"
          label={
            itemUnitPluralName(props.item.item) +
            " per " +
            itemGroupNamePlural(1, props.formik.values.group_name)
          }
          id="individual_per_group"
          value={props.formik.values.individual_per_group}
          onChange={(e, val) => {
            props.formik.setFieldValue("individual_per_group", e.target.value);
            if (
              (props.formik.values.price_per_group > 0) &
              (e.target.value > 0)
            ) {
              setPricePerIndividual(
                formatPrice(
                  props.formik.values.price_per_group / e.target.value,
                ),
              );
            } else if (price_per_individual > 0) {
              props.formik.setFieldValue(
                "price_per_group",
                e.target.value * price_per_individual,
              );
            }
          }}
          error={
            props.formik.touched.individual_per_group &&
            Boolean(props.formik.errors.individual_per_group)
          }
          helperText={
            props.formik.touched.individual_per_group &&
            props.formik.errors.individual_per_group
          }
          variant="standard"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={"Cost per " + itemUnitName(props.item.item)}
          value={price_per_individual}
          onChange={(e, val) => {
            setPricePerIndividual(e.target.value);
            if (props.formik.values.individual_per_group > 0) {
              props.formik.setFieldValue(
                "price_per_group",
                formatPrice(
                  props.formik.values.individual_per_group * e.target.value,
                ),
              );
            } else if (
              (props.formik.values.price_per_group > 0) &
              (e.target.value > 0)
            ) {
              props.formik.setFieldValue(
                "individual_per_group",
                props.formik.values.price_per_group / e.target.value,
              );
            }
          }}
          onBlur={(e, val) => {
            setPricePerIndividual(formatPrice(e.target.value));
          }}
          error={
            props.formik.touched.price_per_individual &&
            Boolean(props.formik.errors.price_per_individual)
          }
          helperText={
            props.formik.touched.price_per_individual &&
            props.formik.errors.price_per_individual
          }
          variant="standard"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="price_per_group"
          label={
            "Cost per " + itemGroupNamePlural(1, props.formik.values.group_name)
          }
          id="price_per_group"
          value={props.formik.values.price_per_group}
          // onChange={props.formik.handleChange}
          onChange={(e, val) => {
            props.formik.setFieldValue("price_per_group", e.target.value);
            if (props.formik.values.individual_per_group > 0) {
              setPricePerIndividual(
                formatPrice(
                  props.formik.values.individual_per_group
                    ? e.target.value / props.formik.values.individual_per_group
                    : 0,
                ),
              );
            } else if (price_per_individual > 0) {
              props.formik.setFieldValue(
                "individual_per_group",
                e.target.value / price_per_individual,
              );
            }
          }}
          error={
            props.formik.touched.price_per_group &&
            Boolean(props.formik.errors.price_per_group)
          }
          helperText={
            props.formik.touched.price_per_group &&
            props.formik.errors.price_per_group
          }
          variant="standard"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="minimum_group_quantity"
          label={
            "Minimum " +
            itemGroupNamePlural(1, props.formik.values.group_name) +
            " order quantity"
          }
          id="minimum_group_quantity"
          value={props.formik.values.minimum_group_quantity}
          onChange={props.formik.handleChange}
          error={
            props.formik.touched.minimum_group_quantity &&
            Boolean(props.formik.errors.minimum_group_quantity)
          }
          helperText={
            props.formik.touched.minimum_group_quantity &&
            props.formik.errors.minimum_group_quantity
          }
          variant="standard"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default ItemPriceFields;
