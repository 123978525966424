import { unsecureAPI } from "../api";

export const createEventContractApi = (data) => {
  return unsecureAPI.post("api/contracts/contract/", data, {});
};

export const getEventContractsApi = (proposal_uuid) => {
  return unsecureAPI.get("api/contracts/contract/", {
    params: { proposal_uuid: proposal_uuid },
  });
};

export const deleteEventContractApi = (uuid) => {
  return unsecureAPI.delete("api/contracts/contract/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: { uuid: uuid },
  });
};

export const resendEventContractToClientApi = (data) => {
  return unsecureAPI.post("api/contracts/contract/resend_to_client", data, {});
};

export const getDefaultContractSections = () => {
  return unsecureAPI.get("api/contract_section/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addContractSectionApi = (data) => {
  return unsecureAPI.post("api/contract_section/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateContractSectionApi = (data) => {
  return unsecureAPI.put("api/contract_section/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteContractSectionApi = (data) => {
  return unsecureAPI.delete("api/contract_section/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: data,
  });
};

export const orderContractSectionApi = (data) => {
  return unsecureAPI.put("api/contract_section_order/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getEsigContractDetailsApi = (contract_id, florist) => {
  return unsecureAPI.get("api/contracts/contract_pdf_url/", {
    params: { contract_id: contract_id, florist: florist },
  });
};

export const copyContractFromSettingsApi = (event_uuid) => {
  return unsecureAPI.post(
    "api/copy_contract_from_settings/",
    {
      event_uuid: event_uuid,
    },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }
  );
};
