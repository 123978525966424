import { unsecureAPI } from "../api";

export const addFormApi = (data) => {
  return unsecureAPI.post(`api/forms/`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteFormApi = (data) => {
  return unsecureAPI.delete(`api/forms/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data,
  });
};

export const getFormApi = (uuid) => {
  return unsecureAPI.get(`api/forms/${uuid}`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateFormApi = (data) => {
  return unsecureAPI.put(`api/forms/`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getFormPublicApi = (uuid) => {
  return unsecureAPI.get(`api/forms_public/${uuid}`);
};

export const submitFormPublicApi = (data) => {
  return unsecureAPI.post(`api/forms_public/submit/`, data);
};

export const deleteFormSubmissionApi = (data) => {
  return unsecureAPI.delete(`api/form_submission/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data,
  });
};
