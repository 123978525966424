import { Decimal } from "decimal.js";
import _ from "lodash";
import { decimalConfig } from "../utils/utils";
import {
  addInventoryAddressApi,
  addInventoryLocationApi,
  deleteInventoryAddressApi,
  deleteInventoryLocationApi,
  updateInventoryAddressApi,
  updateInventoryLocationApi,
} from "../api/InventoryApi";

Decimal.set(decimalConfig);

export const inventoryLocationsSlice = (set, get) => ({
  inventoryAddresses: [],
  inventoryLocations: [],
  addInventoryAddress: (data, cb) => {
    addInventoryAddressApi(data).then((res) => {
      set({
        inventoryAddresses: [...get().inventoryAddresses, res.data],
      });

      if (cb) cb(res.data);
    });
  },
  addInventoryLocation: (data, cb) => {
    addInventoryLocationApi(data).then((res) => {
      set({
        inventoryLocations: [...get().inventoryLocations, res.data],
      });
      if (cb) cb(res.data);
    });
  },
  deleteInventoryAddress: (uuid) => {
    const inventoryAddresses = get().inventoryAddresses;
    const index = _.findIndex(inventoryAddresses, { uuid: uuid });
    let inventory_locations = get().inventoryLocations.filter(
      (il) => il.inventory_address?.uuid !== uuid,
    );
    let inventoryItems = get().inventoryItems.map((ii) =>
      ii.inventory_location?.inventory_address?.uuid === uuid
        ? { ...ii, inventory_location: undefined }
        : ii,
    );
    set({
      inventoryAddresses: [
        ...inventoryAddresses.slice(0, index),
        ...inventoryAddresses.slice(index + 1),
      ],
      inventoryLocations: inventory_locations,
      inventoryItems: inventoryItems,
    });
    deleteInventoryAddressApi(uuid).then((resp) => {
      console.log(resp);
    });
  },
  deleteInventoryLocation: (uuid) => {
    const inventoryLocations = get().inventoryLocations;
    const index = _.findIndex(inventoryLocations, { uuid: uuid });
    let inventory_items = get().inventoryItems.map((ii) =>
      ii.inventory_location?.uuid === uuid
        ? { ...ii, inventory_location: null }
        : ii,
    );
    set({
      inventoryLocations: [
        ...inventoryLocations.slice(0, index),
        ...inventoryLocations.slice(index + 1),
      ],
      inventoryItems: inventory_items,
    });
    deleteInventoryLocationApi(uuid).then((resp) => {
      console.log(resp);
    });
  },
  initializeInventoryAddresses: (inventoryAddresses) => {
    set({ inventoryAddresses: inventoryAddresses });
  },
  initializeInventoryLocations: (inventoryLocations) => {
    set({ inventoryLocations: inventoryLocations });
  },
  updateInventoryAddress: (data) => {
    const inventoryAddresses = get().inventoryAddresses;
    const index = _.findIndex(inventoryAddresses, { uuid: data.uuid });
    let inventory_address = inventoryAddresses[index];
    let new_inventory_address = { ...inventory_address, ...data };
    let inventory_items = get().inventoryItems.map((ii) =>
      ii.inventory_location?.inventory_address?.uuid === data.uuid
        ? {
            ...ii,
            inventory_location: {
              ...ii.inventory_location,
              inventory_address: new_inventory_address,
            },
          }
        : ii,
    );
    set({
      inventoryAddresses: [
        ...inventoryAddresses.slice(0, index),
        new_inventory_address,
        ...inventoryAddresses.slice(index + 1),
      ],
      inventoryItems: inventory_items,
    });
    updateInventoryAddressApi(data).then((resp) => {
      console.log(resp);
    });
  },
  updateInventoryLocation: (data, cb) => {
    const inventoryLocations = get().inventoryLocations;
    const index = _.findIndex(inventoryLocations, { uuid: data.uuid });
    let inventory_location = inventoryLocations[index];
    let new_inventory_location = { ...inventory_location, ...data };
    let inventory_items = get().inventoryItems.map((ii) =>
      ii.inventory_location.uuid === data.uuid
        ? { ...ii, inventory_location: new_inventory_location }
        : ii,
    );
    set({
      inventoryLocations: [
        ...inventoryLocations.slice(0, index),
        new_inventory_location,
        ...inventoryLocations.slice(index + 1),
      ],
      inventoryItems: inventory_items,
    });
    if (cb) cb(new_inventory_location);
    updateInventoryLocationApi(data).then((resp) => {
      console.log(resp);
    });
  },
});
