import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";

import { addUserEvent, getUserDashboard } from "../api/EventsApi";

import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  Dialog,
  DialogTitle,
  TextField,
  Button,
  DialogContent,
  Autocomplete,
  DialogActions,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";

import EventsSummaryTable from "./EventsSummaryTable";
import ReactPlayer from "react-player";
import Goals from "./Goals";
import { userPlanType } from "../utils/user_utils";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: {},
      templates: [],
      newEventDialogOpen: false,
      loaded: false,
    };
    this.handleAddEvent = this.handleAddEvent.bind(this);
    this.setNewEventDialogOpen = this.setNewEventDialogOpen.bind(this);
    this.unsetNewEventDialogOpen = this.unsetNewEventDialogOpen.bind(this);
  }

  goToEvent(event_uuid) {
    window.location.href = "/event/" + event_uuid;
  }

  numEventsToDisplay = 5;

  componentDidMount() {
    this.updateUserEvents();
  }

  handleAddEvent(data) {
    this.unsetNewEventDialogOpen();
    addUserEvent(data).then((resp) => {
      this.updateUserEvents();
      this.goToEvent(resp.data.uuid);
    });
  }

  setNewEventDialogOpen() {
    this.setState({
      newEventDialogOpen: true,
    });
  }

  unsetNewEventDialogOpen() {
    this.setState({
      newEventDialogOpen: false,
    });
  }

  updateUserEvents() {
    getUserDashboard(this.numEventsToDisplay).then((resp) => {
      this.setState({
        events: resp.data.events,
        templates: resp.data.templates,
        loaded: true,
      });
    });
  }

  userIsNew() {
    let date_joined = new Date(this.props.user.date_joined);
    let today = new Date();
    let time_diff = Math.abs(today - date_joined);
    let days_diff = Math.ceil(time_diff / (1000 * 60 * 60 * 24));

    let free_tier = userPlanType(this.props.user) === "FRE";

    return days_diff < 31 && free_tier;
  }

  render() {
    return (
      <Box display="flex" sx={{ pt: "2rem", pb: "2rem" }}>
        <Box
          sx={{
            m: "auto",
            maxWidth: "800px",
            pl: "1rem",
            pr: "1rem",
          }}
        >
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item sm={6} xs="auto">
              <Typography
                variant="h3"
                sx={{
                  textAlign: "left",
                }}
              >
                Hello, {this.props.user.first_name}!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddEventButton
                handleAddEvent={this.handleAddEvent}
                templates={this.state.templates}
              />
            </Grid>
            <DashboardCard title="Inquiries" icon={<ForumOutlinedIcon />}>
              <Grid item xs={12}>
                <EventsSummaryTable
                  id="inquiries-table"
                  title="New Inquiries"
                  events={this.state.events.IN}
                  href="/events?status=in"
                  loaded={this.state.loaded}
                />
              </Grid>
            </DashboardCard>
            <DashboardCard title="Proposals" icon={<AttachEmailOutlinedIcon />}>
              <Grid item xs={12}>
                <EventsSummaryTable
                  id="proposals-table"
                  count={11}
                  title="Proposals"
                  events={this.state.events.PR}
                  href="/events?status=pr"
                  loaded={this.state.loaded}
                />
              </Grid>
            </DashboardCard>
            <DashboardCard
              title="Booked Events"
              icon={<EventAvailableOutlinedIcon />}
            >
              <Grid item xs={12}>
                <EventsSummaryTable
                  id="booked-events-table"
                  count={11}
                  title="Booked Events"
                  events={this.state.events.BO}
                  href="/events?status=bo"
                  loaded={this.state.loaded}
                />
              </Grid>
            </DashboardCard>
            {this.userIsNew() && (
              <Grid item xs={12} sm={4}>
                <Paper sx={{ padding: "1rem" }}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    spacing={0.7}
                  >
                    <Grid item xs="auto" sx={{ display: "flex" }}>
                      <TitleIcon icon={<PlayCircleFilledWhiteOutlinedIcon />} />
                      <Typography variant="h5" sx={{ ml: ".5rem" }}>
                        Get Started
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Typography
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Welcome to FlowerBuddy!
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Typography textAlign={"center"}>
                        Get started by booking a 10 minute demo with us! By
                        doing so, you'll learn all the basics and even earn $30
                        in credit!
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent={"center"}>
                      <Grid item xs="auto">
                        <Button
                          variant="contained"
                          href="https://www.flowerbuddy.co/demo-scheduler"
                          target="_blank"
                          size="small"
                        >
                          Book Demo
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} sm={8}>
              <Paper sx={{ padding: "1rem" }}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs="auto" sx={{ display: "flex" }}>
                    <TitleIcon icon={<StarOutlineOutlinedIcon />} />
                    <Typography variant="h5" sx={{ ml: ".5rem" }}>
                      Goals
                    </Typography>
                  </Grid>

                  <Goals user={this.props.user} />
                </Grid>
              </Paper>
            </Grid>

            {/* <Grid item xs="auto">
                            <Button
                                onClick={this.props.open_welcome_video}
                                variant="contained"
                                color="secondary"
                                startIcon={<PlayArrowIcon />}
                            >
                                Welcome Video
                            </Button>
                        </Grid> */}
          </Grid>
        </Box>
        <WelcomeVideoDialog
          open={this.props.welcome_video_open}
          onClose={this.props.close_welcome_video}
        />
      </Box>
    );
  }
}

const AddEventButton = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [adding_new_event, setAddingNewEvent] = React.useState(false);

  return (
    <Box display="flex">
      <Button
        id="new-event-button"
        variant="contained"
        color="secondary"
        onClick={() => setAddingNewEvent(true)}
        size="large"
        sx={
          fullScreen
            ? {
                m: "auto",
              }
            : {
                ml: "auto",
              }
        }
      >
        <AddOutlinedIcon />
        New Event
      </Button>
      <NewEventDialog
        open={adding_new_event}
        handleCancel={() => setAddingNewEvent(false)}
        handleSubmit={props.handleAddEvent}
        templates={props.templates}
      />
    </Box>
  );
};

const DashboardCard = (props) => {
  return (
    <Grid item xs={12} sm={4}>
      <Paper sx={{ padding: "1rem", height: "230px" }}>
        <Grid container>
          {props.withoutTitle ? null : (
            <Grid item xs={12} sx={{ display: "flex" }}>
              <TitleIcon icon={props.icon} />
              <Typography variant="h5" sx={{ ml: ".5rem" }}>
                {props.title}
              </Typography>
            </Grid>
          )}
          {props.withoutTitle ? null : (
            <Divider sx={{ margin: "1rem", marginBottom: "0" }} />
          )}
          {props.children}
        </Grid>
      </Paper>
    </Grid>
  );
};

const TitleIcon = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        width: "1.5rem",
        height: "1.5rem",
      }}
      borderRadius="50%"
    >
      {React.cloneElement(props.icon, { sx: { fontSize: "1.75rem" } })}
    </Box>
  );
};

export const NewEventDialog = (props) => {
  const validationSchema = Yup.object({
    event_name: Yup.string()
      .min(2, "Event name must be at least 2 characters.")
      .max(50, "Event name cannot contain more than 50 characters")
      .required("Must provie an event name."),
    event_date: props.is_template
      ? undefined
      : Yup.date().required("Must provie a date."),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      event_name: props.initialEventName ? props.initialEventName : "",
      event_template: props.event_template,
      is_template: props.is_template,
      event_date: props.initialEventDate
        ? new Date(props.initialEventDate)
        : new Date(),
    },
    onSubmit: (values) => {
      props.handleCancel();
      props.handleSubmit(values);
    },
  });

  return (
    <Dialog onClose={props.handleCancel} open={props.open}>
      <DialogTitle>
        {props.is_template ? "Create New Template" : "Create New Event"}
      </DialogTitle>
      <DialogContent>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
          <Grid
            container
            sx={{ width: "270px" }}
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <TextField
                name="event_name"
                label={props.is_template ? "Template Name" : "Event Name"}
                id="event_name"
                value={formik.values.event_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.event_name && Boolean(formik.errors.event_name)
                }
                variant="standard"
                fullWidth
              />
            </Grid>
            {!props.disable_template_selection ? (
              <Grid item xs={12}>
                <Autocomplete
                  name="event_template"
                  label={props.is_template ? "From Template" : "Event Template"}
                  id="event_template"
                  options={props.templates}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        props.is_template
                          ? "From Template (Optional)"
                          : "Template Name (Optional)"
                      }
                      variant="standard"
                    />
                  )}
                  renderOption={(props, option, state) => {
                    return (
                      <Box {...props} key={option.uuid}>
                        <Typography>{option.name}</Typography>
                      </Box>
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.uuid === value.uuid
                  }
                  value={formik.values.event_template}
                  onChange={(e, val) => {
                    formik.setFieldValue("event_template", val);
                  }}
                  // onChange={formik.handleChange}
                  error={
                    formik.touched.event_template &&
                    Boolean(formik.errors.event_template)
                  }
                  variant="standard"
                  fullWidth
                />
              </Grid>
            ) : null}
            {!props.is_template ? (
              <Grid item xs={12} display="flex">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateCalendar
                    name="event_date"
                    id="event_date"
                    label="Event Date"
                    value={formik.values.event_date}
                    onChange={(val) => {
                      formik.setFieldValue("event_date", val);
                    }}
                    error={
                      formik.touched.event_date &&
                      Boolean(formik.errors.event_date)
                    }
                  />
                </LocalizationProvider>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          variant="outlined"
          color="info"
          id="cancel-create-new-event"
        >
          Cancel
        </Button>

        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="secondary"
          id="confirm-create-new-event"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const WelcomeVideoDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));

  const url = "https://youtu.be/GRfumFzvfQE";
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Welcome to FlowerBuddy!</DialogTitle>
      <DialogContent>
        {fullScreen ? (
          <Box>
            <ReactPlayer url={url} width="16rem" height="10rem" controls />
          </Box>
        ) : tablet && !fullScreen ? (
          <ReactPlayer url={url} width="25rem" height="15.625rem" controls />
        ) : (
          <ReactPlayer url={url} width="35rem" height="21.875rem" controls />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(Dashboard);
