import { addTaskApi, deleteTaskApi, updateTaskApi } from "../api/EventsApi";
import { getDueDate } from "../utils/task_utils";
import { byUUID } from "../utils/utils";

export const tasksSlice = (set, get) => ({
  tasks: [],
  addTask: (data) => {
    addTaskApi({ event_uuid: get().event.uuid, ...data }).then((resp) => {
      set({
        tasks: [
          ...get().tasks,
          {
            ...resp.data,
            due_date: getDueDate(
              resp.data.due_date_offset_days,
              resp.data.due_date_offset_direction,
              resp.data.event.date,
            ),
          },
        ],
      });
    });
  },
  deleteTask: (uuid) => {
    let tasks = get().tasks;
    set({ tasks: tasks.filter((task) => task.uuid !== uuid) });
    deleteTaskApi({ uuid: uuid }).then((resp) => {
      if (!resp.data.success) {
        set({
          tasks: tasks,
        });
      }
    });
  },
  initializeTasks: (data) => set({ tasks: data }),
  numTasksDue: () => {
    let incomplete_tasks = get().tasks.filter((task) => task.status === "IN");
    return incomplete_tasks.reduce((sum, task) => {
      let today = new Date().toISOString();
      let due_date = get().taskDueDate(task);
      if (due_date < today) {
        return sum + 1;
      } else {
        return sum;
      }
    }, 0);
  },
  taskDueDate: (task) => {
    let due_date_offset_days = task.due_date_offset_days
      ? task.due_date_offset_days
      : 0;
    let due_date_offset_direction = task.due_date_offset_direction;
    let event_date = get().event.date;
    var date = new Date(event_date);
    var direction = due_date_offset_direction === "BE" ? -1 : 1;
    date.setDate(date.getDate() + parseInt(due_date_offset_days) * direction);
    var date_str = date.toISOString();
    return date_str;
  },
  updateTask: (data) => {
    let tasks = get().tasks;
    let index = tasks.findIndex((task) => task.uuid === data.uuid);
    let task = tasks[index];
    set({
      tasks: [
        ...tasks.slice(0, index),
        { ...task, ...data },
        ...tasks.slice(index + 1),
      ],
    });
    updateTaskApi(data);
  },
});
