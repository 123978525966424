import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useAccountState } from "../../state/store";
import { SocialIcon } from "react-social-icons";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

export const Links = (props) => {
  const social_link_props = {
    style: {
      margin: "auto",
      height: "30px",
      width: "30px",
    },
  };

  return (
    <Box sx={{ p: "1rem" }}>
      <Grid container justifyContent="center" spacing={1}>
        <SocialLink
          id="show_website_url"
          url_field="website_url"
          label="Website"
          icon={
            <LanguageOutlinedIcon
              fontSize="large"
              sx={{ m: "auto" }}
              {...social_link_props}
            />
          }
          proposal_section={props.proposal_section}
        />
        <SocialLink
          id="show_facebook_url"
          url_field="facebook_url"
          label="Facebook"
          icon={<SocialIcon network="facebook" {...social_link_props} />}
          proposal_section={props.proposal_section}
        />
        <SocialLink
          id="show_instagram_url"
          url_field="instagram_url"
          label="Instagram"
          icon={<SocialIcon network="instagram" {...social_link_props} />}
          proposal_section={props.proposal_section}
        />
        <SocialLink
          id="show_twitter_url"
          url_field="twitter_url"
          label="Twitter"
          icon={<SocialIcon network="x" {...social_link_props} />}
          proposal_section={props.proposal_section}
        />
        <SocialLink
          id="show_pinterest_url"
          url_field="pinterest_url"
          label="Pinterest"
          icon={<SocialIcon network="pinterest" {...social_link_props} />}
          proposal_section={props.proposal_section}
        />
        <SocialLink
          id="show_tiktok_url"
          url_field="tiktok_url"
          label="TikTok"
          icon={<SocialIcon network="tiktok" {...social_link_props} />}
          proposal_section={props.proposal_section}
        />
      </Grid>
    </Box>
  );
};

const SocialLink = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);
  const [value, setValue] = useState(
    props.proposal_section.style &&
      props.proposal_section.style[props.url_field]
  );

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    if (value && !value.includes("http://") && !value.includes("https://")) {
      setValue("http://" + value);
      updateProposalSectionStyle(props.proposal_section.uuid, {
        [props.url_field]: "http://" + value,
      });
    } else {
      updateProposalSectionStyle(props.proposal_section.uuid, {
        [props.url_field]: value,
      });
    }
  };

  useEffect(() => {
    setValue(
      props.proposal_section.style &&
        props.proposal_section.style[props.url_field]
    );
  }, [props.proposal_section, props.url_field]);

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={2} display="flex">
          <IconButton
            sx={{ m: "auto" }}
            onClick={() =>
              window.open(
                props.proposal_section.style &&
                  props.proposal_section.style[props.url_field]
              )
            }
            id={props.id + "-icon"}
          >
            {props.icon}
          </IconButton>
        </Grid>

        <Grid item xs={10} display="flex">
          <TextField
            label={"www.social.com/myprofile"}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            id={props.id + "-input"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const LinksPreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      spacing={0.5}
      alignItems={"center"}
      height={"100%"}
    >
      {[...Array(5).keys()].map((i) => (
        <Grid item xs="auto">
          <Skeleton variant="circular" width={7} height={7} animation={false} />
        </Grid>
      ))}
    </Grid>
  );
};

export const LinksView = (props) => {
  const social_link_props = {
    style: {
      margin: "auto",
      height: "30px",
      width: "30px",
    },
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <SocialLinkView
        id="show_website_url"
        url_field="website_url"
        icon={
          <LanguageOutlinedIcon
            fontSize="large"
            sx={{ m: "auto" }}
            {...social_link_props}
          />
        }
        proposal_section={props.proposal_section}
      />
      <SocialLinkView
        id="show_facebook_url"
        url_field="facebook_url"
        icon={<SocialIcon network="facebook" {...social_link_props} />}
        proposal_section={props.proposal_section}
      />
      <SocialLinkView
        id="show_instagram_url"
        url_field="instagram_url"
        icon={<SocialIcon network="instagram" {...social_link_props} />}
        proposal_section={props.proposal_section}
      />
      <SocialLinkView
        id="show_twitter_url"
        url_field="twitter_url"
        icon={<SocialIcon network="x" {...social_link_props} />}
        proposal_section={props.proposal_section}
      />
      <SocialLinkView
        id="show_pinterest_url"
        url_field="pinterest_url"
        icon={<SocialIcon network="pinterest" {...social_link_props} />}
        proposal_section={props.proposal_section}
      />
      <SocialLinkView
        id="show_tiktok_url"
        url_field="tiktok_url"
        icon={<SocialIcon network="tiktok" {...social_link_props} />}
        proposal_section={props.proposal_section}
      />
    </Grid>
  );
};

const SocialLinkView = (props) => {
  return props.proposal_section.style &&
    props.proposal_section.style[props.url_field] ? (
    <Grid item xs="auto" display="flex">
      <IconButton
        sx={{ m: "auto" }}
        onClick={() =>
          window.open(props.proposal_section.style[props.url_field])
        }
      >
        {props.icon}
      </IconButton>
    </Grid>
  ) : null;
};
