import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { format } from "date-fns";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Divider,
  Alert,
} from "@mui/material";

import {
  cancelSubscriptionApi,
  getProductsApi,
  setSubscriptionApi,
  getProrationPreviewApi,
  upgradeSubscriptionApi,
  getCustomerApi,
} from "../api/StripeApi";
import { PaymentDetails } from "./PaymentDetails";
import { format_currency } from "../utils/utils";
import { useAccountState } from "../state/store";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { CollectPaymentDetailsForPlan } from "./CollectPaymentDetails";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export const PlanSelection = (props) => {
  const [fre_plan, setFrePlan] = React.useState(undefined);
  const [ppe_plan, setPpePlan] = React.useState(undefined);
  const [unl_plan, setUnlPlan] = React.useState(undefined);
  const [error, setErrorMessage] = React.useState(undefined);
  const [error_alert_open, setErrorAlertOpen] = React.useState(false);
  const setError = (error_message) => {
    setErrorMessage(error_message);
    setErrorAlertOpen(true);
  };
  const fre_plan_period = "month";
  const ppe_plan_period = "month";
  const unl_plan_period = "year";
  React.useEffect(() => {
    getProductsApi().then((resp) => {
      let fre_ind = resp.data.findIndex((plan) => plan.metadata.type === "FRE");
      setFrePlan(resp.data[fre_ind]);
      let ppe_ind = resp.data.findIndex((plan) => plan.metadata.type === "PPE");
      setPpePlan(resp.data[ppe_ind]);
      let unl_ind = resp.data.findIndex((plan) => plan.metadata.type === "UNL");
      setUnlPlan(resp.data[unl_ind]);
    });
  }, []);

  const getPrice = (plan) => {
    return plan.prices.filter((price) => price.active)[0];
  };

  return fre_plan && ppe_plan && unl_plan && props.stripe_customer ? (
    <Grid container justifyContent="space-evenly" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Plan Selection</Typography>
      </Grid>
      {error_alert_open ? (
        <Grid item xs={12}>
          <Alert
            variant="outlined"
            severity="error"
            onClose={() => {
              setErrorAlertOpen(false);
            }}
          >
            {error}
          </Alert>
        </Grid>
      ) : null}
      {props.stripe_customer &&
      props.stripe_customer.subscription &&
      props.stripe_customer.subscription.status == "trialing" ? (
        <Grid item xs={12}>
          <Box>
            <Alert align="center">
              Trial ends on{" "}
              {format(
                new Date(props.stripe_customer.subscription.trial_end),
                "MM/dd/yy",
              )}
            </Alert>
          </Box>
        </Grid>
      ) : null}
      {props.stripe_customer &&
      props.stripe_customer.subscription &&
      props.stripe_customer.subscription.cancel_at_period_end ? (
        <Grid item xs={12}>
          <Alert severity="error">
            Subscription ends on{" "}
            {format(
              new Date(props.stripe_customer.subscription.current_period_end),
              "MM/dd/yy",
            )}{" "}
          </Alert>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={4}>
        <PlanCard
          user={props.user}
          stripe_customer={props.stripe_customer}
          title="Scalable Starter"
          amount={getPrice(fre_plan).unit_amount / 100}
          period={fre_plan_period}
          bullets={[
            {
              heading: "Free Forever",
              bullets: [
                "Pre-loaded Floral Data",
                "Unlimited Proposals",
                "Floral Recipes",
                "Templates",
                "Workflows + Tasks",
              ],
            },
            {
              heading: "After 2% Fee",
              bullets: [
                "E-signatures",
                "Payments (0.5% Invoice Fee)",
                "Amendments",
                "Export Orders",
                "Digital Build Instructions",
              ],
            },
          ]}
          type={"FRE"}
          percent_fee={getPrice(fre_plan).metadata.per_event_fee * 100}
          payment_processing_fee={
            getPrice(fre_plan).metadata.payment_processing_fee * 100
          }
          stripe_price_id={getPrice(fre_plan).id}
          setStripeCustomer={props.setStripeCustomer}
          setError={setError}
          selected={
            props.stripe_customer.subscription &&
            props.stripe_customer.subscription.status == "active" &&
            props.stripe_customer.subscription.metadata.type == "FRE"
          }
          trialing={
            props.stripe_customer.subscription &&
            props.stripe_customer.subscription.status == "trialing" &&
            props.stripe_customer.subscription.metadata.type == "FRE"
          }
          disableSwitch={
            props.stripe_customer.subscription &&
            props.stripe_customer.subscription.metadata.type !== "FRE" &&
            props.stripe_customer.subscription.status !== "trialing"
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <PlanCard
          user={props.user}
          stripe_customer={props.stripe_customer}
          title="Scalable Pro"
          amount={getPrice(ppe_plan).unit_amount / 100}
          period={ppe_plan_period}
          bullets={[
            {
              heading: "$30/mo",
              bullets: [
                "Pre-loaded Floral Data",
                "Unlimited Proposals",
                "Floral Recipes",
                "Templates",
                "Workflows + Tasks",
                "Inventory Management",
              ],
            },
            {
              heading: "After 1% Fee",
              bullets: [
                "E-signatures",
                "Payments (No Fee)",
                "Amendments",
                "Export Orders",
                "Digital Build Instructions",
              ],
            },
          ]}
          type={"PPE"}
          percent_fee={getPrice(ppe_plan).metadata.per_event_fee * 100}
          payment_processing_fee={0}
          stripe_price_id={getPrice(ppe_plan).id}
          setStripeCustomer={props.setStripeCustomer}
          setError={setError}
          selected={
            props.stripe_customer.subscription &&
            props.stripe_customer.subscription.status == "active" &&
            props.stripe_customer.subscription.metadata.type == "PPE"
          }
          trialing={
            props.stripe_customer.subscription &&
            props.stripe_customer.subscription.status == "trialing" &&
            props.stripe_customer.subscription.metadata.type == "PPE"
          }
          disableSwitch={
            props.stripe_customer.subscription &&
            props.stripe_customer.subscription.metadata.type == "UNL" &&
            props.stripe_customer.subscription.status !== "trialing"
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <PlanCard
          user={props.user}
          stripe_customer={props.stripe_customer}
          title="Unlimited"
          amount={getPrice(unl_plan).unit_amount / 100}
          period={unl_plan_period}
          bullets={[
            {
              heading: "No Fees",
              bullets: [
                "Pre-loaded Floral Data",
                "Unlimited Proposals",
                "Floral Recipes",
                "Templates",
                "Workflows + Tasks",
                "Inventory Management",
                "E-signatures",
                "Payments (No Fee)",
                "Amendments",
                "Export Orders",
                "Digital Build Instructions",
              ],
            },
          ]}
          type={"UNL"}
          percent_fee={getPrice(unl_plan).metadata.per_event_fee * 100}
          payment_processing_fee={0}
          stripe_price_id={getPrice(unl_plan).id}
          setStripeCustomer={props.setStripeCustomer}
          setError={setError}
          selected={
            props.stripe_customer.subscription &&
            props.stripe_customer.subscription.status == "active" &&
            props.stripe_customer.subscription.metadata.type == "UNL"
          }
          trialing={
            props.stripe_customer.subscription &&
            props.stripe_customer.subscription.status == "trialing" &&
            props.stripe_customer.subscription.metadata.type == "UNL"
          }
        />
      </Grid>
      {props.stripe_customer ? (
        <Grid item xs={12}>
          <Divider sx={{ mt: "1rem", mb: "1rem" }} />
          <Typography variant="h6">Payment Details</Typography>
          <PaymentDetails
            stripe_customer={props.stripe_customer}
            setStripeCustomer={props.setStripeCustomer}
          />
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <Box display="flex">
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  );
};

const PlanCard = (props) => {
  const [userPlanType] = useAccountState((state) => [state.userPlanType]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [pre_confirming_plan, setPreConfirmingPlan] = React.useState(false);
  const [confirming_plan, setConfirmingPlan] = React.useState(false);
  const [cancel_plan, setCancelPlan] = React.useState(false);
  const [undo_cancel_plan, setUndoCancelPlan] = React.useState(false);
  const [switching_plan, setSwitchingPlan] = React.useState(false);

  return (
    <Paper
      sx={{
        maxWidth: "400px",
        height: "50rem",
        borderColor: props.selected
          ? "green"
          : fullScreen
            ? "lightgrey"
            : "lightgrey",
        borderStyle: "solid",
        borderWidth: "1px",
        pt: "1rem",
      }}
      id={props.title + "-plan-card"}
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        direction={"column"}
        height={"100%"}
      >
        <Grid
          item
          xs={10}
          container
          justifyContent="space-between"
          direction={"column"}
        >
          <Grid item xs="auto" container>
            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center">
                {props.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems={"flex-end"}
            >
              <Grid item xs="auto">
                <Typography
                  variant="h2"
                  textAlign="center"
                  sx={{ mr: "auto", lineHeight: 1, fontWeight: "bold" }}
                >
                  ${props.amount}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography
                  textAlign="flex-start"
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  {"/" + (props.period.includes("month") ? "mo" : "yr")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign="center" sx={{ mt: "1rem" }}>
                  {props.percent_fee + "% Booking Fee"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign="center">
                  {props.payment_processing_fee + "% Invoicing Fee"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <Box display="flex">
              <Box
                sx={{
                  width: "fit-content",
                  m: "auto",
                  p: "1rem",
                  width: "85%",
                }}
              >
                {props.bullets.map((bullet) => (
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="flex-start"
                    spacing={1}
                    sx={{ mt: ".5rem" }}
                  >
                    <Grid item xs="auto" sx={{ pt: "2px" }}>
                      <Typography variant="h6">{bullet.heading}</Typography>
                    </Grid>
                    {bullet.bullets.map((b) => (
                      <Grid item xs={12} container>
                        <Grid item xs="auto">
                          <CheckOutlinedIcon sx={{ pr: ".25rem" }} />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            // variant="h6"
                            sx={{ fontWeight: "light" }}
                          >
                            {b}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Box display="flex">
            {!props.selected &&
            !props.trialing &&
            props.type !== "FRE" &&
            (!props.stripe_customer.subscription ||
              props.stripe_customer.subscription.status !== "active") ? (
              <Button
                onClick={() => setConfirmingPlan(true)}
                size="large"
                variant="contained"
                color="secondary"
                sx={{ m: "auto", width: "90%" }}
                id="select-plan"
                // disabled={userPlanType() === "FRE" && props.type === "FRE"}
              >
                Select
              </Button>
            ) : null}
            {(props.selected || props.trialing) &&
            !props.stripe_customer.subscription?.cancel_at_period_end ? (
              <Button
                onClick={() => setCancelPlan(true)}
                size="large"
                variant="outlined"
                color="error"
                sx={{ m: "auto", width: "90%" }}
                id="cancel-plan"
              >
                Cancel
              </Button>
            ) : null}
            {props.type === "FRE" &&
            props.stripe_customer.subscription?.status !== "active" ? (
              <Button
                size="large"
                variant="outlined"
                color="info"
                sx={{ m: "auto", width: "90%" }}
                id="selected-plan"
                disabled={true}
              >
                Selected
              </Button>
            ) : null}
            {(props.selected || props.trialing) &&
            props.stripe_customer.subscription?.cancel_at_period_end ? (
              <Grid container justifyContent="center">
                <Grid item xs={12} sx={{ display: "flex" }}>
                  <Button
                    onClick={() => setUndoCancelPlan(true)}
                    size="large"
                    variant="outlined"
                    color="secondary"
                    sx={{ m: "auto", width: "90%" }}
                    id="undo-cancel-plan"
                  >
                    Undo Cancellation
                  </Button>
                </Grid>
              </Grid>
            ) : null}
            {props.stripe_customer.subscription &&
            props.stripe_customer.subscription.status == "active" &&
            !props.selected &&
            !props.trialing &&
            props.disableSwitch ? (
              <Tooltip
                title={
                  "Cannot downgrade. Please cancel current subscription and wait for it to expire."
                }
              >
                <Box display="flex" sx={{ width: "100%" }}>
                  <Button
                    onClick={() => setSwitchingPlan(true)}
                    size="large"
                    variant="contained"
                    color="secondary"
                    sx={{ m: "auto", width: "90%" }}
                    disabled={props.disableSwitch}
                    id="switch-plan"
                  >
                    Switch
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
            {props.stripe_customer.subscription &&
            props.stripe_customer.subscription.status == "active" &&
            !props.selected &&
            !props.trialing &&
            !props.disableSwitch ? (
              <Button
                onClick={() => setSwitchingPlan(true)}
                size="large"
                variant="contained"
                color="secondary"
                sx={{ m: "auto", width: "90%" }}
                id="switch-plan"
              >
                Switch
              </Button>
            ) : null}
            {/* {pre_confirming_plan ? (
              <PreConfirmPlanDialog
                plan={props.type}
                open={pre_confirming_plan}
                handleSubmit={() => {
                  setPreConfirmingPlan(false);
                  setConfirmingPlan(true);
                }}
                handleCancel={() => setPreConfirmingPlan(false)}
              />
            ) : null} */}
            {confirming_plan ? (
              <PlanConfirmationDialog
                open={
                  confirming_plan &&
                  props.stripe_customer?.default_payment_method?.billing_details
                    ?.address
                }
                handleCancel={() => setConfirmingPlan(false)}
                user={props.user}
                amount={props.amount}
                stripe_price_id={props.stripe_price_id}
                setStripeCustomer={props.setStripeCustomer}
                setError={props.setError}
              />
            ) : null}
            {confirming_plan ? (
              <CollectPaymentDetailsForPlan
                setStripeCustomer={props.setStripeCustomer}
                open={
                  confirming_plan &&
                  !props.stripe_customer?.default_payment_method
                    ?.billing_details?.address
                }
                handleCancel={() => setConfirmingPlan(false)}
                callback={() => setConfirmingPlan(true)}
                plan_type={props.type}
              />
            ) : null}
            {props.stripe_customer.subscription ? (
              <PlanCancelDialog
                open={cancel_plan}
                handleCancel={() => setCancelPlan(false)}
                end_date={props.stripe_customer.subscription.current_period_end}
                setStripeCustomer={props.setStripeCustomer}
              />
            ) : null}
            {props.stripe_customer.subscription ? (
              <UndoPlanCancelDialog
                open={undo_cancel_plan}
                handleCancel={() => setUndoCancelPlan(false)}
                end_date={props.stripe_customer.subscription.current_period_end}
                stripe_customer={props.stripe_customer}
                setStripeCustomer={props.setStripeCustomer}
                amount={props.stripe_customer.subscription.plan.amount / 100}
              />
            ) : null}
            {props.stripe_customer.subscription &&
            !props.selected &&
            !props.disableSwitch &&
            switching_plan ? (
              <SwitchPlanDialog
                open={switching_plan}
                handleCancel={() => setSwitchingPlan(false)}
                new_price_id={props.stripe_price_id}
                setStripeCustomer={props.setStripeCustomer}
                stripe_customer={props.stripe_customer}
                plan_amount={props.amount}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const PreConfirmPlanDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Welcome Aboard!</DialogTitle>
      {props.plan === "FRE" && (
        <DialogContent>
          <Typography>
            You've selected the Freemium plan. With this plan you'll pay
            absolutely nothing until you have booked your first event. Upon
            booking events, you'll be charged a fee equal to 2% of the event
            price. But don't worry! FlowerBuddy builds that fee into the price
            of your event to help you keep your profit right where you want it!
          </Typography>
        </DialogContent>
      )}
      {props.plan === "PPE" && (
        <DialogContent>
          <Typography>
            You've selected the Scalable plan. With this plan you'll pay $30 per
            month, a 1% fee for booked events, and no additional payment
            processing fee. A great choice for florists who pull in less than
            $150k in revenue annually. And don't worry! FlowerBuddy builds the
            fee into the price of your event to help you keep your profit right
            where you want it!
          </Typography>
        </DialogContent>
      )}
      {props.plan === "UNL" && (
        <DialogContent>
          <Typography>
            You've selected the Unlimited plan. With this plan you'll pay $1800
            anually for unlimited events with no added fees. A great choice for
            florists who pull in $150k or more in revenue annually!
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={props.handleSubmit}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PlanConfirmationDialog = (props) => {
  const [loading, setLoading] = React.useState(false);

  const [first_payment_date, setFirstPaymentDate] = React.useState("");

  const setSubscription = () => {
    setLoading(true);
    setSubscriptionApi(props.stripe_price_id).then((resp) => {
      props.setStripeCustomer(resp.data);
      props.handleCancel();
      setLoading(false);
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Subscription Confirmation</DialogTitle>
      <DialogContent>
        <Typography>
          {props.amount > 0
            ? (props.user.stripe_customer.balance * -1) / 100 < props.amount
              ? "You will be charged " +
                format_currency(
                  props.amount -
                    (props.user.stripe_customer.balance * -1) / 100,
                )
              : format_currency(props.amount) +
                " will be deducted from your FlowerBuddy credit balance"
            : "You will not be charged until an event books."}

          {first_payment_date}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="info"
          variant="outlined"
          onClick={() => props.handleCancel()}
          id="cancel-subscription"
        >
          Cancel
        </Button>
        {!loading ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={setSubscription}
            id="confirm-subscription"
          >
            Confirm
          </Button>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

const PlanConfirmDialog = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = React.useState(false);

  const handleResponse = (error) => {
    if (error) {
      props.setError(error.message);
      setSubmitting(false);
      props.handleCancel();
    } else {
      setTimeout(() => {
        getCustomerApi().then((resp) => {
          props.setStripeCustomer(resp.data);
          props.handleCancel();
        });
      }, 7000);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    if (!stripe || !elements) {
      return;
    }
    if (props.secret_type == "payment_intent") {
      stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: process.env.REACT_APP_BASE_URL + "/account",
          },
          redirect: "if_required",
        })
        .then((result) => {
          handleResponse(result.error);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      stripe
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: process.env.REACT_APP_BASE_URL + "/account",
          },
          redirect: "if_required",
        })
        .then((result) => {
          handleResponse(result.error);
        });
    }
  };

  return (
    <Box>
      <DialogTitle>
        <Typography>Confirm Subscription</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4} sx={{ maxWidth: "350px" }}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold" }}>
              Subscription Details
            </Typography>
            <Typography>
              You will be charged {format_currency(props.amount)} on{" "}
              {format(props.first_payment_date, "MM/dd/yy")}.
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>Payment Info</Typography>
            <form onSubmit={handleSubmit}>
              <PaymentElement />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!submitting ? (
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                onClick={props.handleCancel}
                id="cancel-subscription"
              >
                {props.stripe_customer.subscription &&
                props.stripe_customer.subscription.status == "trialing"
                  ? "Skip"
                  : "Cancel"}
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                id="confirm-subscription"
              >
                Add Payment
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Box>
  );
};

const PlanCancelDialog = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    cancelSubscriptionApi().then((resp) => {
      props.setStripeCustomer(resp.data);
      props.handleCancel();
      setSubmitting(false);
    });
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        <Typography variant="h5">Cancel Subscription Confirmation</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ maxWidth: "350px" }}>
          <Grid item xs={12}>
            <Typography>
              You will lose access to your FlowerBuddy account on{" "}
              {format(new Date(props.end_date), "MM/dd/yy")}.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!submitting ? (
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                onClick={props.handleCancel}
                id="cancel-subscription"
              >
                Keep Subscription
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="error"
                onClick={handleSubmit}
                id="confirm-subscription"
              >
                Cancel Subscription
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

const UndoPlanCancelDialog = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    cancelSubscriptionApi().then((resp) => {
      props.setStripeCustomer(resp.data);
      props.handleCancel();
      setSubmitting(false);
    });
  };

  const getAmount = (stripe_customer) => {
    if (stripe_customer.subscription.items.data) {
      return stripe_customer.subscription.items.data[0].price.unit_amount / 100;
    } else {
      return stripe_customer.subscription.items[0].price.unit_amount / 100;
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        <Typography variant="h5">Undo Cancel Subscription</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ maxWidth: "350px" }}>
          <Grid item xs={12}>
            <Typography>
              You will be charged{" "}
              {format_currency(getAmount(props.stripe_customer))} on{" "}
              {format(new Date(props.end_date), "MM/dd/yy")}.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!submitting ? (
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                onClick={props.handleCancel}
                id="cancel-subscription"
              >
                Nevermind
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                id="confirm-subscription"
              >
                Undo Cancel Subscription
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

const SwitchPlanDialog = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    upgradeSubscriptionApi(props.new_price_id).then((resp) => {
      props.setStripeCustomer({
        ...props.stripe_customer,
        subscription: resp.data,
      });
      setSubmitting(false);
      props.handleCancel();
    });
  };

  const [amount, setAmount] = React.useState(undefined);
  const [invoice_date, setInvoiceDate] = React.useState(undefined);

  React.useEffect(() => {
    if (props.stripe_customer.subscription.status == "trialing") {
      setAmount(props.plan_amount);
      setInvoiceDate(new Date(props.stripe_customer.subscription.trial_end));
      setLoaded(true);
    } else {
      getProrationPreviewApi(props.new_price_id).then((resp) => {
        setAmount(resp.data.total / 100);
        setInvoiceDate(new Date());
        setLoaded(true);
      });
    }
  }, []);

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Switch Subscription Confirmation</DialogTitle>
      {loaded ? (
        <Box>
          <DialogContent>
            <Typography>
              You will be charged {format_currency(amount)} on{" "}
              {format(invoice_date, "MM/dd/yy")}
            </Typography>
          </DialogContent>
          <DialogActions>
            {!submitting ? (
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item xs="auto">
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={props.handleCancel}
                    id="cancel-subscription"
                  >
                    Nevermind
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    id="confirm-subscription"
                  >
                    Switch
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <CircularProgress />
            )}
          </DialogActions>
        </Box>
      ) : (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};
