import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import CroppedImg from "../CroppedImg";
import { eventDateTime } from "../EventDetails";
import { useAccountState } from "../../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { sort_by_order } from "../../utils/utils";
import { ColorPickerDialog } from "../Proposal";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { pad } from "lodash";
import { DefaultStyles } from "../../utils/proposal_utils";

export const ColorPalette = (props) => {
  const [
    addPaletteColor,
    back_office,
    event,
    proposal,
    removePaletteColor,
    updateProposalSection,
  ] = useAccountState((state) => [
    state.addPaletteColor,
    state.back_office,
    state.event,
    state.proposal,
    state.removePaletteColor,
    state.updateProposalSection,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [addingColor, setAddingColor] = useState(false);

  const setAddingColorTrue = () => {
    setAddingColor(true);
  };

  const setAddingColorFalse = () => {
    setAddingColor(false);
  };

  const _addPaletteColor = (color) => {
    addPaletteColor(props.proposal_section.uuid, color);
    setAddingColorFalse();
  };

  const _removePaletteColor = (color_uuid) => {
    removePaletteColor(props.proposal_section.uuid, color_uuid);
  };

  const boxDims = () => {
    return { width: "100%", height: 0, paddingBottom: "100%" };
  };

  return (
    <Box sx={{ p: "1rem" }}>
      <Grid container spacing={1} justifyContent="flex-start">
        <Grid
          item
          xs={12}
          container
          justifyContent={
            props.proposal_section.style?.items_align ||
            DefaultStyles.itemsAlign
          }
          spacing={1}
        >
          {!props.profileSettings &&
            props.proposal_section.proposal_colors
              .sort(sort_by_order)
              .map((proposal_color) => (
                <Grid item xs={2} key={proposal_color.uuid}>
                  <Box
                    sx={{
                      backgroundColor: proposal_color.hex_code,
                      ...boxDims(),
                    }}
                  >
                    <IconButton
                      onClick={() => _removePaletteColor(proposal_color.uuid)}
                      id={proposal_color.uuid + "-remove-palette-color-button"}
                    >
                      <ClearOutlinedIcon />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
          {props.profileSettings &&
            [...Array(3).keys()].map((proposal_color) => (
              <Grid item xs={2} key={proposal_color.uuid}>
                <Box
                  sx={{
                    backgroundColor: proposal_color.hex_code,
                    ...boxDims(),
                  }}
                ></Box>
              </Grid>
            ))}

          {!props.profileSettings && (
            <Grid
              item
              xs={2}
              display={
                props.proposal_section.proposal_colors.length < 6
                  ? "flex"
                  : "none"
              }
            >
              <Box
                sx={{
                  width: "100%",
                  height: 0,
                  paddingBottom: "100%",
                  backgroundColor: "white",
                  borderStyle: "dotted",
                  borderColor: "lightgrey",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    pt: "35%",
                  }}
                >
                  <IconButton
                    size="large"
                    variant="outlined"
                    sx={{ m: "auto", p: 0 }}
                    onClick={setAddingColorTrue}
                    id="add-palette-color-button"
                  >
                    <AddCircleOutlineOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <ColorPickerDialog
          open={addingColor}
          handleCancel={setAddingColorFalse}
          handleSubmit={_addPaletteColor}
        />
      </Grid>
    </Box>
  );
};

export const ColorPalettePreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      spacing={0.5}
      alignItems={"center"}
    >
      <Grid item xs={12}>
        <Skeleton
          variant="rounded"
          width={50}
          height={5}
          sx={{ mt: 3.5 }}
          animation={false}
        />
      </Grid>
      {[...Array(5).keys()].map((i) => (
        <Grid item xs="auto">
          <Skeleton
            variant="rectangular"
            width={10}
            height={10}
            animation={false}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const ColorPaletteView = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const boxDims = () => {
    return { width: "100%", height: 0, paddingBottom: "100%" };
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent={
        props.proposal_section.style?.items_align || DefaultStyles.itemsAlign
      }
    >
      {props.proposal_section.proposal_colors
        .sort(sort_by_order)
        .map((proposal_color) => (
          <Grid item xs={2} key={proposal_color.uuid}>
            <Box
              boxShadow={1}
              sx={{
                backgroundColor: proposal_color.hex_code,
                ...boxDims(),
              }}
            ></Box>
          </Grid>
        ))}
    </Grid>
  );
};
