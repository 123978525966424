import React from "react";
import { withRouter } from "react-router-dom";

import { twicpic_url } from "../utils/cropImage";

import Zoom from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";
import { Avatar, Box, Dialog, DialogContent } from "@mui/material";

const CroppedImg = (props) => {
  const [imageLoadError, setImageLoadError] = React.useState(false);

  const handleError = () => {
    setImageLoadError(true);
  };

  const DefaultComponent = props.default;
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => {
    console.log("OPEN");
    setOpen(!open);
  };

  return !imageLoadError || !DefaultComponent ? (
    <>
      <img
        alt=""
        src={twicpic_url(
          props.img,
          {
            width: props.width,
            height: props.height,
          },
          props.quality,
          props.contain
        )}
        sx={props.sx}
        style={{
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
          marginBottom: props.marginBottom,
          marginTop: props.marginTop,
          maxWidth: props.maxWidth,
          maxHeight: props.maxHeight,
          width: props.width,
          height: props.height,
          objectFit: props.fill ? "cover" : "contain",
          borderRadius: props.borderRadius,
          display: props.display,
        }}
        onError={handleError}
        onClick={props.zoom && toggleOpen}
      />

      <ZoomDialog
        open={open}
        handleCancel={toggleOpen}
        handleError={handleError}
        {...props}
      />
    </>
  ) : (
    <DefaultComponent {...props.defaultProps} />
  );
};

export const CroppedAvatar = (props) => {
  return (
    <Avatar
      id={props.id}
      src={twicpic_url(props.img, { width: props.width, height: props.height })}
      sx={{
        ...props.sx,
        width: props.width && `${props.width}px`,
        height: props.height && `${props.height}px`,
      }}
      onClick={props.onClick}
      key={props.img?.uuid}
    >
      {props.children}
    </Avatar>
  );
};

const ZoomDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent onClick={props.handleCancel}>
        <img
          alt=""
          src={twicpic_url(
            props.img,
            {
              width: "100%",
              height: "100%",
            },
            props.quality,
            props.contain
          )}
          style={{
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
            marginBottom: props.marginBottom,
            marginTop: props.marginTop,
            maxWidth: "90vw", // Limit width to viewport width
            maxHeight: "90vh", // Limit height to viewport height
            width: "100%",
            height: "100%",
            objectFit: props.fill ? "cover" : "contain",
            borderRadius: props.borderRadius,
            display: props.display,
          }}
          onError={props.handleError}
          onClick={props.handleCancel}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(CroppedImg);
