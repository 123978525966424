export default function user_plan(user) {
  if (user?.stripe_customer) {
    if (user.stripe_customer.subscription) {
      return user.stripe_customer.subscription;
    }
  }
  return {
    metadata: { type: "FRE" },
    status: "active",
    items: [{ price: { metadata: { per_event_fee: 0.02 } } }],
  };
}

export function userPlanType(user) {
  const plan = user_plan(user);
  if (plan) {
    return plan.metadata.type;
  } else {
    return undefined;
  }
}

const days_formatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

export function trial_days_left(user) {
  let d1 = new Date();
  let d2 = new Date(user.trial_ends);
  let delta = d2 - d1;
  return days_formatter.format(delta / (1000 * 3600 * 24) + 1);
}

export function flowerbuddyFee(user) {
  if (user_plan(user)) {
    if (user_plan(user).status === "trialing") {
      return 0.0;
    } else {
      return user_plan(user).items[0].price.metadata.per_event_fee;
    }
  } else {
    return 0.0;
  }
}

export function stripeFee() {
  return 0.0315;
}
