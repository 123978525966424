import { unsecureAPI } from "../api";

export const getInventoryPageData = () => {
  return unsecureAPI.get("api/inventory/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getInventoryItemsApi = () => {
  return unsecureAPI.get("api/inventory_item/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const createInventoryTransactionApi = (data) => {
  return unsecureAPI.post("api/inventory_transaction/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getInventoryTransactionsApi = (
  limit,
  offset,
  inventory_item_uuid,
  event_uuid,
) => {
  var url = `api/inventory_transaction/?limit=${limit}&offset=${offset}`;
  if (inventory_item_uuid) {
    url += `&inventory_item_uuid=${inventory_item_uuid}`;
  }
  if (event_uuid) {
    url += `&event_uuid=${event_uuid}`;
  }
  return unsecureAPI.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getRecipeItemsApi = (item_uuid, event_uuid) => {
  return unsecureAPI.get(
    `api/recipe_item/?item_uuid=${item_uuid}&event_uuid=${event_uuid}`,
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    },
  );
};

export const updateInventoryItemApi = (data) => {
  return unsecureAPI.put(`api/inventory_item/`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addInventoryAddressApi = (data) => {
  return unsecureAPI.post(`api/inventory_address/`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addInventoryLocationApi = (data) => {
  return unsecureAPI.post(`api/inventory_location/`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateInventoryLocationApi = (data) => {
  return unsecureAPI.put(`api/inventory_location/`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteInventoryLocationApi = (uuid) => {
  return unsecureAPI.delete(`api/inventory_location/?uuid=${uuid}`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateInventoryAddressApi = (data) => {
  return unsecureAPI.put(`api/inventory_address/`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addInventoryItemApi = (data) => {
  return unsecureAPI.post(`api/inventory_item/`, data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getInventoryItemInfoApi = (event_uuid, inventory_item_uuid) => {
  return unsecureAPI.get(`api/inventory_item_info/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: {
      event_uuid: event_uuid,
      inventory_item_uuid: inventory_item_uuid,
    },
  });
};

export const deleteInventoryItemApi = (inventory_item_uuid) => {
  return unsecureAPI.delete(
    `api/inventory_item/?inventory_item_uuid=${inventory_item_uuid}`,
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    },
  );
};

export const deleteInventoryAddressApi = (uuid) => {
  return unsecureAPI.delete(`api/inventory_address/?uuid=${uuid}`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};
