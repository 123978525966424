import { unsecureAPI } from "../api";

export const getCustomerApi = () => {
  return unsecureAPI.get("stripe/customer/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getSetupIntentApi = () => {
  return unsecureAPI.get("stripe/setup_intent/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getProductsApi = () => {
  return unsecureAPI.get("stripe/product/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const setSubscriptionApi = (price_id) => {
  return unsecureAPI.post(
    "stripe/subscription/",
    { stripe_price_id: price_id },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    },
  );
};

export const upgradeSubscriptionApi = (price_id) => {
  return unsecureAPI.put(
    "stripe/subscription/",
    { price_id: price_id },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    },
  );
};

export const cancelSubscriptionApi = () => {
  return unsecureAPI.delete("stripe/subscription", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deletePaymentMethodApi = () => {
  return unsecureAPI.delete("stripe/payment_method", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getPaymentMethodApi = (payment_method_id) => {
  let data = { payment_method_id: payment_method_id };
  return unsecureAPI.get("stripe/payment_method/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: data,
  });
};

export const getProrationPreviewApi = (price_id) => {
  return unsecureAPI.get("stripe/preview_proration/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: { price_id: price_id },
  });
};

export const getTransactionsApi = (limit, offset, gt, lt) => {
  return unsecureAPI.get("stripe/invoice/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: { limit: limit, offset: offset, gt: gt, lt: lt },
  });
};

export const getBalanceTransactionsApi = (limit, offset, gt, lt) => {
  return unsecureAPI.get("stripe/balance_transaction/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: { limit: limit, offset: offset, gt: gt, lt: lt },
  });
};

export const retryCurrentPaymentApi = () => {
  return unsecureAPI.get("stripe/retry_current_payment/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const payEventFeeApi = (data) => {
  return unsecureAPI.post("stripe/pay_event_fee/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};
