import React from "react";

import PropTypes from "prop-types";
import * as Yup from "yup";

import { useFormik } from "formik";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { SignupLoginField, FormError } from "./SignupForm";
import { Logo } from "./Navbar";

const validationSchema = Yup.object({
  email: Yup.string().email().required("Must provide an email"),
  password: Yup.string().required("Must provide a password"),
});

const LoginForm = (props) => {
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: { email: "", password: "" },
    onSubmit: (values) => {
      values.email = values.email.trim();
      props.handleLogin(values);
    },
  });

  return (
    <Grid container width="325px" sx={{ m: "auto" }}>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs="auto">
          <Logo withText imgStyle={{ maxHeight: "45px" }} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            width: "90%",
            p: "1em",
          }}
        >
          <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormError>
                  {props.bad_credentials
                    ? "Email address and password do not match"
                    : null}
                </FormError>
                <SignupLoginField
                  name="email"
                  label="Email"
                  formik={formik}
                  onBlur={(e) =>
                    formik.setFieldValue("email", e.target.value.trim())
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SignupLoginField
                  name="password"
                  type="password"
                  label="Password"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                {!props.loading ? (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "100%",
                    }}
                    color="secondary"
                  >
                    Sign In
                  </Button>
                ) : (
                  <Box display="flex">
                    <CircularProgress sx={{ m: "auto" }} />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={4}>
                    <Button href="/signup" size="small" color="info">
                      Sign Up
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    <Button
                      onClick={props.toggle_forgot_password}
                      size="small"
                      color="info"
                    >
                      Forgot password
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default LoginForm;

LoginForm.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};
