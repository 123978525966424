import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Box,
  Divider,
  CircularProgress,
  LinearProgress,
  IconButton,
  Paper,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Moment from "moment";
import "react-image-crop/dist/ReactCrop.css";
import { sort_by_order } from "../utils/utils.js";
import { useAccountState } from "../state/store.js";
import {
  DefaultStyles,
  ProposalSectionsByType,
} from "../utils/proposal_utils.js";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { eventDateFormatted } from "../utils/event_utils.js";

const ProposalView = React.forwardRef((props, ref) => {
  const [
    back_office,
    event,
    eventDate,
    eventDateAmended,
    eventHasAmendments,
    floral_event,
    proposal,
    user,
  ] = useAccountState((state) => [
    state.back_office,
    state.event,
    state.eventDate,
    state.eventDateAmended,
    state.eventHasAmendments,
    state.event,
    state.proposal,
    state.user,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loaded, setLoaded] = React.useState(true);
  const InspirationImgListItemProps = [
    { position: 1, cols: 2, rows: 2 },
    { position: 2, cols: 2, rows: 2 },
    { position: 3, cols: 2, rows: 2 },
    { position: 4, cols: 3, rows: 2 },
    { position: 5, cols: 3, rows: 2 },
    { position: 6, cols: 2, rows: 2 },
    { position: 7, cols: 2, rows: 2 },
    { position: 8, cols: 2, rows: 2 },
  ];

  Moment.locale("en");

  const getExpireDate = () => {
    if (proposal.expiration_date) {
      return proposal.expiration_date;
    } else {
      var date = new Date();
      date.setDate(date.getDate() + proposal.default_expiration_days);
      var date_str = date.toISOString();
      return date_str;
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
    >
      {loaded ? (
        <Box
          sx={{
            m: "auto",
            maxWidth: "1200px",
          }}
        >
          {proposal.show_expiration_date &&
            proposal.expires &&
            !props.print &&
            ["IN", "PR"].includes(event.status) && (
              <Grid item xs={12}>
                <Alert severity="info" size="small">
                  <Typography>
                    Proposal expires on{" "}
                    {eventDateFormatted(
                      getExpireDate(),
                      back_office.timezone,
                      back_office.date_format
                    )}
                  </Typography>
                </Alert>
              </Grid>
            )}
          <Paper
            sx={{
              backgroundColor:
                proposal && !props.disableBackgroundColor
                  ? proposal.background_color
                  : undefined,
              backgroundImage:
                proposal && !props.disableBackgroundTexture
                  ? proposal.background_texture
                    ? `url("${
                        process.env.REACT_APP_S3_STATIC_MEDIA_URL +
                        proposal.background_texture
                      }")`
                    : undefined
                  : undefined,
              m: "1rem",
              maxWidth: "900px",
              p: "1rem",
            }}
          >
            {eventHasAmendments() && proposal.amendments_published ? (
              <Alert severity="info">
                Proposal contains amendments. View them in the Scope of Services
                section. Click Sign to sign the amended contract.
              </Alert>
            ) : null}
            <Box
              ref={ref}
              sx={
                props.print
                  ? {
                      // m: "2rem",
                      backgroundColor:
                        proposal && !props.disableBackgroundColor
                          ? proposal.background_color
                          : "white",
                      backgroundImage:
                        proposal && !props.disableBackgroundTexture
                          ? proposal.background_texture
                            ? `url("${
                                process.env.REACT_APP_S3_STATIC_MEDIA_URL +
                                proposal.background_texture
                              }")`
                            : undefined
                          : undefined,
                    }
                  : null
              }
            >
              <Box sx={props.print ? { m: "2rem" } : null}>
                {((proposal.show_expiration_date && proposal.expires) ||
                  (eventDateAmended() && proposal.amendments_published)) && (
                  <Grid container>
                    {eventDateAmended() && proposal.amendments_published && (
                      <Grid item xs={12} display="flex">
                        <Alert severity="info" sx={{ mt: ".5rem" }}>
                          Event date was changed from{" "}
                          {eventDateFormatted(
                            eventDate(true),
                            back_office.timezone,
                            back_office.date_format
                          )}{" "}
                          to{" "}
                          {eventDateFormatted(
                            eventDate(false),
                            back_office.timezone,
                            back_office.date_format
                          )}
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                )}
                {proposal.proposal_sections
                  .filter(
                    (section) =>
                      section.enabled &&
                      (!props.print ||
                        !ProposalSectionsByType[section.section_type]
                          .disablePrint)
                  )
                  .sort(sort_by_order)
                  .map((proposal_section) => (
                    <ProposalSectionView
                      key={proposal_section.uuid}
                      proposal_section={proposal_section}
                    />
                  ))}
              </Box>
            </Box>
          </Paper>
        </Box>
      ) : fullScreen ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress
            sx={{
              m: "auto",
              mt: "50%",
            }}
          />
        </Box>
      ) : (
        <LinearProgress
          sx={{
            mt: "50%",
            ml: "10%",
            mr: "10%",
            p: "5px",
          }}
        />
      )}
    </Box>
  );
});

function ProposalSectionView(props) {
  const Component =
    ProposalSectionsByType[props.proposal_section.section_type].view_component;
  return (
    <>
      <Box
        sx={{
          mt: ProposalSectionsByType[props.proposal_section.section_type]
            .component_props.disableTopMargin
            ? undefined
            : "2rem",
        }}
        className="proposal-section"
      >
        {props.proposal_section.style?.show_heading &&
          props.proposal_section.style?.heading &&
          !ProposalSectionsByType[props.proposal_section.section_type]
            .component_props.disableHeading && (
            <ProposalSectionHeader proposal_section={props.proposal_section} />
          )}
        <Box sx={{ pt: ".5rem" }}>
          {Component && (
            <Component
              proposal_section={props.proposal_section}
              {...ProposalSectionsByType[props.proposal_section.section_type]
                .component_props}
            />
          )}
        </Box>
      </Box>
      <div className="page-break" />
    </>
  );
}

const ProposalSectionHeader = (props) => {
  const [proposal] = useAccountState((state) => [state.proposal]);
  return (
    <Box sx={{ p: ".25rem" }}>
      <Typography
        // variant="h5"
        sx={{
          fontFamily:
            props.proposal_section.style?.heading_font ||
            DefaultStyles.fontFamily,
          color:
            props.proposal_section.style?.heading_font_color ||
            DefaultStyles.fontColor,
          fontSize:
            props.proposal_section.style?.heading_font_size ||
            DefaultStyles.fontSize,
          fontWeight: "bold",
          textAlign:
            props.proposal_section.style?.heading_text_align ||
            DefaultStyles.textAlign,
        }}
      >
        {props.proposal_section.style?.heading}
      </Typography>
      {props.proposal_section.style?.heading_underline && (
        <Divider
          sx={{
            mr: "auto",
            borderColor:
              props.proposal_section.style?.heading_font_color ||
              DefaultStyles.fontColor,
            "&:before": {
              borderTop: "solid 2px",
              borderColor:
                props.proposal_section.style?.heading_font_color ||
                DefaultStyles.fontColor,
            },
            "&:after": {
              borderTop: "solid 2px",
              borderColor:
                props.proposal_section.style?.heading_font_color ||
                DefaultStyles.fontColor,
            },
          }}
        ></Divider>
      )}
    </Box>
  );
};

export const FormError = (props) => {
  return <Typography sx={{ color: "error.main" }}>{props.children}</Typography>;
};

export const ProposalViewDialog = (props) => {
  const [event] = useAccountState((state) => [state.event]);

  return (
    <Dialog
      fullScreen={true}
      open={props.open}
      onClose={props.handleCancel}
      PaperProps={{ sx: { backgroundColor: "background.default" } }}
    >
      <DialogTitle>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs="auto">
            {event.name} Proposal Preview
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={props.handleCancel}>
              <DisabledByDefaultOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ProposalView />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <Grid item xs={"auto"}>
            <Button
              onClick={props.handleCancel}
              color="info"
              variant="outlined"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ProposalView;
