import { unsecureAPI } from "../api";

export const getGoalProgress = (year) => {
  return unsecureAPI.get(`api/goals/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: {
      year: year,
    },
  });
};
