import React from "react";
import { withRouter } from "react-router-dom";
import {
  itemDisplayName,
  materialDisplaySize,
  materialTypeName,
} from "../utils/item_utils";

import {
  Grid,
  Typography,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  CircularProgress,
  Box,
  TablePagination,
  Autocomplete,
  TextField,
} from "@mui/material";

import {
  formatDate,
  formatStringForId,
  format_currency,
  sortByDate,
} from "../utils/utils";
import { useAccountState } from "../state/store";
import {
  TransactionName,
  sortInventoryItemOptions,
} from "../utils/inventory_utils";
import { ExpandButton } from "./EventItem";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { eventDateFormatted, eventNameAndDate } from "../utils/event_utils";
import { RenderColors } from "./InventoryItems";
import { getUserEvents } from "../api/EventsApi";
import { useLocaleContext } from "./locale";

const InventoryLog = (props) => {
  const [
    back_office,
    initializeBackOffice,
    inventoryItems,
    inventoryTransactions,
    getInventoryTransactions,
  ] = useAccountState((state) => [
    state.back_office,
    state.initializeBackOffice,
    state.inventoryItems,
    state.inventoryTransactions,
    state.getInventoryTransactions,
  ]);
  const page_size_options = [10, 25, 50];
  const [limit, setLimit] = React.useState(page_size_options[0]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [next, setNext] = React.useState(null);
  const [previous, setPrevious] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState([]);
  const [event, setEvent] = React.useState(null);
  const [inventoryItem, setInventoryItem] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);
    getInventoryTransactions(
      limit,
      pageNumber * limit,
      inventoryItem?.uuid,
      event?.uuid,
      (res) => {
        setLoading(false);
        setCount(res.count);
        setNext(res.next);
        setPrevious(res.previous);
        console.log(res);
      }
    );
  }, [limit, pageNumber, inventoryItem, event]);

  React.useEffect(() => {
    getUserEvents(false, true).then((res) => {
      setEvents(res.data.events);
      initializeBackOffice(res.data.back_office);
    });
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="inventory_item"
            options={sortInventoryItemOptions(inventoryItems)}
            getOptionLabel={(option) => {
              return itemDisplayName(option.item);
            }}
            groupBy={(option) => materialTypeName(option.item.type)}
            onChange={(event, value) => {
              setInventoryItem(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Item"
                variant="outlined"
                fullWidth
              />
            )}
            value={inventoryItem}
            isOptionEqualToValue={(option, value) => option.uuid === value}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="event"
            options={events}
            getOptionLabel={(option) => {
              return (
                option.name +
                " - " +
                eventDateFormatted(
                  option.date,
                  option.timezone,
                  back_office.date_format
                )
              );
            }}
            // groupBy={(option) => materialTypeName(option.item.type)}
            onChange={(event, value) => {
              setEvent(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Event"
                variant="outlined"
                fullWidth
              />
            )}
            value={event}
            isOptionEqualToValue={(option, value) => option.uuid === value}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Inventory Item</TableCell>
              <TableCell sx={{ fontSize: ".65rem" }}>
                Total / In Stock / Rented / Receiving / Purchased
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress sx={{ m: "auto" }} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : inventoryTransactions.length > 0 ? (
            <TableBody>
              {inventoryTransactions.sort(sortByDate).map((transaction) => (
                <InventoryLogRow inventory_transaction={transaction} />
              ))}
            </TableBody>
          ) : (
            <Box
              sx={{
                border: "dashed 2px",
                borderColor: "info.light",
                borderRadius: "10px",
                p: "1rem",
              }}
            >
              <Typography textAlign={"center"} color="info.main">
                No inventory transactions. Go to the "Inventory" or "Purchases"
                tab to add items to your inventory.
              </Typography>
            </Box>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={page_size_options}
          component="div"
          count={count}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => parseInt(setLimit(e.target.value))}
          page={pageNumber}
          onPageChange={(e, newPage) => setPageNumber(newPage)}
        />
      </Grid>
    </Grid>
  );
};

const InventoryLogRow = (props) => {
  const locale = useLocaleContext();
  const [expanded, setExpanded] = React.useState(false);
  const toggleOpen = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <TableRow>
        <TableCell>
          {formatDate(props.inventory_transaction.date, locale)}
        </TableCell>
        <TableCell>
          {TransactionName(props.inventory_transaction.transaction_type)}
        </TableCell>
        <TableCell>
          <Grid container>
            <Grid item xs={12}>
              {materialTypeName(
                props.inventory_transaction.inventory_item.item.type
              ) +
                " " +
                props.inventory_transaction.inventory_item.item.name}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ color: "info.main", fontSize: ".75rem" }}
              container
              spacing={1}
            >
              <Grid item xs="auto">
                {RenderColors(props.inventory_transaction.inventory_item.item)}
              </Grid>
              <Grid item xs="auto">
                {materialDisplaySize(
                  props.inventory_transaction.inventory_item.item
                )}
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          {" "}
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={2}>
              {props.inventory_transaction.quantity_total}
            </Grid>
            <Grid item xs={2}>
              {props.inventory_transaction.quantity_in_stock}
            </Grid>
            <Grid item xs={2}>
              {props.inventory_transaction.quantity_pulled}
            </Grid>
            <Grid item xs={2}>
              {props.inventory_transaction.quantity_receiving}
            </Grid>
            <Grid item xs={2}>
              {props.inventory_transaction.quantity_purchased}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <ExpandButton
            id={formatStringForId(
              props.inventory_transaction.uuid + "-expand-button"
            )}
            expand={expanded}
            size="large"
            onClick={toggleOpen}
          >
            <KeyboardArrowDownOutlinedIcon fontSize="inherit" />
          </ExpandButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={expanded}>
            <InventoryTransactionDetails
              inventory_transaction={props.inventory_transaction}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const InventoryTransactionDetails = (props) => {
  return (
    <Grid container sx={{ p: "1rem" }}>
      {props.inventory_transaction.transaction_type === "PU" && (
        <>
          <InventoryTransactionDetailItem
            label={"Rented"}
            value={props.inventory_transaction.quantity}
          />
          <InventoryTransactionDetailItem
            label={"Sold"}
            value={props.inventory_transaction.sold}
            shaded
          />
          <InventoryTransactionDetailItem
            label={"Sold Value"}
            value={format_currency(props.inventory_transaction.sold_value)}
          />
          <InventoryTransactionDetailItem
            shaded
            label={"Associated Event"}
            value={
              props.inventory_transaction.event ? (
                <Link
                  href={`/event/${props.inventory_transaction.event.uuid}`}
                  target="_blank"
                  color="inherit"
                >
                  {eventNameAndDate(props.inventory_transaction.event)}
                </Link>
              ) : (
                "None"
              )
            }
          />
        </>
      )}
      {props.inventory_transaction.transaction_type === "ST" && (
        <>
          <InventoryTransactionDetailItem
            label={"Stocked"}
            value={props.inventory_transaction.quantity}
          />
          <InventoryTransactionDetailItem
            label={"Lost"}
            value={props.inventory_transaction.lost}
            shaded
          />
          <InventoryTransactionDetailItem
            shaded
            label={"Associated Event"}
            value={
              props.inventory_transaction.event ? (
                <Link
                  href={`/event/${props.inventory_transaction.event.uuid}`}
                  target="_blank"
                  color="inherit"
                >
                  {eventNameAndDate(props.inventory_transaction.event)}
                </Link>
              ) : (
                "None"
              )
            }
          />
        </>
      )}
      {props.inventory_transaction.transaction_type === "RS" && (
        <>
          <InventoryTransactionDetailItem
            label={"Re-Stocked"}
            value={props.inventory_transaction.quantity}
          />
          <InventoryTransactionDetailItem
            label={"Lost / Stolen / Damaged"}
            value={props.inventory_transaction.lost}
            shaded
          />
          <InventoryTransactionDetailItem
            label={"Associated Event"}
            value={
              props.inventory_transaction.event ? (
                <Link
                  href={`/event/${props.inventory_transaction.event.uuid}`}
                  target="_blank"
                  color="inherit"
                >
                  {eventNameAndDate(props.inventory_transaction.event)}
                </Link>
              ) : (
                "None"
              )
            }
          />
        </>
      )}
      {props.inventory_transaction.transaction_type === "AD" && (
        <>
          <InventoryTransactionDetailItem
            label={"Total Adjustment"}
            value={props.inventory_transaction.adjust_total}
          />
          <InventoryTransactionDetailItem
            label={"In Stock Adjustment"}
            value={props.inventory_transaction.adjust_in_stock}
            shaded
          />
          <InventoryTransactionDetailItem
            label={"Rented Adjustment"}
            value={props.inventory_transaction.adjust_pulled}
          />
          <InventoryTransactionDetailItem
            label={"Receiving Adjustment"}
            value={props.inventory_transaction.adjust_receiving}
            shaded
          />
          <InventoryTransactionDetailItem
            label={"Purchased Adjustment"}
            value={props.inventory_transaction.adjust_purchased}
          />
          <InventoryTransactionDetailItem
            label={"Lost"}
            value={props.inventory_transaction.lost}
            shaded
          />
          <InventoryTransactionDetailItem
            label={"Stolen"}
            value={props.inventory_transaction.stolen}
          />
          <InventoryTransactionDetailItem
            shaded
            label={"Associated Event"}
            value={
              props.inventory_transaction.event ? (
                <Link
                  href={`/event/${props.inventory_transaction.event.uuid}`}
                  target="_blank"
                  color="inherit"
                >
                  {eventNameAndDate(props.inventory_transaction.event)}
                </Link>
              ) : (
                "None"
              )
            }
          />
        </>
      )}
      {props.inventory_transaction.notes && (
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <Typography sx={{ color: "info.main", fontSize: ".75rem" }}>
              Notes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{props.inventory_transaction.notes}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const InventoryTransactionDetailItem = (props) => {
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent={"space-between"}
      sx={
        props.shaded
          ? {
              backgroundColor: "info.main",
              color: "white",
              pl: ".25rem",
              pr: ".25rem",
            }
          : { color: "info.main", pl: ".25rem", pr: ".25rem" }
      }
    >
      <Grid item xs="auto" sx={{ fontSize: ".75rem" }}>
        {props.label}
      </Grid>
      <Grid item xs="auto" sx={{ fontSize: ".75rem", textAlign: "right" }}>
        {props.value}
      </Grid>
    </Grid>
  );
};

export default withRouter(InventoryLog);
