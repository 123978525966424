export const sortPayments = (payments, signing_date, event_date) => {
  const _signing_date = signing_date ? new Date(signing_date) : new Date();
  var _event_date = event_date;
  if (_event_date) {
    _event_date = new Date(_event_date);
  } else {
    _event_date = new Date();
    _event_date.setFullYear(_event_date.getFullYear() + 1);
  }
  const payments_with_date = payments.map((payment) => ({
    ...payment,
    date: getPaymentDate(payment, _signing_date, _event_date),
  }));

  const sorted_payments = payments_with_date.sort(sortByDate);
  return sorted_payments;
};

export const getPaymentDate = (payment, signing_date, event_date) => {
  const date =
    payment.due_event === "SI" ? new Date(signing_date) : new Date(event_date);
  const sign = payment.due_direction === "BE" ? -1 : 1;
  const offset = payment.due_offset ? payment.due_offset : 0;
  date.setDate(date.getDate() + offset * sign);
  return date;
};

const paymentDueDirectionString = (due_direction) => {
  return due_direction === "BE" ? "Before" : "After";
};

const paymentDueEventString = (due_event) => {
  return due_event === "SI" ? "Signing" : "Event";
};

export const getPaymentMethodString = (payment_method) => {
  if (payment_method === "CH") {
    return "Check";
  } else if (payment_method === "CA") {
    return "Cash";
  } else if (payment_method === "ST") {
    return "Web Payment";
  } else if (payment_method === "OT") {
    return "Other";
  }
};

export const getPaymentRelativeDate = (payment) => {
  return (
    payment.due_offset +
    " Days " +
    paymentDueDirectionString(payment.due_direction) +
    " " +
    paymentDueEventString(payment.due_event)
  );
};

const sortByDate = (a, b) => a.date - b.date;

export const webPaymentsEnabled = (payment_schedule, user) => {
  return payment_schedule.enable_web_payments;
};
