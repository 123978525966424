import { unitOtherCostPrice } from "./other_cost_utils";
import { recipePrice } from "./recipe_utils";
import {
  percentField,
  priceField,
  quantityField,
  salesTaxField,
  twoDec,
} from "./utils";

export const AmendableEventItemFields = [
  "quantity",
  "price",
  "is_optional",
  "is_selected",
  "sales_tax",
  "is_percent",
  "percent",
  "markup_percentage",
];

const filterEventItems = (event_items, event_item_type) => {
  return event_items.filter(
    (event_item) => event_item.event_item_type === event_item_type
  );
};

export const recipes = (event_items) => {
  return filterEventItems(event_items, "RE");
};

export const other_costs = (event_items) => {
  return filterEventItems(event_items, "OT");
};

export const taxes = (event_items, nonSpreadableOnly) => {
  if (nonSpreadableOnly) {
    return filterEventItems(event_items, "TA").filter((tax) => !tax.is_percent);
  } else {
    return filterEventItems(event_items, "TA");
  }
};

export function eventItemHasAmendments(event_item) {
  var res = false;
  AmendableEventItemFields.forEach((field) => {
    if (field == "price") {
      if (
        event_item["amend_" + field] !== null &&
        parseFloat(event_item[field]) !==
          parseFloat(event_item["amend_" + field])
      ) {
        res = true;
      }
    } else {
      if (
        event_item["amend_" + field] !== null &&
        event_item[field] !== event_item["amend_" + field]
      ) {
        res = true;
      }
    }
  });
  return res;
}

export function eventItemsWithAmendments(event_items) {
  return event_items.filter((event_item) => eventItemHasAmendments(event_item))
    .length;
}

export function priceNotEqualSuggested(event_item, suggested_price) {
  return (
    (suggested_price || suggested_price === 0) &&
    priceField(event_item) !== null &&
    !priceIsDerived(event_item) &&
    parseFloat(priceField(event_item)).toFixed(2) !==
      parseFloat(suggested_price).toFixed(2)
  );
}

export function priceBelowSuggested(event_item, suggested_price, tolerance) {
  if (!tolerance) {
    tolerance = 0;
  }
  return (
    suggested_price &&
    priceField(event_item) !== null &&
    priceField(event_item) !== undefined &&
    !priceIsDerived(event_item) &&
    twoDec(parseFloat(suggested_price)) -
      twoDec(parseFloat(priceField(event_item))) >
      tolerance
  );
}

export const eventItemTypeName = (event_item) => {
  switch (event_item.event_item_type) {
    case "RE":
      return "Recipe";
    case "OT":
      if (event_item.is_percent) {
        return "Percent";
      } else {
        return "Fixed";
      }
    case "TA":
      return "Tax";
    default:
      return "Group";
  }
};

export const priceIsDerived = (event_item) => {
  if (
    event_item.event_item_type !== "TA" &&
    event_item.event_item_type !== "OT"
  ) {
    return false;
  } else if (event_item.event_item_type === "OT" && !event_item.is_percent) {
    return false;
  } else {
    return event_item.price_is_derived;
  }
};
