import React from "react";

import { format } from "date-fns";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Tooltip,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getTransactionsApi, retryCurrentPaymentApi } from "../api/StripeApi";
import { getDelinquentEvents } from "../api/EventsApi";
import { NewPaymentDetailsWrapper } from "./PaymentDetails";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { format_currency, titleCase } from "../utils/utils";
import { useAccountState } from "../state/store.js";

export const PaymentHistory = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const page_size_options = [25, 50, 100];
  const [transactions, setTransactions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(page_size_options[0]);
  const [delinquentEvents, setDelinquentEvents] = React.useState([]);
  const [retryCurrentPayment, setRetryCurrentPayment] = React.useState(false);
  const [retryPaymentMessage, setRetryPaymentMessage] =
    React.useState(undefined);
  const [allowRetryCurrent, setAllowRetryCurrent] = React.useState(true);
  const [retryingPayment, setRetryingPayment] = React.useState(false);
  const [updatingPayment, setUpdatingPayment] = React.useState(false);

  const total_delinquent_amount = () => {
    return delinquentEvents.reduce(
      (partial, event) => partial + parseFloat(event.flowerbuddy_fee_cost),
      0
    );
  };

  const doRetryCurrentPayment = () => {
    setRetryingPayment(true);
    setAllowRetryCurrent(false);
    retryCurrentPaymentApi().then((resp) => {
      console.log(resp);
      setDelinquentEvents(resp.data.delinquent_events);
      setRetryPaymentMessage(resp.data.message);
      if (resp.data.user) {
        props.updateUserInState(resp.data.user);
      }
      reloadTransactions();
      setRetryingPayment(false);
    });
  };

  const toUnix = (d) => {
    if (d) {
      return Math.floor(new Date(d).getTime() / 1000);
    } else {
      return undefined;
    }
  };

  const reloadTransactions = () => {
    setIsLoading(true);
    getTransactionsApi(
      pageSize,
      pageNumber * pageSize,
      startDate,
      endDate
    ).then((resp) => {
      console.log(resp);
      setTransactions(resp.data.results);
      setTotalPages(resp.data.count);
      setIsLoading(false);
    });
  };

  React.useEffect(() => {
    reloadTransactions(pageSize);
    getDelinquentEvents().then((resp) => {
      setDelinquentEvents(resp.data);
    });
  }, [pageNumber, pageSize]);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      type: "date",

      valueFormatter: formatCreated,
      valueGetter: (params) => new Date(params.row.created),
      sortable: false,
      hideable: false,
      minWidth: 85,
    },
    {
      field: "invoice_total",
      headerName: "Invoice Total",

      valueGetter: (params) => params.row.total,
      valueFormatter: formatAmount,
      sortable: false,
      hideable: false,
      hide: fullScreen,
      minWidth: 110,
    },
    {
      field: "amount_paid",
      // headerName: "Amount Paid",
      renderHeader: (params) => (
        <Grid container alignItems="center">
          <Grid item xs="auto">
            <strong>Amount Paid</strong>
          </Grid>
          {!fullScreen ? (
            <Grid item xs="auto">
              <Tooltip title="Total invoice minus any applicable FlowerBuddy account credit">
                <HelpOutlineOutlinedIcon fontSize="inherit" />
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      ),
      valueGetter: (params) => params.row.amount_paid,
      valueFormatter: formatAmount,
      sortable: false,
      hideable: false,
      minWidth: 120,
    },
    {
      field: "description",
      headerName: "Description",
      valueGetter: (params) =>
        params.row.description
          ? params.row.description
          : params.row.payment_intent?.description
          ? params.row.payment_intent.description
          : params.row.billing_reason
          ? titleCase(params.row.billing_reason.replace("_", " "))
          : "",
      sortable: false,
      hideable: false,
      minWidth: 200,
    },
  ];

  const getTransactionId = (transaction) => {
    return transaction.id;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Payments</Typography>
      </Grid>
      <Grid item xs={12}>
        {delinquentEvents.length > 0 ? (
          <Box
            sx={{
              border: "solid 1px",
              borderColor: "error.main",
              borderRadius: "5px",
              p: ".5rem",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">Failed Payments</Typography>
                <Typography>
                  Collection of the FlowerBuddy fee for the following events
                  failed. Please retry the payment as soon as possible to avoid
                  disruption to your account.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Event</TableCell>
                        <TableCell>FlowerBuddy Fee</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {delinquentEvents.map((event) => (
                        <DelinquentEventLine event={event} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {!retryingPayment ? (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs="auto">
                    {props.user.stripe_customer.default_payment_method &&
                    allowRetryCurrent ? (
                      <Typography color="info.main">
                        <Link
                          color="inherit"
                          onClick={() => {
                            setRetryCurrentPayment(true);
                          }}
                          sx={{ "&:hover": { cursor: "pointer" } }}
                        >
                          Try Again With Existing Payment Method
                        </Link>
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setUpdatingPayment(true)}
                    >
                      Update Payment Method
                    </Button>
                  </Grid>
                  {props.user.stripe_customer.default_payment_method ? (
                    <RetryExistingPaymentDialog
                      stripe_customer={props.user.stripe_customer}
                      total_amount={total_delinquent_amount()}
                      open={retryCurrentPayment}
                      handleCancel={() => {
                        setRetryCurrentPayment(false);
                      }}
                      handleSubmit={doRetryCurrentPayment}
                    />
                  ) : null}
                  <UpdatePaymentDialog
                    open={updatingPayment}
                    handleCancel={() => setUpdatingPayment(false)}
                    callback={doRetryCurrentPayment}
                    setStripeCustomer={props.setStripeCustomer}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} container justifyContent="flex-end">
                  <Grid item xs="auto">
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                {retryPaymentMessage ? (
                  <Alert severity="error">{retryPaymentMessage}</Alert>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={transactions}
          columns={columns}
          getRowId={getTransactionId}
          autoHeight
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          rowHeight={55}
          density="compact"
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={page_size_options}
          pagination
          paginationMode="server"
          loading={isLoading}
          rowCount={totalPages}
          onPageChange={(newPage) => setPageNumber(newPage)}
          sx={{ border: "none" }}
        />
      </Grid>
    </Grid>
  );
};

const formatCreated = (params) => {
  return format(params.value, "MM/dd/yy");
};

const formatAmount = (params) => {
  return format_currency(params.value);
};

const DelinquentEventLine = (props) => {
  return (
    <TableRow>
      <TableCell>
        <Typography sx={{ fontColor: "primary.main" }}>
          <Link color="inherit" href={"/event/" + props.event.uuid}>
            {props.event.name}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>{format_currency(props.event.flowerbuddy_fee_cost)}</TableCell>
    </TableRow>
  );
};

const RetryExistingPaymentDialog = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Confirm Retry Payment</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to retry payment with your exiting payment
          method? The card ending in{" "}
          {props.stripe_customer.default_payment_method.card.last4} will be
          charged a total of {formatCurrency(props.total_amount)}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            props.handleCancel();
            props.handleSubmit();
          }}
        >
          Retry Payment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdatePaymentDialog = (props) => {
  return (
    <NewPaymentDetailsWrapper
      handleCancel={props.handleCancel}
      setStripeCustomer={props.setStripeCustomer}
      callback={props.callback}
      open={props.open}
      onClose={props.handleCancel}
    />
  );
};
