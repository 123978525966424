import React from "react";
import { withRouter } from "react-router-dom";
import {
  Paper,
  Box,
  Grid,
  Typography,
  IconButton,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  AvatarGroup,
  Divider,
  Chip,
  Menu,
  MenuItem,
  Autocomplete,
  TextField,
  createFilterOptions,
  CircularProgress,
  useMediaQuery,
  SwipeableDrawer,
  FormControlLabel,
  Switch,
  Button,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { useAccountState } from "../state/store";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { format_percent, quantityField, twoDec } from "../utils/utils";
import { ItemIcon } from "./ItemIcon";
import CroppedImg from "./CroppedImg";
import { sort } from "fast-sort";
import {
  itemDisplayName,
  materialTypeName,
  recipeFlowers,
  recipeItemsByType,
  recipeMaterials,
} from "../utils/item_utils";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { NotesSection } from "./EventItem";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { DatePicker } from "@mui/x-date-pickers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import BuildPdf from "./BuildPdf";
import { BuildPDFSettings } from "./BackOfficeSettings";
import { track_event } from "../utils/event_tracking";
import InventoryLogDialog from "./InventoryLogDialog";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { AddInventoryItemDialog } from "./InventoryItems";
import { AddInventoryItemToPurchaseDialog } from "./InventoryPurchases";
import AddItemDialog from "./AddItemDialog";
import { getInventoryItemInfoApi } from "../api/InventoryApi";
import { set } from "lodash";
import { RichTextFieldView } from "./RichTextField.js";
import { FileDropZoneDialog } from "./FileUploader.js";

const Build = (props) => {
  const [
    back_office,
    event,
    recipeBuildLaborHoursTotal,
    recipeInstallLaborHoursTotal,
    recipeBuildLaborPercentCompleteTotal,
    recipes,
    recipesAndGroups,
    updateBackOffice,
  ] = useAccountState((state) => [
    state.back_office,
    state.event,
    state.recipeBuildLaborHoursTotal,
    state.recipeInstallLaborHoursTotal,
    state.recipeBuildLaborPercentCompleteTotal,
    state.recipes,
    state.recipesAndGroups,
    state.updateBackOffice,
  ]);

  const togglePublished = () => {
    updateBackOffice({
      public_build_published: !back_office.public_build_published,
    });
  };

  const toggleExpires = () => {
    let update = {
      public_build_expires: !back_office.public_build_expires,
    };
    if (!back_office.public_build_expires) {
      let event_date = new Date(event.date);
      event_date.setDate(
        event_date.getDate() + back_office.public_build_default_expiration_days
      );
      update = { ...update, public_build_expiration_date: event_date };
    }
    updateBackOffice(update);
  };

  const updateExpirationDate = (val) => {
    updateBackOffice({
      public_build_expiration_date: val,
    });
  };

  const [copied, setCopied] = React.useState(false);
  const [qrOpen, setQrOpen] = React.useState(false);
  const [printableSettingsOpen, setPrintableSettingsOpen] =
    React.useState(false);
  const [printableOpen, setPrintableOpen] = React.useState(false);

  return (
    <Box sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
      <Box sx={{ mb: "calc(100vh/2)" }}>
        <Grid
          container
          sx={{ width: "100%", maxWidth: "800px", m: "auto" }}
          rowSpacing={1}
          justifyContent={"space-between"}
        >
          <Grid item xs="auto" container justifyContent={"flex-start"}>
            <Grid item xs="auto">
              <FormControlLabel
                control={
                  <Switch
                    checked={back_office.public_build_published}
                    onChange={togglePublished}
                    id="public-build-published-switch"
                  />
                }
                label={"Published"}
                labelPlacement="bottom"
              />
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                control={
                  <Switch
                    checked={back_office.public_build_expires}
                    onChange={toggleExpires}
                    id="public-build-expires-switch"
                  />
                }
                label={"Expires"}
                labelPlacement="bottom"
              />
            </Grid>
            {back_office.public_build_expires ? (
              <Grid item xs="auto">
                <DatePicker
                  inputFormat={back_office.date_format}
                  name="expire_date"
                  id="expire_date"
                  value={new Date(back_office.public_build_expiration_date)}
                  onChange={updateExpirationDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="filled"
                      size="small"
                      hiddenLabel
                    />
                  )}
                  fullWidth
                  label={"Expiration Date"}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            xs="auto"
            container
            spacing={1}
            justifyContent={"flex-end"}
          >
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                size="small"
                onClick={() => setPrintableSettingsOpen(true)}
                id="build-pdf-button"
              >
                Print PDF
              </Button>
              <PrintablePDFSettingsDialog
                open={printableSettingsOpen}
                handleCancel={() => setPrintableSettingsOpen(false)}
                handleSubmit={() => {
                  setPrintableSettingsOpen(false);
                  setPrintableOpen(true);
                  track_event("Build PDF Generated", {
                    event_uuid: event.uuid,
                  });
                }}
              />
              <PrintablePDFDialog
                open={printableOpen}
                handleCancel={() => setPrintableOpen(false)}
              />
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                size="small"
                onClick={() => {
                  setQrOpen(true);
                  track_event("Build QR Code Generated", {
                    event_uuid: event.uuid,
                  });
                }}
                id="build-qr-code-button"
              >
                Generate QR Code
              </Button>
              <QrCodeDialog
                open={qrOpen}
                handleCancel={() => setQrOpen(false)}
              />
            </Grid>
            <Grid item xs="auto">
              <CopyToClipboard
                text={process.env.REACT_APP_BASE_URL + "/build/" + event.uuid}
                onCopy={() => {
                  setCopied(true);
                  track_event("Build Link Copied", { event_uuid: event.uuid });
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  id="build-copy-link-button"
                >
                  Copy Sharable Link
                </Button>
              </CopyToClipboard>
              <Snackbar
                open={copied}
                autoHideDuration={3000}
                onClose={() => {
                  setCopied(false);
                }}
                message="Link copied!"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: "1rem" }} id="build-summary">
              <Grid
                container
                alignItems={"center"}
                justifyContent="space-evenly"
              >
                <Grid item xs="auto">
                  <CircularProgressWithLabel
                    value={recipeBuildLaborPercentCompleteTotal()}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TotalHoursTable />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <MaterialPullList />
          </Grid>
          {recipesAndGroups()
            .filter((recipe) => !recipe.is_optional || recipe.is_selected)
            .filter(
              (recipe) =>
                recipe.event_item_type !== "GR" ||
                recipe.event_items_in_group.some(
                  (ei) => ei.event_item_type === "RE"
                )
            )
            .map((recipe) => (
              <Grid item xs={12}>
                <RecipeBuildCard key={recipe.uuid} recipe={recipe} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const PrintablePDFSettingsDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      sx={{ maxWidth: "450px", m: "auto" }}
    >
      <DialogTitle>Build PDF Settings</DialogTitle>
      <DialogContent>
        <Grid container>
          <BuildPDFSettings />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="build-pdf-settings-close-button"
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={props.handleSubmit}
          id="build-pdf-settings-generate-button"
        >
          Generate PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PrintablePDFDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel} fullScreen>
      <DialogContent>
        <BuildPdf />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="info"
              onClick={props.handleCancel}
              id="build-pdf-close-button"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const QrCodeDialog = (props) => {
  const [event] = useAccountState((state) => [state.event]);
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogContent id="qr-code-content">
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={process.env.REACT_APP_BASE_URL + "/build/" + event.uuid}
          viewBox={`0 0 256 256`}
        />
      </DialogContent>
    </Dialog>
  );
};

export function CircularProgressWithLabel(props) {
  const boxSize = () => {
    if (props.size) {
      if (props.size === 50) {
        return 4;
      } else if (props.size === 30) {
        return 3;
      } else {
        return 6;
      }
    } else {
      return 10;
    }
  };
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={props.value * 100}
        color="secondary"
        size={props.size ? props.size : 135}
      />
      <Box
        sx={{
          top: boxSize(),
          left: boxSize(),
          bottom: boxSize(),
          right: boxSize(),
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "solid lightgrey 1px",
          borderRadius: "50%",
        }}
      >
        {!props.noLabel ? (
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography
                textAlign="center"
                variant="caption"
                component="div"
                // color="text.secondary"
              >
                {format_percent(props.value)}
              </Typography>
            </Grid>
            {!props.numberOnly ? (
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  variant="caption"
                  // color="text.secondary"
                  component="div"
                >
                  Complete
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </Box>
    </Box>
  );
}

const TotalHoursTable = (props) => {
  const [
    recipeBuildLaborHoursComplete,
    recipeBuildLaborHoursRemaining,
    recipeBuildLaborHoursTotal,
    recipeInstallLaborHoursTotal,
  ] = useAccountState((state) => [
    state.recipeBuildLaborHoursComplete,
    state.recipeBuildLaborHoursRemaining,
    state.recipeBuildLaborHoursTotal,
    state.recipeInstallLaborHoursTotal,
  ]);
  return (
    <Table size="small">
      <TableRow>
        <TableCell></TableCell>
        <TableCell align="center">In Studio</TableCell>
        <TableCell align="center">Onsite</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Hours</TableCell>
        <TableCell align="center">
          {twoDec(recipeBuildLaborHoursTotal())}
        </TableCell>
        <TableCell align="center">
          {twoDec(recipeInstallLaborHoursTotal())}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Completed</TableCell>
        <TableCell align="center">
          {twoDec(recipeBuildLaborHoursComplete())}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Remaining</TableCell>
        <TableCell align="center">
          {twoDec(recipeBuildLaborHoursRemaining())}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </Table>
  );
};

const LaborHours = (props) => {
  return (
    <Grid item xs="auto" container sx={{ ml: "1rem", mr: "1rem" }}>
      <Grid item xs={12}>
        <Typography textAlign="center">{props.label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" fontWeight="bold">
          {twoDec(props.value)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const MaterialPullList = (props) => {
  const [event, materialCounts, user] = useAccountState((state) => [
    state.event,
    state.materialCounts,
    state.user,
  ]);
  const [loaded, setLoaded] = React.useState(false);
  const [inventoryItemInfo, setInventoryItemInfo] = React.useState({});

  React.useEffect(() => {
    getInventoryItemInfoApi(event.uuid).then((response) => {
      setInventoryItemInfo(response.data);
      setLoaded(true);
    });
  }, [event.uuid]);

  const [expanded, setExpanded] = React.useState(false);
  const toggleOpen = () => {
    setExpanded(!expanded);
  };
  return (
    <Paper sx={{ p: "1rem" }}>
      <Grid container alignItems={"center"} justifyContent="space-between">
        <Grid item xs="auto">
          <Typography variant="h6">Hard Good Pull List</Typography>
        </Grid>
        <Grid item xs="auto" container alignItems="center" spacing={1}>
          <Grid item xs="auto">
            <AvatarGroup>
              {materialCounts().map((material_count) => (
                <ItemIcon
                  key={material_count.item.thumbnail}
                  item={material_count.item}
                  width={30}
                  height={30}
                />
              ))}
            </AvatarGroup>
          </Grid>
          <Grid item xs="auto">
            <ExpandButton
              expand={expanded}
              onClick={toggleOpen}
              id="material-pull-list-expand-button"
            >
              <ExpandMoreOutlinedIcon fontSize="inherit" />
            </ExpandButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded}>
            {materialCounts().length > 0 ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Hard Good From Inventory</TableCell>
                    <TableCell align="center">Qty.</TableCell>
                    {user.inventory_enabled && (
                      <>
                        <TableCell align="center">Rented</TableCell>
                        <TableCell align="center">Sold</TableCell>
                        <TableCell />
                      </>
                    )}
                  </TableRow>
                </TableHead>
                {materialCounts()
                  .filter(
                    (material_count) =>
                      material_count.quantity_from_inventory > 0
                  )
                  .map((material_count) => {
                    return (
                      <HardGoodPullListItem
                        key={material_count.item.uuid}
                        material_count={material_count}
                        inventory_item_info={
                          inventoryItemInfo[material_count.item.uuid]
                        }
                        inventory
                        loaded={loaded}
                      />
                    );
                  })}
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={5}>
                      Hard Goods Rented or Purchased
                    </TableCell>
                  </TableRow>
                </TableHead>
                {materialCounts()
                  .filter(
                    (material_count) =>
                      material_count.quantity >
                      material_count.quantity_from_inventory
                  )
                  .map((material_count) => {
                    return (
                      <HardGoodPullListItem
                        key={material_count.item.uuid}
                        material_count={material_count}
                      />
                    );
                  })}
              </Table>
            ) : (
              <Typography textAlign="center">
                No hard goods in event.
              </Typography>
            )}
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
};

const HardGoodPullListItem = (props) => {
  const [event, inventoryItems, inventoryTransactions, user] = useAccountState(
    (state) => [
      state.event,
      state.inventoryItems,
      state.inventoryTransactions,
      state.user,
    ]
  );
  const [firstRender, setFirstRender] = React.useState(true);
  const [loaded, setLoaded] = React.useState(true);
  const [inventory_item_info, setInventoryItemInfo] = React.useState(
    props.inventory_item_info
  );

  React.useEffect(() => {
    setInventoryItemInfo(props.inventory_item_info);
  }, [props.inventory_item_info]);

  const FindInventoryItem = () => {
    return inventoryItems?.find(
      (inventory_item) =>
        inventory_item.item.uuid === props.material_count.item.uuid
    );
  };

  const [inventoryItem, setInventoryItem] = React.useState(FindInventoryItem());
  React.useEffect(() => {
    setInventoryItem(FindInventoryItem());
  }, [inventoryItems]);

  React.useEffect(() => {
    if (!firstRender && event && inventoryItem && user.inventory_enabled) {
      setLoaded(false);
      getInventoryItemInfoApi(event.uuid, inventoryItem.uuid).then(
        (response) => {
          setInventoryItemInfo(response.data[inventoryItem.item.uuid]);
          setLoaded(true);
        }
      );
    }
    setFirstRender(false);
  }, [
    inventoryItem?.quantity_total,
    inventoryItem?.quantity_pulled,
    inventoryItem?.quantity_in_stock,
  ]);

  const quantity = () => {
    return props.inventory
      ? props.material_count.quantity_from_inventory
      : props.material_count.quantity -
          props.material_count.quantity_from_inventory;
  };

  React.useEffect(() => {
    console.log(props.inventory_item_info);
  });

  return (
    <TableRow>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RenderItemName item={props.material_count.item} />
          </Grid>
          {props.inventory &&
            inventoryItem &&
            inventoryItem.inventory_location && (
              <Grid item xs={12}>
                <Typography color="info.main" variant="body2">
                  {`${inventoryItem?.inventory_location?.name}, ${inventoryItem?.inventory_location?.inventory_address?.name}`}
                </Typography>
              </Grid>
            )}
        </Grid>
      </TableCell>
      <TableCell align="center">{quantity()}</TableCell>
      {user.inventory_enabled && (
        <>
          <TableCell align="center">
            {props.inventory && inventory_item_info
              ? inventory_item_info.total_quantity_in_event -
                inventory_item_info.quantity_need_to_pull -
                inventory_item_info.total_quantity_sold
              : ""}
          </TableCell>
          <TableCell align="center">
            {props.inventory && inventory_item_info
              ? inventory_item_info.total_quantity_sold
              : ""}
          </TableCell>
          <TableCell align="left">
            {props.inventory ? (
              props.loaded && loaded ? (
                inventory_item_info && inventoryItem ? (
                  <>
                    {inventory_item_info.quantity_need_to_pull > 0 &&
                      inventoryItem.quantity_in_stock > 0 && (
                        <PullButton
                          inventory_item={inventoryItem}
                          hint_info={inventory_item_info}
                        />
                      )}
                    {inventoryItem.quantity_in_stock <
                      inventory_item_info.quantity_need_to_pull && (
                      <NotInStockButton
                        inventory_item={inventoryItem}
                        num_additional_needed={
                          inventory_item_info.quantity_need_to_pull -
                          inventoryItem.quantity_in_stock
                        }
                        hint_info={inventory_item_info}
                      />
                    )}
                    {inventory_item_info.quantity_need_to_pull === 0 &&
                      (inventory_item_info.quantity_need_to_restock > 0 ||
                        inventory_item_info.quantity_need_to_sell > 0) && (
                        <ReturnButton
                          inventory_item={inventoryItem}
                          hint_info={inventory_item_info}
                        />
                      )}
                  </>
                ) : (
                  <NotInInventoryButton item={props.material_count.item} />
                )
              ) : (
                <CircularProgress />
              )
            ) : null}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const recipeItemsFromEventItems = (event_items, item) => {
  let recipe_items = event_items.map((event_item) => {
    return event_item.recipe_items
      .filter((ri) => ri.item.uuid === item.uuid)
      .map((recipe_item) => {
        return {
          ...recipe_item,
          event_item: event_item,
        };
      });
  });
  return recipe_items.flat();
};

const PullButton = (props) => {
  const [back_office, event, event_items, item_prices] = useAccountState(
    (state) => [
      state.back_office,
      state.event,
      state.event_items,
      state.item_prices,
    ]
  );
  const [actioning, setActioning] = React.useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => setActioning(true)}
      >
        Pull
      </Button>
      <InventoryLogDialog
        open={actioning}
        inventory_item={props.inventory_item}
        transaction_type="PU"
        handleCancel={() => setActioning(false)}
        initialValues={{
          event_id: event.uuid,
        }}
        auto
        hint_info={props.hint_info}
      />
    </>
  );
};

const ReturnButton = (props) => {
  const [back_office, event, event_items, item_prices] = useAccountState(
    (state) => [
      state.back_office,
      state.event,
      state.event_items,
      state.item_prices,
    ]
  );
  const [actioning, setActioning] = React.useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => setActioning(true)}
      >
        Return
      </Button>
      <InventoryLogDialog
        open={actioning}
        inventory_item={props.inventory_item}
        transaction_type="RS"
        handleCancel={() => setActioning(false)}
        initialValues={{
          event_id: event.uuid,
        }}
        auto
        hint_info={props.hint_info}
      />
    </>
  );
};

const NotInInventoryButton = (props) => {
  const [actioning, setActioning] = React.useState(false);
  return (
    <>
      <Tooltip title="Item not in inventory. Click to add it.">
        <IconButton onClick={() => setActioning(true)}>
          <WarningAmberOutlinedIcon />
        </IconButton>
      </Tooltip>
      <AddInventoryItemDialog
        open={actioning}
        handleCancel={() => setActioning(false)}
        item={props.item}
        auto
      />
    </>
  );
};

export const NotInStockButton = (props) => {
  const [event] = useAccountState((state) => [state.event]);

  const [open, setOpen] = React.useState(false);
  const [addingToShoppingList, setAddingToShoppingList] = React.useState(false);
  const [adjusting, setAdjusting] = React.useState(false);
  return (
    <>
      <Tooltip title="Not enough inventory in stock. Click to adjust this items inventory.">
        <IconButton onClick={() => setOpen(true)}>
          <WarningAmberOutlinedIcon />
        </IconButton>
      </Tooltip>
      <PurchaseOrAdjustDialog
        open={open}
        handleCancel={() => setOpen(false)}
        setAddingToShoppingList={setAddingToShoppingList}
        setAdjusting={setAdjusting}
        item={props.inventory_item.item}
      />
      <AddInventoryItemToPurchaseDialog
        open={addingToShoppingList}
        handleCancel={() => setAddingToShoppingList(false)}
        initialValues={{
          inventory_item: props.inventory_item,
          quantity: props.num_additional_needed,
        }}
      />
      <InventoryLogDialog
        open={adjusting}
        inventory_item={props.inventory_item}
        transaction_type="AD"
        handleCancel={() => setAdjusting(false)}
        initialValues={{
          event_id: event.uuid,
          adjust_total:
            props.inventory_item.quantity_in_stock +
            props.num_additional_needed,
          adjust_in_stock:
            props.num_additional_needed +
            props.inventory_item.quantity_in_stock,
        }}
        hint_info={props.hint_info}
        auto
      />
    </>
  );
};

const PurchaseOrAdjustDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        Not Enough Inventory in Stock for {itemDisplayName(props.item)}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} justifyContent={"center"}>
          <Grid item xs={7}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                props.setAddingToShoppingList(true);
                props.handleCancel();
              }}
              fullWidth
            >
              Add to Shopping List
            </Button>
          </Grid>
          <Grid item xs={7}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                props.setAdjusting(true);
                props.handleCancel();
              }}
              fullWidth
            >
              Adjust Inventory Stock
            </Button>
          </Grid>
          <Grid item xs={7}>
            <Button
              variant="outlined"
              color="info"
              onClick={props.handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const sortRecipeImgs = (recipe_imgs) => {
  return sort(recipe_imgs).by([{ asc: (r) => r.position }]);
};

export const RecipeBuildCard = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = React.useState(false);
  const toggleOpen = () => {
    setExpanded(!expanded);
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  return (
    <Paper sx={{ boxShadow: 4 }}>
      <Paper
        sx={{
          p: "0.25rem",
          borderLeftStyle: "solid",
          borderLeftWidth: "3px",
          borderLeftColor:
            props.recipe.event_item_type === "RE"
              ? "info.light"
              : "secondary.main",
          boxShadow: 4,
        }}
        onClick={toggleOpen}
      >
        <Grid
          container
          alignItems={"center"}
          justifyContent="space-between"
          spacing={1}
        >
          <RecipeBuildCardHeader
            recipe={props.recipe}
            expanded={expanded}
            toggleOpen={toggleOpen}
          />
          {!fullScreen ? (
            <Grid item xs={12}>
              <Collapse in={expanded} onClick={stopPropagation}>
                <RecipeBuildCardDetails
                  recipe={props.recipe}
                  public={props.public}
                  user={props.user}
                />
              </Collapse>
            </Grid>
          ) : (
            <SwipeableDrawer
              open={expanded}
              onClose={toggleOpen}
              onOpen={toggleOpen}
              anchor="right"
              sx={{
                "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{
                  p: ".5rem",
                  border: "solid 5px",
                  height: "100%",
                  borderColor: "info.main",
                  overflow: "auto",
                }}
                onClick={stopPropagation}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={"auto"}>
                      <Typography
                        color="info.main"
                        textAlign={"center"}
                        fontSize=".75rem"
                        alignItems={"center"}
                        sx={{ lineHeight: "100%" }}
                      >
                        <KeyboardDoubleArrowRightOutlinedIcon fontSize="inherit" />
                      </Typography>
                    </Grid>
                    <Grid item xs={"auto"}>
                      <Typography
                        color="info.main"
                        textAlign={"center"}
                        fontSize=".75rem"
                        alignItems={"center"}
                        sx={{ lineHeight: "100%" }}
                      >
                        Swipe to Close
                      </Typography>
                    </Grid>
                    <Grid item xs={"auto"}>
                      <Typography
                        color="info.main"
                        textAlign={"center"}
                        fontSize=".75rem"
                        alignItems={"center"}
                        sx={{ lineHeight: "100%" }}
                      >
                        <KeyboardDoubleArrowRightOutlinedIcon fontSize="inherit" />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent={"space-between"}
                    spacing={1}
                  >
                    <RecipeBuildCardHeader
                      recipe={props.recipe}
                      expanded={expanded}
                      toggleOpen={toggleOpen}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RecipeBuildCardDetails
                      recipe={props.recipe}
                      public={props.public}
                      user={props.user}
                    />
                  </Grid>
                </Grid>
              </Box>
            </SwipeableDrawer>
          )}
        </Grid>
      </Paper>
      {props.recipe.event_items_in_group?.filter(
        (ei) => ei.event_item_type === "RE"
      ).length > 0 && (
        <Box sx={{ p: ".5rem", pb: 0 }}>
          {props.recipe.event_items_in_group
            ?.filter((ei) => ei.event_item_type === "RE")
            .map((event_item) => (
              <Box sx={{ pb: ".5rem" }}>
                <RecipeBuildCard key={event_item.uuid} recipe={event_item} />
              </Box>
            ))}
        </Box>
      )}
    </Paper>
  );
};

const RecipeBuildCardHeader = (props) => {
  const [
    getEventItemByUuid,
    getEventItemGroupQuantity,
    recipeBuildLaborPercentComplete,
    recipeLaborTimeEstimateMinutesByType,
  ] = useAccountState((state) => [
    state.getEventItemByUuid,
    state.getEventItemGroupQuantity,
    state.recipeBuildLaborPercentComplete,
    state.recipeLaborTimeEstimateMinutesByType,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid item xs={6} sm={2}>
        <Typography>{props.recipe.name}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={2}
        container
        justifyContent={{ xs: "flex-end", sm: "space-between" }}
      >
        <ValueWithLabel
          label={props.recipe.event_item_group ? "qty each" : "qty"}
          value={quantityField(props.recipe)}
          ignoreFullScreen
        />
        {props.recipe.event_item_group && (
          <ValueWithLabel
            label="qty total"
            value={
              quantityField(props.recipe) *
              getEventItemGroupQuantity(props.recipe)
            }
            ignoreFullScreen
          />
        )}
      </Grid>
      <Grid item xs={7} sm={4}>
        <RecipeLaborTable
          label="In Studio"
          minutes_each={recipeLaborTimeEstimateMinutesByType(
            props.recipe,
            "build"
          )}
          hours_total={twoDec(
            (recipeLaborTimeEstimateMinutesByType(props.recipe, "build") *
              quantityField(props.recipe) *
              getEventItemGroupQuantity(props.recipe)) /
              60
          )}
          status={recipeBuildLaborPercentComplete(props.recipe)}
        />
      </Grid>
      <Grid item xs={4} sm={3}>
        <RecipeLaborTable
          label="Onsite"
          minutes_each={props.recipe.instalation_labor_time_estimate_minutes}
          hours_total={twoDec(
            (props.recipe.instalation_labor_time_estimate_minutes *
              quantityField(props.recipe)) /
              60
          )}
        />
      </Grid>
      {fullScreen && props.expanded ? null : !fullScreen ? (
        <Grid item xs="auto">
          <ExpandButton
            expand={props.expanded}
            onClick={props.toggleOpen}
            id={props.recipe.uuid + "-expand-button"}
          >
            <ExpandMoreOutlinedIcon fontSize="inherit" />
          </ExpandButton>
        </Grid>
      ) : (
        <Grid item xs="auto">
          <ExpandButton
            expand={props.expanded}
            onClick={props.toggleOpen}
            size="small"
            rotated
            id={props.recipe.uuid + "-expand-button"}
          >
            <ExpandMoreOutlinedIcon fontSize="inherit" />
          </ExpandButton>
        </Grid>
      )}
    </>
  );
};

const RecipeBuildCardDetails = (props) => {
  const [updateEventItem] = useAccountState((state) => [state.updateEventItem]);
  return (
    <Grid container>
      <RecipeBuildCardSection label="Inspiration Photos & Notes">
        <Grid item xs={12} container spacing={1}>
          {sortRecipeImgs(props.recipe.recipe_imgs).map((recipe_img) => (
            <Grid item xs="auto">
              <Box sx={{ width: "50px" }}>
                <CroppedImg
                  img={recipe_img.cropped_image}
                  key={recipe_img.uuid}
                  zoom
                  width="100%"
                  height="100%"
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        {props.recipe.proposal_notes ? (
          <Grid item xs={12} container>
            <Grid item xs={2}>
              <Typography sx={{ color: "gray", mt: ".5rem" }}>
                Client Notes
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <RichTextFieldView
                value={props.recipe.proposal_notes_rich_text}
              />
            </Grid>
            <Divider sx={{ width: "100%" }} />
          </Grid>
        ) : null}

        <Grid item xs={12} container>
          <Grid item xs={2}>
            <Typography sx={{ color: "gray", mt: ".5rem" }}>
              Florist Notes
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {props.public ? (
              <RichTextFieldView value={props.recipe.florist_notes_rich_text} />
            ) : (
              <NotesSection
                size="small"
                value={props.recipe.florist_notes_rich_text}
                event_recipe_update={updateEventItem}
                id="florist_notes_rich_text"
                event_recipe_uuid={props.recipe.uuid}
              />
            )}
          </Grid>
          <Divider sx={{ width: "100%" }} />
        </Grid>
      </RecipeBuildCardSection>

      {props.recipe.recipe_items.length > 0 ? (
        <RecipeBuildCardSection label="Stems & Hard Goods">
          <RecipeBuildTable recipe={props.recipe} />
        </RecipeBuildCardSection>
      ) : null}
      <RecipeBuildCardSection label="Progress">
        <RecipeBuildProgress
          recipe={props.recipe}
          public={props.public}
          user={props.user}
        />
      </RecipeBuildCardSection>
    </Grid>
  );
};

const ValueWithLabel = (props) => {
  const theme = useTheme();
  const fullScreen =
    useMediaQuery(theme.breakpoints.down("sm")) && !props.ignoreFullScreen;
  return (
    <Grid item xs="auto" container>
      <Grid item xs={12}>
        <Typography
          textAlign="center"
          sx={{ fontSize: fullScreen ? "0.75rem" : undefined }}
        >
          {props.value}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          textAlign="center"
          sx={{ fontSize: fullScreen ? "0.5rem" : ".75rem", color: "gray" }}
        >
          {props.label}
        </Typography>
      </Grid>
    </Grid>
  );
};

const RecipeLaborTable = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography
          sx={{ fontSize: fullScreen ? "0.75rem" : undefined }}
          textAlign="center"
        >
          {props.label}
        </Typography>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <ValueWithLabel label="min ea." value={props.minutes_each} />
      <ValueWithLabel label="hr tot." value={props.hours_total} />
      {props.status || props.status === 0 ? (
        <ValueWithLabel label="status" value={format_percent(props.status)} />
      ) : null}
    </Grid>
  );
};

const RecipeBuildCardSection = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const toggleOpen = () => {
    setExpanded(!expanded);
  };
  return (
    <Grid item xs={12} container justifyContent={"space-between"} spacing={1}>
      <Grid item xs="auto">
        <Typography sx={{ mt: ".5rem" }}>{props.label}</Typography>
      </Grid>
      <Grid item xs="auto">
        <ExpandButton
          expand={expanded}
          onClick={toggleOpen}
          id={props.label + "-expand-button"}
        >
          <ExpandMoreOutlinedIcon fontSize="inherit" />
        </ExpandButton>
      </Grid>
      <Divider sx={{ width: "100%", mb: ".5rem" }} />
      <Grid item xs={12}>
        <Collapse in={expanded}>{props.children}</Collapse>
      </Grid>
    </Grid>
  );
};

const RecipeBuildTable = (props) => {
  const [getEventItemByUuid, getEventItemGroupQuantity] = useAccountState(
    (state) => [state.getEventItemByUuid, state.getEventItemGroupQuantity]
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Table size="small">
      {["FL", "MA"].map((item_type) => (
        <>
          {recipeItemsByType(props.recipe, item_type).length > 0 ? (
            <TableHead>
              <TableRow>
                {!fullScreen ? (
                  <>
                    <TableCell>
                      {item_type === "FL" ? "Stems" : "Hard Goods"}
                    </TableCell>
                    <TableCell align="center">Each</TableCell>
                    <TableCell align="center">
                      Total
                      {props.recipe.event_item_group &&
                        ` per ${
                          getEventItemByUuid(props.recipe.event_item_group).name
                        }`}
                    </TableCell>
                    {props.recipe.event_item_group && (
                      <TableCell align="center">Total</TableCell>
                    )}
                  </>
                ) : (
                  <TableCell align="center">
                    <Grid container justifyContent={"flex-end"}>
                      <Grid item xs={12}>
                        {item_type === "FL" ? "Stems" : "Hard Goods"}
                      </Grid>
                      <Grid item xs={3}>
                        Each
                      </Grid>
                      <Grid item xs={3}>
                        Total
                      </Grid>
                    </Grid>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
          ) : null}
          {recipeItemsByType(props.recipe, item_type).map((recipe_item) => (
            <TableRow>
              {fullScreen ? (
                <TableCell align="center">
                  <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={12}>
                      <RenderItemName item={recipe_item.item} />
                    </Grid>
                    <Grid item xs={3}>
                      {recipe_item.quantity}
                    </Grid>
                    <Grid item xs={3}>
                      {twoDec(
                        recipe_item.quantity *
                          quantityField(props.recipe) *
                          getEventItemGroupQuantity(props.recipe)
                      )}
                    </Grid>
                  </Grid>
                </TableCell>
              ) : (
                <>
                  <TableCell>
                    <RenderItemName item={recipe_item.item} />
                  </TableCell>
                  <TableCell align="center">{recipe_item.quantity}</TableCell>
                  <TableCell align="center">
                    {twoDec(recipe_item.quantity * quantityField(props.recipe))}
                  </TableCell>
                  {props.recipe.event_item_group && (
                    <TableCell align="center">
                      {twoDec(
                        recipe_item.quantity *
                          quantityField(props.recipe) *
                          getEventItemGroupQuantity(props.recipe)
                      )}
                    </TableCell>
                  )}
                </>
              )}
            </TableRow>
          ))}
        </>
      ))}
    </Table>
  );
};

export const RenderItemName = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      container
      key={props.item.uuid}
      display="flex"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs="auto">
        <ItemIcon
          key={props.item.thumbnail?.uuid}
          item={props.item}
          width={fullScreen ? 20 : 30}
          height={fullScreen ? 20 : 30}
        />
      </Grid>
      <Grid item xs="auto">
        <Tooltip title={itemDisplayName(props.item)} key={props.item.uuid}>
          <Typography noWrap>{itemDisplayName(props.item)}</Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const RecipeBuildProgress = (props) => {
  const [getEventItemGroupQuantity] = useAccountState((state) => [
    state.getEventItemGroupQuantity,
  ]);
  const [buildProgress, setBuildProgress] = React.useState(
    props.recipe.build_progress
  );

  const totalQuantity = () => {
    let quantity = quantityField(props.recipe);
    if (props.recipe.event_item_group) {
      quantity *= getEventItemGroupQuantity(props.recipe);
    }
    return quantity;
  };

  React.useEffect(() => {
    let buildProgress = props.recipe.build_progress;
    if (!buildProgress) {
      setBuildProgress(
        [...Array(totalQuantity()).keys()].map((i) => ({
          status: "IN",
          completed_by: null,
          completed_at: null,
          started_at: null,
          image: null,
        }))
      );
    } else if (buildProgress.length < totalQuantity()) {
      setBuildProgress([
        ...buildProgress,
        ...[...Array(totalQuantity() - buildProgress.length).keys()].map(
          (i) => ({
            status: "IN",
            completed_by: null,
            completed_at: null,
            started_at: null,
            image: null,
          })
        ),
      ]);
    } else if (buildProgress.length > totalQuantity()) {
      setBuildProgress(
        buildProgress.sort(sortBuildProgress).slice(0, totalQuantity())
      );
    } else {
      setBuildProgress(buildProgress.sort(sortBuildProgress));
    }
  }, [props.recipe.build_progress]);

  return buildProgress && buildProgress.length > 0 ? (
    <Grid container spacing={1} sx={{ mt: ".5rem" }}>
      <Grid item xs={12} container justifyContent={"flex-end"} spacing={1}>
        <Grid item xs="auto">
          <Typography>
            Mark All: <Grid item xs="auto"></Grid>
          </Typography>
        </Grid>
        <MarkAllChip
          status="IN"
          label="Incomplete"
          recipe={props.recipe}
          buildProgress={buildProgress}
          public={props.public}
        />
        <MarkAllChip
          status="IP"
          label="In Progress"
          recipe={props.recipe}
          buildProgress={buildProgress}
          public={props.public}
        />
        <MarkAllChip
          status="CO"
          label="Complete"
          recipe={props.recipe}
          buildProgress={buildProgress}
          public={props.public}
        />
      </Grid>
      {buildProgress.sort(sortBuildProgress).map((bp, i) => (
        <>
          <BuildProgressLine
            key={i}
            index={i}
            buildProgressItem={bp}
            buildProgress={buildProgress}
            setBuildProgress={setBuildProgress}
            recipe={props.recipe}
            public={props.public}
            user={props.user}
          />
          <Divider sx={{ width: "100%", mt: ".5rem" }} />
        </>
      ))}
    </Grid>
  ) : null;
};

const MarkAllChip = (props) => {
  const [updateEventItem] = useAccountState((state) => [
    props.public ? state.updateEventItemUnsecure : state.updateEventItem,
  ]);
  const handleClick = () => {
    updateEventItem({
      uuid: props.recipe.uuid,
      build_progress: props.buildProgress.map((bp) => ({
        ...bp,
        status: props.status,
      })),
    });
  };

  return (
    <Grid item xs="auto">
      <Chip
        color={buildProgressStatusColor(props.status)}
        size="small"
        label={props.label}
        onClick={handleClick}
      />
    </Grid>
  );
};

const BuildProgressLine = (props) => {
  const [completedByOptions, updateEventItem] = useAccountState((state) => [
    state.completedByOptions,
    props.public ? state.updateEventItemUnsecure : state.updateEventItem,
  ]);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const unsetMenuAnchorEl = () => {
    setMenuAnchorEl(null);
  };

  const updateBuildProgress = () => {
    updateEventItem({
      uuid: props.recipe.uuid,
      build_progress: props.buildProgress,
    });
  };

  const updateStatus = (status) => {
    const newBuildProgress = [...props.buildProgress];
    const old_status = newBuildProgress[props.index].status;
    newBuildProgress[props.index].status = status;
    if (old_status === "IN" && status !== "IN") {
      newBuildProgress[props.index].started_at = new Date();
    }
    if (status === "CO") {
      newBuildProgress[props.index].completed_at = new Date();
    }
    props.setBuildProgress(newBuildProgress);
    updateBuildProgress();
    unsetMenuAnchorEl();
  };

  const filter = createFilterOptions();

  const handleCompletedByChange = (event, newValue) => {
    const newBuildProgress = [...props.buildProgress];
    let completed_by = newValue
      ? newValue.replace("Add ", "").replaceAll('"', "")
      : null;
    newBuildProgress[props.index].completed_by = completed_by;
    props.setBuildProgress(newBuildProgress);
    updateBuildProgress();
  };

  const [uploadingImage, setUploadingImage] = React.useState(false);

  const updateImage = (data) => {
    const newBuildProgress = [...props.buildProgress];
    newBuildProgress[props.index].image = data[0];
    props.setBuildProgress(newBuildProgress);
    updateBuildProgress();
  };

  const [deleteingImage, setDeletingImage] = React.useState(false);

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid item xs={9} sm={3}>
        <Typography>{props.recipe.name + " " + (props.index + 1)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Chip
          label={buildProgressStatusName(props.buildProgressItem.status)}
          color={buildProgressStatusColor(props.buildProgressItem.status)}
          sx={{
            m: "auto",
            width: "100%",
          }}
          size="small"
          onClick={(e) => setMenuAnchorEl(e.target)}
        />
        <Menu
          sx={{ mt: "25px" }}
          id="menu-statuscell"
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menuAnchorEl)}
          onClose={unsetMenuAnchorEl}
          onClick={unsetMenuAnchorEl}
        >
          {["IN", "IP", "CO"]
            .filter((status) => status !== props.buildProgressItem.status)
            .map((status) => (
              <MenuItem
                onClick={() => updateStatus(status)}
                key={status}
                id={status}
              >
                <Typography textAlign="center" alignItems="center">
                  {buildProgressStatusName(status)}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      </Grid>
      <Grid item xs={9} sm={4}>
        <Autocomplete
          value={props.buildProgressItem.completed_by}
          options={completedByOptions()}
          onChange={handleCompletedByChange}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            if (inputValue && !options.includes(inputValue)) {
              filtered.push(`Add "${inputValue}"`);
            }
            return filtered;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Completed By" />
          )}
          size="small"
          fullWidth
          id={props.index + "completed-by"}
        />
      </Grid>
      <Grid item xs={3} sm={2} container justifyContent={"flex-end"}>
        {props.buildProgressItem.image ? (
          <>
            <Grid item xs="auto">
              <CroppedImg
                img={props.buildProgressItem.image}
                zoom
                width="2.5rem"
                height="2.5rem"
                // maxWidth
              />
            </Grid>
            <Grid item xs="auto">
              <IconButton
                onClick={() => setDeletingImage(true)}
                id={props.index + "delete-image"}
              >
                <DeleteOutlinedIcon fontSize="inherit" />
              </IconButton>
              <DeleteImageDialog
                open={deleteingImage}
                handleCancel={() => setDeletingImage(false)}
                handleSubmit={() => {
                  updateImage(null);
                  setDeletingImage(false);
                }}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs="auto">
            <IconButton
              onClick={() => setUploadingImage(true)}
              id={props.index + "upload-image"}
            >
              <PhotoCameraOutlinedIcon fontSize="inherit" />
            </IconButton>
            <FileDropZoneDialog
              open={uploadingImage}
              handleCancel={() => setUploadingImage(false)}
              handleSubmit={updateImage}
              disableSelector
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const DeleteImageDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Image</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this image?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          variant="outlined"
          color="info"
          id="cancel-delete-image"
        >
          Cancel
        </Button>
        <Button
          onClick={props.handleSubmit}
          variant="contained"
          color="error"
          id="submit-delete-image"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const sortBuildProgress = (a, b) => {
  if (a.status === "IN" && b.status !== "IN") {
    return 1;
  } else if (a.status !== "IN" && b.status === "IN") {
    return -1;
  } else if (a.status === "IN" && b.status === "IN") {
    return 0;
  } else if (a.status !== "CO" && b.status === "CO") {
    return 1;
  } else if (a.status === "CO" && b.status !== "CO") {
    return -1;
  } else if (a.status === "CO" && b.status === "CO") {
    return 0;
  }
};

const buildProgressStatusName = (status) => {
  switch (status) {
    case "IN":
      return "Incomplete";
    case "CO":
      return "Complete";
    case "IP":
      return "In Progress";
    default:
      return "Incomplete";
  }
};

const buildProgressStatusColor = (status) => {
  switch (status) {
    case "IN":
      return undefined;
    case "CO":
      return "success";
    case "IP":
      return "warning";
    default:
      return undefined;
  }
};

const ExpandButton = styled((props) => {
  const { expand, rotated, ...other } = props;
  return <IconButton {...other} sx={{ p: 0, m: "auto" }} size={props.size} />;
})(({ theme, expand, rotated }) => ({
  transform: rotated
    ? !expand
      ? "rotate(270deg)"
      : "rotate(90deg)"
    : !expand
    ? "rotate(0deg)"
    : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default withRouter(Build);
