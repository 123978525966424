/* global analytics */
import React from "react";
import { withRouter } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { IdleTimerProvider } from "react-idle-timer";

import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
  IconButton,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import { getBuildPublicView } from "../api/EventsApi";
import Moment from "moment";

import { AppBar, Toolbar, Button, Box, Tabs, Tab, Paper } from "@mui/material";
import Image from "react-graceful-image";
import { useAccountState } from "../state/store";
import MenuIcon from "@mui/icons-material/Menu";
import { formatPhoneNumber, orderByField } from "../utils/utils";
import { formatInTimeZone } from "date-fns-tz";
import {
  CircularProgressWithLabel,
  RecipeBuildCard,
  RenderItemName,
} from "./Build";
import { uniqBy } from "lodash";
import { EventSockets } from "./Sockets";

const BuildPublic = (props) => {
  const [
    back_office,
    recipeBuildLaborPercentCompleteTotal,
    event,
    initializeBackOffice,
    initializeClients,
    initializeEvent,
    initializeEventItems,
    initializeEventVersion,
    initializeItemPrices,
    initializeProposal,
    initializeUser,
    proposal,
    user,
  ] = useAccountState((state) => [
    state.back_office,
    state.recipeBuildLaborPercentCompleteTotal,
    state.event,
    state.initializeBackOffice,
    state.initializeClients,
    state.initializeEvent,
    state.initializeEventItems,
    state.initializeEventVersion,
    state.initializeItemPrices,
    state.initializeProposal,
    state.initializeUser,
    state.proposal,
    state.user,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [event_uuid] = React.useState(props.match.params.event_uuid);
  const [loaded, setLoaded] = React.useState(false);
  const [idle, setIdle] = React.useState(false);

  const itemPricesDictFromList = (item_prices_list) => {
    let item_prices_dict = {};
    item_prices_list.forEach(
      (item_price, i) => (item_prices_dict[item_price.item] = item_price)
    );
    return item_prices_dict;
  };

  const updateState = () => {
    getBuildPublicView(event_uuid).then((resp) => {
      initializeEvent(resp.data.event);
      initializeEventItems(resp.data.event_items);
      initializeEventVersion(resp.data.event_version);
      initializeItemPrices(
        itemPricesDictFromList(resp.data.event_version.item_prices)
      );
      initializeBackOffice(resp.data.event_version.back_office);
      initializeUser(resp.data.user);
      initializeProposal(resp.data.proposal);
      initializeClients(resp.data.event.clients);
      setLoaded(true);
      analytics.identify(resp.data.user.id);
    });
  };

  React.useEffect(() => {
    updateState();
  }, [props.event_uuid]);

  Moment.locale("en");

  const [tabValue, setTabValue] = React.useState("build_instructions");
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setOpen(false);
  };

  // TODO: FIX THIS
  const expired = () => {
    // return false;
    return (
      back_office.public_build_expires &&
      new Date(back_office.public_build_expiration_date) < new Date()
    );
  };

  // TODO: FIX THIS
  const displayBuildPublic = () => {
    // return true;
    return back_office.public_build_published && !expired();
  };

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * process.env.REACT_APP_IDLE_TIMEOUT_MINUTES}
      onIdle={() => setIdle(true)}
    >
      <IdleDialog open={idle} />
      {loaded ? (
        <Box sx={{ height: "100vh" }}>
          {displayBuildPublic() ? (
            <>
              <AppBar
                position="fixed"
                sx={{ backgroundColor: "white", zIndex: 1000 }}
              >
                <Toolbar disableGutters>
                  {fullScreen ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{ m: 2 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : null}
                  <Grid
                    container
                    spacing={1}
                    sx={{ m: ".25rem", maxWidth: "1000px" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={8} sm={10} container>
                      <Grid item xs={12}>
                        <Typography variant="h5" noWrap>
                          {event.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ ml: ".25rem" }}>
                          {user.company}
                        </Typography>
                      </Grid>
                    </Grid>
                    {!fullScreen && (
                      <Grid item xs="auto" container justifyContent="center">
                        <CircularProgressWithLabel
                          value={recipeBuildLaborPercentCompleteTotal()}
                          size={70}
                          numberOnly
                        />
                      </Grid>
                    )}
                  </Grid>
                </Toolbar>
              </AppBar>
            </>
          ) : null}

          {displayBuildPublic() ? (
            <>
              <Drawer
                variant="persistent"
                anchor="left"
                open={open || !fullScreen}
                sx={{
                  zIndex: 999,
                  width: 240,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: {
                    width: 240,
                    boxSizing: "border-box",
                    zIndex: 999,
                  },
                }}
              >
                <Toolbar />
                <Tabs
                  orientation={"vertical"}
                  variant="scrollable"
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{
                    color: "info",
                    mt: "2rem",
                    ".MuiTabs-flexContainer": {
                      justifyContent: "center",
                    },
                  }}
                  TabIndicatorProps={{
                    style: { background: "gray" },
                  }}
                >
                  <StyledTab
                    label="Event Info"
                    value="event_info"
                    tabValue={tabValue}
                  />

                  <StyledTab
                    label="Hard Good Pull List"
                    value="hard_good_pull_list"
                    tabValue={tabValue}
                  />
                  <StyledTab
                    label="Build Instructions"
                    value="build_instructions"
                    tabValue={tabValue}
                  />
                </Tabs>
              </Drawer>
              <Box
                sx={{
                  ml: fullScreen ? undefined : "240px",
                  mt: "1rem",
                }}
              >
                <Toolbar />
                <TabPanel value={tabValue} index={"event_info"}>
                  <EventDetails />
                </TabPanel>
                <TabPanel value={tabValue} index={"hard_good_pull_list"}>
                  <HardGoodPullList />
                </TabPanel>
                <TabPanel value={tabValue} index={"build_instructions"}>
                  <BuildInstructions />
                </TabPanel>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  border: "dashed lightgrey 2px",
                  borderRadius: "5px",
                  p: "1rem",
                  m: "auto",
                  mt: "1rem",
                  maxWidth: "850px",
                }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography align="center">
                      This page is not published. To view this page, please
                      contact {user.company}.
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Paper sx={{ maxWidth: "350px", p: "1rem" }}>
                      <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12}>
                          <Box display="flex">
                            <Image
                              src={proposal.logo}
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: "70%",
                                maxHeight: "400px",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography align="center" variant="h6">
                            {user.company}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography align="center" variant="h6">
                            {user.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          <EventSockets />
        </Box>
      ) : (
        <Box sx={{ width: "100%", height: "100%" }} display="flex">
          <CircularProgress sx={{ m: "auto" }} color="info" />
        </Box>
      )}
    </IdleTimerProvider>
  );
};

const StyledTab = (props) => {
  return (
    <Tab
      {...props}
      {...a11yProps(props.value)}
      style={{
        color: props.disabled ? "lightgray" : "gray",
        fontWeight: props.tabValue === props.value ? "bold" : "normal",
      }}
    />
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ maxWidth: "800px" }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            overflow: "auto",
            height: "calc(100vh - 60px)",
          }}
        >
          <Box
            sx={{
              mb: "calc(100vh/2)",
            }}
          >
            <Paper
              sx={{
                m: fullScreen ? undefined : "1rem",
                p: fullScreen ? "0.5rem" : "1rem",
              }}
            >
              <Typography>{children}</Typography>
            </Paper>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const IdleDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        window.location.reload(false);
      }}
    >
      <DialogTitle>Welcome Back!</DialogTitle>
      <DialogContent>
        <Typography>
          Please refresh your page to ensure you have the latest data.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            window.location.reload(false);
          }}
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EventDetails = (props) => {
  const [event, eventDateFormatted] = useAccountState((state) => [
    state.event,
    state.eventDateFormatted,
  ]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" textAlign="center">
          {event.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" textAlign="center">
          {eventDateFormatted(true)}
        </Typography>
      </Grid>
      <EventDetailsSection title="Contacts">
        <EventContacts />
      </EventDetailsSection>
      <EventDetailsSection title="Timeline">
        <EventTimeline />
      </EventDetailsSection>
      <EventDetailsSection title="Addresses">
        <EventAddresses />
      </EventDetailsSection>
    </Grid>
  );
};

const EventDetailsSection = (props) => {
  const { children } = props;
  return (
    <Grid item xs={12} container>
      <Grid item xs="auto">
        <Typography variant="h6">{props.title}</Typography>
      </Grid>
      <Divider sx={{ width: "100%", mb: ".5rem" }} />
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const EventContacts = (props) => {
  const [clients, event] = useAccountState((state) => [
    state.clients,
    state.event,
  ]);
  return (
    <Grid container spacing={2}>
      {clients.map((client) => (
        <ContactInfo contact={client} />
      ))}
      {event.event_contacts.map((contact) => (
        <ContactInfo contact={contact} />
      ))}
    </Grid>
  );
};

const ContactInfo = (props) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Grid item xs={12}>
        <Typography fontWeight={"bold"}>{props.contact.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontStyle: "italic" }}>
          {props.contact.type ? props.contact.type : props.contact.notes}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{props.contact.email}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{formatPhoneNumber(props.contact.phone_number)}</Typography>
      </Grid>
    </Grid>
  );
};

const EventTimeline = (props) => {
  const [event, back_office] = useAccountState((state) => [
    state.event,
    state.back_office,
  ]);
  return (
    <Grid container spacing={1}>
      {event.event_locations.sort(orderByField("date")).map((location) => (
        <>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            justifyContent={"space-between"}
          >
            <Grid item xs={5} md={3}>
              {formatInTimeZone(
                new Date(location.date),
                back_office.timezone,
                "MM/dd p"
              )}
            </Grid>
            <Grid item xs={6} md={8} container spacing={0.5}>
              <Grid item xs={12} md={"auto"}>
                {location.name}
              </Grid>{" "}
              <Grid
                item
                xs="auto"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                -
              </Grid>{" "}
              <Grid item xs={12} md={"auto"}>
                {location.location.name}
              </Grid>
            </Grid>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

const EventAddresses = (props) => {
  const [event] = useAccountState((state) => [state.event]);

  const getUniqueLocations = () => {
    const locations = event.event_locations.map((location) => {
      return location.location;
    });
    return uniqBy(locations, "uuid");
  };

  return (
    <Grid container spacing={2}>
      {getUniqueLocations()
        .sort(orderByField("name"))
        .map((location) => (
          <>
            <Grid item xs={12} sm={6} md={4} container>
              <Grid item xs={12}>
                <Typography fontWeight={"bold"}>{location.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                {[
                  location.address_line_1,
                  location.address_line_2,
                  location.city,
                ]
                  .filter((x) => x)
                  .join(", ")}
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {[location.poc, formatPhoneNumber(location.phone_number)]
                    .filter((x) => x)
                    .join(" | ")}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}
    </Grid>
  );
};

const HardGoodPullList = (props) => {
  const [materialCounts] = useAccountState((state) => [state.materialCounts]);
  return materialCounts().length > 0 ? (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Hard Good</TableCell>
          <TableCell align="center">Total Quantity</TableCell>
        </TableRow>
      </TableHead>
      {materialCounts().map((material_count) => {
        return (
          <TableRow>
            <TableCell>
              <RenderItemName item={material_count.item} />
            </TableCell>
            <TableCell align="center">{material_count.quantity}</TableCell>
          </TableRow>
        );
      })}
    </Table>
  ) : (
    <Typography textAlign="center">No hard goods in event.</Typography>
  );
};

const BuildInstructions = (props) => {
  const [recipesAndGroups, user] = useAccountState((state) => [
    state.recipesAndGroups,
    state.user,
  ]);
  return (
    <Grid container spacing={1}>
      {recipesAndGroups()
        .filter((recipe) => !recipe.is_optional || recipe.is_selected)
        .map((recipe) => (
          <Grid item xs={12}>
            <RecipeBuildCard
              key={recipe.uuid}
              recipe={recipe}
              public
              user={user}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default withRouter(BuildPublic);
