import {
  addClientApi,
  deleteClientApi,
  updateClientApi,
} from "../api/EventsApi";

export const clientsSlice = (set, get) => ({
  clients: [],
  addClient: (data) => {
    let clients = get().clients;
    addClientApi({ ...data, event_uuid: get().event.uuid }).then((resp) => {
      set({ clients: [...clients, resp.data] });
    });
  },
  deleteClient: (uuid) => {
    let clients = get().clients;
    let client = clients.find((client) => client.uuid === uuid);
    set({
      clients: clients.filter((client) => client.uuid !== uuid),
    });
    deleteClientApi(uuid).then((resp) => {
      if (!resp.data.success) {
        set({ clients: [...clients, client] });
      }
    });
  },
  initializeClients: (data) => set({ clients: data }),
  updateClient: (data) => {
    let clients = get().clients;
    let index = clients.findIndex((client) => client.uuid === data.uuid);
    set({
      clients: [
        ...clients.slice(0, index),
        { ...clients[index], ...data },
        ...clients.slice(index + 1),
      ],
    });
    updateClientApi(data);
  },
});
