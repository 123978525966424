import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAccountState } from "../../state/store";
import {
  hexToRGB,
  isOptionalField,
  isSelectedField,
  quantityField,
  sort_by_order,
} from "../../utils/utils";
import { RecipeProposalSectionView } from "../Proposal";
import EventItem from "../EventItem";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RichTextFieldView } from "../RichTextField";
import RecipeImgs from "../RecipeImgs";
import { DefaultStyles } from "../../utils/proposal_utils";

export const ScopeOfServices = (props) => {
  const [
    event_items,
    eventLocked,
    otherCosts,
    proposal,
    recipes,
    recipe_proposal_sections,
    recipeListItems,
    updateProposal,
  ] = useAccountState((state) => [
    state.event_items,
    state.eventLocked,
    state.otherCosts,
    state.proposal,
    state.recipes,
    state.recipe_proposal_sections,
    state.recipeListItems,
    state.updateProposal,
  ]);

  const sortRecipes = (a, b) => {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  };

  const updateSwitch = (name, value) => {
    updateProposal({
      [name]: value,
    });
  };

  return (
    <Box sx={{ p: "1rem" }}>
      <Grid container justifyContent="flex-start" spacing={1}>
        <Grid item xs="auto">
          <SettingSwitch
            name={"show_recipe_price"}
            value={proposal.show_recipe_price}
            label="Prices"
            updateSwitch={updateSwitch}
          />
        </Grid>
        <Grid item xs="auto">
          <SettingSwitch
            name={"show_recipe_notes"}
            value={proposal.show_recipe_notes}
            label="Notes"
            updateSwitch={updateSwitch}
          />
        </Grid>
        <Grid item xs="auto">
          <SettingSwitch
            name={"show_recipe_imgs"}
            value={proposal.show_recipe_imgs}
            label="Images"
            updateSwitch={updateSwitch}
          />
        </Grid>
        {recipeListItems().length === 0 ? (
          <ScopeOfServicesSettingsPreview />
        ) : (
          <Grid item sm={12}>
            {recipeListItems()
              .sort(sort_by_order)
              .map((recipe, ind) =>
                recipe.event_item_type ? (
                  <EventItem
                    proposal
                    key={recipe.uuid}
                    event_item={recipe}
                    last={
                      ind === recipeListItems().length - 1 ||
                      !recipeListItems()[ind + 1]?.event_item_type
                    }
                    proposal_section={props.proposal_section}
                  />
                ) : (
                  <RecipeProposalSectionView
                    recipe_proposal_section={recipe}
                    proposal_section={props.proposal_section}
                  />
                )
              )}
          </Grid>
        )}
        <Divider sx={{ width: "100%", mt: "1rem", mb: "1rem" }} />
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs="auto">
            <Typography variant={"h6"}>Tax Settings</Typography>
          </Grid>
          <Grid item xs="auto" container justifyContent="flex-end">
            <Grid item xs="auto">
              <SettingSwitch
                name={"spread_tax"}
                value={proposal.spread_tax}
                label="Spread Tax"
                updateSwitch={updateSwitch}
                labelPlacement="start"
                tooltip="If selected, percentage taxes will be spread among recipes and other costs so that their deisplayed prices will include tax. Note, amount taxes will not be spread."
                disabled={eventLocked()}
              />
            </Grid>
            <Grid item xs="auto">
              <SettingSwitch
                name={"group_tax"}
                value={proposal.group_tax}
                label="Group Taxes"
                updateSwitch={updateSwitch}
                labelPlacement="start"
                tooltip="If selected, taxes will be grouped under a single line labeled 'Tax'. Otherwise, each tax will be listed on a separate line."
                disabled={eventLocked()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item sm={12}>
          <ProposalTotals proposal_section={props.proposal_section} />
        </Grid>
      </Grid>
    </Box>
  );
};

const SettingSwitch = (props) => {
  const toggleChecked = () => {
    props.updateSwitch(props.name, !props.value);
  };

  return (
    <Grid container alignItems="center" spacing={0.25}>
      <Grid item xs="auto">
        <FormControlLabel
          sx={props.sx}
          control={
            <Switch
              checked={props.value}
              onChange={toggleChecked}
              disabled={props.disabled}
              id={props.name + "-switch"}
            />
          }
          label={props.label}
          labelPlacement={props.labelPlacement ? props.labelPlacement : "start"}
        />
      </Grid>

      {props.tooltip ? (
        <Grid item xs="auto">
          <Tooltip title={props.tooltip}>
            <InfoOutlinedIcon fontSize="small" color="info" />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

const ProposalTotals = (props) => {
  const [
    eventPrice,
    eventPriceSubtotal,
    formatCurrency,
    proposal,
    taxes,
    taxLines,
  ] = useAccountState((state) => [
    state.eventPrice,
    state.eventPriceSubtotal,
    state.formatCurrency,
    state.proposal,
    state.taxes,
    state.taxLines,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const textVariant = fullScreen ? "h6" : "h5";

  const fontColor =
    props.proposal_section.style?.font_color || DefaultStyles.fontColor;
  const fontFamily =
    props.proposal_section.style?.font || DefaultStyles.fontFamily;

  return (
    <Grid item xs={12} container justifyContent="flex-end">
      {/* <Grid container justifyContent='end' spacing={1} sx={{width: fullScreen ? '50%' : '20%', ml:'auto', mr:fullScreen ? '5%' : '10%'}}> */}
      {/* <Grid item sm={5}></Grid> */}

      <Divider
        sx={{
          width: "100%",
          borderColor: fontColor,
          mt: "1rem",
        }}
      ></Divider>
      {taxes().length > 0 ? (
        <Grid item xs={12} container justifyContent={"space-between"}>
          <Grid item xs="auto">
            <Typography
              variant={textVariant}
              sx={{
                fontFamily: fontFamily,
                color: fontColor,
              }}
            >
              Subtotal
            </Typography>
          </Grid>

          <Grid item xs="auto">
            <Typography
              variant={textVariant}
              sx={{
                textAlign: "right",
                fontFamily: fontFamily,
                color: fontColor,
              }}
            >
              {formatCurrency(
                eventPriceSubtotal(!proposal.amendments_published)
              )}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      {taxLines(!proposal.amendments_published).map((tax) => (
        <TaxLine
          proposal={proposal}
          textVariant={textVariant}
          label={tax.name}
          amount={tax.amount}
          percent={tax.percent}
          proposal_section={props.proposal_section}
        />
      ))}

      <Grid item xs={12} container justifyContent={"space-between"}>
        <Grid item xs={3}>
          <Typography
            variant={textVariant}
            sx={{
              fontFamily: fontFamily,
              color: fontColor,
            }}
          >
            Total
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Typography
            variant={textVariant}
            sx={{
              textAlign: "right",
              fontFamily: fontFamily,
              color: fontColor,
            }}
          >
            {formatCurrency(eventPrice(!proposal.amendments_published))}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TaxLine = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  const fontColor =
    props.proposal_section.style?.font_color || DefaultStyles.fontColor;
  const fontFamily =
    props.proposal_section.style?.font || DefaultStyles.fontFamily;
  return (
    <Grid item xs={12} container justifyContent={"space-between"}>
      <Grid item xs="auto" container alignItems="center" spacing={0.5}>
        <Grid item xs="auto">
          <Typography
            variant={props.textVariant}
            sx={{
              fontFamily: fontFamily,
              color: fontColor,
            }}
          >
            {props.label}
          </Typography>
        </Grid>

        {props.percent ? (
          <Grid item xs="auto">
            <Typography fontSize=".75rem">
              {" (" + props.percent + "%)"}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={8} sm={4}>
        <Typography
          variant={props.textVariant}
          sx={{
            textAlign: "right",
            fontFamily: fontFamily,
            color: fontColor,
          }}
        >
          {formatCurrency(props.amount)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const ScopeOfServicesView = (props) => {
  const [proposal, recipeListItems] = useAccountState((state) => [
    state.proposal,
    state.recipeListItems,
  ]);

  return (
    <Grid container justifyContent="center">
      {recipeListItems()
        .sort(sort_by_order)
        .filter(
          (recipe) => recipe.quantity !== 0 || proposal.amendments_published
        )
        .map((recipe, ind, arr) =>
          recipe.event_item_type ? (
            <ProposalRecipeLine
              key={recipe.uuid}
              recipe={recipe}
              last={
                ind === arr.length - 1 ||
                (ind !== arr.length - 1 && !arr[ind + 1]?.event_item_type)
              }
              proposal_section={props.proposal_section}
            />
          ) : (
            <RecipeProposalSectionView
              proposal_section={props.proposal_section}
              recipe_proposal_section={recipe}
              view_only
            />
          )
        )}
      <ProposalTotalsView proposal_section={props.proposal_section} />
    </Grid>
  );
};

const ProposalRecipeLine = (props) => {
  const [
    eventItemPriceWithTax,
    eventItemPriceWithTaxAmended,
    eventItemTotalPriceWithTax,
    eventItemTotalPriceWithTaxAmended,
    formatCurrency,
    getEventItemByUuid,
    proposal,
    recipeHasAmendments,

    recipe_proposal_sections,

    updateEventItemUnsecure,
  ] = useAccountState((state) => [
    state.eventItemPriceWithTax,
    state.eventItemPriceWithTaxAmended,
    state.eventItemTotalPriceWithTax,
    state.eventItemTotalPriceWithTaxAmended,
    state.formatCurrency,
    state.getEventItemByUuid,
    state.proposal,
    state.recipeHasAmendments,

    state.recipe_proposal_sections,

    state.updateEventItemUnsecure,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const headerVariant = "body1";
  const bodyVariant = "body2";
  const labelSize = fullScreen ? ".5rem" : ".75rem";

  const toggleSelected = () => {
    updateEventItemUnsecure({
      uuid: props.recipe.uuid,
      is_selected: !isSelectedField(
        props.recipe,
        !proposal.amendments_published
      ),
    });
  };

  const sortImgs = (a, b) => {
    if (a.position > b.position) {
      return 1;
    } else if (a.position < b.position) {
      return -1;
    } else {
      return 0;
    }
  };

  const [recipeImgs, setRecipeImgs] = useState(
    props.recipe.recipe_imgs.sort(sortImgs)
  );

  useEffect(() => {
    setRecipeImgs(props.recipe.recipe_imgs.sort(sortImgs));
  }, [props.recipe]);

  const strikeThrough = () => {
    if (props.recipe.event_item_group) {
      let event_item_group = getEventItemByUuid(props.recipe.event_item_group);
      return (
        (isOptionalField(event_item_group) &&
          !isSelectedField(event_item_group)) ||
        quantityField(event_item_group) === 0
      );
    } else {
      return (
        (isOptionalField(props.recipe) && !isSelectedField(props.recipe)) ||
        quantityField(props.recipe) === 0
      );
    }
  };

  const fontColor =
    props.proposal_section.style?.font_color || DefaultStyles.fontColor;
  const fontFamily =
    props.proposal_section.style?.font || DefaultStyles.fontFamily;

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="flex-end"
      spacing={1}
      justifyContent="space-between"
      className="recipe-line"
      sx={{
        pl:
          (recipe_proposal_sections.length > 0 ||
            props.recipe.event_item_group) &&
          !fullScreen
            ? "1rem"
            : undefined,
      }}
    >
      <Grid item xs={6} sm="auto">
        <Typography
          variant={headerVariant}
          sx={{
            fontFamily: fontFamily,
            color: fontColor,
          }}
        >
          {props.recipe.name}
        </Typography>
      </Grid>

      <Grid item xs="auto">
        {isOptionalField(props.recipe, !proposal.amendments_published) ? (
          proposal.contracts.length === 0 ? (
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={
                    isSelectedField(
                      props.recipe,
                      !proposal.amendments_published
                    ) |
                    !isOptionalField(
                      props.recipe,
                      !proposal.amendments_published
                    )
                  }
                  onChange={toggleSelected}
                  disabled={proposal.contracts.length > 0}
                />
              }
              label="Selected"
              labelPlacement={"start"}
              sx={{
                fontFamily: fontFamily,
                color: fontColor,
              }}
            />
          ) : (
            <Tooltip
              title={
                isSelectedField(props.recipe, !proposal.amendments_published)
                  ? "Selected"
                  : "Not Selected"
              }
            >
              <Chip
                label={
                  isSelectedField(props.recipe, !proposal.amendments_published)
                    ? "Selected"
                    : "Not Selected"
                }
                size="small"
              />
            </Tooltip>
          )
        ) : null}
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        rowSpacing={fullScreen ? 2 : undefined}
      >
        <Grid item xs={3} sm={3}>
          {!props.recipe.is_percent && (
            <Typography
              display="flex"
              sx={{
                alignItems: "end",
                lineHeight: "100%",
                fontFamily: fontFamily,
                color: fontColor,
                textDecoration: strikeThrough() ? "line-through" : undefined,
              }}
              variant={headerVariant}
            >
              {quantityField(props.recipe, !proposal.amendments_published)}
              <Typography
                sx={{
                  fontSize: labelSize,
                  fontFamily: fontFamily,
                  color: fontColor,
                  lineHeight: "100%",
                  pl: ".2rem",
                }}
              >
                qty
              </Typography>
            </Typography>
          )}

          {props.recipe.event_item_group && (
            <Typography
              sx={{
                fontFamily: fontFamily,
                color: fontColor,
                fontSize: labelSize,
                pl: ".2rem",
              }}
            >
              {`per ${getEventItemByUuid(props.recipe.event_item_group).name}`}
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs="auto"
          sm={6}
          container
          spacing={2}
          justifyContent="flex-end"
        >
          {proposal.show_recipe_price && !props.hide_sub_item_prices ? (
            <Grid item xs="auto" sm={6}>
              {!props.recipe.is_percent && (
                <Typography
                  display="flex"
                  sx={{
                    alignItems: "end",
                    lineHeight: "100%",
                    fontFamily: fontFamily,
                    color: fontColor,
                    textDecoration: strikeThrough()
                      ? "line-through"
                      : undefined,
                  }}
                  variant={headerVariant}
                >
                  {formatCurrency(
                    eventItemPriceWithTax(
                      props.recipe,
                      !proposal.amendments_published
                    )
                  )}
                  <Typography
                    sx={{
                      fontSize: labelSize,
                      fontFamily: fontFamily,
                      color: fontColor,
                      lineHeight: "100%",
                      pl: ".2rem",
                    }}
                  >
                    ea
                  </Typography>
                </Typography>
              )}
            </Grid>
          ) : null}
          {proposal.show_recipe_price && !props.hide_sub_item_prices ? (
            <Grid item xs="auto" sm={5}>
              <Typography
                display="flex"
                sx={{
                  alignItems: "end",
                  lineHeight: "100%",
                  fontFamily: fontFamily,
                  color: fontColor,
                  textAlign: "right",
                  textDecoration: strikeThrough() ? "line-through" : undefined,
                }}
                variant={headerVariant}
                align="right"
              >
                {formatCurrency(
                  eventItemTotalPriceWithTax(
                    props.recipe,
                    !proposal.amendments_published
                  )
                )}

                <Typography
                  sx={{
                    fontSize: labelSize,
                    fontFamily: fontFamily,
                    color: fontColor,
                    lineHeight: "100%",
                    pl: ".2rem",
                  }}
                >
                  total
                </Typography>
              </Typography>
              {props.recipe.event_item_group && (
                <Typography
                  sx={{
                    fontSize: labelSize,
                    lineHeight: "100%",
                    pl: ".2rem",
                  }}
                >
                  {`per ${
                    getEventItemByUuid(props.recipe.event_item_group).name
                  }`}
                </Typography>
              )}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {proposal.show_recipe_notes ? (
        <Grid item xs={12}>
          <RichTextFieldView
            font={fontFamily}
            font_color={fontColor}
            value={props.recipe.proposal_notes_rich_text}
            variant={bodyVariant}
          />
        </Grid>
      ) : null}
      {props.recipe.recipe_imgs.length > 0 && proposal.show_recipe_imgs ? (
        <Grid item xs={12}>
          <RecipeImgs imgs={recipeImgs} displayOnly height={50} zoom />
        </Grid>
      ) : null}

      {(recipeHasAmendments(props.recipe) ||
        eventItemTotalPriceWithTaxAmended(props.recipe)) &&
      proposal.amendments_published ? (
        <Grid item xs={12} container alignItems={"center"} spacing={1}>
          <Alert severity="info" sx={{ width: "100%", mt: ".5rem" }}>
            <Grid item xs={12} container>
              {props.recipe.quantity !=
              quantityField(props.recipe, !proposal.amendments_published) ? (
                <Grid item xs={12}>
                  {quantityField(props.recipe, !proposal.amendments_published) >
                  0 ? (
                    props.recipe.quantity !== 0 ? (
                      <Typography>
                        Quantity changed from {props.recipe.quantity} to{" "}
                        {quantityField(
                          props.recipe,
                          !proposal.amendments_published
                        )}
                      </Typography>
                    ) : (
                      <Typography>
                        "{props.recipe.name}" has been added
                      </Typography>
                    )
                  ) : (
                    <Typography>
                      "{props.recipe.name}" has been removed
                    </Typography>
                  )}
                </Grid>
              ) : null}

              {proposal.show_recipe_price &&
              eventItemPriceWithTaxAmended(props.recipe) &&
              quantityField(props.recipe, !proposal.amendments_published) > 0 &&
              props.recipe.quantity !== 0 &&
              !props.recipe.is_percent &&
              isSelectedField(props.recipe) ? (
                <Grid item xs={12}>
                  <Typography>
                    {"Price each changed from "}
                    {formatCurrency(
                      eventItemPriceWithTax(props.recipe, true)
                    )}{" "}
                    {" to "}
                    {formatCurrency(eventItemPriceWithTax(props.recipe, false))}
                  </Typography>
                </Grid>
              ) : null}
              {proposal.show_recipe_price &&
              eventItemTotalPriceWithTaxAmended(props.recipe) &&
              quantityField(props.recipe, !proposal.amendments_published) > 0 &&
              props.recipe.quantity !== 0 &&
              !props.hide_sub_item_prices &&
              isSelectedField(props.recipe) ? (
                <Grid item xs={12}>
                  <Typography>
                    {"Total price changed from "}
                    {formatCurrency(
                      eventItemTotalPriceWithTax(props.recipe, true)
                    )}
                    {" to "}
                    {formatCurrency(
                      eventItemTotalPriceWithTax(props.recipe, false)
                    )}
                  </Typography>
                </Grid>
              ) : null}
              {(isSelectedField(props.recipe) !== props.recipe.is_selected ||
                isOptionalField(props.recipe) !== props.recipe.is_optional) &&
              quantityField(props.recipe) ? (
                <Grid item xs={12}>
                  <Typography>
                    {isSelectedField(props.recipe) ||
                    !isOptionalField(props.recipe)
                      ? props.recipe.name + " has been added"
                      : props.recipe.name + " has been removed"}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Alert>
        </Grid>
      ) : null}
      {!props.recipe.hide_sub_items &&
        props.recipe.event_items_in_group?.length > 0 &&
        quantityField(props.recipe) > 0 && (
          <Box
            sx={{
              ml: "2rem",
              pt: "1rem",
              width: "100%",
              borderLeftStyle: "solid",
              borderLeftWidth: "1px",
              borderLeftColor: hexToRGB(fontColor, 0.25),
            }}
          >
            {props.recipe.event_items_in_group?.map((event_item, ind, arr) => (
              <ProposalRecipeLine
                key={event_item.uuid}
                recipe={event_item}
                last={ind === arr.length - 1}
                hide_sub_item_prices={props.recipe.hide_sub_item_prices}
                proposal_section={props.proposal_section}
              />
            ))}
          </Box>
        )}
      {!props.last ? (
        <Grid item xs={12} sm={12}>
          <Divider
            sx={{
              m: ".5rem",
              mt: "0rem",
              borderColor: hexToRGB(fontColor, 0.25),
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

const ProposalTotalsView = (props) => {
  const [
    eventHasAmendments,
    eventPrice,
    eventPriceAmended,
    eventPriceSubtotal,
    eventPriceSubtotalAmended,
    formatCurrency,
    proposal,
    taxes,
    taxLines,
    taxLinesTotalAmount,
    taxLinesTotalAmountAmended,
  ] = useAccountState((state) => [
    state.eventHasAmendments,
    state.eventPrice,
    state.eventPriceAmended,
    state.eventPriceSubtotal,
    state.eventPriceSubtotalAmended,
    state.formatCurrency,
    state.proposal,
    state.taxes,
    state.taxLines,
    state.taxLinesTotalAmount,
    state.taxLinesTotalAmountAmended,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const headerVariant = fullScreen ? "h6" : "h5";
  const fontColor =
    props.proposal_section.style?.font_color || DefaultStyles.fontColor;
  const fontFamily =
    props.proposal_section.style?.font || DefaultStyles.fontFamily;
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="space-between"
      className="proposal-totals"
    >
      <Divider
        sx={{
          width: "100%",
          borderColor: fontColor,
          mt: "1rem",
        }}
      ></Divider>
      {taxes().length > 0 ? (
        <Grid item xs={12} container justifyContent={"space-between"}>
          <Grid item xs="auto">
            <Typography
              variant={headerVariant}
              sx={{
                fontFamily: fontFamily,
                color: fontColor,
              }}
            >
              Subtotal
            </Typography>
          </Grid>

          <Grid item xs="auto">
            <Typography
              variant={headerVariant}
              sx={{
                textAlign: "right",
                fontFamily: fontFamily,
                color: fontColor,
              }}
            >
              {formatCurrency(
                eventPriceSubtotal(!proposal.amendments_published)
              )}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      {taxLines(!proposal.amendments_published).map((tax) => (
        <TaxLineView
          proposal={proposal}
          headerVariant={headerVariant}
          label={tax.name}
          amount={tax.amount}
          percent={tax.percent}
          proposal_section={props.proposal_section}
        />
      ))}

      <Grid item xs={12} container justifyContent={"space-between"}>
        <Grid item xs="auto">
          <Typography
            variant={headerVariant}
            sx={{
              fontFamily: fontFamily,
              color: fontColor,
            }}
          >
            Total
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Typography
            variant={headerVariant}
            sx={{
              textAlign: "right",
              fontFamily: fontFamily,
              color: fontColor,
            }}
          >
            {formatCurrency(eventPrice(!proposal.amendments_published))}
          </Typography>
        </Grid>
      </Grid>
      {eventHasAmendments() &&
      proposal.amendments_published &&
      (eventPriceAmended() ||
        eventPriceSubtotalAmended() ||
        taxLinesTotalAmountAmended()) ? (
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Alert severity="info">
            <Grid container>
              {eventPriceSubtotalAmended() ? (
                <Grid item xs={12}>
                  <Typography>
                    Subtotal changed from{" "}
                    {formatCurrency(eventPriceSubtotal(true))} to{" "}
                    {formatCurrency(eventPriceSubtotal(false))}
                  </Typography>
                </Grid>
              ) : null}
              {taxLinesTotalAmountAmended() ? (
                <Grid item xs={12}>
                  <Typography>
                    Tax changed from {formatCurrency(taxLinesTotalAmount(true))}{" "}
                    to {formatCurrency(taxLinesTotalAmount(false))}
                  </Typography>
                </Grid>
              ) : null}
              {eventPriceAmended() ? (
                <Grid item xs={12}>
                  <Typography>
                    Total changed from {formatCurrency(eventPrice(true))} to{" "}
                    {formatCurrency(eventPrice(false))}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Alert>
        </Grid>
      ) : null}
    </Grid>
  );
};

const TaxLineView = (props) => {
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);
  const fontColor =
    props.proposal_section.style?.font_color || DefaultStyles.fontColor;
  const fontFamily =
    props.proposal_section.style?.font || DefaultStyles.fontFamily;
  return (
    <Grid item xs={12} container justifyContent={"space-between"}>
      <Grid item xs="auto" container alignItems="center" spacing={0.5}>
        <Grid item xs="auto">
          <Typography
            variant={props.headerVariant}
            sx={{
              fontFamily: fontFamily,
              color: fontColor,
            }}
          >
            {props.label}
          </Typography>
        </Grid>

        {props.percent ? (
          <Grid item xs="auto">
            <Typography fontSize=".75rem">
              {" (" + props.percent + "%)"}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs="auto">
        <Typography
          variant={props.headerVariant}
          sx={{
            textAlign: "right",
            fontFamily: fontFamily,
            color: fontColor,
          }}
        >
          {formatCurrency(props.amount)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ScopeOfServicesSettingsPreview = (props) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <RecipeSectionSettingsPreview />
      </Grid>
      <Grid item xs={12}>
        <RecipeSettingsPreview />
      </Grid>
      <Grid item xs={12}>
        <RecipeSettingsPreview last />
      </Grid>
      <Grid item xs={12}>
        <RecipeSectionSettingsPreview />
      </Grid>
      <Grid item xs={12}>
        <RecipeSettingsPreview last />
      </Grid>
    </Grid>
  );
};

const RecipeSectionSettingsPreview = (props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Skeleton
          variant="text"
          width={200}
          sx={{ fontSize: "2rem" }}
          animation={false}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="text"
          width={600}
          sx={{ fontSize: ".75rem" }}
          animation={false}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={2}
          animation={false}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={2}
          animation={false}
          sx={{ mt: ".1rem" }}
        />
      </Grid>
    </Grid>
  );
};

const RecipeSettingsPreview = (props) => {
  const [proposal] = useAccountState((state) => [state.proposal]);
  return (
    <Grid container sx={{ ml: "2rem" }} spacing={1}>
      <Grid item xs={12}>
        <Skeleton
          variant="text"
          width={200}
          sx={{ fontSize: "1.25rem" }}
          animation={false}
        />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Skeleton
            variant="text"
            width={50}
            sx={{ fontSize: ".75rem" }}
            animation={false}
          />
        </Grid>
        {proposal.show_recipe_price && (
          <>
            <Grid item xs={3}>
              <Skeleton
                variant="text"
                width={50}
                sx={{ fontSize: ".75rem" }}
                animation={false}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                variant="text"
                width={50}
                sx={{ fontSize: ".75rem" }}
                animation={false}
              />
            </Grid>
          </>
        )}
      </Grid>
      {proposal.show_recipe_notes && (
        <Grid item xs={12}>
          <Skeleton
            variant="text"
            width={500}
            sx={{ fontSize: ".75rem" }}
            animation={false}
          />
        </Grid>
      )}
      {proposal.show_recipe_imgs && (
        <>
          <Grid item xs="auto">
            <Skeleton
              variant="rectangular"
              width={75}
              height={75}
              animation={false}
            />
          </Grid>
          <Grid item xs="auto">
            <Skeleton
              variant="rectangular"
              width={55}
              height={75}
              animation={false}
            />
          </Grid>
          <Grid item xs="auto">
            <Skeleton
              variant="rectangular"
              width={125}
              height={75}
              animation={false}
            />
          </Grid>
        </>
      )}
      {!props.last && <Divider sx={{ width: "100%", mt: ".25rem" }} />}
    </Grid>
  );
};
