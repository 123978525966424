import {
  addFinalPayment,
  addPaymentApi,
  deletePaymentApi,
  updatePaymentApi,
  updatePaymentScheduleApi,
} from "../api/PaymentsApi";
import { getPaymentDate } from "../utils/payment_utils";
import { orderByField, twoDec, decimalConfig } from "../utils/utils";
import { Decimal } from "decimal.js";

Decimal.set(decimalConfig);

export const paymentScheduleSlice = (set, get) => ({
  payments: [],
  payment_schedule: {},
  addFinalPayment: () => {
    addFinalPayment(get().event.uuid).then((resp) => {
      set({ payment_schedule: resp.data, payments: resp.data.payments });
    });
  },
  addPayment: () => {
    const sorted_payments = get().sortPayments();
    const payment_schedule = get().payment_schedule;
    const last_payment = sorted_payments[sorted_payments.length - 1];
    const due_sign = last_payment
      ? last_payment.due_direction === "BE"
        ? -1
        : 1
      : -1;
    const due_offset = last_payment
      ? last_payment.due_offset + 1 * due_sign
      : 1;
    const name = "Payment " + (sorted_payments.length + 1);
    const data = {
      due_event: "SI",
      payment_schedule: payment_schedule.uuid,
      due_direction: "AF",
      ...last_payment,
      uuid: undefined,
      due_offset: due_offset,
      date: undefined,
      name: name,
      paid: false,
      paid_on: undefined,
      amount_paid: undefined,
      payment_method: undefined,
      payment_intent: undefined,
      last_client_notification: undefined,
      last_florist_notification: undefined,
      amount_paid_currency: undefined,
    };
    addPaymentApi(data).then((resp) => {
      const payments = get().payments;
      const new_payments = [...payments, resp.data];
      set({ payments: new_payments });
    });
  },
  allPaymentsPaid: () => {
    const payments = get().payments;
    let payments_paid = payments.every((payment) => payment.paid);
    return payments_paid && get().payment_schedule.paid;
  },
  deletePayment: (uuid) => {
    const payments = get().payments;
    const new_payments = payments.filter((payment) => payment.uuid !== uuid);
    set({ payments: new_payments });
    deletePaymentApi(uuid).then((resp) => {
      if (!resp.data.success) {
        set({ payments: payments });
      }
    });
  },
  finalPaymentAmount: (ignore_amendments) => {
    const payment_schedule = get().payment_schedule;
    const event_total_price = get().eventPrice(ignore_amendments);
    if (payment_schedule.paid) {
      const amount_paid = Decimal(parseFloat(payment_schedule.amount_paid));
      const percent = Decimal(
        amount_paid.dividedBy(event_total_price).times(100)
      );
      return {
        amount: amount_paid,
        percent: percent.toDecimalPlaces(2),
      };
    } else {
      const payments_amount = get().paymentsTotal(ignore_amendments);
      if (event_total_price.cmp(0) > 0) {
        const percent = event_total_price
          .minus(payments_amount.amount)
          .dividedBy(event_total_price)
          .times(100);
        const amount = event_total_price.minus(payments_amount.amount);
        return {
          amount: amount.cmp(0) < 0 ? Decimal(0) : amount.toDecimalPlaces(2),
          percent: percent.cmp(0) < 0 ? Decimal(0) : percent.toDecimalPlaces(2),
        };
      } else {
        return {
          amount: Decimal(0),
          percent: Decimal(0),
        };
      }
    }
  },
  initializePayments: (data) => set({ payments: data }),
  initializePaymentSchedule: (data) => set({ payment_schedule: data }),
  outstandingBalance: (ignore_amendments) => {
    let amount_paid = get().payments.reduce(
      (partialSum, payment) =>
        partialSum.plus(payment.paid ? parseFloat(payment.amount_paid) : 0),
      Decimal(0)
    );
    let final_payment = get().payment_schedule;
    if (final_payment.paid) {
      amount_paid = amount_paid.plus(parseFloat(final_payment.amount_paid));
    }
    const event_total_price = get().eventPrice(ignore_amendments);
    let outstanding = event_total_price.minus(amount_paid).toDecimalPlaces(2);
    if (outstanding.cmp(0) < 0) {
      return Decimal(0);
    } else {
      return outstanding;
    }
  },
  paymentAmount: (payment, ignore_amendments) => {
    const event_total_price = get().eventPrice(ignore_amendments);
    if (event_total_price == 0) {
      if (payment.is_percentage) {
        return {
          amount: Decimal(0),
          percent: Decimal(parseFloat(payment.amount)).toDecimalPlaces(2),
        };
      } else {
        return {
          amount: Decimal(parseFloat(payment.amount)).toDecimalPlaces(2),
          percent: Decimal(0),
        };
      }
    } else {
      if (payment.paid) {
        return {
          amount: Decimal(
            twoDec(parseFloat(payment.amount_paid))
          ).toDecimalPlaces(2),
          percent: Decimal(
            parseFloat((payment.amount_paid / event_total_price) * 100)
          ).toDecimalPlaces(2),
        };
      } else if (payment.is_percentage) {
        return {
          amount: Decimal(
            parseFloat((event_total_price * payment.amount) / 100)
          ).toDecimalPlaces(2),
          percent: Decimal(parseFloat(payment.amount)).toDecimalPlaces(2),
        };
      } else {
        return {
          amount: Decimal(parseFloat(payment.amount)).toDecimalPlaces(2),
          percent: Decimal(
            parseFloat((payment.amount / event_total_price) * 100)
          ).toDecimalPlaces(2),
        };
      }
    }
  },
  paymentScheduleErrors: (ignore_amendments) => {
    var errors = [];
    let event = get().event;
    let event_date = event.is_template ? undefined : event.date;
    let payment_schedule = get().payment_schedule;
    let signing_date = get().contractSignDate();
    let template = !Object.keys(event).length || event.is_template;
    const payments_total = get().paymentsTotal(ignore_amendments);
    const final_payment_amount = get().finalPaymentAmount(ignore_amendments);
    if (
      payments_total.percent &&
      !template &&
      !Decimal(100).eq(
        payments_total.percent
          .plus(final_payment_amount.percent)
          .toNearest(0.01)
      )
    ) {
      errors.push("Payments percentages must sum to 100%.");
    }

    if (
      template &&
      Decimal(99).cmp(
        get()
          .payments.filter((p) => p.is_percentage)
          .reduce(
            (partialSum, a) => partialSum.plus(parseFloat(a.amount)),
            Decimal(0)
          )
      ) < 0
    ) {
      errors.push(
        "Payments percentages must sum to 99% or less on template events."
      );
    }

    if (!template) {
      const event_total_price = get().eventPrice(ignore_amendments);
      if (
        payments_total.amount &&
        !event_total_price
          .minus(payments_total.amount.plus(final_payment_amount.amount))
          .absoluteValue()
          .equals(0)
      ) {
        errors.push(
          "Payments dollar amounts must sum to the total event price."
        );
      }
    }
    const sorted_payments = get().sortPayments();

    const last_payment_date = sorted_payments[sorted_payments.length - 1]?.date;

    const _signing_date = signing_date ? new Date(signing_date) : new Date();
    var _event_date = event_date;
    if (_event_date) {
      _event_date = new Date(_event_date);
    } else {
      _event_date = new Date();
      _event_date.setFullYear(_event_date.getFullYear() + 1);
    }

    const final_payment_date = getPaymentDate(
      payment_schedule,
      _signing_date,
      _event_date
    );

    if (last_payment_date && final_payment_date - last_payment_date < 0) {
      errors.push("Final payment must be the last payment scheduled to occur.");
    }
    return errors;
  },
  paymentsTotal: (ignore_amendments) => {
    const payment_amounts = get().payments.map((payment) =>
      get().paymentAmount(payment, ignore_amendments)
    );

    const amount = payment_amounts
      .filter((payment_amount) => payment_amount.amount)
      .reduce(
        (partialSum, a) => partialSum.plus(parseFloat(a.amount)),
        Decimal(0)
      );

    const event_price = get().eventPrice(ignore_amendments);

    const percent = amount.dividedBy(event_price).times(100);

    return { amount: amount, percent: percent };
  },
  sortPayments: () => {
    let signing_date = get().contractSignDate();
    let event = get().event;
    let event_date = event.is_template ? undefined : event.date;
    let payments = get().payments;
    const _signing_date = signing_date ? new Date(signing_date) : new Date();
    var _event_date = event_date;
    if (_event_date) {
      _event_date = new Date(_event_date);
    } else {
      _event_date = new Date();
      _event_date.setFullYear(_event_date.getFullYear() + 1);
    }
    const payments_with_date = payments.map((payment) => ({
      ...payment,
      date: getPaymentDate(payment, _signing_date, _event_date),
    }));
    const sorted_payments = payments_with_date.sort(orderByField("date"));
    return sorted_payments;
  },
  updatePayment: (data) => {
    let payments = get().payments;
    let index = payments.findIndex((payment) => payment.uuid === data.uuid);
    let payment = payments[index];
    set({
      payments: [
        ...payments.slice(0, index),
        { ...payment, ...data },
        ...payments.slice(index + 1),
      ],
    });
    updatePaymentApi(data);
  },
  updatePaymentInState: (data) => {
    let payments = get().payments;
    let index = payments.findIndex((payment) => payment.uuid === data.uuid);
    let payment = payments[index];
    set({
      payments: [
        ...payments.slice(0, index),
        { ...payment, ...data },
        ...payments.slice(index + 1),
      ],
    });
  },
  updatePaymentSchedule: (data) => {
    let payment_schedule = get().payment_schedule;
    set({ payment_schedule: { ...payment_schedule, ...data } });
    updatePaymentScheduleApi({ ...data, uuid: payment_schedule.uuid });
  },
  updatePaymentScheduleInState: (data) => {
    let payment_schedule = get().payment_schedule;
    set({ payment_schedule: { ...payment_schedule, ...data } });
  },
  webPaymentsEnabled: () => {
    return get().payment_schedule.enable_web_payments;
  },
});
