import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Grid,
  Typography,
  Divider,
  Tooltip,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useAccountState } from "../state/store";

const AutoEmailSettings = (props) => {
  const [back_office, updateBackOffice] = useAccountState((state) => [
    state.back_office,
    state.updateBackOffice,
  ]);

  const updateSetting = (e) => {
    let data = {
      [e.target.id]: e.target.value,
    };
    updateBackOffice(data);
  };

  return (
    <Grid container justifyContent="center" spacing={2} display="flex">
      <SettingsSection title="Payment Notification Emails">
        <SettingsSwitch
          label="Upcoming Payment: 7 Days"
          tooltip={"Notify clients 7 days before a payment is due."}
          id="auto_email_payment_upcoming_7_days"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsSwitch
          label="Upcoming Payment: 3 Days"
          tooltip={"Notify clients 3 days before a payment is due."}
          id="auto_email_payment_upcoming_3_days"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsSwitch
          label="Payment Due"
          tooltip={"Notify clients on the day a payment is due."}
          id="auto_email_payment_due"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsSwitch
          label="Payment Overdue: 3 Days"
          tooltip={"Notify clients when a payment is 3 days overdue."}
          id="auto_email_payment_overdue_3_days"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsSwitch
          label="Payment Overdue: 7 Days"
          tooltip={"Notify clients when a payment is 7 days overdue."}
          id="auto_email_payment_overdue_7_days"
          back_office={back_office}
          updateSetting={updateSetting}
        />
      </SettingsSection>
    </Grid>
  );
};

const SettingsSection = (props) => {
  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">{props.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ width: "100%", m: "auto", mb: "1rem" }} />
      </Grid>
      {React.Children.map(props.children, (child) => (
        <Grid item xs={12}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

const SettingsSwitch = (props) => {
  const handleChange = (event) => {
    props.updateSetting({
      target: { value: event.target.checked, id: props.id },
    });
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={props.back_office[props.id]}
            onChange={handleChange}
            disabled={props.disabled}
            id={props.id}
          />
        }
        label={
          <Typography>
            {props.label}{" "}
            <Tooltip title={props.tooltip}>
              <HelpOutlineOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default withRouter(AutoEmailSettings);
