import React from "react";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import itemCost, { itemDisplayName } from "../utils/item_utils.js";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Select,
  ButtonGroup,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ItemPriceFields from "./ItemPriceFields.js";
import AddItemDialog from "./AddItemDialog.js";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { ItemIcon } from "./ItemIcon.js";
import { useAccountState } from "../state/store.js";
import { AddInventoryItemDialog } from "./InventoryItems.js";
import { NotInStockButton } from "./Build.js";
import { quantityField, sort_by_order } from "../utils/utils.js";
import { get, result } from "lodash";
import { LoadingButton } from "@mui/lab";

const RecipeItems = (props) => {
  const item_name = props.item_type === "FL" ? "Flower" : "Hard Good";
  const [
    addRecipeItem,
    addRecipeItems,
    event_items,
    handleRecipeItemDragEnd,
    itemsInEvent,
  ] = useAccountState((state) => [
    state.addRecipeItem,
    state.addRecipeItems,
    state.event_items,
    state.handleRecipeItemDragEnd,
    state.itemsInEvent,
  ]);

  const [addingItem, setAddingItem] = React.useState(false);

  const toggleAddingItem = () => {
    setAddingItem(!addingItem);
  };

  const [loadingAddItem, setLoadingAddItem] = React.useState(false);

  const addItems = (items) => {
    setLoadingAddItem(true);
    addRecipeItems(
      props.recipe,
      items.map((item) => item.uuid),
      () => {
        setLoadingAddItem(false);
      }
    );
  };

  const _handleRecipeItemDragEnd = (result) => {
    handleRecipeItemDragEnd(result, props.recipe);
  };

  return (
    <Box>
      <DragDropContext onDragEnd={_handleRecipeItemDragEnd}>
        <Droppable droppableId="recipe-items">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {props.items
                .filter((ri) => ri.item.item_type == props.item_type)
                .sort(sort_by_order)
                .map((item, index) => (
                  <RecipeItem
                    key={item.uuid}
                    item={item}
                    recipe={props.recipe}
                    id={props.id + "-" + item.uuid}
                    index={index}
                    event_item={props.recipe}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Box display={"flex"}>
        <LoadingButton
          onClick={toggleAddingItem}
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<AddOutlinedIcon />}
          sx={{ mr: "auto", ml: "auto", mt: "1rem" }}
          id="add-item-button"
          loading={loadingAddItem}
        >
          {`Add ${item_name}`}
        </LoadingButton>
      </Box>

      {addingItem ? (
        <AddItemDialog
          open={addingItem}
          handleCancel={toggleAddingItem}
          addItems={addItems}
          filter_items={props.items.map((ri) => ri.item)}
          item_type={props.item_type}
          disable_items_in_event={props.recipe.is_template}
        />
      ) : null}
    </Box>
  );
};

const RecipeItem = (props) => {
  const isFlower = () => {
    return props.item.item.item_type === "FL";
  };
  const item_name = isFlower() ? "Flower" : "Hard Good";
  const [
    deleteRecipeItem,
    item_prices,
    formatCurrency,
    getEventItemByUuid,
    getInventoryItemForItem,
    itemLineCost,
    updateItemPrice,
    updateRecipeItem,
    updateRecipeItemOrder,
    user,
  ] = useAccountState((state) => [
    state.deleteRecipeItem,
    state.item_prices,
    state.formatCurrency,
    state.getEventItemByUuid,
    state.getInventoryItemForItem,
    state.itemLineCost,
    state.updateItemPrice,
    state.updateRecipeItem,
    state.updateRecipeItemOrder,
    state.user,
  ]);
  const getItemPrice = () => {
    if (props.recipe.is_template) {
      return props.recipe.template_item_prices.find(
        (tp) => tp.item === props.item.item.uuid
      );
    } else {
      return item_prices[props.item.item.uuid];
    }
  };

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [editing, setEditing] = React.useState(false);

  const setMenuAnchorElTrue = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const unsetMenuAnchorEl = () => {
    setMenuAnchorEl(null);
  };

  const [menu2AnchorEl] = React.useState(null);

  const unsetMenu2AnchorEl = () => {
    setMenuAnchorEl(null);
  };

  const [editingItemCost, setEditingItemCost] = React.useState(false);

  const toggleEditingItemCost = () => {
    setEditingItemCost(!editingItemCost);
  };

  const [quantity, setQuantity] = React.useState(
    parseFloat(props.item.quantity).toFixed(2)
  );

  React.useEffect(() => {
    setQuantity(parseFloat(props.item.quantity).toFixed(2));
  }, [props.item.quantity]);

  const decreaseRecipeItemQuantity = () => {
    if (props.item.quantity > 1) {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: (parseFloat(props.item.quantity) - 1).toFixed(2),
      });
      setQuantity((parseFloat(props.item.quantity) - 1).toFixed(2));
    } else if (props.item.quantity > 0.1) {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: (parseFloat(props.item.quantity) - 0.1).toFixed(2),
      });
      setQuantity((parseFloat(props.item.quantity) - 0.1).toFixed(2));
    }
  };

  const increaseRecipeItemQuantity = () => {
    if (props.item.quantity >= 1) {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: (parseFloat(props.item.quantity) + 1).toFixed(2),
      });
      setQuantity((parseFloat(props.item.quantity) + 1).toFixed(2));
    } else {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: (parseFloat(props.item.quantity) + 0.1).toFixed(2),
      });
      setQuantity((parseFloat(props.item.quantity) + 0.1).toFixed(2));
    }
  };

  const removeItem = () => {
    deleteRecipeItem(props.recipe, props.item.uuid);
    unsetMenuAnchorEl();
  };

  const updateItemCost = (data) => {
    let item_price_data = {
      ...getItemPrice(),
      ...data,
    };
    updateItemPrice(item_price_data, () => {
      setEditingItemCost(false);
      unsetMenuAnchorEl();
    });
  };

  const updateRecipeItemQuantity = (e) => {
    if (e.target.value !== "" && e.target.value != ".") {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: parseFloat(e.target.value).toFixed(2),
      });
    }
  };

  const updateRecipeItemSaleType = (e) => {
    if (e.target.value !== "") {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        item_sale_type: e.target.value,
      });
    }
  };

  const updateRecipeItemSourceType = (e) => {
    if (e.target.value !== "") {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        item_source_type: e.target.value,
      });
    }
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleQuantityBlur = (e) => {
    try {
      if (parseFloat(e.target.value) > 0) {
        updateRecipeItemQuantity(e);
      } else {
        setQuantity(0);
        updateRecipeItemQuantity({ target: { value: 0 } });
      }
    } catch {
      console.log(e.target.value);
    }
  };

  const [addingInventoryItem, setAddingInventoryItem] = React.useState(false);

  return (
    <Draggable
      draggableId={`${props.recipe?.uuid}:${props.item.uuid}`}
      index={props.index}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Grid
            container
            sx={{ mt: ".25rem" }}
            alignItems="center"
            columnSpacing={2}
            justifyContent={"space-between"}
          >
            <Grid item xs="auto" container alignItems={"center"}>
              <Grid item xs="auto">
                <div {...provided.dragHandleProps}>
                  <DragIndicatorIcon
                    sx={{ color: "info.light", mr: ".25rem" }}
                  />
                </div>
              </Grid>
              <Grid item xs="auto">
                <ItemIcon
                  item={props.item.item}
                  width={40}
                  height={40}
                  sx={{
                    display: { xs: "none", sm: "flex" },
                  }}
                />
                <ItemIcon
                  item={props.item.item}
                  width={25}
                  height={25}
                  sx={{
                    display: { xs: "flex", sm: "none" },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item sm={3} xs={9} container alignItems="center">
              <Tooltip title={itemDisplayName(props.item.item)}>
                <Typography sx={{ ml: { xs: ".5rem", sm: "0" } }} noWrap>
                  {itemDisplayName(props.item.item)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item sm={"auto"} xs={4}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-evenly"
                spacing={0.25}
              >
                <Grid item xs={8}>
                  <Box display="flex">
                    <TextField
                      value={quantity}
                      onChange={handleQuantityChange}
                      onBlur={handleQuantityBlur}
                      inputProps={{
                        inputMode: "decimal",
                        sx: { textAlign: "center", p: "0" },
                      }}
                      sx={{ width: "4rem", m: 0 }}
                      id={props.id + "-quantity-input"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <ButtonGroup
                    orientation="vertical"
                    size="small"
                    sx={{
                      width: "20px",
                      "& .MuiButtonGroup-grouped": {
                        minWidth: "20px !important",
                      },
                    }}
                  >
                    <Button
                      color="info"
                      onClick={increaseRecipeItemQuantity}
                      sx={{
                        p: 0,
                        lineHeight: "inherit",
                        minWidth: "inherit",
                        fontSize: "10px",
                      }}
                      id={props.id + "-increase-quanitity-button"}
                    >
                      +
                    </Button>
                    <Button
                      color="info"
                      onClick={decreaseRecipeItemQuantity}
                      sx={{
                        p: 0,
                        lineHeight: "inherit",
                        minWidth: "inherit",
                        fontSize: "10px",
                      }}
                      id={props.id + "-increase-quanitity-button"}
                    >
                      -
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={2}
              xs={5}
              sx={{ display: { sm: "flex", xs: "none" } }}
            >
              <Typography sx={{ textAlign: "center", ml: "auto", mr: "auto" }}>
                {formatCurrency(itemCost(getItemPrice()))}
                <IconButton
                  onClick={toggleEditingItemCost}
                  size="small"
                  sx={{ mb: ".25rem" }}
                  id={props.id + "-edit-cost-button"}
                >
                  <EditOutlinedIcon fontSize="inherit" />
                </IconButton>
                {itemCost(getItemPrice()).eq(0) ? (
                  <Tooltip
                    title={`No price is set for this ${item_name.toLocaleLowerCase()}. Click the edit icon to set a price.`}
                  >
                    <WarningAmberRoundedIcon
                      fontSize="inherit"
                      sx={{ color: "error.main" }}
                    />
                  </Tooltip>
                ) : null}
              </Typography>

              {editingItemCost ? (
                <EditItemCostDialog
                  item={props.item}
                  item_price={getItemPrice()}
                  open={editingItemCost}
                  handleCancel={toggleEditingItemCost}
                  handleSubmit={updateItemCost}
                />
              ) : null}
            </Grid>
            {!isFlower() && (
              <>
                <Grid item sm={1} xs={"auto"} sx={{ display: "flex" }}>
                  <Select
                    name="type"
                    label="Type"
                    id={props.id + "-sale-type-select"}
                    value={props.item.item_sale_type}
                    renderValue={(value) => value}
                    onChange={updateRecipeItemSaleType}
                    variant="standard"
                    sx={{ fontSize: ".75rem", ml: "auto", mr: "auto" }}
                  >
                    <MenuItem value="RE" sx={{ color: "secondary.main" }}>
                      Rent
                    </MenuItem>
                    <MenuItem value="SE" sx={{ color: "secondary.main" }}>
                      Sell
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item sm={1} xs={"auto"} sx={{ display: "flex" }}>
                  <Select
                    name="type"
                    label="Type"
                    id={props.id + "-source-type-select"}
                    value={props.item.item_source_type}
                    renderValue={(value) => value}
                    onChange={updateRecipeItemSourceType}
                    variant="standard"
                    sx={{ fontSize: ".75rem", ml: "auto", mr: "auto" }}
                  >
                    <MenuItem value="IN" sx={{ color: "secondary.main" }}>
                      Inventory
                    </MenuItem>
                    <MenuItem value="PU" sx={{ color: "secondary.main" }}>
                      Purchase
                    </MenuItem>
                    <MenuItem value="RE" sx={{ color: "secondary.main" }}>
                      Rent
                    </MenuItem>
                  </Select>
                </Grid>{" "}
              </>
            )}
            <Grid
              item
              sm={2}
              xs={"auto"}
              sx={{ display: { sm: "none", xs: "flex" } }}
            >
              <Typography sx={{ textAlign: "center" }}>
                <IconButton
                  onClick={setMenuAnchorElTrue}
                  id={props.id + "-menu-button"}
                >
                  <MoreVertOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Typography>
              <Menu
                sx={{ mt: "25px" }}
                id="menu-appbar"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menuAnchorEl)}
                onClose={unsetMenuAnchorEl}
                onClick={unsetMenuAnchorEl}
              >
                <MenuItem onClick={removeItem}>
                  <DeleteOutlineOutlinedIcon size="small" />
                  <Typography textAlign="center" alignItems="center">
                    {" Delete"}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={toggleEditingItemCost}>
                  <EditOutlinedIcon size="small" />
                  <Typography textAlign="center" alignItems="center">
                    {" Edit Cost"}
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid
              item
              sm={1}
              xs={4}
              sx={{ display: { sm: "flex", xs: "none" } }}
            >
              {!isFlower() && props.item.item_source_type === "IN" ? (
                <>
                  {!getInventoryItemForItem(props.item.item) &&
                    user.inventory_enabled &&
                    !props.event_item.is_template && (
                      <>
                        <Tooltip title="Item not in inventory. Click to add it.">
                          <IconButton
                            onClick={() => setAddingInventoryItem(true)}
                          >
                            <WarningAmberRoundedIcon />
                          </IconButton>
                        </Tooltip>
                        <AddInventoryItemDialog
                          open={addingInventoryItem}
                          handleCancel={() => setAddingInventoryItem(false)}
                          item={props.item.item}
                        />
                      </>
                    )}
                  {/* TODO: fix this. This warning starts to show up if you have things for sale, and have sold them, */}
                  {getInventoryItemForItem(props.item.item) &&
                    user.inventory_enabled &&
                    !props.event_item.is_template &&
                    getInventoryItemForItem(props.item.item).quantity_total <
                      props.item.quantity * quantityField(props.recipe) && (
                      <>
                        <NotInStockButton
                          inventory_item={getInventoryItemForItem(
                            props.item.item
                          )}
                          num_additional_needed={
                            props.item.quantity * quantityField(props.recipe) -
                            getInventoryItemForItem(props.item.item)
                              .quantity_total
                          }
                        />
                      </>
                    )}
                </>
              ) : (
                <Typography
                  sx={{ textAlign: "center", ml: "auto", mr: "auto" }}
                >
                  {formatCurrency(
                    itemLineCost(
                      props.item,
                      undefined,
                      props.recipe.template_item_prices
                    )
                  )}
                </Typography>
              )}
            </Grid>
            {isFlower() ? (
              <Grid
                item
                sm={2}
                xs={6}
                sx={{ display: { sm: "flex", xs: "none" } }}
                container
              >
                <Typography
                  sx={{
                    textAlign: "right",
                    ml: "auto",
                    // mr: "auto",
                    display: "flex",
                  }}
                >
                  <IconButton
                    onClick={removeItem}
                    size="small"
                    id={props.id + "-delete-button"}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                sm={1}
                xs={1}
                sx={{ display: { sm: "flex", xs: "none" } }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  <IconButton
                    onClick={setMenuAnchorElTrue}
                    id={props.id + "-more-button"}
                  >
                    <MoreVertOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
                <Menu
                  sx={{ mt: "25px" }}
                  id="menu-appbar"
                  anchorEl={menu2AnchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(menu2AnchorEl)}
                  onClose={unsetMenu2AnchorEl}
                  onClick={unsetMenu2AnchorEl}
                >
                  <MenuItem
                    onClick={removeItem}
                    id={props.id + "-delete-button"}
                  >
                    <DeleteOutlineOutlinedIcon size="small" />
                    <Typography textAlign="center" alignItems="center">
                      {" Delete"}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Draggable>
  );
};

export const EditItemCostDialog = (props) => {
  const item_name = props.item.item.item_type === "FL" ? "Flower" : "Hard Good";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const formik = useFormik({
    initialValues: {
      group_name: props.item_price.group_name,
      individual_per_group: props.item_price.individual_per_group,
      price_per_group: props.item_price.price_per_group,
      minimum_group_quantity: props.item_price.minimum_group_quantity,
      online_only: false,
      vendor_location: props.item_price.vendor_location,
      update_all_events: true,
    },
    onSubmit: (values) => {
      props.handleCancel();
      props.handleSubmit(values);
      formik.resetForm();
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      onClose={props.handleCancel}
      open={props.open}
      fullScreen={fullScreen}
    >
      <Box sx={fullScreen ? null : { width: "350px" }}>
        <DialogTitle style={{ textAlign: "center" }}>
          Edit Cost of {itemDisplayName(props.item.item)}
        </DialogTitle>
        <DialogContent>
          <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
            <Box sx={{ width: "100%" }}>
              <ItemPriceFields
                item={props.item}
                item_price={props.item_price}
                formik={formik}
                updatingVendor={props.updatingVendor}
              />
              {!props.item_price.event_item &&
                !props.disableUpdateAllEvents && (
                  <Tooltip
                    title={`Check this box to update the cost of this ${item_name.toLocaleLowerCase()} for all active events. Uncheck this box to update the cost for this event only.`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={formik.values.update_all_events}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "update_all_events",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Update For All Events"
                    />
                  </Tooltip>
                )}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleCancel}
            size="small"
            sx={{ ml: "auto" }}
            variant="outlined"
            color="info"
            id="cancel-edit-item-cost-button"
          >
            Discard
          </Button>
          <Button
            onClick={formik.handleSubmit}
            size="small"
            variant="contained"
            color="secondary"
            sx={{ ml: "auto" }}
            id="submit-edit-item-cost-button"
          >
            Update
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default withRouter(RecipeItems);
