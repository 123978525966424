import { format } from "date-fns";
import { Decimal } from "decimal.js";
import { twoDec } from "./utils";

export const decimalConfig = {
  precision: 20, // Set a higher precision. It should be at least number of significant digits you expect in calculations.
  rounding: Decimal.ROUND_HALF_UP, // This is standard for financial and monetary calculations.
};

Decimal.set(decimalConfig);

export const revenueGoal = (goal) => {
  return twoDec(
    (parseFloat(goal.profit) + parseFloat(goal.overhead)) /
      (1 - parseFloat(goal.profit_margin) / 100),
  );
};

export const revenuePerEventGoal = (goal) => {
  let revenue = revenueGoal(goal);
  return twoDec(revenue / parseFloat(goal.event_number));
};
