import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Grid,
  TextField,
  Typography,
  Divider,
  Tooltip,
  InputAdornment,
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { eventLocked } from "../utils/event_utils";
import { useAccountState } from "../state/store";
import { ColorPickerDialog } from "./Proposal";
import { formatStringForId } from "../utils/utils";

const BackOfficeSettings = (props) => {
  const [back_office, updateBackOffice] = useAccountState((state) => [
    state.back_office,
    state.updateBackOffice,
  ]);

  const updateSetting = (e) => {
    let value = e.target.value;
    if (value === "") {
      value = 0;
    }
    let data = {
      [e.target.id]: value,
    };
    updateBackOffice(data);
  };

  return (
    <Grid container justifyContent="center" spacing={2} display="flex">
      <SettingsSection title="Design Fee">
        <SettingsField
          label="Design Fee (%)"
          tooltip={
            "A percentage fee applied to the recipe cost included in the suggested recipe price."
          }
          id="design_fee_percent"
          back_office={back_office}
          updateSetting={updateSetting}
        />
      </SettingsSection>
      <SettingsSection title="Multipliers">
        <SettingsField
          label="Flower Cost"
          tooltip={
            "A multiplier on the cost of flowers included in the suggested recipe price"
          }
          id="flower_cost_multiplier"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsField
          label="Hard Good Rental"
          tooltip={
            "A multiplier on the cost of hard goods rented to clients included in the suggested recipe price"
          }
          id="material_rental_cost_multiplier"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsField
          label="Hard Good Sale"
          tooltip={
            "A multiplier on the cost of hard goods sold to clients included in the suggested recipe price"
          }
          id="material_sale_cost_multiplier"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsField
          label="In Studio Labor"
          tooltip={
            "A multiplier on the cost of in studio labor included in the suggested recipe price"
          }
          id="build_labor_cost_multiplier"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsField
          label="On Site Labor"
          tooltip={
            "A multiplier on the cost of on site labor included in the suggested recipe price"
          }
          id="install_labor_cost_multiplier"
          back_office={back_office}
          updateSetting={updateSetting}
        />
      </SettingsSection>
      <SettingsSection title="Labor Rates">
        <SettingsField
          label="In Studio Labor Hourly Rate"
          tooltip={"Hourly in studio labor cost."}
          id="build_labor_cost_hourly"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsField
          label="On Site Labor Hourly Rate"
          tooltip={"Hourly in on site labor cost."}
          id="install_labor_cost_hourly"
          back_office={back_office}
          updateSetting={updateSetting}
        />
      </SettingsSection>
      <SettingsSection title="Overhead">
        <SettingsSwitch
          label="Account For FlowerBuddy Fee"
          tooltip={
            "When enabled, the FlowerBuddy fee is included in the suggested price of recipes and other costs."
          }
          id="price_in_flowerbuddy_fee"
          back_office={back_office}
          updateSetting={updateSetting}
        />
        <SettingsSwitch
          label="Account For Stripe Fee"
          tooltip={
            "When enabled, the Stripe fee is included in the suggested price of recipes and other costs."
          }
          id="price_in_stripe_fee"
          back_office={back_office}
          updateSetting={updateSetting}
        />
      </SettingsSection>
      <SettingsSection title="Calculation Settings">
        <SettingsSelect
          label="Round Suggested Prices"
          tooltip={
            "Round calculated suggested prices to nearest figure (e.g. nearest whole dollar, nearest 5 dollar increment, etc.)"
          }
          id="round_suggested_prices"
          back_office={back_office}
          updateSetting={updateSetting}
          options={[
            { value: 0, label: "Do Not Round" },
            { value: 1, label: "Nearest Dollar" },
            { value: 5, label: "Nearest 5 Dollars" },
            { value: 10, label: "Nearest 10 Dollars" },
          ]}
        />
      </SettingsSection>
    </Grid>
  );
};

const SettingsSection = (props) => {
  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      id={formatStringForId(props.title + "-grid")}
    >
      <Grid item xs={12}>
        <Typography variant="h6">{props.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ width: "100%", m: "auto", mb: "1rem" }} />
      </Grid>
      {React.Children.map(props.children, (child) => (
        <Grid item xs={12}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export const BuildPDFSettings = (props) => {
  const [back_office, updateBackOffice] = useAccountState((state) => [
    state.back_office,
    state.updateBackOffice,
  ]);
  const updateSetting = (e) => {
    let data = {
      [e.target.id]: e.target.value,
    };
    updateBackOffice(data);
  };
  const [updatingColor, setUpdatingColor] = React.useState(false);
  const updateColor = (color) => {
    setUpdatingColor(false);
    let data = {
      build_pdf_color: color,
    };
    updateBackOffice(data);
  };
  return (
    <>
      <SettingsSwitch
        label="Show Recipe Images"
        tooltip={
          "When enabled, images from each recipes 'Inspiration Photos' section will be displayed on the build instructions."
        }
        id="build_pdf_recipe_images"
        back_office={back_office}
        updateSetting={updateSetting}
      />
      <SettingsSwitch
        label="Show Stem and Hard Good Images"
        tooltip={
          "When enabled, images for each stem and hard good within a recipe will be shown on the build instructions."
        }
        id="build_pdf_item_images"
        back_office={back_office}
        updateSetting={updateSetting}
      />
      <SettingsSwitch
        label="Show Recipe Notes"
        tooltip={
          "When enabled, proposal notes and florist notes will be displayed for each recipe on the build instructions."
        }
        id="build_pdf_recipe_notes"
        back_office={back_office}
        updateSetting={updateSetting}
      />
      <SettingsSwitch
        label="Show Recipe Prices"
        tooltip={
          "When enabled, recipe prices will be displayed on the build instructions."
        }
        id="build_pdf_prices"
        back_office={back_office}
        updateSetting={updateSetting}
      />
      <Button
        onClick={() => setUpdatingColor(true)}
        variant="contained"
        // sx={{ m: "auto" }}
      >
        Accent Color:{" "}
        <Box
          sx={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            mr: ".25rem",
            ml: ".25rem",
            backgroundColor: back_office.build_pdf_color,
          }}
        ></Box>
        {back_office.build_pdf_color}{" "}
        <Tooltip
          title={
            "This can be helpful to color code events when dealing with multiple sets of build instructions. Set this to White #FFFFFF for less ink."
          }
        >
          <HelpOutlineOutlinedIcon fontSize="inherit" />
        </Tooltip>
      </Button>
      <ColorPickerDialog
        open={updatingColor}
        handleCancel={() => setUpdatingColor(false)}
        handleSubmit={updateColor}
      />
    </>
  );
};

const SettingsField = (props) => {
  return (
    <TextField
      label={props.label}
      id={props.id}
      value={props.back_office[props.id] || 0}
      onChange={props.updateSetting}
      variant="filled"
      fullWidth
      disabled={props.disabled}
      InputProps={
        props.tooltip
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={props.tooltip}>
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
};

const SettingsSwitch = (props) => {
  const handleChange = (event) => {
    props.updateSetting({
      target: { value: event.target.checked, id: props.id },
    });
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={props.back_office[props.id]}
            onChange={handleChange}
            disabled={props.disabled}
            id={props.id}
          />
        }
        label={
          <Typography>
            {props.label}{" "}
            <Tooltip title={props.tooltip}>
              <HelpOutlineOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </Typography>
        }
      />
    </FormGroup>
  );
};

const SettingsSelect = (props) => {
  const handleChange = (event) => {
    props.updateSetting({
      target: { value: event.target.value, id: props.id },
    });
  };
  return (
    <Grid container alignItems="center">
      <Grid item xs={11}>
        <FormControl fullWidth>
          <InputLabel id={props.id}>{props.label}</InputLabel>
          <Select
            labelId={props.id}
            id={props.id}
            value={props.back_office[props.id]}
            label={props.label}
            onChange={handleChange}
          >
            {props.options.map((option, ind) => (
              <MenuItem value={option.value} key={ind}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs="auto">
        <Tooltip title={props.tooltip}>
          <HelpOutlineOutlinedIcon color="info" />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default withRouter(BackOfficeSettings);
