import { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  Stack,
} from "@mui/material";
import CroppedImg from "../CroppedImg";
import { useAccountState } from "../../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { dateField } from "../../utils/utils";
import { eventDateFormatted } from "../../utils/event_utils";
import { DefaultStyles } from "../../utils/proposal_utils";
import { FileDropZone } from "../FileUploader";

export const TitlePage1 = (props) => {
  const [
    back_office,
    event,
    proposal,
    updateProposalSection,
    updateProposalSectionStyle,
  ] = useAccountState((state) => [
    state.back_office,
    state.event,
    state.proposal,
    state.updateProposalSection,
    state.updateProposalSectionStyle,
  ]);

  const [uploadingFile, setUploadingFile] = useState(false);
  const uploadImage = (cropped_images) => {
    updateProposalSection({
      uuid: props.proposal_section.uuid,
      image: cropped_images[0],
    });
  };

  const removeImage = () => {
    updateProposalSection({ uuid: props.proposal_section.uuid, image: null });
  };

  const fontColor =
    props.proposal_section.style?.font_color || DefaultStyles.fontColor;
  const fontFamily =
    props.proposal_section.style?.font || DefaultStyles.fontFamily;
  const dateFormat =
    props.proposal_section.style?.date_format || DefaultStyles.dateFormat;

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} display="flex">
        {props.proposal_section?.image && (
          <CroppedImg
            img={props.proposal_section.image}
            marginLeft="auto"
            marginRight="auto"
            marginBottom="2rem"
            marginTop="2rem"
            maxWidth="50%"
            maxHeight="200px"
          />
        )}
        {!props.proposal_section?.image && (
          <FileDropZone
            handleSubmit={uploadImage}
            maxSelections={1}
            forceRecrop
            sx={{ m: "auto", mt: "2rem" }}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        container
        justifyContent={"center"}
        spacing={1}
      >
        {props.proposal_section.image && (
          <Grid item xs={"auto"}>
            <Button
              sx={{ m: "auto" }}
              onClick={removeImage}
              id="remove-logo-button"
              variant="contained"
            >
              Remove Image
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontFamily: fontFamily,
            color: fontColor,
            mt: "1rem",
          }}
        >
          {event.name ? event.name : "Event Name"}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontFamily: fontFamily,
            color: fontColor,
          }}
        >
          {eventDateFormatted(
            dateField(event, false) ? dateField(event, false) : new Date(),
            back_office.timezone,
            dateFormat
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <FormControl sx={{ m: "auto", mt: "1rem" }}>
          <InputLabel>Date Format</InputLabel>
          <Select
            value={dateFormat}
            label="Date Format"
            onChange={(e) =>
              updateProposalSectionStyle(props.proposal_section.uuid, {
                date_format: e.target.value,
              })
            }
            id="date_format"
          >
            <MenuItem value={"M/d/yyyy"}>
              {eventDateFormatted(
                dateField(event, false) ? dateField(event, false) : new Date(),
                back_office.timezone,
                "M/d/yyyy"
              )}
            </MenuItem>
            <MenuItem value={"dd/MM/yyyy"}>
              {eventDateFormatted(
                dateField(event, false) ? dateField(event, false) : new Date(),
                back_office.timezone,
                "dd/MM/yyyy"
              )}
            </MenuItem>
            <MenuItem value={"PPPP"}>
              {eventDateFormatted(
                dateField(event, false) ? dateField(event, false) : new Date(),
                back_office.timezone,
                "PPPP"
              )}
            </MenuItem>
            <MenuItem value={"PPP"}>
              {eventDateFormatted(
                dateField(event, false) ? dateField(event, false) : new Date(),
                back_office.timezone,
                "PPP"
              )}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const TitlePage1Preview = (props) => {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={50}
            height={30}
            sx={{ mt: 1.5 }}
            animation={false}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={40}
            height={5}
            sx={{ mt: 1 }}
            animation={false}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton
            variant="rounded"
            width={20}
            height={5}
            sx={{ mt: 0.5 }}
            animation={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TitlePage1View = (props) => {
  const [back_office, event, eventDate, proposal, updateProposalSection] =
    useAccountState((state) => [
      state.back_office,
      state.event,
      state.eventDate,
      state.proposal,
      state.updateProposalSection,
    ]);
  const fontColor =
    props.proposal_section.style?.font_color || DefaultStyles.fontColor;
  const fontFamily =
    props.proposal_section.style?.font || DefaultStyles.fontFamily;
  const dateFormat =
    props.proposal_section.style?.date_format || DefaultStyles.dateFormat;
  return (
    <Grid item xs={12} container>
      <Grid item xs={12} display="flex">
        {props.proposal_section.image && (
          <CroppedImg
            img={props.proposal_section.image}
            marginLeft="auto"
            marginRight="auto"
            marginBottom="2rem"
            marginTop="2rem"
            maxWidth="50%"
            maxHeight="200px"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontFamily: fontFamily,
            color: fontColor,
            mt: "1rem",
          }}
        >
          {event.name ? event.name : "Event Name"}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontFamily: fontFamily,
            color: fontColor,
          }}
        >
          {dateField(event, !proposal.amendments_published)
            ? eventDateFormatted(
                dateField(event, !proposal.amendments_published),
                back_office.timezone,
                dateFormat
              )
            : eventDateFormatted(new Date(), back_office.timezone, dateFormat)}
        </Typography>
      </Grid>
    </Grid>
  );
};
