export const documentIsSigned = (document) => {
  // return !document.signers.some((signer) => !signer.signed);
  return document.signers
    .filter((signer) => signer.is_client)
    .some((signer) => signer.signed);
};

export const unsignedDocumentExists = (documents) => {
  return documents?.some((document) => !documentIsSigned(document));
};
