import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
  Snackbar,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Logo } from "./Navbar";
import ReCAPTCHA from "react-google-recaptcha";
import { useAccountState } from "../state/store";

const SignupForm = (props) => {
  const [currencies, getCurrencies] = useAccountState((state) => [
    state.currencies,
    state.getCurrencies,
  ]);

  React.useEffect(() => {
    getCurrencies();
  }, []);

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .min(2, "First name must be at least 2 characters.")
      .max(50, "First name cannot contain more than 50 characters")
      .required("Must provie a first name."),
    last_name: Yup.string()
      .min(2, "Last name must be at least 2 characters.")
      .max(50, "Last name cannot contain more than 50 characters")
      .required("Must provie a last name."),
    company: Yup.string()
      .min(2, "Last name must be at least 2 characters.")
      .max(50, "Last name cannot contain more than 50 characters")
      .required("Must provie a company name."),
    email: Yup.string()
      .email()
      .min(2, "email must be at least 2 characters.")
      .max(100, "email cannot contain more than 100 characters")
      .required("Must provie a email."),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters.")
      .max(50, "Password cannot contain more than 50 characters")
      .required("Must provide a password"),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords do not match"
    ),
    source: Yup.string(),
    other_source: Yup.string(),
    // currency: Yup.string().required("Must select a currency"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      currency: { code: "USD", name: "US Dollar" },
      signup_referral_code: props.referral_code
        ? props.referral_code
        : undefined,
      source: "",
      other_source: "",
    },
    onSubmit: (values) => {
      var values_with_captcha = {
        ...values,
        captcha: captcha,
        currency: values.currency.code,
      };
      props.handleSignup(values_with_captcha);
    },
  });

  const [captcha, setCaptcha] = React.useState(undefined);

  return (
    <Grid container width="325px" sx={{ m: "auto" }}>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs="auto">
          <Logo withText imgStyle={{ maxHeight: "45px" }} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            width: "90%",
            padding: "1rem",
          }}
        >
          <form display="contents" onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormError>
                  {props.username_already_exists
                    ? "Email already exists"
                    : null}
                </FormError>
                <SignupLoginField name="email" label="Email" formik={formik} />
              </Grid>
              <Grid item xs={12}>
                <SignupLoginField
                  name="password"
                  type="password"
                  label="Password"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <SignupLoginField
                  name="passwordConfirmation"
                  type="password"
                  label="Repeat Password"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <SignupLoginField
                  name="first_name"
                  label="First Name"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <SignupLoginField
                  name="last_name"
                  label="Last Name"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <SignupLoginField
                  name="company"
                  label="Company"
                  formik={formik}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  label="Currency"
                  options={currencies}
                  getOptionLabel={(option) =>
                    option.code + " (" + option.name + ")"
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.code === value.code
                  }
                  value={formik.values.currency}
                  onChange={(e, val) => {
                    formik.setFieldValue("currency", val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Currency"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ReferralCodeField
                  name="signup_referral_code"
                  label="Referral Code"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="source-label">
                    {"How did you hear about us?"}
                  </InputLabel>
                  <Select
                    id="source"
                    labelId="source-label"
                    label="How did you hear about us?"
                    variant="standard"
                    value={formik.values.source}
                    onChange={(e) => {
                      formik.setFieldValue("source", e.target.value);
                    }}
                  >
                    <MenuItem value="Instagram">Instagram</MenuItem>
                    <MenuItem value="Facebook">Facebook</MenuItem>
                    <MenuItem value="Google">Google</MenuItem>
                    <MenuItem value="Wedding MBA">Wedding MBA</MenuItem>
                    <MenuItem value="SAF">SAF</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {formik.values.source === "Other" ? (
                <Grid item xs={12}>
                  <SignupLoginField
                    name="other_source"
                    label="Other (Please specify)"
                    formik={formik}
                    maxLength={25}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    onChange={setCaptcha}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Typography color="error">{props.signup_error}</Typography>
              </Grid>
              <Grid item xs={12}>
                {!props.loading ? (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "100%",
                    }}
                    color="secondary"
                    disabled={captcha ? false : true}
                  >
                    Start Free Trial
                  </Button>
                ) : (
                  <Box display="flex">
                    <ProgressIndicator />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Grid item xs="auto">
                  <Button
                    // onClick={props.toggle_login}
                    href="/login"
                    size="small"
                    color="info"
                  >
                    Already have an account?
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

const ProgressIndicator = () => {
  const [progress, setProgress] = React.useState(15);

  React.useEffect(() => {
    const totalDuration = 12000; // 20 seconds in milliseconds
    const interval = 1500; // Update every 100 milliseconds
    const step = (interval / totalDuration) * 100; // Calculate the progress step

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          return 100;
        }
        return prevProgress + step;
      });
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <CircularProgress
      variant="determinate"
      value={progress}
      sx={{ m: "auto" }}
    />
  );
};

export const SignupLoginField = (props) => {
  return (
    <TextField
      {...props}
      id={props.name}
      value={props.formik.values[props.name]}
      onChange={props.formik.handleChange}
      error={
        props.formik.touched[props.name] &&
        Boolean(props.formik.errors[props.name])
      }
      helperText={
        props.formik.touched[props.name] && props.formik.errors[props.name]
      }
      variant="standard"
      fullWidth
      inputProps={{ maxLength: props.maxLength }}
    />
  );
};

export const ReferralCodeField = (props) => {
  const [ioDetected, setIoDetected] = React.useState(false);
  const updateReferralCode = (e) => {
    var inputValue = e.target.value;
    if (inputValue.length === 5 && !inputValue.endsWith("-")) {
      inputValue = inputValue.slice(0, 4) + "-" + inputValue.slice(4);
    }
    inputValue = inputValue.replace(/[^a-zA-Z0-9-]/g, "");
    inputValue = inputValue.toUpperCase();
    // if (inputValue.includes("O") || inputValue.includes("I")) {
    //   setIoDetected(true);
    // }
    // inputValue = inputValue.replace(/[OI]/g, "");

    props.formik.setFieldValue("signup_referral_code", inputValue);
  };
  return (
    <>
      <TextField
        {...props}
        id={props.name}
        value={props.formik.values[props.name]}
        onChange={updateReferralCode}
        error={
          props.formik.touched[props.name] &&
          Boolean(props.formik.errors[props.name])
        }
        helperText={
          props.formik.touched[props.name] && props.formik.errors[props.name]
        }
        variant="standard"
        fullWidth
      />
      <Snackbar
        open={ioDetected}
        autoHideDuration={3000}
        onClose={() => {
          setIoDetected(false);
        }}
        message="Referral codes do not contain the letters 'O' or 'I'."
      />
    </>
  );
};

export const FormError = (props) => {
  return <Typography sx={{ color: "error.main" }}>{props.children}</Typography>;
};

export default SignupForm;

SignupForm.propTypes = {
  handleSignup: PropTypes.func.isRequired,
};
