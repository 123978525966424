import { unsecureAPI } from "../api";

export const verifyUserHash = (hash) => {
  let data = { hash: hash };
  return unsecureAPI.post("api/verify_user_hash/", JSON.stringify(data), {
    headers: {
      Authorization: localStorage.getItem("token")
        ? `JWT ${localStorage.getItem("token")}`
        : undefined,
      "Content-Type": "application/json",
    },
  });
};
